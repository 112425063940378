import React, { Component } from 'react';
import avatar from './assets/images/user-profile.png';
import Iframe from 'react-iframe';
import Person1 from '../../images/person1.jpeg';
import Person2 from '../../images/person2.jpeg';
import Person3 from '../../images/person3.jpeg';
import Person4 from '../../images/person4.jpeg';
import Business1 from '../../images/business1.jpeg';
import Business2 from '../../images/business2.jpeg';
import Business3 from '../../images/business3.jpeg';
import Profile from '../../images/profile-business.jpeg';
import Avatar from '@material-ui/core/Avatar';
import BusinessProfile from '../../images/businessacc1.jpeg';
import BusinessImage1 from '../../images/businessimg1.jpg';
import BusinessImage2 from '../../images/businessimg2.jpg';
import BusinessImage3 from '../../images/businessimg3.jpg';
import { connect } from 'react-redux';
import {
	getAllUsers,
	getUser,
	removeUpdate,
	updateUserLocation,
} from '../../store/actions/userActions';
import { useScrollTrigger } from '@material-ui/core';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Row,
	Input,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import { Marker } from 'google-maps-react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Geocode from 'react-geocode';
Geocode.setApiKey('AIzaSyDns9eCy_4Zge-qYP3Ycnp7qtLw_QsPNIE');
class ProfileDetail extends Component {
	Persons = [Person1, Person2, Person3, Person4];
	Business = [Business1, Business2, Business3];
	BusinessImgs = [BusinessImage1, BusinessImage2, BusinessImage3];
	componentDidMount() {
		var { user } = this.props;
		this.props.getUser(user.id);
		this.props.getAllUsers();
		this.props.removeUpdate();
	}
	state = {
		photoModal: '',
		viewBlocked: false,
		viewSix: true,
		locationModal: false,
		newLocation: '',
		newLat: '',
		newLng: '',
	};
	photoToggle = id => {
		if (this.state.photoModal != id) {
			this.setState({ photoModal: id });
		} else {
			this.setState({ photoModal: '' });
		}
	};
	toggleLocationModal = () => {
		this.setState({ locationModal: !this.state.locationModal });
	};
	handleChange = address => {
		// console.log('address', address);
		this.setState({ newLocation: address });
	};
	handleSelect = async address => {
		console.log('address', address);
		geocodeByAddress(address)
			.then(results => getLatLng(results[0]))
			.then(latLng =>
				// console.log("lat", latLng)
				this.setState({
					newLat: latLng.lat,
					newLng: latLng.lng,
					// markerPosition: { lat: latLng.lat, lng: latLng.lng },
					newLocation: address,
				})
			)
			.catch(error => console.error('Error', error));
	};
	render() {
		var { user, users } = this.props;
		var type = user && user.type;
		var is_business =
			this.props.history.location.state &&
			this.props.history.location.state.is_business
				? this.props.history.location.state.is_business
				: false;
		if (
			this.props.history.location.state &&
			this.props.history.location.state.is_own &&
			type == 'business'
		) {
			is_business = true;
		}
		console.log(is_business);
		return (
			<>
				<div class='main-content mt-3 minHeight'>
					{(type == 'individual' || type == 'business') &&
					!is_business ? (
						<section className='container'>
							<div className='row'>
								<div className='col-md-6 profile-section'>
									<div className='description'>
										<div className='row'>
											<div className='col-md-4'>
												<div class='circular'>
													<img
														src={
															user &&
															user.profile_pic
														}
														alt='sunil'
														style={{
															width: '150px',
															height: '150px',
															borderRadius: '50%',
														}}
													/>
												</div>
											</div>
											<div className='col-md-8'>
												<div className='general-info'>
													<h2 className='text-capitalize'>
														{user.first_name}&nbsp;
														{user.last_name}
													</h2>
													<ul
														style={{
															listStyle: 'none',
															margin: '0',
															padding: '0',
														}}
													>
														{/* <li>
															<i class='fas fa-map-marker-alt mr-2'></i>
															{
																user.current_location
															}
														</li> */}
														<li>
															<i class='far fa-flag mr-2'></i>
															{user &&
																user.district.map(
																	(
																		lang,
																		index
																	) => {
																		if (
																			user
																				.district
																				.length -
																				1 ===
																			index
																		) {
																			return (
																				lang.title +
																				'.'
																			);
																		}
																		return (
																			lang.title +
																			', '
																		);
																	}
																)}
														</li>
														<li>
															<i class='fa fa-language mr-2'></i>
															Knows{' '}
															{user.languages.map(
																(
																	lang,
																	index
																) => {
																	if (
																		user
																			.languages
																			.length -
																			1 ===
																		index
																	) {
																		return (
																			lang.title +
																			'.'
																		);
																	}
																	return (
																		lang.title +
																		', '
																	);
																}
															)}
														</li>
														<li>
															<i class='fas fa-user mr-2'></i>
															is{' '}
															{user.status
																? user.status
																: 'Not Mentioned'}
														</li>
														<li>
															<i class='fas fa-binoculars mr-2'></i>
															{user &&
																user.looking_for.map(
																	(
																		look,
																		index
																	) => {
																		if (
																			user
																				.looking_for
																				.length -
																				1 ===
																			index
																		) {
																			return (
																				look.title +
																				'.'
																			);
																		}
																		return (
																			look.title +
																			', '
																		);
																	}
																)}{' '}
														</li>
														<li>
															<i class='fas fa-globe-americas mr-2'></i>
															{user &&
																user.countries.map(
																	(
																		look,
																		index
																	) => {
																		if (
																			user
																				.countries
																				.length -
																				1 ===
																			index
																		) {
																			return (
																				look.title +
																				'.'
																			);
																		}
																		return (
																			look.title +
																			', '
																		);
																	}
																)}
														</li>
														{/* <li>
                              <i class="fa fa-briefcase mr-2"></i>Business
                              Profile
                            </li> */}
													</ul>
												</div>
											</div>
										</div>
									</div>

									<div className='short-description'>
										<h3 className='font-weight-bold  my-4'>
											Short Description
										</h3>
										<p className='font-weight-bold'>
											{user.description}
										</p>
									</div>
								</div>
								<div className='col-md-6'>
									<div className='d-flex'>
										<div className='ml-auto'>
											{/* <button
                        className="btn btn-dark mr-2"
                        onClick={() => {
                          this.setState({
                            newLat: user.lat,
                            newLng: user.lng,
                            // newLocation: user.current_location
                          });
                          this.toggleLocationModal();
                        }}
                      >
                        Change Location
                      </button> */}
											<button
												className='btn btn-dark mr-3'
												onClick={e =>
													this.props.history.push(
														'/dashboard/edit-profile'
													)
												}
											>
												Edit
											</button>
										</div>
									</div>
									<div className='friends mb-4'>
										<h2 className='font-weight-bold'>
											Friends
										</h2>

										<div className='row'>
											{users && this.state.viewSix ? (
												<>
													{users
														.filter(us =>
															user.connects.includes(
																us.id
															)
														)
														.slice(0, 6)
														.map(person => {
															return (
																<div className='col-6 col-md-2 mb-4'>
																	<Link
																		to={
																			'/dashboard/view-profile/' +
																			person.id
																		}
																	>
																		<div
																			className='position-relative'
																			style={{
																				width: '100%',
																				height: 0,
																				paddingTop:
																					'100%',
																			}}
																		>
																			<img
																				src={
																					person
																						.images[0]
																				}
																				style={{
																					position:
																						'absolute',
																					top: 0,
																					left: 0,
																					width: '100%',
																					height: '100%',
																					objectFit:
																						'cover',
																				}}
																			></img>
																		</div>
																	</Link>
																</div>
															);
														})}
													<button
														className='btn btn-dark'
														onClick={() =>
															this.setState({
																viewSix:
																	!this.state
																		.viewSix,
															})
														}
													>
														View More
													</button>
												</>
											) : (
												<>
													{users
														.filter(us =>
															user.connects.includes(
																us.id
															)
														)
														.map(person => {
															return (
																<div className='col-6 col-md-2 mb-4'>
																	<Link
																		to={
																			'/dashboard/view-profile/' +
																			person.id
																		}
																	>
																		<div
																			className='position-relative'
																			style={{
																				width: '100%',
																				height: 0,
																				paddingTop:
																					'100%',
																			}}
																		>
																			<img
																				src={
																					person
																						.images[0]
																				}
																				style={{
																					position:
																						'absolute',
																					top: 0,
																					left: 0,
																					width: '100%',
																					height: '100%',
																					objectFit:
																						'cover',
																				}}
																			></img>
																		</div>
																	</Link>
																</div>
															);
														})}
													<button
														className='btn btn-dark'
														onClick={() =>
															this.setState({
																viewSix:
																	!this.state
																		.viewSix,
															})
														}
													>
														View Less
													</button>
												</>
											)}
											{/* {this.Persons.map((person) => (
                        <div className="col-md-2 mb-4">
                          <div
                            className="position-relative"
                            style={{
                              width: "100%",
                              height: 0,
                              paddingTop: "100%",
                            }}
                          >
                            <img
                              src={person}
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            ></img>
                          </div>
                        </div>
                      ))} */}
										</div>
									</div>
									<div className='friends mb-4'>
										<button
											className='btn btn-dark'
											onClick={() =>
												this.setState({
													viewBlocked:
														!this.state.viewBlocked,
												})
											}
										>
											View Blocked Users
										</button>
										{/* <h2 className="font-weight-bold">Blocked</h2> */}

										<div className='row mt-3'>
											{this.state.viewBlocked && (
												<>
													{users &&
													users.filter(us =>
														user.block.includes(
															us.id
														)
													).length > 0
														? users
																.filter(us =>
																	user.block.includes(
																		us.id
																	)
																)
																.map(person => {
																	return (
																		<div className='col-6 col-md-2 mb-4'>
																			<Link
																				to={
																					'/dashboard/view-profile/' +
																					person.id
																				}
																			>
																				<div
																					className='position-relative'
																					style={{
																						width: '100%',
																						height: 0,
																						paddingTop:
																							'100%',
																					}}
																				>
																					<img
																						src={
																							person
																								.images[0]
																						}
																						style={{
																							position:
																								'absolute',
																							top: 0,
																							left: 0,
																							width: '100%',
																							height: '100%',
																							objectFit:
																								'cover',
																						}}
																					></img>
																				</div>
																			</Link>
																		</div>
																	);
																})
														: "You don't have any blocked users yet"}
												</>
											)}
											{/* {this.Persons.map((person) => (
                        <div className="col-md-2 mb-4">
                          <div
                            className="position-relative"
                            style={{
                              width: "100%",
                              height: 0,
                              paddingTop: "100%",
                            }}
                          >
                            <img
                              src={person}
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            ></img>
                          </div>
                        </div>
                      ))} */}
										</div>
									</div>
									<div className='pictures mb-4'>
										<h2 className='font-weight-bold'>
											Pictures
										</h2>
										<div className='row'>
											{user &&
												user.images.map(business => (
													<div className='col-6 col-md-4 mb-4'>
														<div
															className='position-relative'
															style={{
																width: '100%',
																height: 0,
																paddingTop:
																	'60%',
															}}
														>
															<img
																src={business}
																onClick={e => {
																	e.preventDefault();
																	this.photoToggle(
																		business
																	);
																}}
																style={{
																	position:
																		'absolute',
																	top: 0,
																	left: 0,
																	width: '100%',
																	height: '100%',
																	objectFit:
																		'cover',
																}}
															></img>
														</div>
														<Modal
															isOpen={
																this.state
																	.photoModal ==
																business
															}
															toggle={e => {
																e.preventDefault();
																this.photoToggle(
																	business
																);
															}}
														>
															<ModalBody>
																<img
																	style={{
																		width: '100%',
																	}}
																	src={
																		business
																	}
																></img>
															</ModalBody>
														</Modal>
													</div>
												))}
										</div>
									</div>
								</div>
							</div>
							<Modal
								isOpen={this.state.locationModal}
								toggle={this.toggleLocationModal}
							>
								<ModalHeader toggle={this.toggleLocationModal}>
									{user.current_location}
								</ModalHeader>
								<ModalBody>
									<div
										className='form-group text-left'
										style={{
											height: '400px',
										}}
									>
										<PlacesAutocomplete
											className='autocomplete-signup'
											// searchOptions={{
											//   componentRestrictions: { country: ["LB"] },
											// }}
											required
											value={this.state.newLocation}
											onChange={this.handleChange}
											onSelect={this.handleSelect}
										>
											{({
												getInputProps,
												suggestions,
												getSuggestionItemProps,
												loading,
											}) => (
												<div>
													<Input
														{...getInputProps({
															placeholder:
																'Search Places ...',
															className:
																'location-search-input',
														})}
													/>
													<div className='autocomplete-dropdown-container'>
														{loading && (
															<div>
																Loading...
															</div>
														)}
														{suggestions.map(
															suggestion => {
																const className =
																	suggestion.active
																		? 'suggestion-item--active'
																		: 'suggestion-item';
																// inline style for demonstration purpose
																const style =
																	suggestion.active
																		? {
																				backgroundColor:
																					'#fafafa',
																				cursor: 'pointer',
																		  }
																		: {
																				backgroundColor:
																					'#ffffff',
																				cursor: 'pointer',
																		  };
																return (
																	<div
																		{...getSuggestionItemProps(
																			suggestion,
																			{
																				className,
																				style,
																			}
																		)}
																	>
																		<span>
																			{
																				suggestion.description
																			}
																		</span>
																	</div>
																);
															}
														)}
													</div>
												</div>
											)}
										</PlacesAutocomplete>
										<Row className='mx-1'>
											<Map
												google={this.props.google}
												zoom={9}
												style={{
													height: '97%',
													width: '100%',
												}}
												containerStyle={{
													height: '70%',
													width: '93%',
												}}
												initialCenter={{
													lat: user.lat,
													lng: user.lng,
												}}
												center={{
													lat: this.state.newLat,
													lng: this.state.newLng,
												}}
											>
												<Marker
													draggable={true}
													position={{
														lat: this.state.newLat,
														lng: this.state.newLng,
													}}
												/>
											</Map>
										</Row>
									</div>
								</ModalBody>
								<ModalFooter>
									<Button
										color='danger'
										onClick={this.toggleLocationModal}
									>
										Cancel
									</Button>{' '}
									<Button
										color='success'
										onClick={() => {
											if (
												this.state.newLocation != '' &&
												this.state.newLat != '' &&
												this.state.newLng != ''
											) {
												this.props.updateUserLocation(
													user.id,
													this.state.newLocation,
													this.state.newLat,
													this.state.newLng
												);
												this.setState({
													newLocation: '',
													newLat: '',
													newLng: '',
												});
												this.toggleLocationModal();
											}
										}}
									>
										Update
									</Button>
								</ModalFooter>
							</Modal>
						</section>
					) : (
						<section className='container'>
							<div className='row'>
								<div className='col-md-6 profile-section'>
									<div className='description'>
										<div className='row'>
											<div className='col-md-4'>
												<div className='rounded-circle overflow-hidden'>
													<img
														src={BusinessProfile}
														alt='sunil'
													/>
												</div>
											</div>
											<div className='col-md-8'>
												<div className='general-info'>
													<h2>Ajeen</h2>
													<ul
														style={{
															listStyle: 'none',
															margin: '0',
															padding: '0',
														}}
													>
														<li>
															<i class='fas fa-map-marker-alt mr-2'></i>
															Boston
														</li>
														<li>
															<i class='fa fa-language mr-2'></i>
															English, French,
															Arabic
														</li>
														<li>
															<i class='fa fa-briefcase mr-2'></i>
															Business
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									<div className='contact-info'>
										<ul
											className='d-inline-flex mt-4'
											style={{
												listStyle: 'none',
												padding: '0',
												margin: '0',
												boxShadow:
													'0 3px 6px rgb(0 0 0 / 20%), 0 3px 4px rgb(0 0 0 / 30%)',
												background:
													'rgb(236, 227, 226)',
											}}
										>
											<li className='p-2'>
												<i class='fa fa-inbox'></i>
											</li>
											<li className='p-2'>
												<i class='fa fa-phone'></i>
											</li>
											<li className='p-2'>
												<i class='fa fa-envelope'></i>
											</li>
											<li className='p-2'>
												<i class='fa fa-map'></i>
											</li>
										</ul>
									</div>
									<div className='short-description'>
										<h3 className='font-weight-bold  my-4'>
											Short Description
										</h3>
										<p className='font-weight-bold'>
											Ajeen, authentic Lebanese food
											without travelling to Lebanon. Ajeen
											serves a variety of foods including
											mneech, kababs, falefel, salads,
											desserts and more! Ajeen is hands
											down the most delicious manoushe
											spot in Boston! Check it out for
											yourself!
										</p>
									</div>
								</div>
								<div className='col-md-6'>
									<div className='d-flex'>
										<div className='ml-auto'>
											{this.props.history.location
												.state &&
											this.props.history.location.state
												.is_own ? (
												<button
													className='btn btn-dark'
													onClick={e =>
														this.props.history.push(
															'/dashboard/edit-business-profile'
														)
													}
												>
													Edit
												</button>
											) : (
												<div className='d-inline-block'>
													<i
														className='fa fa-star'
														style={{
															fontSize: '32px',
														}}
													></i>
													<i
														className='fa fa-star'
														style={{
															fontSize: '32px',
														}}
													></i>
													<i
														className='fa fa-star'
														style={{
															fontSize: '32px',
														}}
													></i>
													<i
														className='far fa-star'
														style={{
															fontSize: '32px',
														}}
													></i>
													<i
														className='far fa-star'
														style={{
															fontSize: '32px',
														}}
													></i>
												</div>
											)}
											<i className='fa fa-ellipsis-v ml-3'></i>
										</div>
									</div>
									<div className='pictures mb-4'>
										<h2 className='font-weight-bold'>
											Pictures
										</h2>
										<div className='row'>
											{user &&
												user.images.map(img => (
													<div className='col-md-4 mb-4'>
														<div
															className='position-relative'
															style={{
																width: '100%',
																height: 0,
																paddingTop:
																	'60%',
															}}
														>
															<img
																src={img}
																style={{
																	position:
																		'absolute',
																	top: 0,
																	left: 0,
																	width: '100%',
																	objectFit:
																		'cover',
																}}
															></img>
														</div>
													</div>
												))}
										</div>
									</div>
									<div className='pictures mb-4'>
										<h2 className='font-weight-bold'>
											Location
										</h2>
										<Iframe
											url='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2948.6100527302824!2d-71.13302628448052!3d42.350836479187436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e379c5d9881d01%3A0xbd064db202aa6e44!2s1219%20Commonwealth%20Avenue%2C%20Allston%2C%20MA%2002134%2C%20USA!5e0!3m2!1sen!2slb!4v1606249663630!5m2!1sen!2slb'
											width='100%'
											height='450px'
											frameborder='0'
											style='border:0;'
											allowfullscreen=''
											aria-hidden='false'
											tabindex='0'
										></Iframe>
									</div>
								</div>
							</div>
						</section>
					)}
				</div>
			</>
		);
	}
}
export function mapStateToProps(state) {
	return {
		loading: state.auth.requested,
		registered: state.auth.registered,
		user: state.authUser.user,
		users: state.users.users,
	};
}
export function mapDispatchToProps(dispatch) {
	return {
		// registeredComplete: () => dispatch(removeRegistered()),
		// login: (credentials) => dispatch(login(credentials)),
		// loginGoogle: () => dispatch(signupGoogle()),
		getUser: uid => dispatch(getUser(uid)),
		getAllUsers: () => dispatch(getAllUsers()),
		removeUpdate: () => dispatch(removeUpdate()),
		updateUserLocation: (id, location, lat, lng) =>
			dispatch(updateUserLocation(id, location, lat, lng)),

		// personelInfo: (Info) => dispatch(updatePersonelInfo(Info)),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	GoogleApiWrapper({
		apiKey: 'AIzaSyDns9eCy_4Zge-qYP3Ycnp7qtLw_QsPNIE',
	})(ProfileDetail)
);

// AIzaSyAUqgNDaKv0CHCmPIvxK6Sp9WT1CGLGf2E Prev Key
// AIzaSyDJeDZs3qWrf65ZKGIIM4EqiPl6L4NqO40 Dawood Bhai Provided key
