import firebase from "firebase";
export const firebaseConfig = {
  apiKey: "AIzaSyDJeDZs3qWrf65ZKGIIM4EqiPl6L4NqO40",
  authDomain: "beyroot-platform.firebaseapp.com",
  projectId: "beyroot-platform",
  storageBucket: "beyroot-platform.appspot.com",
  messagingSenderId: "99711915143",
  appId: "1:99711915143:web:9569b105fa831809769422",
  measurementId: "G-PG4YGCFE37",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;
