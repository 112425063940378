import firebase from "./../firebase";
import { v4 as uuidv4 } from "uuid";
export const login = (credentials) => {
  return (dispatch) => {
    dispatch({
      type: "ACTION_REQUEST",
    });
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((data) => {
        console.log('datta user', data.user)
        if (data.user.emailVerified) {
          firebase
            .firestore()
            .collection("users")
            .doc(data.user.uid)
            .onSnapshot((doc) => {
              console.log(doc.data());
              var tempUser = {};
              tempUser = { id: doc.id, ...doc.data() };
              dispatch({
                type: "LOGIN_SUCCESS",
                user: tempUser,
                error: "",
              });
              dispatch({
                type: "ACTION_REQUEST_END",
              });
            });
        } else {
          alert("User Email Not Verified");
          dispatch({
            type: "ACTION_REQUEST_END",
          });
        }
      })

      .catch((error) => {
        dispatch({
          type: "LOGIN_FAIL",
          uid: "",
          error: error,
        });
        dispatch({
          type: "ACTION_REQUEST_END",
        });
        alert(error, "danger");
      });
  };
};
export const logout = () => {
  return (dispatch) => {
    firebase
      .auth()
      .signOut()
      .then((data) => {
        localStorage.clear();
        dispatch({
          type: "LOGOUT_SUCCESS",
          uid: null,
          error: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: "LOGOUT_FAIL",
          uid: null,
          error: error,
        });
      });
  };
};

export const removeAuthError = () => {
  return (dispatch) => {
    dispatch({
      type: "AUTH_RESET",
      uid: "",
      error: "",
    });
  };
};
export const removeRegistered = () => {
  return (dispatch) => {
    dispatch({
      type: "REGISTER_REMOVE",
    });
  };
};
export const register = (credentials) => {
  return async (dispatch) => {
    dispatch({
      type: "ACTION_REQUEST",
    });
    firebase
      .auth()
      .createUserWithEmailAndPassword(credentials.email, credentials.password)
      .then((data) => {
        data.user.sendEmailVerification();
        firebase
          .firestore()
          .collection("users")
          .doc(data.user.uid)
          .set({
            first_name: credentials.first_name,
            last_name: credentials.last_name,
            email: credentials.email,
            lat: credentials.lat,
            lng: credentials.lng,
            current_location: credentials.current_location,
            // location: credentials.location,
            type: credentials.type,
            connects: [],
            block: [],
          })
          .then((res) => {
            dispatch({
              type: "REGISTER_SUCCESS",
            });
            dispatch({
              type: "ACTION_REQUEST_END",
            });
            alert(`Verification Email Sent to ${credentials.email}`);
          })
          .catch((err) => {
            dispatch({
              type: "REGISTER_FAIL",
            });
            dispatch({
              type: "ACTION_REQUEST_END",
            });
            alert(err, "danger");
          });
      })
      .catch((err) => {
        dispatch({
          type: "REGISTER_FAIL",
        });
        dispatch({
          type: "ACTION_REQUEST_END",
        });
        alert(err, "danger");
        // console.log(err);
      });
  };
};
export const recoverAccount = (email) => {
  return (dispatch) => {
    dispatch({
      type: "ACTION_REQUEST",
    });
    firebase
      .auth()
      .sendEmailVerification(email)
      .then((res) => {
        dispatch({
          type: "ACTION_REQUEST_END",
        });
        dispatch({
          type: "ACCOUNT_RECOVER",
        });
        alert("Email Sent for Account Recovery");
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "ACCOUNT_RECOVER_FAIL",
        });
        dispatch({
          type: "ACTION_REQUEST_END",
        });
        alert(err);
      });
  };
};
export const sendVerificationEmail = (email) => {
  return async (dispatch) => {
    dispatch({
      type: "ACTION_REQUEST",
    });
    firebase
      .auth()
      .then((res) => {
        dispatch({
          type: "ACTION_REQUEST_END",
        });
        dispatch({
          type: "EMAIL_VERIFICATION",
        });
        alert(`Verification Email Sent to ${email}`);
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "EMAIL_VERIFICATION_FAIL",
        });
        dispatch({
          type: "ACTION_REQUEST_END",
        });
        alert(err);
      });
  };
}
export const signupFacebook = () => {
  return (dispatch) => {
    dispatch({
      type: "ACTION_REQUEST",
    });
    var provider = new firebase.auth.FacebookAuthProvider();
    const firestore = firebase.firestore();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        console.log(result.user);
        var FreshUser = await firestore
          .collection("users")
          .doc(result.user.uid)
          .get();
        if (!FreshUser.exists) {
          firestore
            .collection("users")
            .doc(result.user.uid)
            .set({
              name: result.user.displayName,
              email: result.user.email,
              block: [],
              connects: [],
            })
            .then(async (res) => {
              var newUser = await firebase
                .firestore()
                .collection("users")
                .doc(result.user.uid)
                .get();
              console.log(newUser.data());
              dispatch({
                type: "GOOGLE_SIGNUP_SUCCESS",
                user: { id: newUser.data().id, ...newUser.data() },
                error: "",
              });
              dispatch({
                type: "ACTION_REQUEST_END",
              });
            })
            .catch((error) => {
              dispatch({
                type: "GOOGLE_SIGNUP_FAIL",
                uid: "",
                error: error,
              });
              dispatch({
                type: "ACTION_REQUEST_END",
              });
            });
        } else {
          dispatch({
            type: "GOOGLE_SIGNUP_SUCCESS",
            user: { id: FreshUser.id, ...FreshUser.data() },
            error: "",
          });
          dispatch({
            type: "ACTION_REQUEST_END",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "GOOGLE_SIGNUP_FAIL",
          uid: "",
          error: error,
        });
        dispatch({
          type: "ACTION_REQUEST_END",
        });
      });
  };
};
export const phoneLogin = (user, location) => {
  return async (dispatch) => {
    console.log(user);
    dispatch({
      type: "ACTION_REQUEST",
    });
    var FreshUser = await firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .get();
    console.log(FreshUser);
    if (!FreshUser.exists) {
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .set({
          type: "individual",
          phone: user.phoneNumber,
          block: [],
          connects: [],
          current_location: location.current_location,
          lat: location.lat,
          lng: location.lng,
        })
        .then(async (res) => {
          var newUser = await firebase
            .firestore()
            .collection("users")
            .doc(user.uid)
            .get();

          dispatch({
            type: "PHONE_LOGIN",

            user: { id: newUser.id, ...newUser.data() },
            error: "",
          });
          dispatch({
            type: "ACTION_REQUEST_END",
          });
        })
        .catch((error) => {
          dispatch({
            type: "PHONE_SIGNUP_FAIL",
          });
          dispatch({
            type: "ACTION_REQUEST_END",
          });
          console.log(error);
          alert(error);
        });
    } else {
      dispatch({
        type: "PHONE_LOGIN",
        user: { id: FreshUser.id, ...FreshUser.data() },
        error: "",
      });
      dispatch({
        type: "ACTION_REQUEST_END",
      });
    }
  };
};

export const phoneRegRemove = () => {
  return async (dispatch) => {
    dispatch({
      type: "PHONE_REMOVE",
    });
  };
};
