import React, { Component } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import "./aboutus.css";

import pic1 from "../../images/IMG_1824.jpg";
import pic2 from "../../images/IMG_2900.jpg";
import pic3 from "../../images/IMG_5559.jpg";
import { fadeInUp } from "react-animations";
import Radium, { StyleRoot } from "radium";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animating1: false,
      animating2: false,
      animating3: false,
      fadeInUp: {
        animation: "x 1.5s",
        animationName: Radium.keyframes(fadeInUp, "bounce"),
      },
    };
  }

  componentDidMount = () => {
    // window.addEventListener("scroll", this.handleScroll);
    // window.scrollTo(0, 0);
    this.forceUpdate();
  };

  componentWillUnmount = () => {
    // window.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = (event) => {
    const isTop = window.scrollY > 200;
    const isTop2 = window.scrollY > 200;
    const isTop3 = window.scrollY > 800;
    if (isTop == true) {
      this.setState({
        animating1: true,
      });
    }
    if (isTop2 == true) {
      this.setState({
        animating2: true,
      });
    }
    if (isTop3 == true) {
      this.setState({
        animating3: true,
      });
    }
  };

  render() {
    console.log(this.state.animating1);
    return (
      <>
        <Navbar />
        <div class="aboutus">
          <div class="container">
            <div class="row">
              {/* <!-- Left side Image--> */}
              <div class="col-lg-12 aboutUs">
                <h1>About us</h1>
              </div>
            </div>
          </div>
        </div>
        {/* <!--Banner Section start here--> */}

        <div class="  container-fluid">
          <div className="row">
            <div className="col-md-6 mt-4  side-img ">
              <StyleRoot>
                <img
                  className="mb-4 rounded shadow"
                  style={this.state.fadeInUp}
                  src={pic1}
                />
              </StyleRoot>
            </div>

            <div className="col-md-6  mt-4 align-self-center  ">
              <StyleRoot>
                <div
                  className="card rounded shadow"
                  style={this.state.fadeInUp}
                >
                  <h4 className="p-3">About Us</h4>
                  <p className="p-3  text-justify">
                    {/* <!--Content Section start here--> */}To unite, inspire
                    and equip the Lebanese Diaspora so that together we can
                    transform our motherland. To provide Lebanese with the
                    opportunity to connect, provide resources and assist each
                    other. To connect and empower the Lebanese Diaspora all over
                    the world in one platform. To help business owners and
                    entrepreneurs connect to the right induvials to help them
                    take the next step in their endeavors. To connect
                    individuals who are new to a country to induvials who have
                    been in their shoes. To recognize the success stories of
                    Lebanese expats with various backgrounds and to connect them
                    with their roots, which would begin our journey to transform
                    Lebanon. Connecting the Lebanese that you wouldn’t
                    necessarily connect to in your areas (since you didn’t know
                    they existed).
                  </p>
                </div>
              </StyleRoot>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6  mt-4 align-self-center  ">
              <StyleRoot>
                <div
                  className="card rounded shadow"
                  style={this.state.animating2 ? this.state.fadeInUp : {}}
                >
                  <h4 className="p-3">Mission Statement</h4>
                  <p className="p-3  text-justify">
                    {/* <!--Content Section start here--> */}To unite, inspire
                    and equip the Lebanese Diaspora so that together we can
                    transform our motherland. To provide Lebanese with the
                    opportunity to connect, provide resources and assist each
                    other. To connect and empower the Lebanese Diaspora all over
                    the world in one platform. To help business owners and
                    entrepreneurs connect to the right induvials to help them
                    take the next step in their endeavors. To connect
                    individuals who are new to a country to induvials who have
                    been in their shoes. To recognize the success stories of
                    Lebanese expats with various backgrounds and to connect them
                    with their roots, which would begin our journey to transform
                    Lebanon. Connecting the Lebanese that you wouldn’t
                    necessarily connect to in your areas (since you didn’t know
                    they existed).
                  </p>
                </div>
              </StyleRoot>
            </div>
            <div className="col-md-6 mt-4  side-img ">
              <StyleRoot>
                <img
                  style={this.state.animating2 ? this.state.fadeInUp : {}}
                  className="mb-4 rounded shadow"
                  src={pic2}
                />
              </StyleRoot>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6 mt-4  side-img ">
              <StyleRoot>
                <img
                  style={this.state.animating3 ? this.state.fadeInUp : {}}
                  className="mb-4 rounded shadow"
                  src={pic3}
                />
              </StyleRoot>
            </div>
            <div className="col-md-6  mt-4 align-self-center  ">
              <StyleRoot>
                <div
                  className="card rounded shadow"
                  style={this.state.animating3 ? this.state.fadeInUp : {}}
                >
                  <h4 className="p-3">Vision Statement</h4>
                  <p className="p-3  text-justify">
                    {/* <!--Content Section start here--> */}To unite, inspire
                    and equip the Lebanese Diaspora so that together we can
                    transform our motherland. To provide Lebanese with the
                    opportunity to connect, provide resources and assist each
                    other. To connect and empower the Lebanese Diaspora all over
                    the world in one platform. To help business owners and
                    entrepreneurs connect to the right induvials to help them
                    take the next step in their endeavors. To connect
                    individuals who are new to a country to induvials who have
                    been in their shoes. To recognize the success stories of
                    Lebanese expats with various backgrounds and to connect them
                    with their roots, which would begin our journey to transform
                    Lebanon. Connecting the Lebanese that you wouldn’t
                    necessarily connect to in your areas (since you didn’t know
                    they existed).
                  </p>
                </div>
              </StyleRoot>
            </div>
          </div>
        </div>
        <Footer className="relative-position bottom-0" />
      </>
    );
  }
}
export default AboutUs;
