import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import logo from "../images/logo1.PNG";
import { Row, Col } from "reactstrap";
function DashboardFooter() {
  return (
    <>
      <div class="container-fluid footer-container  Footer">
        <div class="row">
          <div class=" d-none d-lg-block col-lg-4 text-center mt-3 mb-4 col-md-4 col-sm-12">
            <img className="footer-logo" src={logo} />
          </div>

          <div class=" col-lg-4 menu-item-1 mt-4 text-left d-none d-lg-block  col-md-4 col-sm-12">
            <ul className=" text-left ">
              <Link to="/" target="_blank">
                <li className="">Home</li>
              </Link>
              <Link to="/aboutus" target="_blank">
                <li className="ml-3">About Us</li>
              </Link>
              {/* <Link to="/privacy-policy"><li className="ml-3">Privacy Policy</li>	</Link> */}
              <Link to="/terms-of-use" target="_blank">
                <li className="ml-3">Terms of Use</li>
              </Link>
              <Link to="/connect-beyroot" target="_blank">
                <li className="ml-3">Connect Beyroot</li>
              </Link>
            </ul>
          </div>
          <div class=" col-lg-4 menu-item-1 mt-4 text-center d-flex d-lg-none  col-md-4 col-sm-12">
            <Link to="/" target="_blank">
              <p
                className="text-white"
                style={{ fontSize: "13px", fontWeight: "bolder" }}
              >
                Home
              </p>
            </Link>
            <Link to="/aboutus" target="_blank">
              <p
                className="text-white ml-3 "
                style={{ fontSize: "13px", fontWeight: "bolder" }}
              >
                About Us
              </p>
            </Link>
            {/* <Link to="/privacy-policy"><p className="ml-3">Privacy Policy</p>	</Link> */}

            <Link to="/terms-of-use" target="_blank">
              <p
                className="text-white ml-3"
                style={{ fontSize: "13px", fontWeight: "bolder" }}
              >
                Terms Of Use
              </p>
            </Link>
            <Link to="/connect-beyroot" target="_blank">
              <p
                className="text-white ml-3"
                style={{ fontSize: "13px", fontWeight: "bolder" }}
              >
                Connect Beyroot
              </p>
            </Link>
          </div>
          {/* <div class=" col-lg-3 menu-item-1 mt-4  text-center col-md-3 col-sm-12">

						<ul className=" text-left">
							{/* <Link to="/"><li className="ml-3">Home</li></Link>
							<Link to="/aboutus"><li className="ml-3">About Us</li></Link>
							<Link to="/privacy-policy"><li className="ml-3">Privacy Policy</li>	</Link>
							<Link to="/terms-of-use"><li className="ml-3">Terms Of Use</li></Link> 
							<Link to="/connect-beyroot"><li className="ml-3">Connect Beyroot</li></Link>
						</ul>
						</div> */}
          <div class=" text-center mt-3 col-lg-4 menu-item-2 col-md-4 col-sm-12 Socail_icon">
            {/* <a href="#" class="fa ml-3 text-white fa-snapchat-ghost"></a> */}
            <a
              href="https://web.facebook.com/Leadersbeyondcedars/?_rdc=1&_rdr"
              target="_blank"
              class="fa ml-3 text-white fa-facebook"
            ></a>
            <a
              href="https://www.instagram.com/leadersbeyondcedars.com"
              target="_blank"
              class="fa ml-3 text-white fa-instagram"
            ></a>
            <a href="#" class="fab ml-3 text-white fa-linkedin"></a>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardFooter;
