import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import logo from "../images/newlogo.png";
import logo from "../../src/images/logo1.PNG";
import logo2 from "../images/logo2.png";
import { logout } from "../store/actions/authActions";
import { Row, Col } from "reactstrap";
export class Navbar extends Component {
  state = {
    scrollY: 0,
    scrolling: false,
  };
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll(event) {
    console.log(window.scrollY);
    if (window.scrollY === 0 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
    } else if (window.scrollY !== 0 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
    }
  }
  render() {
    var obj = { backgroundColor: "transparent" };
    console.log(window.pageYOffset);
    var obj2 = {
      backgroundColor: "white",
      boxShadow: "0 0 10px 10px rgba(0,0,0,0.35)",
    };
    var { auth, authUser, user } = this.props;
    console.log(this.state.scrolling);

    return (
      <>
        <nav
          className="d-none d-lg-block "
          style={this.state.scrolling ? obj2 : obj}
        >
          <div className="container header ">
            <div className="row">
              <div className="col-sm-6 logo">
                <Link to="/">
                  <img style={{ width: "200px", height: "100px" }} src={logo} />
                </Link>
              </div>

              <div className="col-sm-6 signIn">
                <p className="m-0">
                  {authUser && authUser.uid ? (
                    user.age ? (
                      <>
                        <Link to="/dashboard" className="header_BtnBtn">
                          Dashboard
                        </Link>
                        <Link to="/forum" className="header_BtnBtn">
                          Forum
                        </Link>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.logout();
                          }}
                          className="header_BtnBtn"
                        >
                          Logout
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link to="/dashboard" className="header_BtnBtn">
                          Fill Profile
                        </Link>
                        <Link to="/forum" className="header_BtnBtn">
                          Forum
                        </Link>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.logout();
                          }}
                          className="header_BtnBtn"
                        >
                          Logout
                        </Link>
                      </>
                    )
                  ) : (
                    <>
                      <Link to="/forum" className="header_BtnBtn">
                        Forum
                      </Link>
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </nav>
        {/* mobile nav */}
        <nav className="d-lg-none Footer">
          <div className="container header ">
            <Row>
              <Col xs={{ size: 4 }}>
                <Link className="" to="/">
                  <img src={logo} />
                </Link>
              </Col>
              <Col className="text-right align-items-center">
                <p className="mb-0">
                  {authUser && authUser.uid ? (
                    user.age ? (
                      <>
                        <Link
                          to="/dashboard"
                          className="header_BtnBtn"
                          style={{ fontWeight: "bolder" }}
                        >
                          Dashboard
                        </Link>
                        <Link
                          to="/forum"
                          className="header_BtnBtn"
                          style={{ fontWeight: "bolder" }}
                        >
                          Forum
                        </Link>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.logout();
                          }}
                          className="header_BtnBtn"
                          style={{ fontWeight: "bolder" }}
                        >
                          Logout
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link
                          to="/dashboard"
                          className="header_BtnBtn"
                          style={{ fontWeight: "bolder" }}
                        >
                          Fill Profile
                        </Link>
                        <Link
                          to="/forum"
                          className="header_BtnBtn"
                          style={{ fontWeight: "bolder" }}
                        >
                          Forum
                        </Link>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.logout();
                          }}
                          className="header_BtnBtn"
                          style={{ fontWeight: "bolder" }}
                        >
                          Logout
                        </Link>
                      </>
                    )
                  ) : (
                    <>
                      <Link
                        to="/forum"
                        className="header_BtnBtn"
                        style={{ fontWeight: "bolder" }}
                      >
                        Forum
                      </Link>
                    </>
                  )}
                </p>
              </Col>
            </Row>
            <div className="row">
              <div className="col-sm-6 signIn"></div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}
export function mapStateToProps(state) {
  return {
    auth: state.auth,
    authUser: state.authUser,
    // registered: state.auth.registered,
    user: state.authUser.user,
    // users: state.users.users,
    chats: state.chat.chats,
    connect_requests: state.connect.connect_requests,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    // registeredComplete: () => dispatch(removeRegistered()),
    logout: () => dispatch(logout()),
    // loginGoogle: () => dispatch(signupGoogle()),

    getUser: (uid) => dispatch(getUser(uid)),
    // getAllUsers: () => dispatch(getAllUsers()),
    // getChats: (uid) => dispatch(getChats(uid)),
    // sendMessage: (msg) => dispatch(sendMessage(msg)),

    // removeConnect: (loggedUid, uid) => dispatch(removeConnect(loggedUid, uid)),
    // getConnectRequests: () => dispatch(getConnectRequests()),
    // removeRequest: (uid) => dispatch(removeRequest(uid)),
    // acceptRequest: (uid) => dispatch(acceptRequest(uid)),

    // personelInfo: (Info) => dispatch(updatePersonelInfo(Info)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
