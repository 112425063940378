import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DatePicker, Upload } from "antd";
import "antd/lib/date-picker/style/css";
import { connect } from "react-redux";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import {
  getUser,
  makeProfile,
  removeImage,
  updatePersonelInfo,
} from "../../store/actions/userActions";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../components/cropImage";
import moment from "moment";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  UncontrolledTooltip,
  Button,
  Row,
  Col,
  ModalFooter,
} from "reactstrap";
import { Redirect } from "react-router";
import ImgCrop from "antd-img-crop";
const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.title,
});
const countries = [
  { title: "Åland Islands" },
  { title: "Afghanistan" },
  { title: "Albania" },
  { title: "Algeria" },
  { title: "American Samoa" },
  { title: "Andorra" },
  { title: "Angola" },
  { title: "Anguilla" },
  { title: "Antarctica" },
  { title: "Antigua and Barbuda" },
  { title: "Argentina" },
  { title: "Armenia" },
  { title: "Aruba" },
  { title: "Austria" },
  { title: "Azerbaijan" },
  { title: "Bahamas" },
  { title: "Bahrain" },
  { title: "Bangladesh" },
  { title: "Barbados" },
  { title: "Belarus" },
  { title: "Belgium" },
  { title: "Belize" },
  { title: "Benin" },
  { title: "Bermuda" },
  { title: "Bhutan" },
  { title: "Bolivia, Plurinational State of" },
  { title: "Bonaire, Sint Eustatius and Saba" },
  { title: "Bosnia and Herzegovina" },
  { title: "Bouvet Island" },
  { title: "Brazil" },
  { title: "British Indian Ocean Territory" },
  { title: "Brunei Darussalam" },
  { title: "Bulgaria" },
  { title: "Burkina Faso" },
  { title: "Burundi" },
  { title: "Cambodia" },
  { title: "Cameroon" },
  { title: "Canada" },
  { title: "Cape Verde" },
  { title: "Cayman Islands" },
  { title: "Central African Republic" },
  { title: "Chad" },
  { title: "Chile" },
  { title: "China" },
  { title: "Christmas Island" },
  { title: "Cocos (Keeling) Islands" },
  { title: "Colombia" },
  { title: "Comoros" },
  { title: "Congo" },
  { title: "Congo, the Democratic Republic of the" },
  { title: "Cook Islands" },
  { title: "Costa Rica" },
  { title: "Côte d'Ivoire" },
  { title: "Croatia" },
  { title: "Cuba" },
  { title: "Curaçao" },
  { title: "Cyprus" },
  { title: "Czech Republic" },
  { title: "Denmark" },
  { title: "Djibouti" },
  { title: "Dominica" },
  { title: "Dominican Republic" },
  { title: "Ecuador" },
  { title: "Egypt" },
  { title: "El Salvador" },
  { title: "England" },
  { title: "Equatorial Guinea" },
  { title: "Eritrea" },
  { title: "Estonia" },
  { title: "Ethiopia" },
  { title: "Falkland Islands (Malvinas)" },
  { title: "Faroe Islands" },
  { title: "Fiji" },
  { title: "Finland" },
  { title: "France" },
  { title: "French Guiana" },
  { title: "French Polynesia" },
  { title: "French Southern Territories" },
  { title: "Gabon" },
  { title: "Gambia" },
  { title: "Georgia" },
  { title: "Germany" },
  { title: "Ghana" },
  { title: "Gibraltar" },
  { title: "Greece" },
  { title: "Greenland" },
  { title: "Grenada" },
  { title: "Guadeloupe" },
  { title: "Guam" },
  { title: "Guatemala" },
  { title: "Guernsey" },
  { title: "Guinea" },
  { title: "Guinea-Bissau" },
  { title: "Guyana" },
  { title: "Haiti" },
  { title: "Heard Island and McDonald Islands" },
  { title: "GuaHoly See (Vatican City State)m" },
  { title: "Honduras" },
  { title: "Hong Kong" },
  { title: "Hungary" },
  { title: "Iceland" },
  { title: "India" },
  { title: "Indonesia" },
  { title: "Iran, Islamic Republic of" },
  { title: "Iraq" },
  { title: "Ireland" },
  { title: "Isle of Man" },
  { title: "Israel" },
  { title: "Italy" },
  { title: "Jamaica" },
  { title: "Japan" },
  { title: "Jersey" },
  { title: "Jordan" },
  { title: "Kazakhstan" },
  { title: "Kenya" },
  { title: "Kiribati" },
  { title: "Korea, Democratic People's Republic of" },
  { title: "Korea, Republic of" },
  { title: "Kuwait" },
  { title: "Kyrgyzstan" },
  { title: "Lao People's Democratic Republic" },
  { title: "Latvia" },
  { title: "Lebanon" },
  { title: "Lesotho" },
  { title: "Liberia" },
  { title: "Libya" },
  { title: "Lithuania" },
  { title: "Luxembourg" },
  { title: "Macao" },
  { title: "Macedonia, the former Yugoslav Republic of" },
  { title: "Madagascar" },
  { title: "Malawi" },
  { title: "Malaysia" },
  { title: "Maldives" },
  { title: "Mali" },
  { title: "Malta" },
  { title: "Marshall Islands" },
  { title: "Martinique" },
  { title: "Mauritania" },
  { title: "Mauritius" },
  { title: "Mayotte" },
  { title: "Mexico" },
  { title: "Micronesia, Federated States of" },
  { title: "Moldova, Republic of" },
  { title: "Monaco" },
  { title: "Mongolia" },
  { title: "Morocco" },
  { title: "Mozambique" },
  { title: "Myanmar" },
  { title: "Namibia" },
  { title: "Nauru" },
  { title: "Nepal" },
  { title: "Netherlands" },
  { title: "New Caledonia" },
  { title: "New Zealand" },
  { title: "Nicaragua" },
  { title: "Niger" },
  { title: "Nigeria" },
  { title: "Niue" },
  { title: "Norfolk Island" },
  { title: "Northern Mariana Islands" },
  { title: "Norway" },
  { title: "Oman" },
  { title: "Pakistan" },
  { title: "Palau" },
  { title: "Palestinian Territory, Occupied" },
  { title: "Panama" },
  { title: "Papua New Guinea" },
  { title: "Peru" },
  { title: "Philippines" },
  { title: "Pitcairn" },
  { title: "Poland" },
  { title: "Portugal" },
  { title: "Puerto" },
  { title: "Rico" },
  { title: "Qatar" },
  { title: "Réunion" },
  { title: "Romania" },
  { title: "Russian Federation" },
  { title: "Rwanda" },
  { title: "Saint Barthélemy" },
  { title: "Saint Helena, Ascension and Tristan da Cunha" },
  { title: "Saint Kitts and Nevis" },
  { title: "Saint Lucia" },
  { title: "Saint Martin (French part)" },
  { title: "Saint Pierre and Miquelon" },
  { title: "Saint Vincent and the Grenadines" },
  { title: "Samoa" },
  { title: "San Marino" },
  { title: "Sao Tome and Principe" },
  { title: "Saudi Arabia" },
  { title: "Scontland" },
  { title: "Senegal" },
  { title: "Serbia" },
  { title: "Seychelles" },
  { title: "Sierra Leone" },
  { title: "Singapore" },
  { title: "Sint Maarten (Dutch part)" },
  { title: "Slovakia" },
  { title: "Slovenia" },
  { title: "Solomon Islands" },
  { title: "Somalia" },
  { title: "South Africa" },
  { title: "South Georgia and the South Sandwich Islands" },
  { title: "South Sudan" },
  { title: "Spain" },
  { title: "Sri Lanka" },
  { title: "Sudan" },
  { title: "Suriname" },
  { title: "Svalbard and Jan Mayen" },
  { title: "Swaziland" },
  { title: "Sweden" },
  { title: "Switzerland" },
  { title: "Syrian Arab Republic" },
  { title: "Taiwan, Province of China" },
  { title: "Tajikistan" },
  { title: "Tanzania, United Republic of" },
  { title: "Thailand" },
  { title: "Timor-Leste" },
  { title: "Togo" },
  { title: "Tokelau" },
  { title: "Tonga" },
  { title: "Trinidad and Tobago" },
  { title: "Tunisia" },
  { title: "Turkey" },
  { title: "Turkmenistan" },
  { title: "Turks and Caicos Islands" },
  { title: "Tuvalu" },
  { title: "Uganda" },
  { title: "Ukraine" },
  { title: "United Arab Emirates" },
  { title: "United States" },
  { title: "United States Minor Outlying Islands" },
  { title: "Uruguay" },
  { title: "Uzbekistan" },
  { title: "	Venezuela, Bolivarian Republic of" },
  { title: "Viet Nam" },
  { title: "Virgin Islands, British" },
  { title: "Virgin Islands, U.S." },
  { title: "Wales" },
  { title: "Wallis and Futuna" },
  { title: "Western Sahara" },
  { title: "Yemen" },
  { title: "Zambia" },
  { title: "Zimbabwe" },
];

const languages = [
  { title: "Afrikaans" },
  { title: "Albanian" },
  { title: "Arabic" },
  { title: "Armenian" },
  { title: "Basque" },
  { title: "Bengali" },
  { title: "Bulgarian" },
  { title: "Catalan" },
  { title: "Cambodian" },
  { title: "Chinese (Mandarin)" },
  { title: "Croatian" },
  { title: "Czech" },
  { title: "Danish" },
  { title: "Dutch" },
  { title: "English" },
  { title: "Estonian" },
  { title: "Fiji" },
  { title: "Finnish" },
  { title: "French" },
  { title: "Georgian" },
  { title: "German" },
  { title: "Greek" },
  { title: "Gujarati" },
  { title: "Hebrew" },
  { title: "Hindi" },
  { title: "Hungarian" },
  { title: "Icelandic" },
  { title: "Indonesian" },
  { title: "Irish" },
  { title: "Italian" },
  { title: "Japanese" },
  { title: "Javanese" },
  { title: "Korean" },
  { title: "Latin" },
  { title: "Latvian" },
  { title: "Lithuanian" },
  { title: "Macedonian" },
  { title: "Malay" },
  { title: "Malayalam" },
  { title: "Maltese" },
  { title: "Maori" },
  { title: "Marathi" },
  { title: "Mongolian" },
  { title: "Nepali" },
  { title: "Norwegian" },
  { title: "Persian" },
  { title: "Polish" },
  { title: "Portuguese" },
  { title: "Punjabi" },
  { title: "Quechua" },
  { title: "Romanian" },
  { title: "Russian" },
  { title: "Samoan" },
  { title: "Serbian" },
  { title: "Slovak" },
  { title: "Slovenian" },
  { title: "Spanish" },
  { title: "Swahili" },
  { title: "Swedish" },
  { title: "Tamil" },
  { title: "Tatar" },
  { title: "Telugu" },
  { title: "Thai" },
  { title: "Tibetan" },
  { title: "Tonga" },
  { title: "Turkish" },
  { title: "Ukrainian" },
  { title: "Urdu" },
  { title: "Uzbek" },
  { title: "Vietnamese" },
  { title: "Welsh" },
  { title: "Xhosa" },
];

const districts = [
  { title: "Akkar" },
  { title: "Ashrafiyeh" },
  { title: "Baabda" },
  { title: "Baalbek" },
  { title: "Batroun" },
  { title: "Bint Jbeil" },
  { title: "Bsharri" },
  { title: "Byblos" },
  { title: "Chouf- Beiteddine" },
  { title: "Hasbaya" },
  { title: "Hermel" },
  { title: "Jezzine" },
  { title: "Keserwan- Jounieh" },
  { title: "Marjeyoun" },
  { title: "Matn- Jdeideh" },
  { title: "Miniyeh-Danniyeh District" },
  { title: "Nabatieh" },
  { title: "Rashaya" },
  { title: "Sidon" },
  { title: "Tripoli" },
  { title: "Tyre" },
  { title: "Zahle" },
  { title: "Zgharta" },
];

const lookings = [
  { title: "Ancestry" },
  { title: "Business" },
  { title: "Dating" },
  { title: "Friendship" },
  { title: "Networking" },
];

const smoke = [
  { title: "Yes, frequent smoke" },
  { title: "Social Smoker" },
  { title: "Hookah Smoker" },
  { title: "Non Smoker" },
];
const drinking = [
  { title: "No, never" },
  { title: "Social drinker" },
  { title: "Frequent drinker" },
];
const relegions = [
  { title: "Armenian Catholic" },
  { title: "Armenian Orthodox" },
  { title: "Christian" },
  { title: "Druze" },
  { title: "Maronite" },
  { title: "Melkite" },
  { title: "Muslim" },
  { title: "Orthodox" },
  { title: "Syriac Catholic" },
  { title: "Roman Catholic" },
  { title: "Other" },
];
const professions = [
  { title: "Arts" },
  { title: "Accounting/ Finance" },
  { title: "Agriculture" },
  { title: "AI/ machine learning" },
  { title: "Army/ Military" },
  { title: "Architect" },
  { title: "Athletics" },
  { title: "Automotive" },
  { title: "Construction" },
  { title: "Consulting" },
  { title: "Education" },
  { title: "Engineering" },
  { title: "Finance" },
  { title: "Fishing and farming" },
  { title: "Graphic design/Website Development" },
  { title: "Healthcare" },
  { title: "Hospitality/Tourisim" },
  { title: "IT" },
  { title: "Law /Legal" },
  { title: "Manufacturing" },
  { title: "Marketing" },
  { title: "Media" },
  { title: "Oil and Gas" },
  { title: "Other" },
  { title: "Pharmacital" },
  { title: "Real Estate" },
  { title: "Transportation" },
];
const education = [
  { title: "High school" },
  { title: "GED" },
  { title: "In college" },
  { title: "In school" },
  { title: "Tech/ vocational" },
  { title: "Associates" },
  { title: "Bachelors" },
  { title: "Masters" },
  { title: "PhD" },
  { title: "Doctorate" },
];

const status = [
  { title: "Divorced" },
  { title: "In relationship" },
  { title: "Married" },
  { title: "Other" },
  { title: "Seperated" },
  { title: "Single" },
  { title: "Widowed" },
];
// countries
const defaultProps = {
  options: countries,
  getOptionLabel: (option) => option.title,
};
const defaultProps2 = {
  options: languages,
  getOptionLabel: (option) => option.title,
};

//  districts
const defaultProps3 = {
  options: districts,
  getOptionLabel: (option) => option.title,
};
const defaultProps5 = {
  options: smoke,
  getOptionLabel: (option) => option.title,
};

// looking for
const defaultProps4 = {
  options: lookings,
  getOptionLabel: (option) => option.title,
};
const defaultProps6 = {
  options: drinking,
  getOptionLabel: (option) => option.title,
};
const defaultProps7 = {
  options: relegions,
  getOptionLabel: (option) => option.title,
};
const defaultProps8 = {
  options: professions,
  getOptionLabel: (option) => option.title,
};

const defaultProps9 = {
  options: education,
  getOptionLabel: (option) => option.title,
};
const defaultProps10 = {
  options: status,
  getOptionLabel: (option) => option.title,
};
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

class EditProfile extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    this.state = {
      selectedFile: null,
      selectedFileList: [],
      files: [],
      filesUrl: [],
      croppedImages: [],
      croppedImagesUrls: [],
      Age: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1 / 1,
    };
  }
  onChange = (info) => {
    console.log("Info:", info);
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        nextState.selectedFileList = [
          ...this.state.selectedFileList,
          info.file,
        ];
        break;
      case "done":
        let selectedFileList = JSON.parse(
          JSON.stringify(this.state.selectedFileList)
        );
        let index = selectedFileList.findIndex(
          (file) => file.uid == info.file.uid
        );
        selectedFileList[index] = info.file;
        nextState.selectedFile = info.file;
        nextState.selectedFileList = selectedFileList;
        break;

      default:
        // error or removed
        nextState.selectedFile = null;
        nextState.selectedFileList = this.state.selectedFileList.filter(
          (file) => file.name != info.file.name
        );
    }
    console.log(nextState);
    this.setState(() => ({ ...nextState }));
  };
  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedArea, croppedAreaPixels });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };
  saveImage = async (index) => {
    try {
      const croppedImage = await getCroppedImg(
        this.state.filesUrl[index],
        this.state.croppedAreaPixels
      );
      console.log("donee", { croppedImage });
      this.setState({
        croppedImagesUrls: [...this.state.croppedImagesUrls, croppedImage.url],
        croppedImages: [...this.state.croppedImages, croppedImage],
      });
      console.log(this.state.croppedImages);
      // setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e);
    }
  };
  handleFile = async (e) => {
    e.preventDefault();
    var files = e.target.files;
    var flag = true;
    for (var i = 0; i < e.target.files.length; i++) {
      if (
        files[i].name
          .substr(files[i].name.lastIndexOf(".") + 1, files[i].length)
          .toLowerCase() != "png" &&
        files[i].name
          .substr(files[i].name.lastIndexOf(".") + 1, files[i].length)
          .toLowerCase() != "jpg" &&
        files[i].name
          .substr(files[i].name.lastIndexOf(".") + 1, files[i].length)
          .toLowerCase() != "jpeg"
      ) {
        flag = false;
      }
    }
    if (flag) {
      if (e.target.files.length + this.state.files.length < 5) {
        this.setState({ files: [...this.state.files, ...e.target.files] });
        var newUrls = [];
        for (var i = 0; i < e.target.files.length; i++) {
          newUrls.push(URL.createObjectURL(e.target.files[i]));
        }
        this.setState({ filesUrl: [...this.state.filesUrl, ...newUrls] });
      } else {
        alert("Please Select maximum of 4 files");
      }
    } else {
      alert("Only Select Image files");
    }
  };
  personelInfo = (e) => {
    e.preventDefault();

    if (e.target.experience.value > 50) {
      alert("Experience cannot be above 50 years");
    } else {
      if (e.target.description.value) {
        if (
          e.target.description.value.length >= 10 ||
          e.target.description.value.length <= 500
        ) {
          // if (
          //   e.target.height_in.value === "cm" &&
          //   (e.target.height.value < 90 || e.target.height.value > 230)
          // ) {
          //   alert("Height should be between 90cm to 230cm");
          // }
          //  else {
          // if (
          //   e.target.height_in.value === "ft" &&
          //   (e.target.height.value < 1 || e.target.height.value > 8)
          // ) {
          //   alert("Height should be between 1ft to 8ft");
          // } else {
          // if (
          //   e.target.height_in.value === "inch" &&
          //   (e.target.height.value < 36 || e.target.height.value > 96)
          // ) {
          //   alert("Height should be between 36inches to 96 inches");
          // }
          //  else {
          let finalSelectedFilesList = this.state.filesUrl.concat(
            this.state.selectedFileList
          );
          // console.log("selected lst", this.state.selectedFileList)
          // console.log("files lst", this.state.filesUrl)
          // console.log("final selected lst", finalSelectedFilesList)
          // console.log('length', Number(this.state.filesUrl.length) + Number(this.state.selectedFileList.length))
          this.props.personelInfo({
            id: this.props.user.id,
            first_name: e.target.first_name.value,
            last_name: e.target.last_name.value,
            gender: e.target.gender.value,
            files: finalSelectedFilesList,
            age: this.state.Age,
            experience: e.target.experience.value,
            description: e.target.description.value,
            countries: this.state.countries,
            languages: this.state.languages,
            district: this.state.district,
            looking_for: this.state.looking_for,
            // smoke: e.target.smoke.value,
            // drinking: e.target.drinking.value,
            religion: e.target.religion.value,
            profession: e.target.profession.value,
            education: e.target.education.value,
            status: e.target.status.value,
            // height: e.target.height.value,
          });
        } else {
          alert("Description should be 10 to 500 characters long");
        }
      } else {
        alert("Please add some description");
      }
      // }
      // }
      // }
    }
  };
  onDateChange = (date, dateString) => {
    this.setState({ date, dateString });
  };
  componentDidMount() {
    var { user } = this.props;
    this.props.getUser(user.id);
    this.setState({
      filesUrl: user.images,
      croppedImages: user.images,
      croppedImagesUrls: user.images,
      files: user.images,
      countries: user.countries,
      languages: user.languages,
      district: user.district,
      looking_for: user.looking_for,
      Age: user.age,
      experience: user.experience,
      height: user.height,
    });
  }
  render() {
    var { user, loading, updated } = this.props;
    console.log(this.state);
    if (updated) {
      return <Redirect to="/dashboard/profile-detail" />;
    }

    return (
      <>
        <div class="container">
          <div class="row">
            <div class="col-md-10 col-md-offset-3">
              <div class="form-area My_Area">
                <form role="form" onSubmit={this.personelInfo}>
                  <br style={{ clear: "both" }} />
                  <h2
                    className="pl-3"
                    style={{ marginBottom: " 25px ", fontWeight: "600" }}
                  >
                    Edit Profile
                  </h2>
                  <div class="col-md-12">
                    {" "}
                    <br />
                    <h3> PERSONAL INFORMATION</h3>
                    <div class="form-group">
                      <label for="Country">First Name:</label>
                      <input
                        type="text"
                        class="form-control"
                        name="first_name"
                        placeholder="First Name"
                        defaultValue={user.first_name}
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label for="Country">Last Name:</label>
                      <input
                        type="text"
                        class="form-control"
                        name="last_name"
                        placeholder="Last Name"
                        defaultValue={user.last_name}
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label for="Country">Gender:</label>
                      <select
                        class="form-select"
                        id="gender"
                        required="true"
                        defaultValue={user.gender}
                        name="gender"
                      >
                        <option value="ml">Male</option>
                        <option value="fml">Female</option>
                      </select>
                    </div>
                    <label htmlFor="Country">Photos of Yourself</label>
                    <br />
                    {/* {console.log(this.state.selectedFileList)} */}
                    <ImgCrop grid>
                      <Upload
                        listType="picture-card"
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        fileList={this.state.selectedFileList}
                        customRequest={dummyRequest}
                        onChange={this.onChange}
                      >
                        {Number(this.state.filesUrl.length) +
                          Number(this.state.selectedFileList.length) <
                          4 && "+ Upload"}
                      </Upload>
                    </ImgCrop>
                    {/* <label className="custom-input">

                      <input
                        className="mt-3 d-none"
                        type="file"
                        multiple
                        onChange={this.handleFile}
                        id="myFile"
                        name="listingFile"
                        disabled={this.state.filesUrl.length == 4}
                      />
                      <i class="fas fa-lg text-primary fa-upload"></i> Upload
                      files
                    </label>
                    <br></br> */}
                    <Row>
                      {this.state.filesUrl.map((items, index) => {
                        console.log(user.profile_pic);
                        return (
                          <>
                            <Col xs={6} lg={3} className="px-0 px-sm-3 mb-2">
                              <div>
                                <img
                                  key={index}
                                  height="100"
                                  width="100"
                                  src={this.state.filesUrl[index] || items}
                                />
                                <i
                                  hidden={user.profile_pic == items}
                                  onClick={() => {
                                    let {
                                      filesUrl,
                                      files,
                                      // croppedImages,
                                      // croppedImagesUrls,
                                    } = this.state;
                                    if (filesUrl[index] === files[index]) {
                                      this.props.removeImage(
                                        files[index],
                                        user.id
                                      );
                                    }

                                    filesUrl.splice(index, 1);
                                    files.splice(index, 1);
                                    // croppedImages.splice(index, 1);
                                    // croppedImagesUrls.splice(index, 1);

                                    this.setState({
                                      filesUrl,
                                      files,
                                      // croppedImages,
                                      // croppedImagesUrls,
                                    }); // This will update the state and trigger a rerender of the components
                                  }}
                                  class=" mr-2 far text-danger fa-times-circle"
                                ></i>
                              </div>
                              {user.profile_pic != items &&
                              user.images[index] ? (
                                <Button
                                  className="mt-2"
                                  size="sm"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.makeProfile(index, user.id);
                                  }}
                                  class=" mr-2 "
                                  color="success"
                                >
                                  Make Profile Pic
                                </Button>
                              ) : null}
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                    <p className="text-danger">
                      *Maximum 4 pictures can be uploaded*
                    </p>
                    <p className="text-danger">
                      *Cannot make profile image if it's a new image and not
                      updated*
                    </p>
                  </div>
                  <div class="form-group">
                    <label for="Country">Date of Birth:</label>
                    <br />
                    <DatePicker
                      format="YYYY-MM-DD"
                      value={this.state.Age ? moment(this.state.Age) : null}
                      disabledDate={(current) => {
                        return (
                          (current &&
                            current.isAfter(
                              moment().subtract(18, "years"),
                              "s"
                            )) ||
                          (current &&
                            current.isBefore(
                              moment().subtract(99, "years"),
                              "s"
                            ))
                        );
                      }}
                      style={{ width: "100%" }}
                      name="Age"
                      placeholder="Age 18-----99"
                      onChange={(date, dateString) => {
                        this.setState({ Age: dateString });
                      }}
                    />
                    {/* <input
                      type="date"
                      class="form-control"
                      name="Age"
                      placeholder="Age 18-----99"
                      min={moment().subtract(99, "y").format("YYYY-MM-DD")}
                      max={moment().subtract(18, "y").format("YYYY-MM-DD")}
                      onChange={(e) => {
                        e.preventDefault();

                        this.setState({ Age: e.target.value });
                      }}
                      value={this.state.Age}
                      required
                    /> */}
                  </div>
                  <div className="form-group">
                    <label htmlFor="Country">Years Of Work Experience:</label>
                    <input
                      type="number"
                      className="form-control"
                      id="age"
                      name="experience"
                      onChange={(e) => {
                        e.preventDefault();
                        if (e.target.value < 0) {
                          alert("Cannot add negative experience");
                        } else if (e.target.value.length >= 3) {
                          alert("experience should be less than 50");
                        } else if (e.target.value < 50) {
                          this.setState({ experience: e.target.value });
                        } else {
                          alert("experience should be less than 50");
                        }
                      }}
                      value={this.state.experience}
                      placeholder="Years Of Experience"
                      defaultValue={user.experience}
                    />
                  </div>
                  <div className="form-group">
                    {this.state.countries ? (
                      <Autocomplete
                        getOptionLabel={(option) => option.title}
                        options={defaultProps.options.filter((option) => {
                          var titles = this.state.countries.reduce(
                            (acc, val) => acc.concat(val.title),
                            []
                          );

                          return !titles.includes(option.title);
                        })}
                        id="auto-complete"
                        autoComplete
                        multiple={true}
                        filterOptions={filterOptions}
                        includeInputInList
                        onChange={(e, value) => {
                          e.preventDefault();
                          if (value.length > 4) {
                            alert("Maximum 4 countries can be added");
                          } else {
                            this.setState({ countries: value });
                          }
                        }}
                        defaultValue={this.state.countries}
                        value={this.state.countries}
                        renderInput={(params) => (
                          <TextField
                            id="outlined-basic"
                            style={{ padding: "10px" }}
                            {...params}
                            label="WHAT COUNTRIES DO YOU IDENTIFY WITH (Select up to 4)"
                            margin="normal"
                            name="countries"
                          />
                        )}
                      />
                    ) : null}
                  </div>
                  <div className="form-group">
                    {this.state.languages ? (
                      <Autocomplete
                        getOptionLabel={(option) => option.title}
                        options={defaultProps2.options.filter((option) => {
                          var titles = this.state.languages.reduce(
                            (acc, val) => acc.concat(val.title),
                            []
                          );

                          return !titles.includes(option.title);
                        })}
                        id="auto-complete"
                        autoComplete
                        multiple={true}
                        filterOptions={filterOptions}
                        includeInputInList
                        onChange={(e, value) => {
                          e.preventDefault();
                          if (value.length > 4) {
                            alert("Maximum 4 languages can be added");
                          } else {
                            this.setState({ languages: value });
                          }
                        }}
                        defaultValue={this.state.languages}
                        value={this.state.languages}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ padding: "10px" }}
                            label="LANGUAGE(S) YOU FEEL COMFORTABLE CONVERSING IN. (Select up to 4)"
                            margin="normal"
                            name="languages"
                          />
                        )}
                      />
                    ) : null}
                  </div>
                  <div className="form-group">
                    {this.state.district ? (
                      <Autocomplete
                        getOptionLabel={(option) => option.title}
                        options={defaultProps3.options.filter((option) => {
                          var titles = this.state.district.reduce(
                            (acc, val) => acc.concat(val.title),
                            []
                          );

                          return !titles.includes(option.title);
                        })}
                        id="auto-complete"
                        autoComplete
                        multiple
                        includeInputInList
                        filterOptions={filterOptions}
                        onChange={(e, value) => {
                          e.preventDefault();
                          if (value.length > 2) {
                            alert("Maximum 2 districts can be added");
                          } else {
                            this.setState({ district: value });
                          }
                        }}
                        defaultValue={this.state.district}
                        value={this.state.district}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ padding: "10px" }}
                            label="WHICH DISTRICT  DO YOU/ YOUR FAMILY COME FROM IN LEBANON? (Select up to 2)"
                            margin="normal"
                            name="district"
                          />
                        )}
                      />
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="Country">
                      A short Bio/ describe yourself/ what you are looking for
                      <b className="text-danger">
                        *Description should be at least 10 characters long*
                      </b>
                      :
                    </label>
                    <Input
                      type="textarea"
                      className="form-control"
                      name="description"
                      maxLength={500}
                      minLength={10}
                      defaultValue={user.description}
                      placeholder="Description"
                    />
                  </div>

                  <br />
                  <div class="col-xs-12">
                    <h3>INTEREST</h3>

                    <div className="form-group">
                      {this.state.looking_for ? (
                        <Autocomplete
                          {...defaultProps4}
                          getOptionLabel={(option) => option.title}
                          options={defaultProps4.options.filter((option) => {
                            var titles = this.state.looking_for.reduce(
                              (acc, val) => acc.concat(val.title),
                              []
                            );

                            return !titles.includes(option.title);
                          })}
                          id="auto-complete"
                          autoComplete
                          multiple={true}
                          includeInputInList
                          filterOptions={filterOptions}
                          defaultValue={this.state.looking_for}
                          value={this.state.looking_for}
                          onChange={(e, value) => {
                            e.preventDefault();
                            if (value.length > 4) {
                              alert("Maximum 4 interests can be added");
                            } else {
                              this.setState({ looking_for: value });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ padding: "10px" }}
                              label="What are you looking for on this app?:"
                              margin="normal"
                              name="looking_for"
                            />
                          )}
                        />
                      ) : null}
                    </div>
                    {/* <div className="form-group">
                      <Autocomplete
                        {...defaultProps5}
                        id="auto-complete"
                        autoComplete
                        includeInputInList
                        filterOptions={filterOptions}
                        defaultValue={{ title: user.smoke }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ padding: "10px" }}
                            label="SMOKE (Optional):"
                            margin="normal"
                            name="smoke"
                          />
                        )}
                      />
                    </div>
                    <div className="form-group">
                      <Autocomplete
                        {...defaultProps6}
                        id="auto-complete"
                        autoComplete
                        includeInputInList
                        filterOptions={filterOptions}
                        defaultValue={{ title: user.drinking }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ padding: "10px" }}
                            label="ALCOHOL (Optional)::"
                            margin="normal"
                            name="drinking"
                          />
                        )}
                      />
                    </div> */}
                    <div className="form-group">
                      <Autocomplete
                        {...defaultProps7}
                        id="auto-complete"
                        autoComplete
                        includeInputInList
                        filterOptions={filterOptions}
                        defaultValue={{ title: user.religion }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ padding: "10px" }}
                            label="Religion:"
                            margin="normal"
                            required
                            name="religion"
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div class="col-md-12">
                    {" "}
                    <br />
                    <br />
                    <h3>OTHER</h3>
                    <div className="form-group">
                      <Autocomplete
                        {...defaultProps8}
                        id="auto-complete"
                        autoComplete
                        includeInputInList
                        filterOptions={filterOptions}
                        defaultValue={{ title: user.profession }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ padding: "10px" }}
                            label="Profession/ industry:"
                            margin="normal"
                            required
                            name="profession"
                          />
                        )}
                      />
                    </div>
                    <div className="form-group">
                      <Autocomplete
                        {...defaultProps9}
                        id="auto-complete"
                        autoComplete
                        includeInputInList
                        filterOptions={filterOptions}
                        defaultValue={{ title: user.education }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ padding: "10px" }}
                            label="Highest level of education:"
                            margin="normal"
                            required
                            name="education"
                          />
                        )}
                      />
                    </div>
                    <div className="form-group">
                      <Autocomplete
                        {...defaultProps10}
                        id="auto-complete"
                        autoComplete
                        includeInputInList
                        filterOptions={filterOptions}
                        defaultValue={{ title: user.status }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ padding: "10px" }}
                            label="RELATIONSHIP STATUS (Optional):"
                            margin="normal"
                            name="status"
                          />
                        )}
                      />
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="Country">Height:</label>
                      <InputGroup>
                        <Input
                          type="number"
                          className="form-control"
                          id="height"
                          name="height"
                          onChange={(e) => {
                            e.preventDefault();
                            if (e.target.value) {
                              if (this.state.height_in === "ft") {
                                if (e.target.value.length >= 2) {
                                  alert("height should be 1ft to 8 ft");
                                } else if (
                                  e.target.value <= 8 &&
                                  e.target.value >= 1
                                ) {
                                  this.setState({ height: e.target.value });
                                } else {
                                  alert("height should be 1ft to 8 ft");
                                }
                              } else {
                                if (this.state.height_in === "inch") {
                                  if (e.target.value.length >= 3) {
                                    alert(
                                      "height should be 36inches to 96 inches"
                                    );
                                  } else if (
                                    e.target.value.length == 2 &&
                                    e.target.value < 96 &&
                                    e.target.value > 36
                                  ) {
                                    this.setState({ height: e.target.value });
                                  } else {
                                    if (e.target.value.length == 1) {
                                      this.setState({ height: e.target.value });
                                    } else {
                                      alert(
                                        "height should be 36inches to 96 inches"
                                      );
                                    }
                                  }
                                } else {
                                  if (e.target.value.length > 3) {
                                    alert("height should be 90cm to 230 cm");
                                  } else if (
                                    e.target.value.length >= 2 &&
                                    e.target.value < 230 &&
                                    e.target.value > 90
                                  ) {
                                    this.setState({ height: e.target.value });
                                  } else {
                                    if (e.target.value.length >= 1) {
                                      this.setState({ height: e.target.value });
                                    } else {
                                      alert("height should be 90cm to 230 cm");
                                    }
                                  }
                                }
                              }
                            } else {
                              this.setState({ height: e.target.value });
                            }
                          }}
                          placeholder={
                            this.state.height_in === "cm"
                              ? "90cm----------230cm"
                              : this.state.height_in === "inch"
                              ? "36inches----------96inches"
                              : this.state.height_in === "ft"
                              ? "1ft----------8ft"
                              : null
                          }
                          value={this.state.height}
                        />
                        <InputGroupAddon>
                          <Input
                            name="height_in"
                            type="select"
                            defaultValue={user.height_in}
                          >
                            <option>ft</option>
                            <option>inch</option>
                            <option>cm</option>
                          </Input>
                        </InputGroupAddon>
                      </InputGroup>
                    </div> */}
                  </div>

                  <button
                    type="submit"
                    id="submit"
                    name="submit"
                    class="btn btn-primary Update"
                    disabled={
                      loading ||
                      !this.state.Age ||
                      !this.state.looking_for.length
                    }
                  >
                    {loading ? <Spinner size="sm" /> : "Update"}
                  </button>
                </form>
              </div>
            </div>
          </div>
          <Modal
            isOpen={
              this.state.files.length &&
              this.state.croppedImages.length < this.state.files.length
            }
          >
            <ModalHeader>Crop Images</ModalHeader>
            <ModalBody>
              <div
                style={{
                  position: "relative",
                  top: "0",
                  width: "100%",
                  height: "60vh",
                }}
              >
                <Cropper
                  image={this.state.filesUrl[this.state.croppedImages.length]}
                  crop={this.state.crop}
                  zoom={this.state.zoom}
                  aspect={this.state.aspect}
                  onCropChange={this.onCropChange}
                  onCropComplete={this.onCropComplete}
                  onZoomChange={this.onZoomChange}
                  style={{ width: "100%" }}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.saveImage(this.state.croppedImages.length);
                }}
              >
                Save
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </>
    );
  }
}
export function mapStateToProps(state) {
  return {
    loading: state.auth.requested,
    updated: state.auth.updated,
    registered: state.auth.registered,
    user: state.authUser.user,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    // registeredComplete: () => dispatch(removeRegistered()),
    // login: (credentials) => dispatch(login(credentials)),
    // loginGoogle: () => dispatch(signupGoogle()),
    getUser: (uid) => dispatch(getUser(uid)),
    removeImage: (image, user_id) => dispatch(removeImage(image, user_id)),
    makeProfile: (image, user_id) => dispatch(makeProfile(image, user_id)),
    personelInfo: (Info) => dispatch(updatePersonelInfo(Info)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
