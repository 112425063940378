import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import firebase from "./../firebase";
export const getPosts = () => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("posts")
      .onSnapshot((query) => {
        var tempUsers = [];
        query.forEach((doc) => {
          tempUsers.push({ id: doc.id, ...doc.data() });
        });

        dispatch({
          type: "GET_POSTS",
          posts: tempUsers,
        });
      });
  };
};
export const getMyPosts = (id) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("posts")
      .where("user_id", "==", id)
      .onSnapshot((query) => {
        var tempUsers = [];
        query.forEach((doc) => {
          tempUsers.push({ id: doc.id, ...doc.data() });
        });

        dispatch({
          type: "GET_MY_POSTS",
          posts: tempUsers,
        });
      });
  };
};
export const deletePost = (id) => {
  return async (dispatch) => {
    var post = await firebase.firestore().collection("posts").doc(id).get();
    var postImage = post.data().image;
    if (postImage) {
      await firebase.storage().refFromURL(postImage).delete();
    }
    firebase
      .firestore()
      .collection("posts")
      .doc(id)
      .delete()
      .then((query) => {
        dispatch({
          type: "DELETE_POST",
        });
      });
  };
};
export const updatePost = (pos, image) => {
  return async (dispatch) => {
    var post = await firebase.firestore().collection("posts").doc(pos.id).get();
    var obj;
    var url = post.data().image ? post.data().image : [];
    var tempUrl = null;
    for (let i = 0; i < image.length; i++) {
      if (image[i] != undefined) {
        var data = await firebase
          .storage()
          .ref()
          .child(
            "post_images/" + uuidv4() + "." + image[i].name.split(".").pop()
          )
          .put(image[i]);
        tempUrl = await data.ref.getDownloadURL();
        url.push(tempUrl);
      }
    }
    console.log(url.length, post.data().image.length);
    if (url.length == post.data().image.length) {
      obj = { text: pos.text };
    } else {
      obj = { text: pos.text, image: url };
    }
    firebase
      .firestore()
      .collection("posts")
      .doc(pos.id)
      .update(obj)
      .then((query) => {
        dispatch({
          type: "UPDATE_POST",
        });
        alert("Post Updated");
      });
  };
};
export const createPost = (post, image) => {
  return async (dispatch) => {
    var url = [];
    var tempUrl = null;
    for (let i = 0; i < image.length; i++) {
      if (image[i] != undefined) {
        var data = await firebase
          .storage()
          .ref()
          .child(
            "post_images/" + uuidv4() + "." + image[i].name.split(".").pop()
          )
          .put(image[i]);
        tempUrl = await data.ref.getDownloadURL();
        url.push(tempUrl);
      }
    }

    var obj;
    if (url.length) {
      obj = { ...post, image: url };
    } else {
      obj = { ...post };
    }
    await firebase
      .firestore()
      .collection("posts")
      .add({
        ...obj,
        date: moment().format(),
        comments: [],
        vote: [],
        downVote: [],
      })
      .then((query) => {
        dispatch({
          type: "CREATE_POST",
        });
      });
  };
};
export const upPost = (post_id, user_id) => {
  return async (dispatch) => {
    var doc = await firebase.firestore().collection("posts").doc(post_id).get();
    var docVotes = doc.data().vote;
    if (docVotes.includes(user_id)) {
      await firebase
        .firestore()
        .collection("posts")
        .doc(post_id)
        .update({
          vote: firebase.firestore.FieldValue.arrayRemove(user_id),
        })
        .then((query) => {
          dispatch({
            type: "UP_POST",
          });
        });
    } else {
      await firebase
        .firestore()
        .collection("posts")
        .doc(post_id)
        .update({
          vote: firebase.firestore.FieldValue.arrayUnion(user_id),
          downVote: firebase.firestore.FieldValue.arrayRemove(user_id),
        })
        .then((query) => {
          dispatch({
            type: "UP_POST",
          });
        });
    }
  };
};
export const downPost = (post_id, user_id) => {
  return async (dispatch) => {
    var doc = await firebase.firestore().collection("posts").doc(post_id).get();
    var docDownVotes = doc.data().downVote;
    if (docDownVotes.includes(user_id)) {
      await firebase
        .firestore()
        .collection("posts")
        .doc(post_id)
        .update({
          downVote: firebase.firestore.FieldValue.arrayRemove(user_id),
        })
        .then((query) => {
          dispatch({
            type: "DOWN_POST",
          });
        });
    } else {
      await firebase
        .firestore()
        .collection("posts")
        .doc(post_id)
        .update({
          downVote: firebase.firestore.FieldValue.arrayUnion(user_id),
          vote: firebase.firestore.FieldValue.arrayRemove(user_id),
        })
        .then((query) => {
          dispatch({
            type: "DOWN_POST",
          });
        });
    }
  };
};

export const comment = (comment, post_id) => {
  return async (dispatch) => {
    await firebase
      .firestore()
      .collection("posts")
      .doc(post_id)
      .update({
        comments: firebase.firestore.FieldValue.arrayUnion({
          ...comment,
          date: moment().format(),
          vote: [],
          downVote: [],
        }),
      })
      .then((query) => {
        dispatch({
          type: "CREATE_COMMENT",
        });
      });
  };
};
export const deleteComment = (index, post_id) => {
  return async (dispatch) => {
    var post = await firebase
      .firestore()
      .collection("posts")
      .doc(post_id)
      .get();
    var comments = post.data().comments;
    comments.splice(index, 1);
    await firebase
      .firestore()
      .collection("posts")
      .doc(post_id)
      .update({
        comments: comments,
      })
      .then((query) => {
        dispatch({
          type: "DELETE_COMMENT",
        });
      });
  };
};
export const upComment = (comment, post_id) => {
  return async (dispatch) => {
    var post = await firebase
      .firestore()
      .collection("posts")
      .doc(post_id)
      .get();

    var comments = post.data().comments;

    if (!comments[comment.index].vote.includes(comment.user_id)) {
      comments[comment.index].vote.push(comment.user_id);
      comments[comment.index].downVote = comments[
        comment.index
      ].downVote.filter((vote) => vote != comment.user_id);
    } else {
      comments[comment.index].vote = comments[comment.index].vote.filter(
        (vote) => vote != comment.user_id
      );
    }

    firebase
      .firestore()
      .collection("posts")
      .doc(post_id)
      .update({ comments })
      .then((res) => {
        dispatch({ type: "UP_COMMENT" });
      });
  };
};
export const downComment = (comment, post_id) => {
  return async (dispatch) => {
    var post = await firebase
      .firestore()
      .collection("posts")
      .doc(post_id)
      .get();

    var comments = post.data().comments;

    if (!comments[comment.index].downVote.includes(comment.user_id)) {
      comments[comment.index].downVote.push(comment.user_id);
      comments[comment.index].vote = comments[comment.index].vote.filter(
        (vote) => vote != comment.user_id
      );
    } else {
      comments[comment.index].downVote = comments[
        comment.index
      ].downVote.filter((vote) => vote != comment.user_id);
    }
    firebase
      .firestore()
      .collection("posts")
      .doc(post_id)
      .update({ comments })
      .then((res) => {
        dispatch({ type: "DOWN_COMMENT" });
      });
  };
};

export const removePostImage = (post_id, index) => {
  return async (dispatch) => {
    var post = await firebase
      .firestore()
      .collection("posts")
      .doc(post_id)
      .get();
    var images = post.data().image;
    var image = post.data().image[index];
    await firebase.storage().refFromURL(image).delete();
    images.splice(index, 1);

    firebase
      .firestore()
      .collection("posts")
      .doc(post_id)
      .update({ image: images })
      .then((res) => {
        dispatch({ type: "IMAGE_REMOVED" });
      });
  };
};
