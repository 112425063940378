const initState = {
  authError: "",
  requested: false,
  registered: false,
  capche: false,
  updated: false,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        authError: "",
        registered: false,
      };
    case "PHONE_LOGIN":
      return {
        ...state,
        authError: "",
        registered: false,
      };
    case "CAPCHE_DONE":
      return {
        ...state,
        capche: true,
      };
    case "LOGIN_FAIL":
      return {
        ...state,
        authError: action.error.message,
        registered: false,
      };
    case "REGISTER_SUCCESS":
      return {
        ...state,
        authError: "",
        registered: true,
      };
    case "PHONE_SIGNUP":
      return {
        ...state,
        authError: "",
        phone_registered: true,
      };
    case "PHONE_REMOVE":
      return {
        ...state,
        authError: "",
        phone_registered: false,
      };
    case "REGISTER_FAIL":
      return {
        ...state,
        registered: false,
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        authError: "",
        registered: false,
      };
    case "LOGOUT_FAIL":
      return {
        ...state,
        authError: "",
        registered: false,
      };
    case "AUTH_RESET":
      return {
        ...state,
        authError: "",
      };
    case "REGISTER_REMOVE":
      return {
        ...state,
        registered: false,
      };
    case "ACCOUNT_RECOVER":
      return {
        ...state,
        authError: "",
        registered: true,
      };
    case "EMAIL_VERIFICATION":
      return {
        ...state,
        authError: "",
        registered: true,
      };
    case "GET_USER":
      return {
        ...state,
      };
    case "ACTION_REQUEST":
      return {
        ...state,
        requested: true,
      };
    case "ACTION_REQUEST_END":
      return {
        ...state,
        requested: false,
      };
    case "UPDATE_COMPLETE":
      return {
        ...state,
        updated: true,
      };
    case "UPDATE_COMPLETE_REMOVE":
      return {
        ...state,
        updated: false,
      };

    default:
      return state;
  }
};

export default authReducer;
