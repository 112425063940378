import React, { Component } from 'react';
import '../../css/bootstrap.css';
import '../../css/FillProfile.css';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import '../../js/script';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const countries = [
	{ title: 'Åland Islands' },
	{ title: 'Afghanistan' },
	{ title: 'Albania' },
	{ title: 'Algeria' },
	{ title: 'American Samoa' },
	{ title: 'Andorra' },
	{ title: 'Angola' },
	{ title: 'Anguilla' },
	{ title: 'Antarctica' },
	{ title: 'Antigua and Barbuda' },
	{ title: 'Argentina' },
	{ title: 'Armenia' },
	{ title: 'Aruba' },
	{ title: 'Austria' },
	{ title: 'Azerbaijan' },
	{ title: 'Bahamas' },
	{ title: 'Bahrain' },
	{ title: 'Bangladesh' },
	{ title: 'Barbados' },
	{ title: 'Belarus' },
	{ title: 'Belgium' },
	{ title: 'Belize' },
	{ title: 'Benin' },
	{ title: 'Bermuda' },
	{ title: 'Bhutan' },
	{ title: 'Bolivia, Plurinational State of' },
	{ title: 'Bonaire, Sint Eustatius and Saba' },
	{ title: 'Bosnia and Herzegovina' },
	{ title: 'Bouvet Island' },
	{ title: 'Brazil' },
	{ title: 'British Indian Ocean Territory' },
	{ title: 'Brunei Darussalam' },
	{ title: 'Bulgaria' },
	{ title: 'Burkina Faso' },
	{ title: 'Burundi' },
	{ title: 'Cambodia' },
	{ title: 'Cameroon' },
	{ title: 'Canada' },
	{ title: 'Cape Verde' },
	{ title: 'Cayman Islands' },
	{ title: 'Central African Republic' },
	{ title: 'Chad' },
	{ title: 'Chile' },
	{ title: 'China' },
	{ title: 'Christmas Island' },
	{ title: 'Cocos (Keeling) Islands' },
	{ title: 'Colombia' },
	{ title: 'Comoros' },
	{ title: 'Congo' },
	{ title: 'Congo, the Democratic Republic of the' },
	{ title: 'Cook Islands' },
	{ title: 'Costa Rica' },
	{ title: "Côte d'Ivoire" },
	{ title: 'Croatia' },
	{ title: 'Cuba' },
	{ title: 'Curaçao' },
	{ title: 'Cyprus' },
	{ title: 'Czech Republic' },
	{ title: 'Denmark' },
	{ title: 'Djibouti' },
	{ title: 'Dominica' },
	{ title: 'Dominican Republic' },
	{ title: 'Ecuador' },
	{ title: 'Egypt' },
	{ title: 'El Salvador' },
	{ title: 'England' },
	{ title: 'Equatorial Guinea' },
	{ title: 'Eritrea' },
	{ title: 'Estonia' },
	{ title: 'Ethiopia' },
	{ title: 'Falkland Islands (Malvinas)' },
	{ title: 'Faroe Islands' },
	{ title: 'Fiji' },
	{ title: 'Finland' },
	{ title: 'France' },
	{ title: 'French Guiana' },
	{ title: 'French Polynesia' },
	{ title: 'French Southern Territories' },
	{ title: 'Gabon' },
	{ title: 'Gambia' },
	{ title: 'Georgia' },
	{ title: 'Germany' },
	{ title: 'Ghana' },
	{ title: 'Gibraltar' },
	{ title: 'Greece' },
	{ title: 'Greenland' },
	{ title: 'Grenada' },
	{ title: 'Guadeloupe' },
	{ title: 'Guam' },
	{ title: 'Guatemala' },
	{ title: 'Guernsey' },
	{ title: 'Guinea' },
	{ title: 'Guinea-Bissau' },
	{ title: 'Guyana' },
	{ title: 'Haiti' },
	{ title: 'Heard Island and McDonald Islands' },
	{ title: 'GuaHoly See (Vatican City State)m' },
	{ title: 'Honduras' },
	{ title: 'Hong Kong' },
	{ title: 'Hungary' },
	{ title: 'Iceland' },
	{ title: 'India' },
	{ title: 'Indonesia' },
	{ title: 'Iran, Islamic Republic of' },
	{ title: 'Iraq' },
	{ title: 'Ireland' },
	{ title: 'Isle of Man' },
	{ title: 'Israel' },
	{ title: 'Italy' },
	{ title: 'Jamaica' },
	{ title: 'Japan' },
	{ title: 'Jersey' },
	{ title: 'Jordan' },
	{ title: 'Kazakhstan' },
	{ title: 'Kenya' },
	{ title: 'Kiribati' },
	{ title: "Korea, Democratic People's Republic of" },
	{ title: 'Korea, Republic of' },
	{ title: 'Kuwait' },
	{ title: 'Kyrgyzstan' },
	{ title: "Lao People's Democratic Republic" },
	{ title: 'Latvia' },
	{ title: 'Lebanon' },
	{ title: 'Lesotho' },
	{ title: 'Liberia' },
	{ title: 'Libya' },
	{ title: 'Lithuania' },
	{ title: 'Luxembourg' },
	{ title: 'Macao' },
	{ title: 'Macedonia, the former Yugoslav Republic of' },
	{ title: 'Madagascar' },
	{ title: 'Malawi' },
	{ title: 'Malaysia' },
	{ title: 'Maldives' },
	{ title: 'Mali' },
	{ title: 'Malta' },
	{ title: 'Marshall Islands' },
	{ title: 'Martinique' },
	{ title: 'Mauritania' },
	{ title: 'Mauritius' },
	{ title: 'Mayotte' },
	{ title: 'Mexico' },
	{ title: 'Micronesia, Federated States of' },
	{ title: 'Moldova, Republic of' },
	{ title: 'Monaco' },
	{ title: 'Mongolia' },
	{ title: 'Morocco' },
	{ title: 'Mozambique' },
	{ title: 'Myanmar' },
	{ title: 'Namibia' },
	{ title: 'Nauru' },
	{ title: 'Nepal' },
	{ title: 'Netherlands' },
	{ title: 'New Caledonia' },
	{ title: 'New Zealand' },
	{ title: 'Nicaragua' },
	{ title: 'Niger' },
	{ title: 'Nigeria' },
	{ title: 'Niue' },
	{ title: 'Norfolk Island' },
	{ title: 'Northern Mariana Islands' },
	{ title: 'Norway' },
	{ title: 'Oman' },
	{ title: 'Pakistan' },
	{ title: 'Palau' },
	{ title: 'Palestinian Territory, Occupied' },
	{ title: 'Panama' },
	{ title: 'Papua New Guinea' },
	{ title: 'Peru' },
	{ title: 'Philippines' },
	{ title: 'Pitcairn' },
	{ title: 'Poland' },
	{ title: 'Portugal' },
	{ title: 'Puerto' },
	{ title: 'Rico' },
	{ title: 'Qatar' },
	{ title: 'Réunion' },
	{ title: 'Romania' },
	{ title: 'Russian Federation' },
	{ title: 'Rwanda' },
	{ title: 'Saint Barthélemy' },
	{ title: 'Saint Helena, Ascension and Tristan da Cunha' },
	{ title: 'Saint Kitts and Nevis' },
	{ title: 'Saint Lucia' },
	{ title: 'Saint Martin (French part)' },
	{ title: 'Saint Pierre and Miquelon' },
	{ title: 'Saint Vincent and the Grenadines' },
	{ title: 'Samoa' },
	{ title: 'San Marino' },
	{ title: 'Sao Tome and Principe' },
	{ title: 'Saudi Arabia' },
	{ title: 'Scontland' },
	{ title: 'Senegal' },
	{ title: 'Serbia' },
	{ title: 'Seychelles' },
	{ title: 'Sierra Leone' },
	{ title: 'Singapore' },
	{ title: 'Sint Maarten (Dutch part)' },
	{ title: 'Slovakia' },
	{ title: 'Slovenia' },
	{ title: 'Solomon Islands' },
	{ title: 'Somalia' },
	{ title: 'South Africa' },
	{ title: 'South Georgia and the South Sandwich Islands' },
	{ title: 'South Sudan' },
	{ title: 'Spain' },
	{ title: 'Sri Lanka' },
	{ title: 'Sudan' },
	{ title: 'Suriname' },
	{ title: 'Svalbard and Jan Mayen' },
	{ title: 'Swaziland' },
	{ title: 'Sweden' },
	{ title: 'Switzerland' },
	{ title: 'Syrian Arab Republic' },
	{ title: 'Taiwan, Province of China' },
	{ title: 'Tajikistan' },
	{ title: 'Tanzania, United Republic of' },
	{ title: 'Thailand' },
	{ title: 'Timor-Leste' },
	{ title: 'Togo' },
	{ title: 'Tokelau' },
	{ title: 'Tonga' },
	{ title: 'Trinidad and Tobago' },
	{ title: 'Tunisia' },
	{ title: 'Turkey' },
	{ title: 'Turkmenistan' },
	{ title: 'Turks and Caicos Islands' },
	{ title: 'Tuvalu' },
	{ title: 'Uganda' },
	{ title: 'Ukraine' },
	{ title: 'United Arab Emirates' },
	{ title: 'United States' },
	{ title: 'United States Minor Outlying Islands' },
	{ title: 'Uruguay' },
	{ title: 'Uzbekistan' },
	{ title: '	Venezuela, Bolivarian Republic of' },
	{ title: 'Viet Nam' },
	{ title: 'Virgin Islands, British' },
	{ title: 'Virgin Islands, U.S.' },
	{ title: 'Wales' },
	{ title: 'Wallis and Futuna' },
	{ title: 'Western Sahara' },
	{ title: 'Yemen' },
	{ title: 'Zambia' },
	{ title: 'Zimbabwe' },
];

const languages = [
	{ title: 'Afrikaans' },
	{ title: 'Albanian' },
	{ title: 'Arabic' },
	{ title: 'Armenian' },
	{ title: 'Basque' },
	{ title: 'Bengali' },
	{ title: 'Bulgarian' },
	{ title: 'Catalan' },
	{ title: 'Cambodian' },
	{ title: 'Chinese (Mandarin)' },
	{ title: 'Croatian' },
	{ title: 'Czech' },
	{ title: 'Danish' },
	{ title: 'Dutch' },
	{ title: 'English' },
	{ title: 'Estonian' },
	{ title: 'Fiji' },
	{ title: 'Finnish' },
	{ title: 'French' },
	{ title: 'Georgian' },
	{ title: 'German' },
	{ title: 'Greek' },
	{ title: 'Gujarati' },
	{ title: 'Hebrew' },
	{ title: 'Hindi' },
	{ title: 'Hungarian' },
	{ title: 'Icelandic' },
	{ title: 'Indonesian' },
	{ title: 'Irish' },
	{ title: 'Italian' },
	{ title: 'Japanese' },
	{ title: 'Javanese' },
	{ title: 'Korean' },
	{ title: 'Latin' },
	{ title: 'Latvian' },
	{ title: 'Lithuanian' },
	{ title: 'Macedonian' },
	{ title: 'Malay' },
	{ title: 'Malayalam' },
	{ title: 'Maltese' },
	{ title: 'Maori' },
	{ title: 'Marathi' },
	{ title: 'Mongolian' },
	{ title: 'Nepali' },
	{ title: 'Norwegian' },
	{ title: 'Persian' },
	{ title: 'Polish' },
	{ title: 'Portuguese' },
	{ title: 'Punjabi' },
	{ title: 'Quechua' },
	{ title: 'Romanian' },
	{ title: 'Russian' },
	{ title: 'Samoan' },
	{ title: 'Serbian' },
	{ title: 'Slovak' },
	{ title: 'Slovenian' },
	{ title: 'Spanish' },
	{ title: 'Swahili' },
	{ title: 'Swedish' },
	{ title: 'Tamil' },
	{ title: 'Tatar' },
	{ title: 'Telugu' },
	{ title: 'Thai' },
	{ title: 'Tibetan' },
	{ title: 'Tonga' },
	{ title: 'Turkish' },
	{ title: 'Ukrainian' },
	{ title: 'Urdu' },
	{ title: 'Uzbek' },
	{ title: 'Vietnamese' },
	{ title: 'Welsh' },
	{ title: 'Xhosa' },
];

const districts = [
	{ title: 'Akkar' },
	{ title: 'Baalbek' },
	{ title: 'Hermel' },
	{ title: 'Rashaya' },
	{ title: 'Zahle' },
	{ title: 'Baabda' },
	{ title: 'Byblos' },
	{ title: 'Chouf- Beiteddine' },
	{ title: '	Keserwan- Jounieh' },
	{ title: 'Matn- Jdeideh' },
	{ title: 'Bint Jbeil' },
	{ title: 'Hasbaya' },
	{ title: 'Marjeyoun' },
	{ title: 'Nabatieh' },
	{ title: 'Batroun' },
	{ title: 'Bsharri' },
	{ title: 'Miniyeh-Danniyeh District' },
	{ title: 'Tripoli' },
	{ title: 'Zgharta' },
	{ title: 'Sidon' },
	{ title: 'Jezzine' },
	{ title: 'Tyre' },
	{ title: 'Ashrafiyeh' },
];

const lookings = [
	{ title: 'Investment' },
	{ title: 'Mentor' },
	{ title: 'Investor' },
	{ title: 'Mentee' },
	{ title: 'Internship' },
	{ title: 'Networking' },
	{ title: 'Job' },
	{ title: 'Freelance' },
	{ title: 'Art time' },
	{ title: 'People to hire' },
	{ title: 'Business owner' },
	{ title: 'Select all that apply' },
];

const Experience = [
	{ title: 'Less than 1' },
	{ title: '1-2 years' },
	{ title: '3-5 years' },
	{ title: '6-10 years' },
	{ title: '10+ years' },
];

const professions = [
	{ title: 'Auto' },
	{ title: 'Agriculture' },
	{ title: 'Education' },
	{ title: 'Consulting' },
	{ title: 'IT' },
	{ title: 'Healthcare' },

	{ title: 'Law' },
	{ title: 'Hospitality/Tourism' },

	{ title: 'Pharmaceutical' },
];

const professions2 = [
	{ title: 'Auto' },
	{ title: 'Agriculture' },
	{ title: 'Education' },
	{ title: 'Consulting' },
	{ title: 'IT' },
	{ title: 'Healthcare' },

	{ title: 'Law' },
	{ title: 'Hospitality/Tourism' },

	{ title: 'Pharmaceutical' },
	{ title: 'No Preference' },
];

const education = [
	{ title: 'High school' },
	{ title: 'GED' },
	{ title: 'In college' },
	{ title: 'In school' },
	{ title: 'Tech/ vocational' },
	{ title: 'Associates' },
	{ title: 'Bachelors' },
	{ title: 'Masters' },
	{ title: 'PhD' },
	{ title: 'Doctorate' },
];

// countries
const defaultProps = {
	options: countries,
	getOptionLabel: option => option.title,
};
const flatProps = {
	options: countries.map(option => option.title),
};

//   languages
const flatProps2 = {
	options: languages.map(option => option.title),
};
const defaultProps2 = {
	options: languages,
	getOptionLabel: option => option.title,
};

//  districts
const defaultProps3 = {
	options: districts,
	getOptionLabel: option => option.title,
};

const flatProps3 = {
	options: districts.map(option => option.title),
};

//  lookings
const defaultProps4 = {
	options: lookings,
	getOptionLabel: option => option.title,
};

const flatProps4 = {
	options: lookings.map(option => option.title),
};

//  Experience
const defaultProps5 = {
	options: Experience,
	getOptionLabel: option => option.title,
};

const flatProps5 = {
	options: Experience.map(option => option.title),
};

//   professions
const defaultProps8 = {
	options: professions,
	getOptionLabel: option => option.title,
};

const flatProps8 = {
	options: professions.map(option => option.title),
};
//   professions2
const defaultProps9 = {
	options: professions2,
	getOptionLabel: option => option.title,
};

const flatProps9 = {
	options: professions2.map(option => option.title),
};
// education
const defaultProps10 = {
	options: education,
	getOptionLabel: option => option.title,
};

const flatProps10 = {
	options: education.map(option => option.title),
};
export default class EditBusinessProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			files: [],
			files2: [],
			filesUrl: [],
			filesUrl2: [],
		};
	}

	handleFile = async e => {
		await this.setState({
			[e.target.name]: e.target.files,
			filesUrl: [
				...this.state.filesUrl,
				URL.createObjectURL(e.target.files[0]),
			],
		});
		this.setState({
			files: [...this.state.files, e.target.files[0]],
		});
	};
	handleFile2 = async e => {
		await this.setState({
			[e.target.name]: e.target.files,
			filesUrl2: [
				...this.state.filesUrl,
				URL.createObjectURL(e.target.files[0]),
			],
		});
		this.setState({
			files2: [...this.state.files2, e.target.files[0]],
		});
	};
	state = {
		loaded: false,
	};
	render() {
		return (
			<>
				<div className='container mt-3'>
					<form
						role='form'
						onSubmit={e => {
							e.preventDefault();
						}}
					>
						<div className='row'>
							<div className='col-xs-12'>
								<div className='col-md-12'>
									<h2>Edit Profile</h2>
									<h4>PERSONAL INFORMATION</h4>
									<div className='form-group'>
										<label htmlFor='Country'>
											{' '}
											Name/Company/Organization:
										</label>
										<input
											type='text'
											className='form-control'
											id='name'
											name='name'
											placeholder='Name'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='Country'>Gender:</label>
										<select
											className='form-select'
											id='gender'
											name='gender'
										>
											<option value='ml'>Male</option>
											<option value='fml'>Female</option>
											<option value='non'>None</option>
										</select>
									</div>
									<div className='form-group'>
										<label htmlFor='Country'>
											BUSINESS IMAGES
										</label>
										<br />
										<label>
											<input
												className='mt-3 d-none'
												type='file'
												onChange={this.handleFile}
												id='myFile'
												name='listingFile'
												required
												disabled={
													this.state.filesUrl
														.length == 5
												}
											/>
											<i class='fas fa-lg text-primary fa-upload'></i>{' '}
											Upload files
										</label>
										<br></br>

										{this.state.filesUrl.map(
											(items, index) => {
												return (
													<>
														<img
															key={index}
															height='70'
															width='70'
															src={items}
														/>
														<i
															onClick={() => {
																let {
																	filesUrl,
																} = this.state;

																filesUrl.splice(
																	index,
																	1
																);

																this.setState({
																	filesUrl,
																}); // This will update the state and trigger a rerender of the components
															}}
															class='far text-danger fa-times-circle'
														></i>
													</>
												);
											}
										)}
									</div>
									<div className='form-group'>
										<label htmlFor='Country'>
											PERSONAL IMAGES
										</label>
										<br />
										<label>
											<input
												className='mt-3 d-none'
												type='file'
												onChange={this.handleFile2}
												id='myFile'
												name='listingFile2'
												required
												disabled={
													this.state.filesUrl2
														.length == 5
												}
											/>
											<i class='fas fa-lg text-primary fa-upload'></i>{' '}
											Upload files
										</label>
										<br></br>

										{this.state.filesUrl2.map(
											(items, index) => {
												return (
													<>
														<img
															key={index}
															height='70'
															width='70'
															src={items}
														/>
														<i
															onClick={() => {
																let {
																	filesUrl2,
																} = this.state;

																filesUrl2.splice(
																	index,
																	1
																);

																this.setState({
																	filesUrl2,
																}); // This will update the state and trigger a rerender of the components
															}}
															class='far text-danger fa-times-circle'
														></i>
													</>
												);
											}
										)}
									</div>
									<div className='form-group'>
										<label htmlFor='Country'>
											Connect with:
										</label>
										<select
											className='form-select'
											id='gender'
											name='gender'
										>
											<option value='ml'>Male</option>
											<option value='fml'>Female</option>
											<option value='mlfml'>Both</option>
										</select>
									</div>
									{/* <div className="form-group">
                    <label htmlFor="Country">Location:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="c_location"
                      name="c_location"
                      placeholder="Current Location"
                    />
                  </div> */}
									<div className='form-group'>
										<Autocomplete
											{...defaultProps}
											id='auto-complete'
											autoComplete
											multiple={true}
											includeInputInList
											renderInput={params => (
												<TextField
													{...params}
													label='Countries you identify with:'
													margin='normal'
												/>
											)}
										/>

										{/* <label htmlFor="Country">
											Countries you identify with:
										</label>
										<select
											className="form-select"
											id="country"
											multiple="multiple"
											name="country"
										>
											<option value="AF">Afghanistan</option>
											<option value="AX">Åland Islands</option>
											<option value="AL">Albania</option>
											<option value="DZ">Algeria</option>
											<option value="AS">American Samoa</option>
											<option value="AD">Andorra</option>
											<option value="AO">Angola</option>
											<option value="AI">Anguilla</option>
											<option value="AQ">Antarctica</option>
											<option value="AG">Antigua and Barbuda</option>
											<option value="AR">Argentina</option>
											<option value="AM">Armenia</option>
											<option value="AW">Aruba</option>
											<option value="AU">Australia</option>
											<option value="AT">Austria</option>
											<option value="AZ">Azerbaijan</option>
											<option value="BS">Bahamas</option>
											<option value="BH">Bahrain</option>
											<option value="BD">Bangladesh</option>
											<option value="BB">Barbados</option>
											<option value="BY">Belarus</option>
											<option value="BE">Belgium</option>
											<option value="BZ">Belize</option>
											<option value="BJ">Benin</option>
											<option value="BM">Bermuda</option>
											<option value="BT">Bhutan</option>
											<option value="BO">
												Bolivia, Plurinational State of
											</option>
											<option value="BQ">
												Bonaire, Sint Eustatius and Saba
											</option>
											<option value="BA">Bosnia and Herzegovina</option>
											<option value="BW">Botswana</option>
											<option value="BV">Bouvet Island</option>
											<option value="BR">Brazil</option>
											<option value="IO">British Indian Ocean Territory</option>
											<option value="BN">Brunei Darussalam</option>
											<option value="BG">Bulgaria</option>
											<option value="BF">Burkina Faso</option>
											<option value="BI">Burundi</option>
											<option value="KH">Cambodia</option>
											<option value="CM">Cameroon</option>
											<option value="CA">Canada</option>
											<option value="CV">Cape Verde</option>
											<option value="KY">Cayman Islands</option>
											<option value="CF">Central African Republic</option>
											<option value="TD">Chad</option>
											<option value="CL">Chile</option>
											<option value="CN">China</option>
											<option value="CX">Christmas Island</option>
											<option value="CC">Cocos (Keeling) Islands</option>
											<option value="CO">Colombia</option>
											<option value="KM">Comoros</option>
											<option value="CG">Congo</option>
											<option value="CD">
												Congo, the Democratic Republic of the
											</option>
											<option value="CK">Cook Islands</option>
											<option value="CR">Costa Rica</option>
											<option value="CI">Côte d'Ivoire</option>
											<option value="HR">Croatia</option>
											<option value="CU">Cuba</option>
											<option value="CW">Curaçao</option>
											<option value="CY">Cyprus</option>
											<option value="CZ">Czech Republic</option>
											<option value="DK">Denmark</option>
											<option value="DJ">Djibouti</option>
											<option value="DM">Dominica</option>
											<option value="DO">Dominican Republic</option>
											<option value="EC">Ecuador</option>
											<option value="EG">Egypt</option>
											<option value="SV">El Salvador</option>
											<option value="GQ">Equatorial Guinea</option>
											<option value="ER">Eritrea</option>
											<option value="EE">Estonia</option>
											<option value="ET">Ethiopia</option>
											<option value="FK">Falkland Islands (Malvinas)</option>
											<option value="FO">Faroe Islands</option>
											<option value="FJ">Fiji</option>
											<option value="FI">Finland</option>
											<option value="FR">France</option>
											<option value="GF">French Guiana</option>
											<option value="PF">French Polynesia</option>
											<option value="TF">French Southern Territories</option>
											<option value="GA">Gabon</option>
											<option value="GM">Gambia</option>
											<option value="GE">Georgia</option>
											<option value="DE">Germany</option>
											<option value="GH">Ghana</option>
											<option value="GI">Gibraltar</option>
											<option value="GR">Greece</option>
											<option value="GL">Greenland</option>
											<option value="GD">Grenada</option>
											<option value="GP">Guadeloupe</option>
											<option value="GU">Guam</option>
											<option value="GT">Guatemala</option>
											<option value="GG">Guernsey</option>
											<option value="GN">Guinea</option>
											<option value="GW">Guinea-Bissau</option>
											<option value="GY">Guyana</option>
											<option value="HT">Haiti</option>
											<option value="HM">
												Heard Island and McDonald Islands
											</option>
											<option value="VA">Holy See (Vatican City State)</option>
											<option value="HN">Honduras</option>
											<option value="HK">Hong Kong</option>
											<option value="HU">Hungary</option>
											<option value="IS">Iceland</option>
											<option value="IN">India</option>
											<option value="ID">Indonesia</option>
											<option value="IR">Iran, Islamic Republic of</option>
											<option value="IQ">Iraq</option>
											<option value="IE">Ireland</option>
											<option value="IM">Isle of Man</option>
											<option value="IL">Israel</option>
											<option value="IT">Italy</option>
											<option value="JM">Jamaica</option>
											<option value="JP">Japan</option>
											<option value="JE">Jersey</option>
											<option value="JO">Jordan</option>
											<option value="KZ">Kazakhstan</option>
											<option value="KE">Kenya</option>
											<option value="KI">Kiribati</option>
											<option value="KP">
												Korea, Democratic People's Republic of
											</option>
											<option value="KR">Korea, Republic of</option>
											<option value="KW">Kuwait</option>
											<option value="KG">Kyrgyzstan</option>
											<option value="LA">
												Lao People's Democratic Republic
											</option>
											<option value="LV">Latvia</option>
											<option value="LB">Lebanon</option>
											<option value="LS">Lesotho</option>
											<option value="LR">Liberia</option>
											<option value="LY">Libya</option>
											<option value="LI">Liechtenstein</option>
											<option value="LT">Lithuania</option>
											<option value="LU">Luxembourg</option>
											<option value="MO">Macao</option>
											<option value="MK">
												Macedonia, the former Yugoslav Republic of
											</option>
											<option value="MG">Madagascar</option>
											<option value="MW">Malawi</option>
											<option value="MY">Malaysia</option>
											<option value="MV">Maldives</option>
											<option value="ML">Mali</option>
											<option value="MT">Malta</option>
											<option value="MH">Marshall Islands</option>
											<option value="MQ">Martinique</option>
											<option value="MR">Mauritania</option>
											<option value="MU">Mauritius</option>
											<option value="YT">Mayotte</option>
											<option value="MX">Mexico</option>
											<option value="FM">
												Micronesia, Federated States of
											</option>
											<option value="MD">Moldova, Republic of</option>
											<option value="MC">Monaco</option>
											<option value="MN">Mongolia</option>
											<option value="ME">Montenegro</option>
											<option value="MS">Montserrat</option>
											<option value="MA">Morocco</option>
											<option value="MZ">Mozambique</option>
											<option value="MM">Myanmar</option>
											<option value="NA">Namibia</option>
											<option value="NR">Nauru</option>
											<option value="NP">Nepal</option>
											<option value="NL">Netherlands</option>
											<option value="NC">New Caledonia</option>
											<option value="NZ">New Zealand</option>
											<option value="NI">Nicaragua</option>
											<option value="NE">Niger</option>
											<option value="NG">Nigeria</option>
											<option value="NU">Niue</option>
											<option value="NF">Norfolk Island</option>
											<option value="MP">Northern Mariana Islands</option>
											<option value="NO">Norway</option>
											<option value="OM">Oman</option>
											<option value="PK">Pakistan</option>
											<option value="PW">Palau</option>
											<option value="PS">
												Palestinian Territory, Occupied
											</option>
											<option value="PA">Panama</option>
											<option value="PG">Papua New Guinea</option>
											<option value="PY">Paraguay</option>
											<option value="PE">Peru</option>
											<option value="PH">Philippines</option>
											<option value="PN">Pitcairn</option>
											<option value="PL">Poland</option>
											<option value="PT">Portugal</option>
											<option value="PR">Puerto Rico</option>
											<option value="QA">Qatar</option>
											<option value="RE">Réunion</option>
											<option value="RO">Romania</option>
											<option value="RU">Russian Federation</option>
											<option value="RW">Rwanda</option>
											<option value="BL">Saint Barthélemy</option>
											<option value="SH">
												Saint Helena, Ascension and Tristan da Cunha
											</option>
											<option value="KN">Saint Kitts and Nevis</option>
											<option value="LC">Saint Lucia</option>
											<option value="MF">Saint Martin (French part)</option>
											<option value="PM">Saint Pierre and Miquelon</option>
											<option value="VC">
												Saint Vincent and the Grenadines
											</option>
											<option value="WS">Samoa</option>
											<option value="SM">San Marino</option>
											<option value="ST">Sao Tome and Principe</option>
											<option value="SA">Saudi Arabia</option>
											<option value="SN">Senegal</option>
											<option value="RS">Serbia</option>
											<option value="SC">Seychelles</option>
											<option value="SL">Sierra Leone</option>
											<option value="SG">Singapore</option>
											<option value="SX">Sint Maarten (Dutch part)</option>
											<option value="SK">Slovakia</option>
											<option value="SI">Slovenia</option>
											<option value="SB">Solomon Islands</option>
											<option value="SO">Somalia</option>
											<option value="ZA">South Africa</option>
											<option value="GS">
												South Georgia and the South Sandwich Islands
											</option>
											<option value="SS">South Sudan</option>
											<option value="ES">Spain</option>
											<option value="LK">Sri Lanka</option>
											<option value="SD">Sudan</option>
											<option value="SR">Suriname</option>
											<option value="SJ">Svalbard and Jan Mayen</option>
											<option value="SZ">Swaziland</option>
											<option value="SE">Sweden</option>
											<option value="CH">Switzerland</option>
											<option value="SY">Syrian Arab Republic</option>
											<option value="TW">Taiwan, Province of China</option>
											<option value="TJ">Tajikistan</option>
											<option value="TZ">Tanzania, United Republic of</option>
											<option value="TH">Thailand</option>
											<option value="TL">Timor-Leste</option>
											<option value="TG">Togo</option>
											<option value="TK">Tokelau</option>
											<option value="TO">Tonga</option>
											<option value="TT">Trinidad and Tobago</option>
											<option value="TN">Tunisia</option>
											<option value="TR">Turkey</option>
											<option value="TM">Turkmenistan</option>
											<option value="TC">Turks and Caicos Islands</option>
											<option value="TV">Tuvalu</option>
											<option value="UG">Uganda</option>
											<option value="UA">Ukraine</option>
											<option value="AE">United Arab Emirates</option>
											<option value="GB">United Kingdom</option>
											<option value="US">United States</option>
											<option value="UM">
												United States Minor Outlying Islands
											</option>
											<option value="UY">Uruguay</option>
											<option value="UZ">Uzbekistan</option>
											<option value="VU">Vanuatu</option>
											<option value="VE">
												Venezuela, Bolivarian Republic of
											</option>
											<option value="VN">Viet Nam</option>
											<option value="VG">Virgin Islands, British</option>
											<option value="VI">Virgin Islands, U.S.</option>
											<option value="WF">Wallis and Futuna</option>
											<option value="EH">Western Sahara</option>
											<option value="YE">Yemen</option>
											<option value="ZM">Zambia</option>
											<option value="ZW">Zimbabwe</option>
										</select>*/}
									</div>
									<div className='form-group'>
										<Autocomplete
											{...defaultProps2}
											id='auto-complete'
											autoComplete
											multiple={true}
											includeInputInList
											renderInput={params => (
												<TextField
													{...params}
													label='Language you feel comfortable conversing in:'
													margin='normal'
												/>
											)}
										/>
										{/* <label htmlFor="Country">
											Language you feel comfortable conversing in:
										</label>
										<select
											className="form-select"
											id="language"
											multiple="multiple"
											name="language"
										>
											<option value="AF">Afrikaans</option>
											<option value="SQ">Albanian</option>
											<option value="AR">Arabic</option>
											<option value="HY">Armenian</option>
											<option value="EU">Basque</option>
											<option value="BN">Bengali</option>
											<option value="BG">Bulgarian</option>
											<option value="CA">Catalan</option>
											<option value="KM">Cambodian</option>
											<option value="ZH">Chinese (Mandarin)</option>
											<option value="HR">Croatian</option>
											<option value="CS">Czech</option>
											<option value="DA">Danish</option>
											<option value="NL">Dutch</option>
											<option value="EN">English</option>
											<option value="ET">Estonian</option>
											<option value="FJ">Fiji</option>
											<option value="FI">Finnish</option>
											<option value="FR">French</option>
											<option value="KA">Georgian</option>
											<option value="DE">German</option>
											<option value="EL">Greek</option>
											<option value="GU">Gujarati</option>
											<option value="HE">Hebrew</option>
											<option value="HI">Hindi</option>
											<option value="HU">Hungarian</option>
											<option value="IS">Icelandic</option>
											<option value="ID">Indonesian</option>
											<option value="GA">Irish</option>
											<option value="IT">Italian</option>
											<option value="JA">Japanese</option>
											<option value="JW">Javanese</option>
											<option value="KO">Korean</option>
											<option value="LA">Latin</option>
											<option value="LV">Latvian</option>
											<option value="LT">Lithuanian</option>
											<option value="MK">Macedonian</option>
											<option value="MS">Malay</option>
											<option value="ML">Malayalam</option>
											<option value="MT">Maltese</option>
											<option value="MI">Maori</option>
											<option value="MR">Marathi</option>
											<option value="MN">Mongolian</option>
											<option value="NE">Nepali</option>
											<option value="NO">Norwegian</option>
											<option value="FA">Persian</option>
											<option value="PL">Polish</option>
											<option value="PT">Portuguese</option>
											<option value="PA">Punjabi</option>
											<option value="QU">Quechua</option>
											<option value="RO">Romanian</option>
											<option value="RU">Russian</option>
											<option value="SM">Samoan</option>
											<option value="SR">Serbian</option>
											<option value="SK">Slovak</option>
											<option value="SL">Slovenian</option>
											<option value="ES">Spanish</option>
											<option value="SW">Swahili</option>
											<option value="SV">Swedish</option>
											<option value="TA">Tamil</option>
											<option value="TT">Tatar</option>
											<option value="TE">Telugu</option>
											<option value="TH">Thai</option>
											<option value="BO">Tibetan</option>
											<option value="TO">Tonga</option>
											<option value="TR">Turkish</option>
											<option value="UK">Ukrainian</option>
											<option value="UR">Urdu</option>
											<option value="UZ">Uzbek</option>
											<option value="VI">Vietnamese</option>
											<option value="CY">Welsh</option>
											<option value="XH">Xhosa</option>
										</select>*/}
									</div>
									<div className='form-group'>
										<Autocomplete
											{...defaultProps}
											id='auto-complete'
											autoComplete
											multiple={true}
											includeInputInList
											renderInput={params => (
												<TextField
													{...params}
													label='	Where they came from in Lebanon (based on district):'
													margin='normal'
												/>
											)}
										/>
										{/* <label htmlFor="Country">
											Where they came from in Lebanon (based on district):
										</label>
										<select
											className="form-select"
											id="district"
											multiple="multiple"
											name="district"
										>
											<option value="Akkar">Akkar</option>
											<option value="Baalbek">Baalbek</option>
											<option value="Hermel">Hermel</option>
											<option value="Rashaya">Rashaya</option>
											<option value="Zahle">Zahle</option>
											<option value="Aley">Aley</option>
											<option value="Baabda">Baabda</option>
											<option value="Byblos">Byblos</option>
											<option value="Chouf- Beiteddine">
												Chouf- Beiteddine
											</option>
											<option value=" Keserwan- Jounieh">
												Keserwan- Jounieh
											</option>
											<option value="Matn-Jdeideh">Matn- Jdeideh</option>
											<option value="Bint-Jbeil">Bint Jbeil</option>
											<option value="Hasbaya">Hasbaya</option>
											<option value="Marjeyoun">Marjeyoun</option>
											<option value="Nabatieh">Nabatieh</option>
											<option value="Batroun">Batroun</option>
											<option value="Bsharri">Bsharri</option>
											<option value="Miniyeh-Danniyeh">
												Miniyeh-Danniyeh District
											</option>
											<option value="Tripoli">Tripoli</option>
											<option value="Zgharta">Zgharta</option>
											<option value="Sidon">Sidon</option>
											<option value="Jezzine">Jezzine</option>
											<option value="Tyre">Tyre</option>
											<option value="Ashrafiyeh">Ashrafiyeh</option>
										</select> */}
									</div>
									<div className='form-group'>
										<Autocomplete
											{...defaultProps4}
											id='auto-complete'
											autoComplete
											includeInputInList
											renderInput={params => (
												<TextField
													{...params}
													label='What you are looking for?'
													margin='normal'
												/>
											)}
										/>
										{/* <label htmlFor="Country">What you are looking for?</label>
										<select className="form-select">
											<option>Investment</option>
											<option>Investor</option>
											<option>Mentor</option>
											<option>Mentee</option>
											<option>Internship</option>
											<option>Job</option>
											<option>Networking</option>
											<option>Freelance</option>
											<option>Art time</option>
											<option>People to hire</option>
											<option>Business owner</option>
											<option>Select all that apply</option>
										</select>*/}
									</div>
									<div className='form-group'>
										<Autocomplete
											{...defaultProps4}
											id='auto-complete'
											autoComplete
											includeInputInList
											renderInput={params => (
												<TextField
													{...params}
													label='	Who do you want to connect with:'
													margin='normal'
												/>
											)}
										/>
										{/* <label htmlFor="Country">
											Who do you want to connect with:
										</label>
										<select className="form-select">
											<option>Investment</option>
											<option>Investor</option>
											<option>Mentor</option>
											<option>Mentee</option>
											<option>Internship</option>
											<option>Job</option>
											<option>Networking</option>
											<option>Freelance</option>
											<option>Art time</option>
											<option>People to hire</option>
											<option>Business owner</option>
											<option>Select all that apply</option>
										</select> */}
									</div>

									<div className='form-group'>
										<Autocomplete
											{...defaultProps5}
											id='auto-complete'
											autoComplete
											includeInputInList
											renderInput={params => (
												<TextField
													{...params}
													label='	Year of Experience:'
													margin='normal'
												/>
											)}
										/>
										{/* <label htmlFor="Country">Year of Experience:</label>
										<select className="form-select">
											<option>Less than 1</option>
											<option>1-2 years</option>
											<option>3-5 years</option>
											<option>6-10 years</option>
											<option>10+ years</option>
										</select> */}
									</div>
									<div className='form-group'>
										<Autocomplete
											{...defaultProps8}
											id='auto-complete'
											autoComplete
											includeInputInList
											renderInput={params => (
												<TextField
													{...params}
													label='	Profession/Industry:'
													margin='normal'
												/>
											)}
										/>
										{/* <label htmlFor="Country">Profession/Industry:</label>
										<select multiple className="form-select">
											<option>Auto</option>
											<option>Agriculture</option>
											<option>Education</option>
											<option>Consulting</option>
											<option>IT</option>
											<option>Healthcare</option>
											<option>Law</option>
											<option>Hospitality/Tourism</option>
											<option>Pharmaceutica</option>
										</select> */}
									</div>
									<div className='form-group'>
										<Autocomplete
											{...defaultProps9}
											id='auto-complete'
											autoComplete
											includeInputInList
											renderInput={params => (
												<TextField
													{...params}
													label='	Do you want to connect w/ someone who is:'
													margin='normal'
												/>
											)}
										/>
										{/* <label htmlFor="Country">
											

											Do you want to connect w/ someone who is:
										</label>
										<select multiple className="form-select">
											<option>Auto</option>
											<option>Agriculture</option>
											<option>Education</option>
											<option>Consulting</option>
											<option>IT</option>
											<option>Healthcare</option>
											<option>Law</option>
											<option>Hospitality/Tourism</option>
											<option>Pharmaceutica</option>
											<option>No Preference</option>
										</select> */}
									</div>
									<div className='form-group'>
										<Autocomplete
											{...defaultProps10}
											id='auto-complete'
											autoComplete
											includeInputInList
											renderInput={params => (
												<TextField
													{...params}
													label='	Highest Level of Education:'
													margin='normal'
												/>
											)}
										/>
										{/* <label htmlFor="Country">Highest Level of Education:</label>
										<select className="form-select">
											<option>Highschool</option>
											<option>GED</option>
											<option>Tech</option>
											<option>Vocational</option>
											<option>In college</option>
											<option>Associates</option>
											<option>Bachelors</option>
											<option>Masters</option>
											<option>PhD</option>
											<option>Doctorate</option>
										</select> */}
									</div>
									<div className='form-group'>
										<Autocomplete
											{...defaultProps10}
											id='auto-complete'
											autoComplete
											includeInputInList
											renderInput={params => (
												<TextField
													{...params}
													label='	Do you want to connect w/ someone who is:'
													margin='normal'
												/>
											)}
										/>
										{/* <label htmlFor="Country">
											Do you want to connect w/ someone who is:
										</label>
										<select className="form-select">
											<option>Highschool</option>
											<option>GED</option>
											<option>Tech</option>
											<option>Vocational</option>
											<option>In college</option>
											<option>Associates</option>
											<option>Bachelors</option>
											<option>Masters</option>
											<option>PhD</option>
											<option>Doctorate</option>
											<option>No Preference</option>
										</select> */}
									</div>
									<div className='form-group'>
										<label htmlFor='Country'>
											{' '}
											Business LinkdIn Url:
										</label>
										<input
											type='text'
											className='form-control'
											id='name'
											name='name'
											placeholder=' https://....'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='Country'>
											{' '}
											Business Web Url:
										</label>
										<input
											type='text'
											className='form-control'
											id='name'
											name='name'
											placeholder=' https://....'
										/>
									</div>

									<div class='float-right'>
										<button
											className='btn btn-primary nextBtn  btn-lg pull-right'
											type='submit'
											onClick={e => {
												e.preventDefault();
												this.props.history.push(
													'/dashboard'
												);
											}}
										>
											Finish
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</>
		);
	}
}
