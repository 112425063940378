import moment from "moment";
import firebase from "./../firebase";
export const getConnectRequests = () => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("connect_requests")
      .onSnapshot((query) => {
        var tempUsers = [];
        query.forEach((doc) => {
          tempUsers.push({ id: doc.id, ...doc.data() });
        });

        dispatch({
          type: "GET_CONNECT_REQUESTS",
          connect_requests: tempUsers,
        });
      });
  };
};
export const connectRequest = (loggedUid, uid) => {
  return (dispatch) => {
    firebase.firestore().collection("notifications").add({
      user: loggedUid,
      notification: "You have a connect request from ",
      notification_for: uid,
      date: moment().format(),
      status: "unread",
    });
    firebase
      .firestore()
      .collection("connect_requests")
      .add({
        request_by: loggedUid,
        requested_to: uid,
      })
      .then((res) => {
        dispatch({
          type: "CONNET_REQUEST",
        });
      });
  };
};
export const removeConnect = (loggedUid, uid) => {
  return async (dispatch) => {
    await firebase
      .firestore()
      .collection("users")
      .doc(loggedUid)
      .update({
        connects: firebase.firestore.FieldValue.arrayRemove(uid),
      });
    firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({
        connects: firebase.firestore.FieldValue.arrayRemove(loggedUid),
      })
      .then((res) => {
        dispatch({
          type: "REMOVE_CONNECT",
        });
      });
  };
};
export const removeRequest = (id) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("connect_requests")
      .doc(id)
      .delete()
      .then((res) => {
        dispatch({
          type: "CONNECT_REQUEST_DELETE",
        });
      });
  };
};
export const blockUser = (block) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("users")
      .doc(block.logged_id)
      .update({
        block: firebase.firestore.FieldValue.arrayUnion(block.block_id),
      });
    firebase
      .firestore()
      .collection("connect_requests")
      .doc(block.request_id)
      .delete()
      .then((res) => {
        dispatch({
          type: "CONNECT_REQUEST_DELETE",
        });
      });
  };
};
export const unblockUser = (block) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("users")
      .doc(block.logged_id)
      .update({
        block: firebase.firestore.FieldValue.arrayRemove(block.block_id),
      });
  };
};
export const acceptRequest = (id) => {
  return async (dispatch) => {
    var request = await firebase
      .firestore()
      .collection("connect_requests")
      .doc(id)
      .get();

    var request_by = request.data().request_by;
    var requested_to = request.data().requested_to;
    firebase.firestore().collection("notifications").add({
      user: requested_to,
      notification: "Your connect request is accepted by ",
      notification_for: request_by,
      date: moment().format(),
      status: "unread",
    });
    await firebase
      .firestore()
      .collection("users")
      .doc(request_by)
      .update({
        connects: firebase.firestore.FieldValue.arrayUnion(requested_to),
      });
    await firebase
      .firestore()
      .collection("users")
      .doc(requested_to)
      .update({
        connects: firebase.firestore.FieldValue.arrayUnion(request_by),
      });

    firebase
      .firestore()
      .collection("connect_requests")
      .doc(id)
      .delete()
      .then((res) => {
        dispatch({
          type: "CONNECT_REQUEST_ACCEPTED",
        });
      });
  };
};
