import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { removeConnect } from "../../store/actions/connectActions";
import { getAllUsers, getUser } from "../../store/actions/userActions";

class Notifications extends Component {
  state = { search: "" };
  componentDidMount() {
    var { loggedUser } = this.props;
    this.props.getUser(loggedUser.id);
    this.props.getAllUsers();
  }
  render() {
    var { loggedUser, users } = this.props;
    var connectUsers =
      users &&
      loggedUser &&
      users.filter((us) => loggedUser.connects.includes(us.id));

    return (
      <>
        <div className="minHeight">
          <div class=" mt-5 main-content container-fluid">
            <div class=" ml-lg-5 ml-2 pl-2 page-title">
              <h3>Connects</h3>
            </div>
            <div class="col-md-4   col-md-offset-3">
              <div id="custom-search-input">
                <div class="input-group col-md-12 p-0">
                  <input
                    type="text"
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ search: e.target.value });
                    }}
                    class="  search-query form-control"
                    placeholder="Search"
                  />
                  <span class="input-group-btn">
                    <button class="btn btn-danger" type="button">
                      <span class=" glyphicon glyphicon-search"></span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid   pl-3 pr-3 ">
            <div className="container-fluid   shadow profile-card">
              <section class="container-fluid mt-4 mb-4 ">
                <div class="container-fluid p-0">
                  <div class="row mb-3">
                    {connectUsers &&
                    connectUsers.filter((us) => {
                      if (this.state.search) {
                        return (
                          us.first_name
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()) ||
                          us.last_name
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase())
                        );
                      } else {
                        return true;
                      }
                    }).length ? null : (
                      <p className="text-danger mt-3">
                        {this.state.search
                          ? "Try some other search!"
                          : "No Connects Yet!"}
                      </p>
                    )}
                    {connectUsers &&
                      connectUsers
                        .filter((us) => {
                          if (this.state.search) {
                            return (
                              us.first_name
                                .toLowerCase()
                                .includes(this.state.search.toLowerCase()) ||
                              us.last_name
                                .toLowerCase()
                                .includes(this.state.search.toLowerCase())
                            );
                          } else {
                            return true;
                          }
                        })
                        .map((us) => {
                          return (
                            <div class="col-md-6 mt-4 mb-4 p-0">
                              <div class="d-flex flex-row rounded">
                                <div class="p-0 img-div-cust-width">
                                  <Link
                                    to={{
                                      pathname:
                                        "/dashboard/view-profile/" + us.id,
                                      state: { is_business: false },
                                    }}
                                  >
                                    <img
                                      // src={user && user.images && user.images[0]}
                                      src={us && us.images && us.images[0]}
                                      alt="sunil"
                                      // style={{
                                      //   width: "150px",
                                      //   height: "150px",
                                      //   // borderRadius: "50%",
                                      // }}
                                    />
                                  </Link>
                                </div>
                                <div class="pl-3 pt-2 pr-2 pb-2 w-75 border-left">
                                  <Link
                                    to={{
                                      pathname:
                                        "/dashboard/view-profile/" + us.id,
                                      state: { is_business: false },
                                    }}
                                    className="text-decoration-none"
                                  >
                                    <h4 class="text-primary">
                                      {us.first_name}&nbsp;{us.last_name}
                                    </h4>
                                  </Link>
                                  <h5 class="text-info">{us.profession}</h5>

                                  <Link
                                    to={{
                                      pathname:
                                        "/dashboard/view-profile/" + us.id,
                                      state: { is_business: false },
                                    }}
                                  >
                                    {" "}
                                    <p class="text-right m-0">
                                      <a class="btn btn-primary">
                                        <i class="far fa-user"></i> View Profile
                                      </a>
                                    </p>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export function mapStateToProps(state) {
  return {
    loading: state.auth.requested,
    registered: state.auth.registered,
    loggedUser: state.authUser.user,
    users: state.users.users,
    connect_requests: state.connect.connect_requests,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    // registeredComplete: () => dispatch(removeRegistered()),
    // login: (credentials) => dispatch(login(credentials)),
    // loginGoogle: () => dispatch(signupGoogle()),

    getUser: (uid) => dispatch(getUser(uid)),
    getAllUsers: () => dispatch(getAllUsers()),
    removeConnect: (loggedUid, uid) => dispatch(removeConnect(loggedUid, uid)),
    // getConnectRequests: () => dispatch(getConnectRequests()),
    // removeRequest: (uid) => dispatch(removeRequest(uid)),
    // acceptRequest: (uid) => dispatch(acceptRequest(uid)),

    // personelInfo: (Info) => dispatch(updatePersonelInfo(Info)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
