import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

import doctor from '../../images/doctor.png';
import couple from '../../images/couple.png';
import businessman from '../../images/businessman.png';
import enginer from '../../images/engineers.png';
import { fadeInUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import {
	Col,
	Button,
	Form,
	Input,
	Row,
	Modal,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Carousel,
	CarouselControl,
	CarouselIndicators,
	CarouselItem,
	Spinner,
} from 'reactstrap';
import Privacy from './privacyPolicy';
import OuterPrivacy from './outerPrivacyPolicy';
import { connect } from 'react-redux';
import {
	register,
	sendVerificationEmail,
	signupFacebook,
} from '../../store/actions/authActions';
import PhoneComponent from './phoneComponent';
import Geocode from 'react-geocode';
Geocode.setApiKey('AIzaSyDns9eCy_4Zge-qYP3Ycnp7qtLw_QsPNIE');
Geocode.setLanguage('en');
Geocode.enableDebug();

class SignUp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			animating1: false,
			animating2: false,
			modal: false,
			verification_email: '',
			emailModal: false,
			checkbox: false,
			animating3: false,
			fadeInUp: {
				animation: 'x 1.5s',
				animationName: Radium.keyframes(fadeInUp, 'bounce'),
			},
		};
	}
	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};
	toggleEmailModal = () => {
		this.setState({
			emailModal: !this.state.emailModal,
		});
	};
	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	componentDidMount = () => {
		window.scrollTo(0, 0);
		console.log('Navigoator function');

		// navigator.geolocation.getCurrentPosition(this.getCoordinates, err => {
		// 	console.log(err);
		// });
	};
	register = e => {
		e.preventDefault();
		if (e.target.password.value == e.target.confirm_password.value) {
			var pattern = new RegExp('[A-Za-z]+');
			this.props.register({
				first_name: e.target.first_name.value,
				last_name: e.target.last_name.value,
				email: e.target.email.value,
				password: e.target.password.value,
				lat: 'N/A',
				lng: 'N/A',
				current_location: 'N/A',

				// location: e.target.location.value,
				type: 'individual',
			});
		} else {
			alert('Passwords Do Not Match Check Password.');
		}
	};
	getCoordinates = async position => {
		console.log(position);
		console.log('Get Coordinates function');
		Geocode.fromLatLng(
			position.coords.latitude,
			position.coords.longitude
		).then(
			response => {
				const address = response.results[0].formatted_address;
				this.setState({
					address,
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				});
			},
			error => {
				console.error(error);
			}
		);
	};
	render() {
		var { phone_registered, user } = this.props;
		if (this.props.registered) {
			return <Redirect to='/signin' />;
		}
		if (user && user.age) {
			return <Redirect to='/dashboard' />;
		}
		if (user && user.type === 'individual') {
			return <Redirect to='/fillprofile/individual' />;
		}
		if (user && user.type === 'business') {
			return <Redirect to='/fillprofile/business' />;
		}

		return (
			<>
				<Navbar />

				<div className='signUp_Banner '>
					<div className='container'>
						<div className='row FormSection'>
							<div className='col-sm-7 Left_bannerSection order-2 order-md-1'>
								<StyleRoot>
									<div
										className='pr-4'
										style={this.state.fadeInUp}
									>
										<h1 className='text-indigo'>
											FIND YOUR BEYROOTERS
										</h1>
										<h5 className='text-indigo'>
											Connecting Lebanese around the globe
											using Geolocation and filters.
										</h5>
										<p
											className='text-justify'
											style={{ color: 'rgba(0,0,0,0.5)' }}
										>
											Beirut (/beɪˈruːt/ romanized:
											Bayrūt) is the capital and largest
											city of Lebanon. Root-/r ot/the part
											of a plant o͞which attaches it to the
											ground or to a support, typically
											underground, conveying water and
											nourishment to the rest of the plant
											via numerous branches and fibers.
											Like the roots of the cedar tree,
											the Lebanese diaspora also spread
											all over the world. No matter where
											you go or what you do, Beirut will
											always be a part of you. This is why
											we established Connect BeyRoot.
										</p>
										<div className='banner_icon'>
											<div className='img1'>
												<img src={couple} className />
												<p>
													<strong>MAKE</strong>
													<br />
													FRIENDS
												</p>
											</div>
											<div className='img1'>
												<img src={enginer} className />
												<p>
													<strong>TAG</strong>
													<br />
													THEM
												</p>
											</div>
											<div className='img1'>
												<img
													src={businessman}
													className
												/>
												<p>
													<strong>SHARE</strong>
													<br />
													THEM
												</p>
											</div>
										</div>
									</div>
								</StyleRoot>
							</div>
							<div className='col-sm-5 right_FormSection order-1 order-md-2'>
								{/*Form with header*/}
								<StyleRoot>
									<div className='card bg-color rounded-0'>
										<div
											className='card-header p-0'
											style={{ background: 'inherit' }}
										>
											<form style={this.state.fadeInUp}>
												<br />
												<div className='bg-info1 text-white text-center py-2'>
													<h3> SIGNUP HERE</h3>
													<div className='text-center'>
														<Button
															className='m-1'
															color='primary'
															type='button'
															disabled={
																this.props
																	.loading
																// || !this.state.address
															}
															onClick={
																this.toggle
															}
														>
															<i
																style={{
																	color: 'white !important',
																}}
																className='fas fa-phone-alt'
															/>{' '}
															Sign Up with Phone
														</Button>
														<Modal
															isOpen={
																this.state.modal
															}
															toggle={this.toggle}
															// className={className}
														>
															<ModalHeader
																toggle={
																	this.toggle
																}
															>
																Phone Signup
															</ModalHeader>
															<ModalBody>
																<PhoneComponent
																// location={{
																// 	address:
																// 		this
																// 			.state
																// 			.address,
																// 	lat: this
																// 		.state
																// 		.lat,
																// 	lng: this
																// 		.state
																// 		.lng,
																// }}
																/>
															</ModalBody>
														</Modal>
														<Modal
															isOpen={
																this.state
																	.modal2
															}
															toggle={
																this.toggle2
															}
															// className={className}
														>
															<ModalHeader
																toggle={
																	this.toggle2
																}
															>
																Phone Signup
															</ModalHeader>
															<ModalBody>
																<div id='recaptcha-container'></div>
															</ModalBody>
														</Modal>
													</div>
													<br />
													OR
													<br />
													Sign Up with Email
													<br />
												</div>
												{/* {!this.state.address ? (
                          <p className="text-danger text-center">
                            You must allow browser location to sign up
                          </p>
                        ) : null} */}
											</form>
										</div>
										<form
											style={this.state.fadeInUp}
											onSubmit={this.register}
										>
											<div className='card-body p-3 customCard'>
												{' '}
												{/*Body*/}
												<div className='form-group'>
													<div className='input-group mb-2'>
														<div className='input-group-prepend'>
															<div className='input-group-text'>
																<i className='fa fa-user text-info' />
															</div>
														</div>
														<input
															type='text'
															className='form-control'
															id='fname'
															name='first_name'
															placeholder='First Name'
															required
														/>
													</div>
												</div>
												<div className='form-group'>
													<div className='input-group mb-2'>
														<div className='input-group-prepend'>
															<div className='input-group-text'>
																<i className='fa fa-user text-info' />
															</div>
														</div>
														<input
															type='text'
															className='form-control'
															id='lname'
															name='last_name'
															placeholder='Last Name'
															required
														/>
													</div>
												</div>
												<div className='form-group'>
													<div className='input-group mb-2'>
														<div className='input-group-prepend'>
															<div className='input-group-text'>
																<i className='fa fa-envelope text-info' />
															</div>
														</div>
														<input
															type='email'
															className='form-control'
															id='email'
															name='email'
															placeholder='Email'
															required
														/>
													</div>
												</div>
												<div className='form-group'>
													<div className='input-group mb-2'>
														<div className='input-group-prepend'>
															<div className='input-group-text'>
																<i className='fa fa-key text-info' />
															</div>
														</div>
														<input
															type='password'
															className='form-control'
															name='password'
															placeholder='Password'
															required
														/>
													</div>
												</div>
												<div className='form-group'>
													<div className='input-group mb-2'>
														<div className='input-group-prepend'>
															<div className='input-group-text'>
																<i className='fa fa-key text-info' />
															</div>
														</div>
														<input
															type='password'
															className='form-control'
															name='confirm_password'
															placeholder='Confirm Password'
															required
														/>
													</div>
												</div>
												{/* <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="c_location"
                            name="current_location"
                            value={this.state.address}
                            readOnly
                          />
                          <p
                            style={{ fontSize: "12px" }}
                            className="text-danger"
                          >
                            (This is your location and it's private) only used
                            for geo location.
                          </p>
                        </div> */}
												{/* <div className="form-group">
                          <div className="input-group mb-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="fa fa-location-arrow	 text-info" />
                              </div>
                            </div>
                            <input
                              className="form-control"
                              placeholder="Location"
                              required
                              name="location"
                            />
                          </div>
                        </div> */}
												{/* <div className="form-group">
                          <div className="input-group mb-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="fa fa-folder-open text-info" />
                              </div>
                            </div>
                            <select
                              className="form-control"
                              placeholder="Location"
                              required
                              name="type"
                            >
                              <option value="">-- Account Type --</option>
                              <option value="individual">Individual</option>
                              <option value="business">Business</option>
                            </select>
                          </div>
                        </div> */}
												<div className='form-check d-flex ml-2 align-items-center'>
													<input
														type='checkbox'
														className='form-check-input mb-2'
														id='exampleCheck1'
														onChange={e => {
															// e.preventDefault();
															this.setState({
																checkbox:
																	!this.state
																		.checkbox,
															});
														}}
														required
													/>
													<label
														className='form-check-label'
														htmlFor='exampleCheck1'
													>
														I agree to the&nbsp;
														<Link
															to='/terms-of-use'
															target='blank'
															style={{
																color: '#4f0170',
																cursor: 'pointer',
															}}
														>
															Terms and Conditions
														</Link>
													</label>
												</div>
												<div className='text-center mb-3'>
													<Button
														type='submit'
														className='btn btn-info btn-block rounded-0 py-2 mt-4'
														// onClick={() => {
														// 	this.props.history.push("/fillprofile");
														// }}s

														disabled={
															this.props
																.loading ||
															// !this.state.address ||
															!this.state.checkbox
														}
														color='info'
													>
														{this.props.loading ? (
															<Spinner size='sm' />
														) : (
															'Submit'
														)}{' '}
													</Button>
												</div>
												<div className='mb-4'>
													<Link
														to='/signin'
														style={{
															color: '#4f0170',
															cursor: 'pointer',
														}}
													>
														Already have an account?
														Sign in here
													</Link>
												</div>
												<div className='mb-4'>
													<Button
														color='info'
														className='btn btn-info'
														onClick={
															this
																.toggleEmailModal
														}
													>
														Send Verification Email
													</Button>
												</div>
												<Modal
													isOpen={
														this.state.emailModal
													}
													toggle={
														this.toggleEmailModal
													}
												>
													<ModalHeader
														toggle={
															this
																.toggleEmailModal
														}
													>
														Send Verification Email
													</ModalHeader>
													<ModalBody>
														<div className='form-group'>
															<div className='input-group mb-2'>
																<div className='input-group-prepend'>
																	<div className='input-group-text'>
																		<i className='fa fa-envelope text-info' />
																	</div>
																</div>
																<input
																	type='email'
																	className='form-control'
																	id='verification_email'
																	name='verification_email'
																	value={
																		this
																			.state
																			.verification_email
																	}
																	onChange={
																		this
																			.handleChange
																	}
																	placeholder='Email'
																	required
																/>
															</div>
														</div>
														<Button
															color='info'
															style={{
																float: 'right',
															}}
															onClick={e => {
																if (
																	this.state
																		.verification_email !=
																	''
																) {
																	this.props.sendVerificationEmail(
																		this
																			.state
																			.verification_email
																	);
																	this.setState(
																		{
																			verification_email:
																				'',
																		}
																	);
																	this.toggleEmailModal();
																}
															}}
														>
															{this.props
																.loading ? (
																<Spinner size='sm' />
															) : (
																'Send'
															)}
														</Button>
													</ModalBody>
												</Modal>
											</div>
										</form>
									</div>
								</StyleRoot>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</>
		);
	}
}
export function mapStateToProps(state) {
	return {
		loading: state.auth.requested,
		registered: state.auth.registered,
		phone_registered: state.auth.phone_registered,
		user: state.authUser.user,
	};
}
export function mapDispatchToProps(dispatch) {
	return {
		// registeredComplete: () => dispatch(registeredComplete()),
		register: credentials => dispatch(register(credentials)),
		// loginGoogle: () => dispatch(signupGoogle()),
		sendVerificationEmail: email => dispatch(sendVerificationEmail(email)),

		loginFacebook: () => dispatch(signupFacebook()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
