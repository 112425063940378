import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Slider } from 'antd';

import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import {
	Button,
	Col,
	Form,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
} from 'reactstrap';
import { getAllUsers } from '../../store/actions/userActions';
import moment from 'moment';
const filterOptions = createFilterOptions({
	matchFrom: 'start',
	stringify: option => option.title,
});
const countries = [
	{ title: 'Åland Islands' },
	{ title: 'Afghanistan' },
	{ title: 'Albania' },
	{ title: 'Algeria' },
	{ title: 'American Samoa' },
	{ title: 'Andorra' },
	{ title: 'Angola' },
	{ title: 'Anguilla' },
	{ title: 'Antarctica' },
	{ title: 'Antigua and Barbuda' },
	{ title: 'Argentina' },
	{ title: 'Armenia' },
	{ title: 'Aruba' },
	{ title: 'Austria' },
	{ title: 'Azerbaijan' },
	{ title: 'Bahamas' },
	{ title: 'Bahrain' },
	{ title: 'Bangladesh' },
	{ title: 'Barbados' },
	{ title: 'Belarus' },
	{ title: 'Belgium' },
	{ title: 'Belize' },
	{ title: 'Benin' },
	{ title: 'Bermuda' },
	{ title: 'Bhutan' },
	{ title: 'Bolivia, Plurinational State of' },
	{ title: 'Bonaire, Sint Eustatius and Saba' },
	{ title: 'Bosnia and Herzegovina' },
	{ title: 'Bouvet Island' },
	{ title: 'Brazil' },
	{ title: 'British Indian Ocean Territory' },
	{ title: 'Brunei Darussalam' },
	{ title: 'Bulgaria' },
	{ title: 'Burkina Faso' },
	{ title: 'Burundi' },
	{ title: 'Cambodia' },
	{ title: 'Cameroon' },
	{ title: 'Canada' },
	{ title: 'Cape Verde' },
	{ title: 'Cayman Islands' },
	{ title: 'Central African Republic' },
	{ title: 'Chad' },
	{ title: 'Chile' },
	{ title: 'China' },
	{ title: 'Christmas Island' },
	{ title: 'Cocos (Keeling) Islands' },
	{ title: 'Colombia' },
	{ title: 'Comoros' },
	{ title: 'Congo' },
	{ title: 'Congo, the Democratic Republic of the' },
	{ title: 'Cook Islands' },
	{ title: 'Costa Rica' },
	{ title: "Côte d'Ivoire" },
	{ title: 'Croatia' },
	{ title: 'Cuba' },
	{ title: 'Curaçao' },
	{ title: 'Cyprus' },
	{ title: 'Czech Republic' },
	{ title: 'Denmark' },
	{ title: 'Djibouti' },
	{ title: 'Dominica' },
	{ title: 'Dominican Republic' },
	{ title: 'Ecuador' },
	{ title: 'Egypt' },
	{ title: 'El Salvador' },
	{ title: 'England' },
	{ title: 'Equatorial Guinea' },
	{ title: 'Eritrea' },
	{ title: 'Estonia' },
	{ title: 'Ethiopia' },
	{ title: 'Falkland Islands (Malvinas)' },
	{ title: 'Faroe Islands' },
	{ title: 'Fiji' },
	{ title: 'Finland' },
	{ title: 'France' },
	{ title: 'French Guiana' },
	{ title: 'French Polynesia' },
	{ title: 'French Southern Territories' },
	{ title: 'Gabon' },
	{ title: 'Gambia' },
	{ title: 'Georgia' },
	{ title: 'Germany' },
	{ title: 'Ghana' },
	{ title: 'Gibraltar' },
	{ title: 'Greece' },
	{ title: 'Greenland' },
	{ title: 'Grenada' },
	{ title: 'Guadeloupe' },
	{ title: 'Guam' },
	{ title: 'Guatemala' },
	{ title: 'Guernsey' },
	{ title: 'Guinea' },
	{ title: 'Guinea-Bissau' },
	{ title: 'Guyana' },
	{ title: 'Haiti' },
	{ title: 'Heard Island and McDonald Islands' },
	{ title: 'GuaHoly See (Vatican City State)m' },
	{ title: 'Honduras' },
	{ title: 'Hong Kong' },
	{ title: 'Hungary' },
	{ title: 'Iceland' },
	{ title: 'India' },
	{ title: 'Indonesia' },
	{ title: 'Iran, Islamic Republic of' },
	{ title: 'Iraq' },
	{ title: 'Ireland' },
	{ title: 'Isle of Man' },
	{ title: 'Israel' },
	{ title: 'Italy' },
	{ title: 'Jamaica' },
	{ title: 'Japan' },
	{ title: 'Jersey' },
	{ title: 'Jordan' },
	{ title: 'Kazakhstan' },
	{ title: 'Kenya' },
	{ title: 'Kiribati' },
	{ title: "Korea, Democratic People's Republic of" },
	{ title: 'Korea, Republic of' },
	{ title: 'Kuwait' },
	{ title: 'Kyrgyzstan' },
	{ title: "Lao People's Democratic Republic" },
	{ title: 'Latvia' },
	{ title: 'Lebanon' },
	{ title: 'Lesotho' },
	{ title: 'Liberia' },
	{ title: 'Libya' },
	{ title: 'Lithuania' },
	{ title: 'Luxembourg' },
	{ title: 'Macao' },
	{ title: 'Macedonia, the former Yugoslav Republic of' },
	{ title: 'Madagascar' },
	{ title: 'Malawi' },
	{ title: 'Malaysia' },
	{ title: 'Maldives' },
	{ title: 'Mali' },
	{ title: 'Malta' },
	{ title: 'Marshall Islands' },
	{ title: 'Martinique' },
	{ title: 'Mauritania' },
	{ title: 'Mauritius' },
	{ title: 'Mayotte' },
	{ title: 'Mexico' },
	{ title: 'Micronesia, Federated States of' },
	{ title: 'Moldova, Republic of' },
	{ title: 'Monaco' },
	{ title: 'Mongolia' },
	{ title: 'Morocco' },
	{ title: 'Mozambique' },
	{ title: 'Myanmar' },
	{ title: 'Namibia' },
	{ title: 'Nauru' },
	{ title: 'Nepal' },
	{ title: 'Netherlands' },
	{ title: 'New Caledonia' },
	{ title: 'New Zealand' },
	{ title: 'Nicaragua' },
	{ title: 'Niger' },
	{ title: 'Nigeria' },
	{ title: 'Niue' },
	{ title: 'Norfolk Island' },
	{ title: 'Northern Mariana Islands' },
	{ title: 'Norway' },
	{ title: 'Oman' },
	{ title: 'Pakistan' },
	{ title: 'Palau' },
	{ title: 'Palestinian Territory, Occupied' },
	{ title: 'Panama' },
	{ title: 'Papua New Guinea' },
	{ title: 'Peru' },
	{ title: 'Philippines' },
	{ title: 'Pitcairn' },
	{ title: 'Poland' },
	{ title: 'Portugal' },
	{ title: 'Puerto' },
	{ title: 'Rico' },
	{ title: 'Qatar' },
	{ title: 'Réunion' },
	{ title: 'Romania' },
	{ title: 'Russian Federation' },
	{ title: 'Rwanda' },
	{ title: 'Saint Barthélemy' },
	{ title: 'Saint Helena, Ascension and Tristan da Cunha' },
	{ title: 'Saint Kitts and Nevis' },
	{ title: 'Saint Lucia' },
	{ title: 'Saint Martin (French part)' },
	{ title: 'Saint Pierre and Miquelon' },
	{ title: 'Saint Vincent and the Grenadines' },
	{ title: 'Samoa' },
	{ title: 'San Marino' },
	{ title: 'Sao Tome and Principe' },
	{ title: 'Saudi Arabia' },
	{ title: 'Scontland' },
	{ title: 'Senegal' },
	{ title: 'Serbia' },
	{ title: 'Seychelles' },
	{ title: 'Sierra Leone' },
	{ title: 'Singapore' },
	{ title: 'Sint Maarten (Dutch part)' },
	{ title: 'Slovakia' },
	{ title: 'Slovenia' },
	{ title: 'Solomon Islands' },
	{ title: 'Somalia' },
	{ title: 'South Africa' },
	{ title: 'South Georgia and the South Sandwich Islands' },
	{ title: 'South Sudan' },
	{ title: 'Spain' },
	{ title: 'Sri Lanka' },
	{ title: 'Sudan' },
	{ title: 'Suriname' },
	{ title: 'Svalbard and Jan Mayen' },
	{ title: 'Swaziland' },
	{ title: 'Sweden' },
	{ title: 'Switzerland' },
	{ title: 'Syrian Arab Republic' },
	{ title: 'Taiwan, Province of China' },
	{ title: 'Tajikistan' },
	{ title: 'Tanzania, United Republic of' },
	{ title: 'Thailand' },
	{ title: 'Timor-Leste' },
	{ title: 'Togo' },
	{ title: 'Tokelau' },
	{ title: 'Tonga' },
	{ title: 'Trinidad and Tobago' },
	{ title: 'Tunisia' },
	{ title: 'Turkey' },
	{ title: 'Turkmenistan' },
	{ title: 'Turks and Caicos Islands' },
	{ title: 'Tuvalu' },
	{ title: 'Uganda' },
	{ title: 'Ukraine' },
	{ title: 'United Arab Emirates' },
	{ title: 'United States' },
	{ title: 'United States Minor Outlying Islands' },
	{ title: 'Uruguay' },
	{ title: 'Uzbekistan' },
	{ title: '	Venezuela, Bolivarian Republic of' },
	{ title: 'Viet Nam' },
	{ title: 'Virgin Islands, British' },
	{ title: 'Virgin Islands, U.S.' },
	{ title: 'Wales' },
	{ title: 'Wallis and Futuna' },
	{ title: 'Western Sahara' },
	{ title: 'Yemen' },
	{ title: 'Zambia' },
	{ title: 'Zimbabwe' },
];

const languages = [
	{ title: 'Afrikaans' },
	{ title: 'Albanian' },
	{ title: 'Arabic' },
	{ title: 'Armenian' },
	{ title: 'Basque' },
	{ title: 'Bengali' },
	{ title: 'Bulgarian' },
	{ title: 'Catalan' },
	{ title: 'Cambodian' },
	{ title: 'Chinese (Mandarin)' },
	{ title: 'Croatian' },
	{ title: 'Czech' },
	{ title: 'Danish' },
	{ title: 'Dutch' },
	{ title: 'English' },
	{ title: 'Estonian' },
	{ title: 'Fiji' },
	{ title: 'Finnish' },
	{ title: 'French' },
	{ title: 'Georgian' },
	{ title: 'German' },
	{ title: 'Greek' },
	{ title: 'Gujarati' },
	{ title: 'Hebrew' },
	{ title: 'Hindi' },
	{ title: 'Hungarian' },
	{ title: 'Icelandic' },
	{ title: 'Indonesian' },
	{ title: 'Irish' },
	{ title: 'Italian' },
	{ title: 'Japanese' },
	{ title: 'Javanese' },
	{ title: 'Korean' },
	{ title: 'Latin' },
	{ title: 'Latvian' },
	{ title: 'Lithuanian' },
	{ title: 'Macedonian' },
	{ title: 'Malay' },
	{ title: 'Malayalam' },
	{ title: 'Maltese' },
	{ title: 'Maori' },
	{ title: 'Marathi' },
	{ title: 'Mongolian' },
	{ title: 'Nepali' },
	{ title: 'Norwegian' },
	{ title: 'Persian' },
	{ title: 'Polish' },
	{ title: 'Portuguese' },
	{ title: 'Punjabi' },
	{ title: 'Quechua' },
	{ title: 'Romanian' },
	{ title: 'Russian' },
	{ title: 'Samoan' },
	{ title: 'Serbian' },
	{ title: 'Slovak' },
	{ title: 'Slovenian' },
	{ title: 'Spanish' },
	{ title: 'Swahili' },
	{ title: 'Swedish' },
	{ title: 'Tamil' },
	{ title: 'Tatar' },
	{ title: 'Telugu' },
	{ title: 'Thai' },
	{ title: 'Tibetan' },
	{ title: 'Tonga' },
	{ title: 'Turkish' },
	{ title: 'Ukrainian' },
	{ title: 'Urdu' },
	{ title: 'Uzbek' },
	{ title: 'Vietnamese' },
	{ title: 'Welsh' },
	{ title: 'Xhosa' },
];

const districts = [
	{ title: 'Akkar' },
	{ title: 'Ashrafiyeh' },
	{ title: 'Baabda' },
	{ title: 'Baalbek' },
	{ title: 'Batroun' },
	{ title: 'Bint Jbeil' },
	{ title: 'Bsharri' },
	{ title: 'Byblos' },
	{ title: 'Chouf- Beiteddine' },
	{ title: 'Hasbaya' },
	{ title: 'Hermel' },
	{ title: 'Jezzine' },
	{ title: '	Keserwan- Jounieh' },
	{ title: 'Marjeyoun' },
	{ title: 'Matn- Jdeideh' },
	{ title: 'Miniyeh-Danniyeh District' },
	{ title: 'Nabatieh' },
	{ title: 'Rashaya' },
	{ title: 'Sidon' },
	{ title: 'Tripoli' },
	{ title: 'Tyre' },
	{ title: 'Zahle' },
	{ title: 'Zgharta' },
];

const lookings = [
	{ title: 'Friendship' },
	{ title: 'Networking' },
	{ title: 'Dating' },
	{ title: 'Business' },
	{ title: 'Ancestry' },
];

const smoke = [
	{ title: 'Yes, frequent smoke' },
	{ title: 'Social Smoker' },
	{ title: 'Hookah Smoker' },
	{ title: 'Non Smoker' },
];
const drinking = [
	{ title: 'No, never' },
	{ title: 'Social drinker' },
	{ title: 'Frequent drinker' },
];
const relegions = [
	{ title: 'Armenian Catholic' },
	{ title: 'Armenian Orthodox' },
	{ title: 'Christian' },
	{ title: 'Druze' },
	{ title: 'Maronite' },
	{ title: 'Melkite' },
	{ title: 'Muslim' },
	{ title: 'Orthodox' },
	{ title: 'Syriac Catholic' },
	{ title: 'Roman Catholic' },
	{ title: 'Other' },
];

const professions = [
	{ title: 'Arts' },
	{ title: 'Accounting/ Finance' },
	{ title: 'Agriculture' },
	{ title: 'AI/ machine learning' },
	{ title: 'Army/ Military' },
	{ title: 'Athletics' },
	{ title: 'Automotive' },
	{ title: 'Construction' },
	{ title: 'Consulting' },
	{ title: 'Education' },
	{ title: 'Engineering' },
	{ title: 'Engineering' },
	{ title: 'Finance' },
	{ title: 'Fishing and farming' },
	{ title: 'Graphic design/Website Development' },
	{ title: 'Healthcare' },
	{ title: 'Hospitality/Tourisim' },
	{ title: 'IT' },
	{ title: 'Law /Legal' },
	{ title: 'Manufacturing' },
	{ title: 'Marketing' },
	{ title: 'Media' },
	{ title: 'Oil and Gas' },
	{ title: 'Other' },
	{ title: 'Pharmacital' },
	{ title: 'Real Estate' },
	{ title: 'Transportation' },
];
const education = [
	{ title: 'High school' },
	{ title: 'GED' },
	{ title: 'In college' },
	{ title: 'In school' },
	{ title: 'Tech/ vocational' },
	{ title: 'Associates' },
	{ title: 'Bachelors' },
	{ title: 'Masters' },
	{ title: 'PhD' },
	{ title: 'Doctorate' },
];

const status = [
	{ title: 'Divorced' },
	{ title: 'In relationship' },
	{ title: 'Married' },
	{ title: 'Other' },
	{ title: 'Seperated' },
	{ title: 'Single' },
	{ title: 'Widowed' },
];
// countries
const defaultProps = {
	options: countries,
	getOptionLabel: option => option.title,
};
const flatProps = {
	options: countries.map(option => option.title),
};

//   languages
const flatProps2 = {
	options: languages.map(option => option.title),
};
const defaultProps2 = {
	options: languages,
	getOptionLabel: option => option.title,
};

//  districts
const defaultProps3 = {
	options: districts,
	getOptionLabel: option => option.title,
};

const flatProps3 = {
	options: districts.map(option => option.title),
};
// looking for
const defaultProps4 = {
	options: lookings,
	getOptionLabel: option => option.title,
};

const flatProps4 = {
	options: lookings.map(option => option.title),
};
//   smoke
const defaultProps5 = {
	options: smoke,
	getOptionLabel: option => option.title,
};

const flatProps5 = {
	options: smoke.map(option => option.title),
};
//   drinking
const defaultProps6 = {
	options: drinking,
	getOptionLabel: option => option.title,
};

const flatProps6 = {
	options: smoke.map(option => option.title),
};
//   relegions
const defaultProps7 = {
	options: relegions,
	getOptionLabel: option => option.title,
};

const flatProps7 = {
	options: relegions.map(option => option.title),
};

//   professions
const defaultProps8 = {
	options: professions,
	getOptionLabel: option => option.title,
};

const flatProps8 = {
	options: professions.map(option => option.title),
};
//   education

const defaultProps9 = {
	options: education,
	getOptionLabel: option => option.title,
};

const flatProps9 = {
	option: education.map(option => option.title),
};

//   status

const defaultProps10 = {
	options: status,
	getOptionLabel: option => option.title,
};

const flatProps10 = {
	option: status.map(option => option.title),
};

class Search extends Component {
	state = {
		search: '',
		km: 0,
		modal: false,
		ageStart: 0,
		ageEnd: 0,
		expStart: 0,
		expEnd: 0,
		gender: 'Both',
		heightStart: 90,
		heightEnd: 90,
		heightStartFt: 1,
		heightEndFt: 1,
		near_me: false,
		filter: false,
		looking_for: null,
		height_in: 'ft',
	};
	componentDidMount() {
		this.props.getAllUsers();
	}
	withinRadius(point, interest, kms) {
		// 'use strict';
		let R = 6371;
		let deg2rad = n => {
			return Math.tan(n * (Math.PI / 180));
		};
		let dLat = deg2rad(interest.latitude - point.latitude);
		let dLon = deg2rad(interest.longitude - point.longitude);
		let a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(deg2rad(point.latitude)) *
				Math.cos(deg2rad(interest.latitude)) *
				Math.sin(dLon / 2) *
				Math.sin(dLon / 2);
		let c = 2 * Math.asin(Math.sqrt(a));
		let d = R * c;
		return d <= kms;
	}
	toggle = e => {
		e.preventDefault();
		this.setState({ modal: !this.state.modal });
	};
	render() {
		var { users, user } = this.props;
		console.log(this.state);
		return (
			<>
				<div class=' mt-sm-2 mt-lg-5 mt-md-5 main-content container-fluid'>
					<div class='ml-lg-5 ml-3 page-title'>
						<h3>Search</h3>
						<div class='row'>
							<div class='col-md-4 col-md-offset-3'>
								<div id='custom-search-input'>
									<div class='input-group col-md-12 p-0'>
										<input
											type='text'
											onChange={e => {
												e.preventDefault();
												this.setState({
													search: e.target.value,
												});
											}}
											class='  search-query form-control'
											placeholder='Search'
										/>
										<span class='input-group-btn'>
											<button
												class='btn btn-danger'
												type='button'
											>
												<span class=' glyphicon glyphicon-search'></span>
											</button>
										</span>
									</div>
								</div>
							</div>
							<div class='col-md-4 col-md-offset-3'>
								<Button
									className='mt-2'
									type='button'
									color='info'
									onClick={e => {
										e.preventDefault();
										this.toggle(e);
									}}
								>
									<i class='fas fa-filter'></i>
									&nbsp; Filter Users
								</Button>
								<Button
									className='mt-2 ml-2'
									type='button'
									color='info'
									onClick={e => {
										e.preventDefault();
										this.setState({
											search: '',
											modal: false,
											ageStart: 0,
											ageEnd: 0,
											expStart: 0,
											expEnd: 0,
											heightStart: 90,
											heightEnd: 90,
											near_me: false,
											filter: false,
											countries: [],
											languages: [],
											looking_for: null,
											km: 0,
										});
									}}
								>
									{/* <i class="fas fa-filter"></i> */}
									{/* &nbsp; */}
									Clear Filters
								</Button>
								<Modal
									isOpen={this.state.modal}
									toggle={this.toggle}
									// className={className}
								>
									<ModalHeader toggle={this.toggle}>
										WHO DO YOU WANT TO CONNECT WITH
									</ModalHeader>
									<ModalBody>
										<Form>
											{/* <InputGroup id="nearme" className="mb-2">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Input
                              addon
                              type="checkbox"
                              name="near_me"
                              aria-label="Checkbox for following text input"
                              checked={this.state.near_me}
                              onChange={(e) => {
                                this.setState({
                                  near_me: !this.state.near_me,
                                });
                              }}
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Near Me" disabled />
                      </InputGroup>
                      <h6 className="text-center text-danger">
                        *If you check <b>Near Me</b> users near you in a certain
                        radius would show up / Unchecking it would show you all
                        global users*
                      </h6>
                      <Label>Distance in Km</Label>
                      <Slider
                        defaultValue={this.state.km}
                        //  onChange={}
                        disabled={!this.state.near_me}
                        max={120}
                        onChange={(value) => {
                          this.setState({
                            km: value,
                          });
                        }}
                      /> */}
											<div className='form-group'>
												{/* <Autocomplete
                          {...defaultProps3}
                          id="auto-complete"
                          autoComplete
                          includeInputInList
                          defaultValue={this.state.district}
                          onChange={(e, value) => {
                            e.preventDefault();
                            this.setState({ district: value });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="CONNECT WITH PEOPLE FROM (Select up to 4)"
                              margin="normal"
                              name="district"
                            />
                          )}
                        /> */}
												<Autocomplete
													{...defaultProps3}
													id='auto-complete'
													autoComplete
													filterOptions={
														filterOptions
													}
													multiple={true}
													includeInputInList
													onChange={(e, value) => {
														e.preventDefault();
														if (value.length > 4) {
															alert(
																'Maximum 4 districts can be added'
															);
														} else {
															this.setState({
																district: value,
															});
														}
													}}
													value={this.state.district}
													renderInput={params => (
														<TextField
															style={{
																padding: '10px',
															}}
															{...params}
															label='CONNECT WITH PEOPLE FROM (Select up to 4)'
															margin='normal'
															name='district'
														/>
													)}
												/>
											</div>
											<div className='form-group'>
												<label htmlFor='Country'>
													CONNECT WITH:
												</label>
												<select
													className='form-select'
													id='gender'
													name='gender'
													defaultValue={
														this.state.gender
													}
													onChange={e => {
														e.preventDefault();
														this.setState({
															gender: e.target
																.value,
														});
													}}
												>
													<option value='Both'>
														Both
													</option>
													<option value='ml'>
														Male
													</option>
													<option value='fml'>
														Female
													</option>
												</select>
											</div>
											<div className='form-group'>
												<label>Age Range:</label>
												<Slider
													range
													defaultValue={[
														this.state.ageStart,
														this.state.ageEnd,
													]}
													//  onChange={}
													max={99}
													min={18}
													onChange={value => {
														this.setState({
															ageStart: value[0],
															ageEnd: value[1],
														});
													}}
												/>
											</div>
											<div className='form-group'>
												<label>WORK EXPERIENCE:</label>
												<Slider
													range
													defaultValue={[
														this.state.expStart,
														this.state.expEnd,
													]}
													//  onChange={}
													max={50}
													min={0}
													onChange={value => {
														this.setState({
															expStart: value[0],
															expEnd: value[1],
														});
													}}
												/>
											</div>
											{/* <div className="form-group">
                        <label>Height Parameter:</label>
                        <Input
                          name="height_in"
                          type="select"
                          onChange={(e) => {
                            e.preventDefault();
                            this.setState({ height_in: e.target.value });
                          }}
                        >
                          <option>ft</option>
                          <option>cm</option>
                        </Input>
                        <label>Height:</label>
                        <Slider
                          range
                          defaultValue={
                            this.state.height_in == "ft"
                              ? [
                                  this.state.heightStartFt,
                                  this.state.heightEndFt,
                                ]
                              : [this.state.heightStart, this.state.heightEnd]
                          }
                          //  onChange={}
                          max={this.state.height_in == "ft" ? 8 : 230}
                          min={this.state.height_in == "ft" ? 1 : 90}
                          onChange={(value) => {
                            if (this.state.height_in == "ft") {
                              this.setState({
                                heightStartFt: value[0],
                                heightEndFt: value[1],
                              });
                            } else {
                              this.setState({
                                heightStart: value[0],
                                heightEnd: value[1],
                              });
                            }
                          }}
                        />
                      </div> */}
											<div className='form-group'>
												<Autocomplete
													{...defaultProps}
													id='auto-complete'
													autoComplete
													filterOptions={
														filterOptions
													}
													multiple={true}
													includeInputInList
													onChange={(e, value) => {
														e.preventDefault();
														if (value.length > 4) {
															alert(
																'Maximum 4 countries can be added'
															);
														} else {
															this.setState({
																countries:
																	value,
															});
														}
													}}
													value={this.state.countries}
													renderInput={params => (
														<TextField
															id='outlined-basic'
															style={{
																padding: '10px',
															}}
															{...params}
															label='COUNTRIES THEY IDENTIFY WITH(Select up to 4):'
															margin='normal'
															name='countries'
														/>
													)}
												/>
											</div>
											<div className='form-group'>
												<Autocomplete
													{...defaultProps2}
													id='auto-complete'
													autoComplete
													filterOptions={
														filterOptions
													}
													multiple={true}
													includeInputInList
													onChange={(e, value) => {
														e.preventDefault();
														if (value.length > 4) {
															alert(
																'Maximum 4 languages can be added'
															);
														} else {
															this.setState({
																languages:
																	value,
															});
														}
													}}
													value={this.state.languages}
													renderInput={params => (
														<TextField
															{...params}
															style={{
																padding: '10px',
															}}
															label='LANGUAGE(S) THEY FEEL COMFORTABLE CONVERSING IN (Select up to 4):'
															margin='normal'
															name='languages'
														/>
													)}
												/>
											</div>
											<div className='form-group'>
												<Autocomplete
													{...defaultProps4}
													id='auto-complete'
													autoComplete
													filterOptions={
														filterOptions
													}
													includeInputInList
													defaultValue={
														this.state.looking_for
													}
													onChange={(e, value) => {
														e.preventDefault();
														this.setState({
															looking_for: value,
														});
													}}
													renderInput={params => (
														<TextField
															{...params}
															style={{
																padding: '10px',
															}}
															label='WHAT ARE THEY LOOKING FOR:'
															margin='normal'
															name='looking_for'
														/>
													)}
												/>
											</div>
											{/* <div className="form-group">
                        <Autocomplete
                          {...defaultProps5}
                          id="auto-complete"
                          autoComplete
                          filterOptions={filterOptions}
                          includeInputInList
                          defaultValue={this.state.smoke}
                          onChange={(e, value) => {
                            e.preventDefault();
                            this.setState({
                              smoke: value,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ padding: "10px" }}
                              label="Smoke:"
                              margin="normal"
                              name="smoke"
                            />
                          )}
                        />
                      </div>{" "} */}
											{/* <div className="form-group">
                        <Autocomplete
                          {...defaultProps6}
                          id="auto-complete"
                          autoComplete
                          filterOptions={filterOptions}
                          includeInputInList
                          defaultValue={this.state.drinking}
                          onChange={(e, value) => {
                            e.preventDefault();
                            this.setState({
                              drinking: value,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ padding: "10px" }}
                              label="Drinking:"
                              margin="normal"
                              name="drinking"
                            />
                          )}
                        />
                      </div> */}
											<div className='form-group'>
												<Autocomplete
													{...defaultProps7}
													id='auto-complete'
													autoComplete
													filterOptions={
														filterOptions
													}
													multiple={true}
													includeInputInList
													onChange={(e, value) => {
														e.preventDefault();
														if (value.length > 4) {
															alert(
																'Maximum 4 religions can be added'
															);
														} else {
															this.setState({
																religion: value,
															});
														}
													}}
													value={this.state.religion}
													renderInput={params => (
														<TextField
															{...params}
															style={{
																padding: '10px',
															}}
															label='THEIR RELIGION (Select up to 4):'
															margin='normal'
															name='religion'
														/>
													)}
												/>
											</div>{' '}
											<div className='form-group'>
												<Autocomplete
													{...defaultProps8}
													id='auto-complete'
													autoComplete
													filterOptions={
														filterOptions
													}
													multiple={true}
													includeInputInList
													onChange={(e, value) => {
														e.preventDefault();
														if (value.length > 4) {
															alert(
																'Maximum 4 professions can be added'
															);
														} else {
															this.setState({
																profession:
																	value,
															});
														}
													}}
													value={
														this.state.profession
													}
													renderInput={params => (
														<TextField
															{...params}
															style={{
																padding: '10px',
															}}
															label='THEIR PROFESSION/ INDUSTRY: (Select up to 4):'
															margin='normal'
															name='profession'
														/>
													)}
												/>
											</div>
											<div className='form-group'>
												<Autocomplete
													{...defaultProps9}
													id='auto-complete'
													autoComplete
													filterOptions={
														filterOptions
													}
													includeInputInList
													defaultValue={
														this.state.education
													}
													onChange={(e, value) => {
														e.preventDefault();
														this.setState({
															education: value,
														});
													}}
													renderInput={params => (
														<TextField
															{...params}
															style={{
																padding: '10px',
															}}
															label='THEIR LEVEL OF EDUCATION:'
															margin='normal'
															name='education'
														/>
													)}
												/>
											</div>
											<div className='form-group'>
												<Autocomplete
													{...defaultProps10}
													id='auto-complete'
													autoComplete
													filterOptions={
														filterOptions
													}
													includeInputInList
													defaultValue={
														this.state.status
													}
													onChange={(e, value) => {
														e.preventDefault();
														this.setState({
															status: value,
														});
													}}
													renderInput={params => (
														<TextField
															{...params}
															style={{
																padding: '10px',
															}}
															label='THEIR STATUS:'
															margin='normal'
															name='status'
														/>
													)}
												/>
											</div>
											<Row>
												<Col className='text-right'>
													<Button
														color='info'
														onClick={e => {
															e.preventDefault();
															this.setState({
																filter: true,
															});
															this.toggle(e);
														}}
													>
														Filter
													</Button>
												</Col>
											</Row>
										</Form>{' '}
									</ModalBody>
								</Modal>
							</div>
						</div>
					</div>
				</div>

				<section class='container mt-4 mb-4'>
					<div class='container'>
						<div class='row mb-3'>
							{user &&
								users &&
								users
									.filter(us => us.id != user.id)
									.filter(us => us.filled)
									.filter(us => {
										if (this.state.filter) {
											if (this.state.near_me && user) {
												console.log(
													this.withinRadius(
														{
															latitude: user.lat,
															longitude: user.lng,
														},
														{
															latitude: us.lat,
															longitude: us.lng,
														},
														this.state.km
													)
												);
												return this.withinRadius(
													{
														latitude: user.lat,
														longitude: user.lng,
													},
													{
														latitude: us.lat,
														longitude: us.lng,
													},
													this.state.km
												);
											} else {
												return true;
											}
										} else {
											return true;
										}
									})
									.filter(us => {
										if (this.state.search) {
											return (
												us.first_name
													.toLowerCase()
													.includes(
														this.state.search.toLowerCase()
													) ||
												us.last_name
													.toLowerCase()
													.includes(
														this.state.search.toLowerCase()
													)
											);
										} else {
											return true;
										}
									})
									.filter(us => {
										if (this.state.filter) {
											if (this.state.gender === 'Both') {
												return true;
											} else {
												return (
													us.gender ===
													this.state.gender
												);
											}
										} else {
											return true;
										}
									})
									.filter(us => {
										if (this.state.filter) {
											if (this.state.ageEnd > 0) {
												console.log(
													moment(us.age).format()
												);
												var userAge = moment(us.age);
												var startAge =
													moment().subtract(
														this.state.ageStart,
														'y'
													);
												var endAge = moment().subtract(
													this.state.ageEnd,
													'y'
												);
												console.log(
													startAge.format(),
													endAge.format()
												);
												return userAge.isBetween(
													endAge,
													startAge,
													's',
													'[]'
												);
											} else {
												return true;
											}
										} else {
											return true;
										}
									})
									.filter(us => {
										if (this.state.filter) {
											if (this.state.expEnd > 0) {
												return (
													us.experience >=
														this.state.expStart &&
													us.experience <=
														this.state.expEnd
												);
											} else {
												return true;
											}
										} else {
											return true;
										}
									})
									.filter(us => {
										if (this.state.filter) {
											if (this.state.height_in == 'ft') {
												if (
													this.state.heightEndFt > 1
												) {
													if (
														this.state.height_in ==
														us.height_in
													) {
														return (
															us.height >=
																this.state
																	.heightStartFt &&
															us.height <=
																this.state
																	.heightEndFt
														);
													} else {
														var usCmHeight =
															us.height;
														var usInHeight =
															parseInt(
																usCmHeight /
																	2.54
															);
														var usFtHeight =
															parseInt(
																usInHeight / 12
															);
														var usInRemaining =
															usInHeight % 12;
														var compareHeight =
															usFtHeight +
															usInRemaining / 100;
														return (
															compareHeight >=
																this.state
																	.heightStartFt &&
															compareHeight <=
																this.state
																	.heightEndFt
														);
													}
												} else {
													return true;
												}
											} else {
												if (this.state.heightEnd > 90) {
													if (
														this.state.height_in ==
														us.height_in
													) {
														return (
															us.height >=
																this.state
																	.heightStart &&
															us.height <=
																this.state
																	.heightEnd
														);
													} else {
														var usFtHeight =
															Math.trunc(
																us.height
															); // returns 3
														var usInHeight =
															Number(
																(
																	us.height -
																	usFtHeight
																).toFixed(3)
															) * 100;
														var totalInHeight =
															usFtHeight * 12 +
															usInHeight;
														var usCmHeight =
															totalInHeight *
															2.54;

														return (
															usCmHeight >=
																this.state
																	.heightStart &&
															usCmHeight <=
																this.state
																	.heightEnd
														);
													}
												} else {
													return true;
												}
											}
										} else {
											return true;
										}
									})
									.filter(us => {
										if (this.state.filter) {
											if (this.state.district) {
												var districts =
													us.district &&
													us.district.reduce(
														(acc, val) => {
															return acc.concat(
																val.title
															);
														},
														[]
													);
												if (districts) {
													return districts.some(dis =>
														this.state.district.find(
															dist =>
																dist.title ==
																dis
														)
													);
												} else {
													return false;
												}
											} else {
												return true;
											}
										} else {
											return true;
										}
									})
									.filter(us => {
										if (this.state.filter) {
											if (this.state.countries) {
												var countries =
													us.countries &&
													us.countries.reduce(
														(acc, val) => {
															return acc.concat(
																val.title
															);
														},
														[]
													);
												if (countries) {
													return countries.some(cou =>
														this.state.countries.find(
															coun =>
																coun.title ==
																cou
														)
													);
												} else {
													return false;
												}
											} else {
												return true;
											}
										} else {
											return true;
										}
									})
									.filter(us => {
										if (this.state.filter) {
											if (this.state.languages) {
												var languages =
													us.languages &&
													us.languages.reduce(
														(acc, val) => {
															return acc.concat(
																val.title
															);
														},
														[]
													);
												if (languages) {
													return languages.some(lan =>
														this.state.languages.find(
															lang =>
																lang.title ==
																lan
														)
													);
												} else {
													return false;
												}
											} else {
												return true;
											}
										} else {
											return true;
										}
									})
									.filter(us => {
										if (this.state.filter) {
											if (this.state.looking_for) {
												var looking_for =
													us.looking_for &&
													us.looking_for.reduce(
														(acc, val) => {
															return acc.concat(
																val.title
															);
														},
														[]
													);
												if (looking_for) {
													return looking_for.includes(
														this.state.looking_for
															.title
													);
												} else {
													return false;
												}
											} else {
												return true;
											}
										} else {
											return true;
										}
									})
									.filter(us => {
										if (this.state.filter) {
											if (this.state.smoke) {
												return (
													us.smoke ===
													this.state.smoke.title
												);
											} else {
												return true;
											}
										} else {
											return true;
										}
									})
									.filter(us => {
										if (this.state.filter) {
											if (this.state.drinking) {
												return (
													us.drinking ===
													this.state.drinking.title
												);
											} else {
												return true;
											}
										} else {
											return true;
										}
									})
									.filter(us => {
										if (this.state.filter) {
											if (this.state.religion) {
												return this.state.religion.filter(
													rel =>
														rel.title == us.religion
												).length;
											} else {
												return true;
											}
										} else {
											return true;
										}
									})
									.filter(us => {
										if (this.state.filter) {
											if (this.state.profession) {
												return this.state.profession.filter(
													pro =>
														pro.title ==
														us.profession
												).length;
											} else {
												return true;
											}
										} else {
											return true;
										}
									})
									.filter(us => {
										if (this.state.filter) {
											if (this.state.education) {
												return (
													us.education ===
													this.state.education.title
												);
											} else {
												return true;
											}
										} else {
											return true;
										}
									})
									.filter(us => {
										if (this.state.filter) {
											if (this.state.status) {
												return (
													us.status ===
													this.state.status.title
												);
											} else {
												return true;
											}
										} else {
											return true;
										}
									})
									.map(us => (
										<div class='col-md-6 mt-2'>
											<div class='d-flex flex-row profile-card rounded'>
												<div class='p-0 w-25'>
													<img
														// src={user && user.images && user.images[0]}
														src={
															us &&
															us.images &&
															us.images[0]
														}
														alt='sunil'
														className='search-image-filter'
														// style={{
														//   width: "100%",
														//   height: "130px",
														//   // borderRadius: "50%",
														// }}
													/>
												</div>
												<div class='pl-3 pt-2 pr-2 pb-2 w-75 border-left'>
													<h4 class='text-primary w-100 text-left'>
														{us.first_name +
															' ' +
															us.last_name}
													</h4>
													<h5 class='text-info'>
														{us.profession}
													</h5>

													<Link
														to={{
															pathname:
																'/dashboard/view-profile/' +
																us.id,
															state: {
																is_business: false,
															},
														}}
													>
														{' '}
														<p class='text-right m-0'>
															<a class='btn btn-primary'>
																<i class='far fa-user'></i>{' '}
																View Profile
															</a>
														</p>
													</Link>
												</div>
											</div>
										</div>
									))}
						</div>
					</div>
				</section>
			</>
		);
	}
}
export function mapStateToProps(state) {
	return {
		loading: state.auth.requested,
		registered: state.auth.registered,
		user: state.authUser.user,
		users: state.users.users,
	};
}
export function mapDispatchToProps(dispatch) {
	return {
		// registeredComplete: () => dispatch(removeRegistered()),
		// login: (credentials) => dispatch(login(credentials)),
		// loginGoogle: () => dispatch(signupGoogle()),
		getAllUsers: () => dispatch(getAllUsers()),
		// personelInfo: (Info) => dispatch(updatePersonelInfo(Info)),
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(Search);
