import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Alert, Col, Row } from "reactstrap";
import { removeConnect } from "../../store/actions/connectActions";
import {
  getNotifications,
  readNotifications,
} from "../../store/actions/notificationActions";
import { getAllUsers, getUser } from "../../store/actions/userActions";

class Connects extends Component {
  state = { search: "" };
  componentDidMount() {
    var { loggedUser } = this.props;
    this.props.getUser(loggedUser.id);
    this.props.getNotifications(loggedUser.id);
    this.props.getAllUsers();
  }
  render() {
    var { loggedUser, users, notifications } = this.props;

    return (
      <>
        <div class=" mt-5 main-content container-fluid minHeight">
          <div class=" ml-lg-5 ml-2 pl-2 page-title">
            <h3>Notifications</h3>
          </div>
          {notifications && notifications.length ? null : (
            <div className="container-fluid   pl-3 pr-3  ">
              <div className="container-fluid   shadow profile-card">
                <section class="container-fluid mt-4 mb-4">
                  <div class="container-fluid">
                    <div class="row mb-3">
                      {" "}
                      <p className="text-danger mt-3">
                        {"No Notifications Yet!"}
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          )}

          <Row>
            {notifications &&
              notifications.map((notification) => {
                this.props.readNotifications(notification.id);
                return (
                  <Col lg={{ size: 6 }}>
                    <Link
                      className="text-decoration-none"
                      to={
                        notification.notification ==
                        "You have a connect request from "
                          ? "/dashboard/connects-request"
                          : "#"
                      }
                    >
                      <Alert
                        color="info"
                        style={{
                          background: "#41ccc7",
                          color: "#f7f7f7",
                        }}
                      >
                        <h4 className="alert-heading">Connect Request</h4>
                        <p>
                          {notification.notification}
                          &nbsp;
                          <b className="text-capitalize">
                            {users &&
                            notification &&
                            users.find((us) => us.id === notification.user)
                              ? users.find((us) => us.id === notification.user)
                                  .first_name
                              : null}
                            &nbsp;
                            {users &&
                            notification &&
                            users.find((us) => us.id === notification.user)
                              ? users.find((us) => us.id === notification.user)
                                  .last_name
                              : null}
                          </b>
                        </p>
                        <hr />
                        <p className="mb-0">
                          {moment(notification.date).format("DD MMM")}
                          &nbsp;
                          {moment(notification.date).format("LT")}
                        </p>
                      </Alert>
                    </Link>
                  </Col>
                );
              })}
          </Row>
        </div>
      </>
    );
  }
}
export function mapStateToProps(state) {
  return {
    loading: state.auth.requested,
    registered: state.auth.registered,
    loggedUser: state.authUser.user,
    users: state.users.users,
    notifications: state.notifications.notifications,
    connect_requests: state.connect.connect_requests,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    // registeredComplete: () => dispatch(removeRegistered()),
    // login: (credentials) => dispatch(login(credentials)),
    // loginGoogle: () => dispatch(signupGoogle()),

    getUser: (uid) => dispatch(getUser(uid)),
    getNotifications: (uid) => dispatch(getNotifications(uid)),
    readNotifications: (uid) => dispatch(readNotifications(uid)),
    getAllUsers: () => dispatch(getAllUsers()),
    removeConnect: (loggedUid, uid) => dispatch(removeConnect(loggedUid, uid)),
    // getConnectRequests: () => dispatch(getConnectRequests()),
    // removeRequest: (uid) => dispatch(removeRequest(uid)),
    // acceptRequest: (uid) => dispatch(acceptRequest(uid)),

    // personelInfo: (Info) => dispatch(updatePersonelInfo(Info)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Connects);
