import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import couple from '../../images/couple.png';
import businessman from '../../images/businessman.png';
import enginer from '../../images/engineers.png';
import { fadeInUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import 'font-awesome/css/font-awesome.min.css';
import PhoneComponent from './phoneComponent';

// import { Button } from "bootstrap";
import {
	Col,
	Button,
	Form,
	Input,
	Row,
	Modal,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Carousel,
	CarouselControl,
	CarouselIndicators,
	CarouselItem,
	Label,
	Spinner,
} from 'reactstrap';
import { connect } from 'react-redux';
import {
	login,
	phoneLogin,
	phoneLoginCapcha,
	phoneRegRemove,
	removeRegistered,
	signupFacebook,
} from './../../store/actions/authActions';
import { getUser } from '../../store/actions/userActions';
import Geocode from 'react-geocode';
Geocode.setApiKey('AIzaSyDns9eCy_4Zge-qYP3Ycnp7qtLw_QsPNIE');
Geocode.setLanguage('en');
Geocode.enableDebug();

export class SignIn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			animating1: false,
			modal: false,
			modal2: false,
			modal3: false,
			animating2: false,
			animating3: false,
			fadeInUp: {
				animation: 'x 1.5s',
				animationName: Radium.keyframes(fadeInUp, 'bounce'),
			},
		};
	}
	toggle = () => {
		// e.preventDefault();
		this.setState({
			modal: !this.state.modal,
		});
	};
	toggle2 = () => {
		this.setState({
			modal2: !this.state.modal2,
		});
	};
	toggle3 = () => {
		this.setState({
			modal3: !this.state.modal3,
		});
	};
	componentDidMount = () => {
		window.addEventListener('scroll', this.handleScroll);
		window.scrollTo(0, 0);
		var { phone_registered } = this.props;
		if (phone_registered) {
			this.toggle();
		}
		this.props.phoneRegRemove();
		this.props.registeredComplete();
		// console.log(this.props.authUser.uid);
		if (this.props.authUser.uid) {
			// console.log("fetching");
			this.props.getUser(this.props.authUser.uid);
		}
		// if (!this.props.authUser.uid) {
		//   navigator.geolocation.getCurrentPosition(this.getCoordinates);
		// }
		// var { capche } = this.props;
	};

	componentWillUnmount = () => {
		window.removeEventListener('scroll', this.handleScroll);
	};
	getCoordinates = async position => {
		await console.log(position);

		Geocode.fromLatLng(
			position.coords.latitude,
			position.coords.longitude
		).then(
			response => {
				var result = response.results;
				const address = response.results[0].formatted_address;
				this.setState({
					address,
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				});
			},
			error => {
				console.error(error);
			}
		);
	};

	handleScroll = event => {
		const isTop = window.scrollY > 350;
		const isTop2 = window.scrollY > 700;
		const isTop3 = window.scrollY > 1100;
		if (isTop == true) {
			this.setState({
				animating1: true,
			});
		}
		if (isTop2 == true) {
			this.setState({
				animating2: true,
			});
		}
		if (isTop3 == true) {
			this.setState({
				animating3: true,
			});
		}
	};
	state = {
		redirect: false,
		type: '',
	};
	login = e => {
		e.preventDefault();
		this.props.login({
			email: e.target.email.value,
			password: e.target.password.value,
		});
	};
	render() {
		var { user, capche, phone_registered } = this.props;
		if (user && user.age) {
			return <Redirect to='/dashboard' />;
		}
		if (user && user.type === 'individual') {
			return <Redirect to='/fillprofile/individual' />;
		}
		if (user && user.type === 'business') {
			return <Redirect to='/fillprofile/business' />;
		}
		return (
			<>
				<Navbar />

				<div className='signUp_Banner '>
					<div className='container'>
						<div className='row FormSection'>
							<div className='col-sm-7 Left_bannerSection  order-2 order-md-1'>
								<StyleRoot>
									<div
										style={this.state.fadeInUp}
										className='pr-4'
									>
										<h1 className='text-indigo'>
											FIND YOUR BEYROOTERS
										</h1>
										<h6 className='text-indigo'>
											Connecting Lebanese around the globe
											using Geolocation and filters.
										</h6>
										<p
											className='text-justify'
											style={{ color: 'rgba(0,0,0,0.5)' }}
										>
											Beirut (/beɪˈruːt/ romanized:
											Bayrūt) is the capital and largest
											city of Lebanon. Root-/r ot/the part
											of a plant o͞which attaches it to the
											ground or to a support, typically
											underground, conveying water and
											nourishment to the rest of the plant
											via numerous branches and fibers.
											Like the roots of the cedar tree,
											the Lebanese diaspora also spread
											all over the world. No matter where
											you go or what you do, Beirut will
											always be a part of you. This is why
											we established Connect BeyRoot.
										</p>
										<div className='banner_icon'>
											<div className='img1'>
												<img src={couple} className />
												<p>
													<strong>MAKE</strong>
													<br />
													FRIENDS
												</p>
											</div>
											<div className='img1'>
												<img src={enginer} className />
												<p>
													<strong>TAG</strong>
													<br />
													THEM
												</p>
											</div>
											<div className='img1'>
												<img
													src={businessman}
													className
												/>
												<p>
													<strong>SHARE</strong>
													<br />
													THEM
												</p>
											</div>
										</div>
									</div>
								</StyleRoot>
							</div>
							<div className='col-sm-5 right_FormSection  order-1 order-md-2'>
								{/*Form with header*/}
								<StyleRoot>
									<div style={this.state.fadeInUp}>
										<div className='card bg-color rounded-0'>
											<div
												className='card-header p-0'
												style={{
													background: 'inherit',
												}}
											>
												<br />
												<div className='text-white text-center py-2'>
													<h3 className='text-indigo '>
														SIGN IN HERE
													</h3>
													<div className='text-center'>
														<Button
															className='m-1'
															color='primary'
															type='button'
															disabled={
																this.props
																	.loading
																// !this.state
																// 	.address
															}
															onClick={
																this.toggle
															}
														>
															<i
																style={{
																	color: 'white !important',
																}}
																className='fas fa-phone-alt'
															/>{' '}
															Sign In with Phone
														</Button>
														<Modal
															isOpen={
																this.state
																	.modal &&
																!phone_registered
															}
															toggle={this.toggle}
															// className={className}
														>
															<ModalHeader
																toggle={
																	this.toggle
																}
															>
																Phone Login
															</ModalHeader>
															<ModalBody>
																<PhoneComponent
																	location={{
																		address:
																			this
																				.state
																				.address,
																		lat: this
																			.state
																			.lat,
																		lng: this
																			.state
																			.lng,
																	}}
																/>{' '}
															</ModalBody>
														</Modal>
														<Modal
															isOpen={
																this.state
																	.modal2
															}
															toggle={
																this.toggle2
															}
															// className={className}
														>
															<ModalHeader
																toggle={
																	this.toggle2
																}
															>
																Phone Login
															</ModalHeader>
															<ModalBody>
																<div id='recaptcha-container'></div>
															</ModalBody>
														</Modal>
													</div>
													{/* {!this.state.address ? (
														<p className='text-danger text-center'>
															You must allow
															browser location to
															sign in with phone
														</p>
													) : null} */}
													<br />
													OR
													<br />
													Sign in with email
													<br />
												</div>
											</div>

											<form onSubmit={this.login}>
												<div className='card-body p-3 customCard'>
													{/*Body*/}
													<div className='form-group'>
														<div className='input-group mb-2'>
															<div className='input-group-prepend'>
																<div className='input-group-text'>
																	<i className='fa fa-envelope text-info' />
																</div>
															</div>
															<input
																type='email'
																className='form-control'
																id='email'
																name='email'
																placeholder='Email'
															/>
														</div>
													</div>
													<div className='form-group'>
														<div className='input-group mb-2'>
															<div className='input-group-prepend'>
																<div className='input-group-text'>
																	<i className='fa fa-key text-info' />
																</div>
															</div>
															<input
																type='password'
																className='form-control'
																name='password'
																placeholder='Password'
																required
															/>
														</div>
													</div>
													<div className='form-group'>
														<Link
															className='text-white'
															to='/forgotpassword'
														>
															Forgot Password?
														</Link>
													</div>

													<div className='text-center mb-3'>
														<Button
															type='submit'
															disabled={
																this.props
																	.loading
															}
															className='btn btn-info btn-block rounded-0 py-2 mt-4'
															// onClick={() => {
															// 	this.props.history.push("/fillprofile");
															// }}
															color='info'
														>
															{this.props
																.loading ? (
																<Spinner size='sm' />
															) : (
																'Login'
															)}{' '}
														</Button>
													</div>
													<div className='mb-4'>
														<Link
															to='/signup'
															style={{
																color: '#4f0170',
																cursor: 'pointer',
															}}
														>
															Don't have account?
															Sign up here
														</Link>
													</div>
												</div>
											</form>
										</div>
									</div>
								</StyleRoot>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</>
		);
	}
}
export function mapStateToProps(state) {
	return {
		loading: state.auth.requested,
		registered: state.auth.registered,
		phone_registered: state.auth.phone_registered,
		user: state.authUser.user,
		auth: state.auth,
		capche: state.auth.capche,
		authUser: state.authUser,
	};
}
export function mapDispatchToProps(dispatch) {
	return {
		registeredComplete: () => dispatch(removeRegistered()),
		phoneRegRemove: () => dispatch(phoneRegRemove()),
		login: credentials => dispatch(login(credentials)),

		getUser: uid => dispatch(getUser(uid)),
		// loginGoogle: () => dispatch(signupGoogle()),
		loginFacebook: () => dispatch(signupFacebook()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
