import React, { Component } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import connect1 from "../../images/IMG_5096.jpg";
import connect2 from "../../images/IMG_1560.jpg";
import connect3 from "../../images/IMG_5176.jpg";
import { fadeInUp } from "react-animations";
import Radium, { StyleRoot } from "radium";

export default class connectBeroot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animating1: false,
      animating2: false,
      animating3: false,
      fadeInUp: {
        animation: "x 1.5s",
        animationName: Radium.keyframes(fadeInUp, "bounce"),
      },
    };
  }

  // componentDidMount = () => {
  //   window.addEventListener("scroll", this.handleScroll);
  //   window.scrollTo(0, 0);
  // };

  // componentWillUnmount = () => {
  //   window.removeEventListener("scroll", this.handleScroll);
  // };

  handleScroll = (event) => {
    const isTop = window.scrollY > 200;
    const isTop2 = window.scrollY > 200;
    const isTop3 = window.scrollY > 800;
    if (isTop == true) {
      this.setState({
        animating1: true,
      });
    }
    if (isTop2 == true) {
      this.setState({
        animating2: true,
      });
    }
    if (isTop3 == true) {
      this.setState({
        animating3: true,
      });
    }
  };
  render() {
    return (
      <>
        <Navbar />
        <div class="aboutus">
          <div class="container">
            <div class="row">
              {/* <!-- Left side Image--> */}
              <div class="col-lg-12 aboutUs">
                <h1>Connect Beyroot</h1>
              </div>
            </div>
          </div>
        </div>
        {/* <!--Banner Section start here--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 mt-4  side-img ">
              <StyleRoot>
                <img
                  className="mb-4 rounded shadow"
                  style={this.state.fadeInUp}
                  src={connect1}
                />
              </StyleRoot>
            </div>
            <div className="col-md-6 mt-4 align-self-center side-text">
              <StyleRoot>
                <div
                  className="card rounded shadow"
                  style={this.state.fadeInUp}
                >
                  <h3 className="p-3 pb-0">What is Connect Beyroot?</h3>
                  <p className=" p-3 text-justify">
                    To connect and empower the Lebanese Diaspora all over the
                    world in one platform. To help business owners and
                    entrepreneurs connect to the right induvials to help them
                    take the next step in their endeavors. To connect
                    individuals who are new to a country toinduvials who have
                    been in their shoes. To recognize the success stories of
                    Lebanese expats with various backgrounds and to connect them
                    with their roots, which would begin our journey to transform
                    Lebanon. Connecting the Lebanese that you wouldn’t
                    necessarily connect to in your areas (since you didn’t know
                    they existed).
                  </p>
                </div>
              </StyleRoot>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6 mt-4 align-self-center side-text">
              <StyleRoot>
                <div
                  className="card rounded shadow"
                  style={this.state.animating2 ? this.state.fadeInUp : {}}
                >
                  <h4 className="p-3">About Lebanon</h4>
                  <p className=" p-3 text-justify">
                    Lebanon, is one of the world’s smallest sovereign states and
                    is one of theoldest citiesinthe world, having been inhabited
                    for more than 5,000 years. Lebanon has one operating
                    airport; Rafic Hariri International Airport located in the
                    capital Beirut which is also the largest city in Lebanon.
                    Lebanon has many cultural characteristics with the Arab
                    world, but it has attributes that differentiate it from the
                    neighboring Arab countries. Lebanon is surprisingly very
                    mountainous compared to many other countries. Luckily the
                    mountainous landscape has served as an asylum for diverse
                    religious and ethnic groups throughout history. Lebanon is
                    generally subtropical and is characterized by hot, dry
                    summers and mild, humid winters (this also depends where you
                    are, and which mountain you’re visiting). In late Spring /
                    early summers you could have a snowball fight, then drive a
                    couple hours and tan at the beach. This is an extraordinary
                    feature in Lebanon due to the mountainous country. Lebanon
                    is one of the most densely populated countries in the
                    Mediterranean area with about 7 million people total. Out of
                    that 7 million, about 4.8 million are registered Lebanese,
                    just under 1 million foreign workers and about half a
                    million refugees from Syria and Palestine. Lebanon has one
                    of the highest literacy rates in the Middle East. The
                    majority of Lebanese in Lebanon,speak English, Arabic and
                    French fluently. Coincidently enough, Lebanese tend to mix
                    all 3 languages when conversing;Hi(Hello in English) kifik/
                    kifuk(how are you in Arabic) Ca va(I’m ok in French)? Even
                    though Lebanon is in the Middle East, don’t assume that
                    everyone is Muslim and must cover up. This is a typical
                    misconception about Lebanon. In fact, there are 18 religious
                    communities in Lebanon. Lebanese are also known for their
                    array of delicious Mediterranean food. We are sure you’ll
                    find something you love!
                  </p>
                </div>
              </StyleRoot>
            </div>
            <div className="col-md-6 mt-4 align-self-center  side-img ">
              <StyleRoot>
                <img
                  className="mb-4 rounded shadow"
                  style={this.state.animating2 ? this.state.fadeInUp : {}}
                  src={connect2}
                />
              </StyleRoot>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6 mt-4  side-img ">
              <StyleRoot>
                <img
                  className="mb-4 rounded shadow"
                  style={this.state.animating3 ? this.state.fadeInUp : {}}
                  src={connect3}
                />
              </StyleRoot>
            </div>
            <div className="col-md-6 mt-4 align-self-center side-text">
              <StyleRoot>
                <div
                  className="card rounded shadow"
                  style={this.state.animating3 ? this.state.fadeInUp : {}}
                  src={connect3}
                >
                  <h4 className="p-3">Lebanese Diaspora</h4>
                  <p className=" p-3 text-justify">
                    We are descendants of Lebanese immigrants who, voluntarily
                    or as refugees, emigrated from Lebanon to live and work
                    abroad. Although there isn’t an exact number of how many
                    Lebanese people there are, it is estimated that there are
                    about 13-18 million Lebanese worldwide and about 8.6 -14
                    million of those individuals live outside of Lebanon.
                  </p>
                </div>
              </StyleRoot>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
