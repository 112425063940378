import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getChats } from "../../store/actions/chatsActions";
import { getConnectRequests } from "../../store/actions/connectActions";
import { getUser } from "../../store/actions/userActions";

class Home extends Component {
  componentDidMount() {
    var { user } = this.props;
    this.props.getChats(user.id);
    this.props.getUser(user.id);
    this.props.getConnectRequests();
  }
  render() {
    var { user, chats, connect_requests } = this.props;
    return (
      <>
        <div class="main-content container-fluid minHeight">
          <div class=" pt-3 page-title">
            <h3>Dashboard</h3>
          </div>
          <section class="section">
            <div class="row mb-2">
              <div class="col-12 col-md-4">
                <Link to="/dashboard/connects" className="text-decoration-none">
                  <div class="card card-statistic">
                    <div class="card-body p-0 myBox">
                      <div class="d-flex flex-column">
                        <div class="px-3 py-3">
                          <h3 class="card-title">YOUR BEYROOTERS</h3>
                          <div class="card-right">
                            <p>{user && user.connects.length}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div class="col-12 col-md-4">
                <Link to="/dashboard/chats" className="text-decoration-none">
                  <div class="card card-statistic">
                    <div class="card-body p-0 myBox">
                      <div class="d-flex flex-column">
                        <div class="px-3 py-3">
                          <h3 class="card-title">YOUR CHATS</h3>
                          <div class="card-right">
                            <p>{chats && chats.length}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div class="col-12 col-md-4">
                <Link
                  to="/dashboard/connects-request"
                  className="text-decoration-none"
                >
                  <div class="card card-statistic">
                    <div class="card-body p-0 myBox">
                      <div class="d-flex flex-column">
                        <div class="px-3 py-3">
                          <h3 class="card-title">PENDING REQUESTS</h3>
                          <div class="card-right">
                            <p>
                              {connect_requests &&
                                connect_requests.filter(
                                  (req) => req.requested_to === user.id
                                ).length}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
export function mapStateToProps(state) {
  return {
    // loading: state.auth.requested,
    // registered: state.auth.registered,
    user: state.authUser.user,
    // users: state.users.users,
    chats: state.chat.chats,
    connect_requests: state.connect.connect_requests,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    // registeredComplete: () => dispatch(removeRegistered()),
    // login: (credentials) => dispatch(login(credentials)),
    // loginGoogle: () => dispatch(signupGoogle()),

    getUser: (uid) => dispatch(getUser(uid)),
    // getAllUsers: () => dispatch(getAllUsers()),
    getChats: (uid) => dispatch(getChats(uid)),
    // sendMessage: (msg) => dispatch(sendMessage(msg)),

    // removeConnect: (loggedUid, uid) => dispatch(removeConnect(loggedUid, uid)),
    getConnectRequests: () => dispatch(getConnectRequests()),
    // removeRequest: (uid) => dispatch(removeRequest(uid)),
    // acceptRequest: (uid) => dispatch(acceptRequest(uid)),

    // personelInfo: (Info) => dispatch(updatePersonelInfo(Info)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
