import { combineReducers } from "redux";
import authReducer from "./authReducer";
import authUserReducer from "./authUserReducer";
import connectReducer from "./connectReducer";
import chatReducer from "./chatsReducer";
import usersReducer from "./usersReducer";
import notificationReducer from "./notificationReducer";
import postReducer from "./postReducer";
// import userReducer from "./userReducer";
const rootReducer = combineReducers({
  auth: authReducer,
  authUser: authUserReducer,
  users: usersReducer,
  connect: connectReducer,
  chat: chatReducer,
  post: postReducer,
  notifications: notificationReducer,
});
export default rootReducer;
