const initState = {
  user: null,
};

const authUserReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        uid: action.user.id,
        user: { ...action.user },
      };
    case "PHONE_LOGIN":
      return {
        ...state,
        uid: action.user.id,
        user: { ...action.user },
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        uid: null,
        user: null,
      };

    case "GET_USER":
      return {
        ...state,
        user: action.user,
      };

    default:
      return state;
  }
};

export default authUserReducer;
