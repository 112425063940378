const initState = {
  connect_requests: [],
};

const connectReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_CONNECT_REQUESTS":
      return {
        ...state,
        connect_requests: action.connect_requests,
      };
    default:
      return state;
  }
};

export default connectReducer;
