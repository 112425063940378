import React, { Component } from "react";
import "../../css/bootstrap.css";
import "../../css/FillProfile.css";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import "../../js/script";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const countries = [
  { title: "Åland Islands" },
  { title: "Afghanistan" },
  { title: "Albania" },
  { title: "Algeria" },
  { title: "American Samoa" },
  { title: "Andorra" },
  { title: "Angola" },
  { title: "Anguilla" },
  { title: "Antarctica" },
  { title: "Antigua and Barbuda" },
  { title: "Argentina" },
  { title: "Armenia" },
  { title: "Aruba" },
  { title: "Austria" },
  { title: "Azerbaijan" },
  { title: "Bahamas" },
  { title: "Bahrain" },
  { title: "Bangladesh" },
  { title: "Barbados" },
  { title: "Belarus" },
  { title: "Belgium" },
  { title: "Belize" },
  { title: "Benin" },
  { title: "Bermuda" },
  { title: "Bhutan" },
  { title: "Bolivia, Plurinational State of" },
  { title: "Bonaire, Sint Eustatius and Saba" },
  { title: "Bosnia and Herzegovina" },
  { title: "Bouvet Island" },
  { title: "Brazil" },
  { title: "British Indian Ocean Territory" },
  { title: "Brunei Darussalam" },
  { title: "Bulgaria" },
  { title: "Burkina Faso" },
  { title: "Burundi" },
  { title: "Cambodia" },
  { title: "Cameroon" },
  { title: "Canada" },
  { title: "Cape Verde" },
  { title: "Cayman Islands" },
  { title: "Central African Republic" },
  { title: "Chad" },
  { title: "Chile" },
  { title: "China" },
  { title: "Christmas Island" },
  { title: "Cocos (Keeling) Islands" },
  { title: "Colombia" },
  { title: "Comoros" },
  { title: "Congo" },
  { title: "Congo, the Democratic Republic of the" },
  { title: "Cook Islands" },
  { title: "Costa Rica" },
  { title: "Côte d'Ivoire" },
  { title: "Croatia" },
  { title: "Cuba" },
  { title: "Curaçao" },
  { title: "Cyprus" },
  { title: "Czech Republic" },
  { title: "Denmark" },
  { title: "Djibouti" },
  { title: "Dominica" },
  { title: "Dominican Republic" },
  { title: "Ecuador" },
  { title: "Egypt" },
  { title: "El Salvador" },
  { title: "England" },
  { title: "Equatorial Guinea" },
  { title: "Eritrea" },
  { title: "Estonia" },
  { title: "Ethiopia" },
  { title: "Falkland Islands (Malvinas)" },
  { title: "Faroe Islands" },
  { title: "Fiji" },
  { title: "Finland" },
  { title: "France" },
  { title: "French Guiana" },
  { title: "French Polynesia" },
  { title: "French Southern Territories" },
  { title: "Gabon" },
  { title: "Gambia" },
  { title: "Georgia" },
  { title: "Germany" },
  { title: "Ghana" },
  { title: "Gibraltar" },
  { title: "Greece" },
  { title: "Greenland" },
  { title: "Grenada" },
  { title: "Guadeloupe" },
  { title: "Guam" },
  { title: "Guatemala" },
  { title: "Guernsey" },
  { title: "Guinea" },
  { title: "Guinea-Bissau" },
  { title: "Guyana" },
  { title: "Haiti" },
  { title: "Heard Island and McDonald Islands" },
  { title: "GuaHoly See (Vatican City State)m" },
  { title: "Honduras" },
  { title: "Hong Kong" },
  { title: "Hungary" },
  { title: "Iceland" },
  { title: "India" },
  { title: "Indonesia" },
  { title: "Iran, Islamic Republic of" },
  { title: "Iraq" },
  { title: "Ireland" },
  { title: "Isle of Man" },
  { title: "Israel" },
  { title: "Italy" },
  { title: "Jamaica" },
  { title: "Japan" },
  { title: "Jersey" },
  { title: "Jordan" },
  { title: "Kazakhstan" },
  { title: "Kenya" },
  { title: "Kiribati" },
  { title: "Korea, Democratic People's Republic of" },
  { title: "Korea, Republic of" },
  { title: "Kuwait" },
  { title: "Kyrgyzstan" },
  { title: "Lao People's Democratic Republic" },
  { title: "Latvia" },
  { title: "Lebanon" },
  { title: "Lesotho" },
  { title: "Liberia" },
  { title: "Libya" },
  { title: "Lithuania" },
  { title: "Luxembourg" },
  { title: "Macao" },
  { title: "Macedonia, the former Yugoslav Republic of" },
  { title: "Madagascar" },
  { title: "Malawi" },
  { title: "Malaysia" },
  { title: "Maldives" },
  { title: "Mali" },
  { title: "Malta" },
  { title: "Marshall Islands" },
  { title: "Martinique" },
  { title: "Mauritania" },
  { title: "Mauritius" },
  { title: "Mayotte" },
  { title: "Mexico" },
  { title: "Micronesia, Federated States of" },
  { title: "Moldova, Republic of" },
  { title: "Monaco" },
  { title: "Mongolia" },
  { title: "Morocco" },
  { title: "Mozambique" },
  { title: "Myanmar" },
  { title: "Namibia" },
  { title: "Nauru" },
  { title: "Nepal" },
  { title: "Netherlands" },
  { title: "New Caledonia" },
  { title: "New Zealand" },
  { title: "Nicaragua" },
  { title: "Niger" },
  { title: "Nigeria" },
  { title: "Niue" },
  { title: "Norfolk Island" },
  { title: "Northern Mariana Islands" },
  { title: "Norway" },
  { title: "Oman" },
  { title: "Pakistan" },
  { title: "Palau" },
  { title: "Palestinian Territory, Occupied" },
  { title: "Panama" },
  { title: "Papua New Guinea" },
  { title: "Peru" },
  { title: "Philippines" },
  { title: "Pitcairn" },
  { title: "Poland" },
  { title: "Portugal" },
  { title: "Puerto" },
  { title: "Rico" },
  { title: "Qatar" },
  { title: "Réunion" },
  { title: "Romania" },
  { title: "Russian Federation" },
  { title: "Rwanda" },
  { title: "Saint Barthélemy" },
  { title: "Saint Helena, Ascension and Tristan da Cunha" },
  { title: "Saint Kitts and Nevis" },
  { title: "Saint Lucia" },
  { title: "Saint Martin (French part)" },
  { title: "Saint Pierre and Miquelon" },
  { title: "Saint Vincent and the Grenadines" },
  { title: "Samoa" },
  { title: "San Marino" },
  { title: "Sao Tome and Principe" },
  { title: "Saudi Arabia" },
  { title: "Scontland" },
  { title: "Senegal" },
  { title: "Serbia" },
  { title: "Seychelles" },
  { title: "Sierra Leone" },
  { title: "Singapore" },
  { title: "Sint Maarten (Dutch part)" },
  { title: "Slovakia" },
  { title: "Slovenia" },
  { title: "Solomon Islands" },
  { title: "Somalia" },
  { title: "South Africa" },
  { title: "South Georgia and the South Sandwich Islands" },
  { title: "South Sudan" },
  { title: "Spain" },
  { title: "Sri Lanka" },
  { title: "Sudan" },
  { title: "Suriname" },
  { title: "Svalbard and Jan Mayen" },
  { title: "Swaziland" },
  { title: "Sweden" },
  { title: "Switzerland" },
  { title: "Syrian Arab Republic" },
  { title: "Taiwan, Province of China" },
  { title: "Tajikistan" },
  { title: "Tanzania, United Republic of" },
  { title: "Thailand" },
  { title: "Timor-Leste" },
  { title: "Togo" },
  { title: "Tokelau" },
  { title: "Tonga" },
  { title: "Trinidad and Tobago" },
  { title: "Tunisia" },
  { title: "Turkey" },
  { title: "Turkmenistan" },
  { title: "Turks and Caicos Islands" },
  { title: "Tuvalu" },
  { title: "Uganda" },
  { title: "Ukraine" },
  { title: "United Arab Emirates" },
  { title: "United States" },
  { title: "United States Minor Outlying Islands" },
  { title: "Uruguay" },
  { title: "Uzbekistan" },
  { title: "	Venezuela, Bolivarian Republic of" },
  { title: "Viet Nam" },
  { title: "Virgin Islands, British" },
  { title: "Virgin Islands, U.S." },
  { title: "Wales" },
  { title: "Wallis and Futuna" },
  { title: "Western Sahara" },
  { title: "Yemen" },
  { title: "Zambia" },
  { title: "Zimbabwe" },
];
const languages = [
  { title: "Afrikaans" },
  { title: "Albanian" },
  { title: "Arabic" },
  { title: "Armenian" },
  { title: "Basque" },
  { title: "Bengali" },
  { title: "Bulgarian" },
  { title: "Catalan" },
  { title: "Cambodian" },
  { title: "Chinese (Mandarin)" },
  { title: "Croatian" },
  { title: "Czech" },
  { title: "Danish" },
  { title: "Dutch" },
  { title: "English" },
  { title: "Estonian" },
  { title: "Fiji" },
  { title: "Finnish" },
  { title: "French" },
  { title: "Georgian" },
  { title: "German" },
  { title: "Greek" },
  { title: "Gujarati" },
  { title: "Hebrew" },
  { title: "Hindi" },
  { title: "Hungarian" },
  { title: "Icelandic" },
  { title: "Indonesian" },
  { title: "Irish" },
  { title: "Italian" },
  { title: "Japanese" },
  { title: "Javanese" },
  { title: "Korean" },
  { title: "Latin" },
  { title: "Latvian" },
  { title: "Lithuanian" },
  { title: "Macedonian" },
  { title: "Malay" },
  { title: "Malayalam" },
  { title: "Maltese" },
  { title: "Maori" },
  { title: "Marathi" },
  { title: "Mongolian" },
  { title: "Nepali" },
  { title: "Norwegian" },
  { title: "Persian" },
  { title: "Polish" },
  { title: "Portuguese" },
  { title: "Punjabi" },
  { title: "Quechua" },
  { title: "Romanian" },
  { title: "Russian" },
  { title: "Samoan" },
  { title: "Serbian" },
  { title: "Slovak" },
  { title: "Slovenian" },
  { title: "Spanish" },
  { title: "Swahili" },
  { title: "Swedish" },
  { title: "Tamil" },
  { title: "Tatar" },
  { title: "Telugu" },
  { title: "Thai" },
  { title: "Tibetan" },
  { title: "Tonga" },
  { title: "Turkish" },
  { title: "Ukrainian" },
  { title: "Urdu" },
  { title: "Uzbek" },
  { title: "Vietnamese" },
  { title: "Welsh" },
  { title: "Xhosa" },
];

const districts = [
  { title: "Akkar" },
  { title: "Baalbek" },
  { title: "Hermel" },
  { title: "Rashaya" },
  { title: "Zahle" },
  { title: "Baabda" },
  { title: "Byblos" },
  { title: "Chouf- Beiteddine" },
  { title: "	Keserwan- Jounieh" },
  { title: "Matn- Jdeideh" },
  { title: "Bint Jbeil" },
  { title: "Hasbaya" },
  { title: "Marjeyoun" },
  { title: "Nabatieh" },
  { title: "Batroun" },
  { title: "Bsharri" },
  { title: "Miniyeh-Danniyeh District" },
  { title: "Tripoli" },
  { title: "Zgharta" },
  { title: "Sidon" },
  { title: "Jezzine" },
  { title: "Tyre" },
  { title: "Ashrafiyeh" },
];

const lookings = [
  { title: "Investment" },
  { title: "Mentor" },
  { title: "Investor" },
  { title: "Mentee" },
  { title: "Internship" },
  { title: "Networking" },
  { title: "Job" },
  { title: "Freelance" },
  { title: "Art time" },
  { title: "People to hire" },
  { title: "Business owner" },
  { title: "Select all that apply" },
];

const Experience = [
  { title: "Less than 1" },
  { title: "1-2 years" },
  { title: "3-5 years" },
  { title: "6-10 years" },
  { title: "10+ years" },
];

const professions = [
  { title: "Auto" },
  { title: "Agriculture" },
  { title: "Education" },
  { title: "Consulting" },
  { title: "IT" },
  { title: "Healthcare" },

  { title: "Law" },
  { title: "Hospitality/Tourism" },

  { title: "Pharmaceutical" },
];

const professions2 = [
  { title: "Auto" },
  { title: "Agriculture" },
  { title: "Education" },
  { title: "Consulting" },
  { title: "IT" },
  { title: "Healthcare" },

  { title: "Law" },
  { title: "Hospitality/Tourism" },

  { title: "Pharmaceutical" },
  { title: "No Preference" },
];

const education = [
  { title: "High school" },
  { title: "GED" },
  { title: "In college" },
  { title: "In school" },
  { title: "Tech/ vocational" },
  { title: "Associates" },
  { title: "Bachelors" },
  { title: "Masters" },
  { title: "PhD" },
  { title: "Doctorate" },
];

// countries
const defaultProps = {
  options: countries,
  getOptionLabel: (option) => option.title,
};
const flatProps = {
  options: countries.map((option) => option.title),
};

//   languages
const flatProps2 = {
  options: languages.map((option) => option.title),
};
const defaultProps2 = {
  options: languages,
  getOptionLabel: (option) => option.title,
};

//  districts
const defaultProps3 = {
  options: districts,
  getOptionLabel: (option) => option.title,
};

const flatProps3 = {
  options: districts.map((option) => option.title),
};

//  lookings
const defaultProps4 = {
  options: lookings,
  getOptionLabel: (option) => option.title,
};

const flatProps4 = {
  options: lookings.map((option) => option.title),
};

//  Experience
const defaultProps5 = {
  options: Experience,
  getOptionLabel: (option) => option.title,
};

const flatProps5 = {
  options: Experience.map((option) => option.title),
};

//   professions
const defaultProps8 = {
  options: professions,
  getOptionLabel: (option) => option.title,
};

const flatProps8 = {
  options: professions.map((option) => option.title),
};
//   professions2
const defaultProps9 = {
  options: professions2,
  getOptionLabel: (option) => option.title,
};

const flatProps9 = {
  options: professions2.map((option) => option.title),
};
// education
const defaultProps10 = {
  options: education,
  getOptionLabel: (option) => option.title,
};

const flatProps10 = {
  options: education.map((option) => option.title),
};
export default class FillProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      files2: [],
      filesUrl: [],
      filesUrl2: [],
    };
  }

  handleFile = async (e) => {
    await this.setState({
      [e.target.name]: e.target.files,
      filesUrl: [
        ...this.state.filesUrl,
        URL.createObjectURL(e.target.files[0]),
      ],
    });
    this.setState({
      files: [...this.state.files, e.target.files[0]],
    });
  };
  handleFile2 = async (e) => {
    await this.setState({
      [e.target.name]: e.target.files,
      filesUrl2: [
        ...this.state.filesUrl,
        URL.createObjectURL(e.target.files[0]),
      ],
    });
    this.setState({
      files2: [...this.state.files2, e.target.files[0]],
    });
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  state = {
    loaded: false,
  };
  componentWillMount() {
    if (localStorage.getItem("reloaded") == null) {
      location.reload();
      localStorage.setItem("reloaded", true);
    }
  }
  componentWillUnmount() {
    localStorage.removeItem("reloaded");
  }
  render() {
    return (
      <>
        <Navbar />
        <div className="container fill_Container  mt-sm-160px">
          <form
            role="form"
            onSubmit={(e) => {
              e.preventDefault();
              this.props.history.push("/dashboard");
            }}
          >
            <div className="row" id="step-1">
              <div className="col-xs-12">
                <div className="col-md-12">
                  <h3>PERSONAL INFORMATION</h3>
                  <div className="form-group">
                    <label htmlFor="Country"> Name/Company/Organization:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="Country">Gender:</label>
                    <select className="form-select" id="gender" name="gender">
                      <option value="ml">Male</option>
                      <option value="fml">Female</option>
                      <option value="non">None</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="Country">BUSINESS IMAGES</label>
                    <br />
                    <label>
                      <input
                        className="mt-3 d-none"
                        type="file"
                        onChange={this.handleFile}
                        id="myFile"
                        name="listingFile"
                        required
                        disabled={this.state.filesUrl.length == 5}
                      />
                      <i class="fas fa-lg text-primary fa-upload"></i> Upload
                      files
                    </label>
                    <br></br>

                    {this.state.filesUrl.map((items, index) => {
                      return (
                        <>
                          <img key={index} height="70" width="70" src={items} />
                          <i
                            onClick={() => {
                              let { filesUrl } = this.state;

                              filesUrl.splice(index, 1);

                              this.setState({
                                filesUrl,
                              }); // This will update the state and trigger a rerender of the components
                            }}
                            class="far text-danger fa-times-circle"
                          ></i>
                        </>
                      );
                    })}
                  </div>
                  <div className="form-group">
                    <label htmlFor="Country">PERSONAL IMAGES</label>
                    <br />
                    <label>
                      <input
                        className="mt-3 d-none"
                        type="file"
                        onChange={this.handleFile2}
                        id="myFile"
                        name="listingFile2"
                        required
                        disabled={this.state.filesUrl2.length == 5}
                      />
                      <i class="fas fa-lg text-primary fa-upload"></i> Upload
                      files
                    </label>
                    <br></br>

                    {this.state.filesUrl2.map((items, index) => {
                      return (
                        <>
                          <img key={index} height="70" width="70" src={items} />
                          <i
                            onClick={() => {
                              let { filesUrl2 } = this.state;

                              filesUrl2.splice(index, 1);

                              this.setState({
                                filesUrl2,
                              }); // This will update the state and trigger a rerender of the components
                            }}
                            class="far text-danger fa-times-circle"
                          ></i>
                        </>
                      );
                    })}
                  </div>
                  <div className="form-group">
                    <label htmlFor="Country">Connect with:</label>
                    <select className="form-select" id="gender" name="gender">
                      <option value="ml">Male</option>
                      <option value="fml">Female</option>
                      <option value="mlfml">Both</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="Country">Location:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="c_location"
                      name="c_location"
                      placeholder="Current Location"
                    />
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps}
                      id="auto-complete"
                      autoComplete
                      multiple={true}
                      includeInputInList
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Countries you identify with:"
                          margin="normal"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps2}
                      id="auto-complete"
                      autoComplete
                      multiple={true}
                      includeInputInList
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Language you feel comfortable conversing in:"
                          margin="normal"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps}
                      id="auto-complete"
                      autoComplete
                      multiple={true}
                      includeInputInList
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="	Where they came from in Lebanon (based on district):"
                          margin="normal"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps4}
                      id="auto-complete"
                      autoComplete
                      includeInputInList
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="What you are looking for?"
                          margin="normal"
                        />
                      )}
                    />
                    {/* <label htmlFor="Country">What you are looking for?</label>
										<select className="form-select">
											<option>Investment</option>
											<option>Investor</option>
											<option>Mentor</option>
											<option>Mentee</option>
											<option>Internship</option>
											<option>Job</option>
											<option>Networking</option>
											<option>Freelance</option>
											<option>Art time</option>
											<option>People to hire</option>
											<option>Business owner</option>
											<option>Select all that apply</option>
										</select>*/}
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps4}
                      id="auto-complete"
                      autoComplete
                      includeInputInList
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="	Who do you want to connect with:"
                          margin="normal"
                        />
                      )}
                    />
                    {/* <label htmlFor="Country">
											Who do you want to connect with:
										</label>
										<select className="form-select">
											<option>Investment</option>
											<option>Investor</option>
											<option>Mentor</option>
											<option>Mentee</option>
											<option>Internship</option>
											<option>Job</option>
											<option>Networking</option>
											<option>Freelance</option>
											<option>Art time</option>
											<option>People to hire</option>
											<option>Business owner</option>
											<option>Select all that apply</option>
										</select> */}
                  </div>

                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps5}
                      id="auto-complete"
                      autoComplete
                      includeInputInList
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="	Year of Experience:"
                          margin="normal"
                        />
                      )}
                    />
                    {/* <label htmlFor="Country">Year of Experience:</label>
										<select className="form-select">
											<option>Less than 1</option>
											<option>1-2 years</option>
											<option>3-5 years</option>
											<option>6-10 years</option>
											<option>10+ years</option>
										</select> */}
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps8}
                      id="auto-complete"
                      autoComplete
                      includeInputInList
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="	Profession/Industry:"
                          margin="normal"
                        />
                      )}
                    />
                    {/* <label htmlFor="Country">Profession/Industry:</label>
										<select multiple className="form-select">
											<option>Auto</option>
											<option>Agriculture</option>
											<option>Education</option>
											<option>Consulting</option>
											<option>IT</option>
											<option>Healthcare</option>
											<option>Law</option>
											<option>Hospitality/Tourism</option>
											<option>Pharmaceutica</option>
										</select> */}
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps9}
                      id="auto-complete"
                      autoComplete
                      includeInputInList
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="	Do you want to connect w/ someone who is:"
                          margin="normal"
                        />
                      )}
                    />
                    {/* <label htmlFor="Country">
											

											Do you want to connect w/ someone who is:
										</label>
										<select multiple className="form-select">
											<option>Auto</option>
											<option>Agriculture</option>
											<option>Education</option>
											<option>Consulting</option>
											<option>IT</option>
											<option>Healthcare</option>
											<option>Law</option>
											<option>Hospitality/Tourism</option>
											<option>Pharmaceutica</option>
											<option>No Preference</option>
										</select> */}
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps10}
                      id="auto-complete"
                      autoComplete
                      includeInputInList
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="	Highest Level of Education:"
                          margin="normal"
                        />
                      )}
                    />
                    {/* <label htmlFor="Country">Highest Level of Education:</label>
										<select className="form-select">
											<option>Highschool</option>
											<option>GED</option>
											<option>Tech</option>
											<option>Vocational</option>
											<option>In college</option>
											<option>Associates</option>
											<option>Bachelors</option>
											<option>Masters</option>
											<option>PhD</option>
											<option>Doctorate</option>
										</select> */}
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps10}
                      id="auto-complete"
                      autoComplete
                      includeInputInList
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="	Do you want to connect w/ someone who is:"
                          margin="normal"
                        />
                      )}
                    />
                    {/* <label htmlFor="Country">
											Do you want to connect w/ someone who is:
										</label>
										<select className="form-select">
											<option>Highschool</option>
											<option>GED</option>
											<option>Tech</option>
											<option>Vocational</option>
											<option>In college</option>
											<option>Associates</option>
											<option>Bachelors</option>
											<option>Masters</option>
											<option>PhD</option>
											<option>Doctorate</option>
											<option>No Preference</option>
										</select> */}
                  </div>
                  <div className="form-group">
                    <label htmlFor="Country"> Business LinkdIn Url:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder=" https://...."
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="Country"> Business Web Url:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder=" https://...."
                    />
                  </div>

                  <div class="float-right">
                    <button
                      className="btn btn-primary nextBtn  btn-lg pull-right"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/dashboard");
                      }}
                    >
                      Finish
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <Footer />
      </>
    );
  }
}
