import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

const ForumCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [modal, setModal] = useState("");
  const toggle = (id) => {
    if (modal == id) {
      setModal("");
    } else {
      setModal(id);
    }
  };

  const next = () => {
    const nextIndex =
      activeIndex === props.items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    const nextIndex =
      activeIndex === 0 ? props.items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = props.items.map((item) => {
    return (
      <CarouselItem key={item.src}>
        <img
          src={item.src}
          alt={item.altText}
          onClick={(e) => {
            e.preventDefault();
            toggle(item.src);
          }}
        />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });

  return (
    <>
      <Carousel
        interval={false}
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        {slides}
        {slides.length > 1 ? (
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
        ) : null}
        {slides.length > 1 ? (
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        ) : null}
      </Carousel>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={(e) => {
          e.preventDefault();
          toggle(modal);
        }}
      >
        <ModalHeader
          toggle={(e) => {
            e.preventDefault();
            toggle(modal);
          }}
        />
        <ModalBody>
          <img style={{ width: "100%" }} src={modal} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default ForumCarousel;
