import React, { Component } from "react";
import userProfile from "./assets/images/user-profile.png";
import { getAllUsers, getUser } from "../../store/actions/userActions";
import {
  getChats,
  readMessages,
  sendMessage,
  startChat,
} from "../../store/actions/chatsActions";
import { connect } from "react-redux";
import moment from "moment";
import {
  Button,
  Col,
  Input,
  ModalBody,
  Row,
  Modal,
  ModalHeader,
  Label,
  Form,
} from "reactstrap";
import { Badge } from "@material-ui/core";
// import { Modal } from "bootstrap";

class Chats extends Component {
  componentDidMount() {
    var { loggedUser } = this.props;
    this.props.getAllUsers();
    this.props.getUser(loggedUser.id);
    this.props.getChats(loggedUser.id);
    if (this.props.location.state && this.props.location.state.chat) {
      this.setState({ chat: this.props.location.state.chat });
    }
    if (this.state.chat) {
      this.props.readMessages({
        chat_id: this.state.chat,
        user_id: loggedUser.id,
      });
    }
    this.scrollToBottom();
  }
  state = { chat: "", search: "", modal: false };
  scrollToBottom = () => {
    const { messageList } = this.refs;
    messageList.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  toggle = (e) => {
    e.preventDefault();
    this.setState({ modal: !this.state.modal });
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.scrollToBottom();
  }
  render() {
    var { chats, users, loggedUser } = this.props;

    return (
      <>
        <div className="container-fluid pt-4">
          <h3 className=" text-center">Messaging</h3>
          <Row>
            <Col className="text-right">
              <Button
                className="mb-2"
                color="success"
                size="sm"
                onClick={this.toggle}
              >
                Start Chat
              </Button>
              <Modal isOpen={this.state.modal} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>Start Chat</ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (e.target.connect.value) {
                        if (e.target.message.value) {
                          var thisChat =
                            chats &&
                            loggedUser &&
                            chats.find(
                              (chat) =>
                                chat.users.includes(loggedUser.id) &&
                                chat.users.includes(e.target.connect.value)
                            );

                          if (thisChat) {
                            this.props.sendMessage({
                              chat_id: thisChat.id,
                              message: e.target.message.value,
                              time: moment().format(),
                              user_id: loggedUser.id,
                            });
                            this.setState({ chat: thisChat.id });
                          } else {
                            this.props.startChat({
                              user1: loggedUser.id,
                              user2: e.target.connect.value,
                              message: e.target.message.value,
                              time: moment().format(),
                            });
                          }
                          this.toggle(e);
                        } else {
                          alert("Cannot send empty message");
                        }
                      } else {
                        alert("Select Connect To Message");
                      }
                    }}
                  >
                    <Label>Connect Name</Label>
                    <p className="text-danger">
                      {loggedUser && loggedUser.connects.length
                        ? null
                        : "*No Connects Yet*"}
                    </p>
                    <Input name="connect" type="select">
                      <option hidden value="">
                        Select Connect
                      </option>
                      {loggedUser &&
                        loggedUser.connects.map((connect) => {
                          return (
                            <option value={connect}>
                              {users &&
                                users.find((us) => us.id == connect) &&
                                users.find((us) => us.id == connect)
                                  .first_name +
                                  " " +
                                  users.find((us) => us.id == connect)
                                    .last_name}
                            </option>
                          );
                        })}
                    </Input>
                    <Input
                      className="mt-2"
                      type="textarea"
                      name="message"
                      placeholder="Message"
                    />
                    <Row>
                      <Col className="text-right">
                        <Button className="mt-2" color="success">
                          Send
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
          <div className="d-none d-lg-block messaging">
            <div className="  shadow inbox_msg">
              <div className="inbox_people">
                <div className="headind_srch">
                  <div className="recent_heading">
                    <h4>Recent</h4>
                  </div>
                  <div className="srch_bar">
                    <div className="stylish-input-group">
                      <input
                        type="text"
                        className="search-bar"
                        placeholder="Search"
                        value={this.state.search}
                        onChange={(e) => {
                          e.preventDefault();
                          this.setState({ search: e.target.value });
                        }}
                      />
                      <span className="input-group-addon">
                        <button type="button">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="inbox_chat">
                  {chats &&
                  loggedUser &&
                  chats.filter((chat) => {
                    var otherUserId = chat.users.find(
                      (us) => us !== loggedUser.id
                    );
                    var otherUser =
                      users && users.find((us) => us.id === otherUserId);

                    return (
                      (otherUser &&
                        otherUser.first_name
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase())) ||
                      (otherUser &&
                        otherUser.last_name
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase()))
                    );
                  }).length ? (
                    chats
                      .filter((chat) => {
                        var otherUserId = chat.users.find(
                          (us) => us !== loggedUser.id
                        );
                        var otherUser =
                          users && users.find((us) => us.id === otherUserId);
                        return (
                          (otherUser &&
                            otherUser.first_name
                              .toLowerCase()
                              .includes(this.state.search.toLowerCase())) ||
                          (otherUser &&
                            otherUser.last_name
                              .toLowerCase()
                              .includes(this.state.search.toLowerCase()))
                        );
                      })
                      .sort((ch1, ch2) => {
                        if (
                          ch1.messages.length > 0 &&
                          ch2.messages.length > 0
                        ) {
                          var lastTime1 = moment(
                            ch1.messages.slice(-1)[0].time
                          );
                          var lastTime2 = moment(
                            ch2.messages.slice(-1)[0].time
                          );

                          if (lastTime1.isBefore(lastTime2, "s")) {
                            return 1;
                          } else {
                            if (lastTime1.isAfter(lastTime2, "s")) {
                              return -1;
                            } else {
                              return 0;
                            }
                          }
                        } else {
                          return 0;
                        }
                      })
                      .map((chat) => {
                        var otherUserId = chat.users.find(
                          (us) => us !== loggedUser.id
                        );
                        var otherUser =
                          users && users.find((us) => us.id === otherUserId);

                        return (
                          <div
                            className={
                              this.state.chat === chat.id
                                ? "chat_list active_chat"
                                : "chat_list"
                            }
                            //active_chat when chat is active
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ chat: chat.id });
                              this.props.readMessages({
                                chat_id: chat.id,
                                user_id: loggedUser.id,
                              });
                            }}
                          >
                            <div className="chat_people">
                              <div className="chat_img">
                                <img
                                  style={{
                                    borderRadius: "50%",
                                    width: "50px",
                                    height: "50px",
                                  }}
                                  src={otherUser && otherUser.images[0]}
                                  alt={otherUser.first_name}
                                />
                              </div>

                              <div className="chat_ib">
                                <h5>
                                  {otherUser && otherUser.first_name}&nbsp;
                                  {otherUser && otherUser.last_name}
                                  {chat.messages && otherUser ? (
                                    chat.unread.includes(loggedUser.id) ? (
                                      <i
                                        style={{
                                          fontSize: "10px",
                                          color: "red",
                                        }}
                                        class="fas fa-circle ml-2"
                                      ></i>
                                    ) : null
                                  ) : null}
                                  <span className="chat_date">
                                    {chat.messages
                                      ? chat.messages.slice(-1)[0] &&
                                        moment(
                                          chat.messages.slice(-1)[0].time
                                        ).format("MMM DD")
                                      : ""}
                                  </span>
                                </h5>
                                <p className="mt-2 mb-2">
                                  {chat.messages && chat.messages.slice(-1)[0]
                                    ? chat.messages.slice(-1)[0].message
                                        .length > 20
                                      ? chat.messages
                                          .slice(-1)[0]
                                          .message.slice(0, 20) + "...."
                                      : chat.messages.slice(-1)[0].message
                                    : "No messages begin conversation"}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <h5 className="text-center mt-2 mb-2">
                      No Chats Found Message Someone to start Chat
                    </h5>
                  )}
                </div>
              </div>
              <div className="mesgs">
                <div className="msg_history">
                  {chats &&
                  chats
                    .filter((chat) => {
                      var otherUserId = chat.users.find(
                        (us) => us !== loggedUser.id
                      );
                      var otherUser =
                        users && users.find((us) => us.id === otherUserId);

                      return (
                        (otherUser &&
                          otherUser.first_name
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase())) ||
                        (otherUser &&
                          otherUser.last_name
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()))
                      );
                    })
                    .find((chat) => {
                      if (chat.id === this.state.chat) {
                        this.props.readMessages({
                          chat_id: this.state.chat,
                          user_id: loggedUser.id,
                        });
                        return chat.id == this.state.chat;
                      } else {
                        return false;
                      }
                    })
                    ? chats
                        .filter((chat) => {
                          var otherUserId = chat.users.find(
                            (us) => us !== loggedUser.id
                          );
                          var otherUser =
                            users && users.find((us) => us.id === otherUserId);

                          return (
                            (otherUser &&
                              otherUser.first_name
                                .toLowerCase()
                                .includes(this.state.search.toLowerCase())) ||
                            (otherUser &&
                              otherUser.last_name
                                .toLowerCase()
                                .includes(this.state.search.toLowerCase()))
                          );
                        })
                        .find((chat) => chat.id === this.state.chat)
                        .messages.map((message) => {
                          if (message.user_id === loggedUser.id) {
                            return (
                              <div className="outgoing_msg">
                                <div className="sent_msg">
                                  <p>{message.message}</p>
                                  <span className="time_date">
                                    {moment(message.time).format("HH:mm")} |
                                    &nbsp;{" "}
                                    {moment(message.time).format("MMM DD")}
                                  </span>
                                </div>
                              </div>
                            );
                          } else {
                            // this.props.readMessages({
                            //   chat_id: this.state.chat,
                            //   message_from: message.user_id,
                            // });
                            var otherUser =
                              users &&
                              users.find((user) => user.id === message.user_id);
                            return (
                              <div className="incoming_msg">
                                <div className="incoming_msg_img">
                                  <img
                                    style={{
                                      borderRadius: "50%",
                                      width: "50px",
                                      height: "50px",
                                    }}
                                    src={otherUser && otherUser.images[0]}
                                    alt={otherUser.first_name}
                                  />
                                </div>
                                <div className="received_msg">
                                  <div className="received_withd_msg">
                                    <p>{message.message}</p>
                                    <span className="time_date">
                                      {moment(message.time).format("HH:mm")} |
                                      &nbsp;{" "}
                                      {moment(message.time).format("MMM DD")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })
                    : "No chat selected"}
                  <div ref="messageList"></div>
                </div>
                <div hidden={!this.state.chat} className="type_msg">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (e.target.message.value) {
                        var time = moment().format();
                        this.props.sendMessage({
                          chat_id: this.state.chat,
                          user_id: loggedUser.id,
                          time,
                          message: e.target.message.value,
                        });
                        e.target.message.value = "";
                      } else {
                        alert("Cannot send empty message");
                      }
                    }}
                    className="input_msg_write"
                  >
                    <input
                      name="message"
                      type="text"
                      className="write_msg"
                      placeholder="Type a message"
                    />
                    <button className="msg_send_btn p-0">
                      <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="d-lg-none messaging">
            <div className="shadow inbox_msg">
              <div hidden={this.state.chat} className="inbox_people_mobile">
                <div className="headind_srch">
                  <div className="recent_heading">
                    <h4>Recent</h4>
                  </div>
                  <div className="srch_bar">
                    <div className="stylish-input-group">
                      <input
                        type="text"
                        className="search-bar"
                        placeholder="Search"
                        value={this.state.search}
                        onChange={(e) => {
                          e.preventDefault();
                          this.setState({ search: e.target.value });
                        }}
                      />
                      <span className="input-group-addon">
                        <button type="button">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="inbox_chat">
                  {chats &&
                  loggedUser &&
                  chats.filter((chat) => {
                    var otherUserId = chat.users.find(
                      (us) => us !== loggedUser.id
                    );
                    var otherUser =
                      users && users.find((us) => us.id === otherUserId);

                    return (
                      (otherUser &&
                        otherUser.first_name
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase())) ||
                      (otherUser &&
                        otherUser.last_name
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase()))
                    );
                  }).length ? (
                    chats
                      .filter((chat) => {
                        var otherUserId = chat.users.find(
                          (us) => us !== loggedUser.id
                        );
                        var otherUser =
                          users && users.find((us) => us.id === otherUserId);

                        return (
                          (otherUser &&
                            otherUser.first_name
                              .toLowerCase()
                              .includes(this.state.search.toLowerCase())) ||
                          (otherUser &&
                            otherUser.last_name
                              .toLowerCase()
                              .includes(this.state.search.toLowerCase()))
                        );
                      })
                      .sort((ch1, ch2) => {
                        if (
                          ch1.messages.length > 0 &&
                          ch2.messages.length > 0
                        ) {
                          var lastTime1 = moment(
                            ch1.messages.slice(-1)[0].time
                          );
                          var lastTime2 = moment(
                            ch2.messages.slice(-1)[0].time
                          );

                          if (lastTime1.isBefore(lastTime2, "s")) {
                            return 1;
                          } else {
                            if (lastTime1.isAfter(lastTime2, "s")) {
                              return -1;
                            } else {
                              return 0;
                            }
                          }
                        } else {
                          return 0;
                        }
                      })
                      .map((chat) => {
                        var otherUserId = chat.users.find(
                          (us) => us !== loggedUser.id
                        );
                        var otherUser =
                          users && users.find((us) => us.id === otherUserId);

                        return (
                          <div
                            className={
                              this.state.chat === chat.id
                                ? "chat_list active_chat"
                                : "chat_list"
                            }
                            //active_chat when chat is active
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ chat: chat.id });
                              this.props.readMessages({
                                chat_id: chat.id,
                                user_id: loggedUser.id,
                              });
                            }}
                          >
                            <div className="chat_people d-flex">
                              <div className="chat_img">
                                <img
                                  style={{
                                    borderRadius: "50%",
                                    width: "50px",
                                    height: "50px",
                                  }}
                                  src={otherUser && otherUser.images[0]}
                                  alt={otherUser.first_name}
                                />
                              </div>
                              <div className="chat_ib">
                                <h5>
                                  {otherUser && otherUser.first_name}&nbsp;
                                  {otherUser && otherUser.last_name}
                                  {chat.messages && otherUser ? (
                                    chat.unread.includes(loggedUser.id) ? (
                                      <i
                                        style={{
                                          fontSize: "10px",
                                          color: "red",
                                        }}
                                        class="fas fa-circle ml-2"
                                      ></i>
                                    ) : null
                                  ) : null}
                                  <span className="chat_date">
                                    {chat.messages
                                      ? chat.messages.slice(-1)[0] &&
                                        moment(
                                          chat.messages.slice(-1)[0].time
                                        ).format("MMM DD")
                                      : ""}
                                  </span>
                                </h5>
                                <p className="mt-2 mb-2">
                                  {chat.messages && chat.messages.slice(-1)[0]
                                    ? chat.messages.slice(-1)[0].message
                                        .length > 20
                                      ? chat.messages
                                          .slice(-1)[0]
                                          .message.slice(0, 20) + "...."
                                      : chat.messages.slice(-1)[0].message
                                    : "No messages begin conversation"}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <h5 className="text-center mt-2 mb-2">
                      No Chats Found Message Someone to start Chat
                    </h5>
                  )}
                </div>
              </div>
              <div hidden={!this.state.chat} className="mesgs_mobile">
                <Row>
                  <Col className="text-left">
                    <Button
                      size="sm"
                      color="dark"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ chat: "" });
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
                <div className="msg_history mt-3">
                  {chats &&
                  chats
                    .filter((chat) => {
                      var otherUserId = chat.users.find(
                        (us) => us !== loggedUser.id
                      );
                      var otherUser =
                        users && users.find((us) => us.id === otherUserId);

                      return (
                        (otherUser &&
                          otherUser.first_name
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase())) ||
                        (otherUser &&
                          otherUser.last_name
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()))
                      );
                    })
                    .find((chat) => chat.id === this.state.chat)
                    ? chats
                        .filter((chat) => {
                          var otherUserId = chat.users.find(
                            (us) => us !== loggedUser.id
                          );
                          var otherUser =
                            users && users.find((us) => us.id === otherUserId);

                          return (
                            (otherUser &&
                              otherUser.first_name
                                .toLowerCase()
                                .includes(this.state.search.toLowerCase())) ||
                            (otherUser &&
                              otherUser.last_name
                                .toLowerCase()
                                .includes(this.state.search.toLowerCase()))
                          );
                        })
                        .find((chat) => {
                          if (chat.id === this.state.chat) {
                            this.props.readMessages({
                              chat_id: this.state.chat,
                              user_id: loggedUser.id,
                            });
                            return chat.id == this.state.chat;
                          } else {
                            return false;
                          }
                        })
                        .messages.map((message) => {
                          if (message.user_id === loggedUser.id) {
                            return (
                              <div className="outgoing_msg">
                                <div className="sent_msg">
                                  <p>{message.message}</p>
                                  <span className="time_date">
                                    {moment(message.time).format("HH:mm")} |
                                    &nbsp;{" "}
                                    {moment(message.time).format("MMM DD")}
                                  </span>
                                </div>
                              </div>
                            );
                          } else {
                            // this.props.readMessages({
                            //   chat_id: this.state.chat,
                            //   message_from: message.user_id,
                            // });
                            var otherUser =
                              users &&
                              users.find((user) => user.id === message.user_id);

                            return (
                              <div className="incoming_msg">
                                <div className="incoming_msg_img_mobile">
                                  <img
                                    style={{
                                      borderRadius: "50%",
                                      width: "50px",
                                      height: "50px",
                                    }}
                                    src={otherUser && otherUser.images[0]}
                                    alt={otherUser.first_name}
                                  />
                                </div>
                                <div className="received_msg_mobile">
                                  <div className="received_withd_msg">
                                    <p>{message.message}</p>
                                    <span className="time_date">
                                      {moment(message.time).format("HH:mm")} |
                                      &nbsp;{" "}
                                      {moment(message.time).format("MMM DD")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })
                    : "No chat selected"}
                </div>
                <div hidden={!this.state.chat} className="type_msg">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (e.target.message.value) {
                        var time = moment().format();
                        this.props.sendMessage({
                          chat_id: this.state.chat,
                          user_id: loggedUser.id,
                          time,
                          message: e.target.message.value,
                        });
                        e.target.message.value = "";
                      } else {
                        alert("Cannot Send Empty Message");
                      }
                    }}
                    className="input_msg_write"
                  >
                    <input
                      name="message"
                      type="text"
                      className="write_msg"
                      placeholder="Type a message"
                    />
                    <button className="msg_send_btn p-0">
                      <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export function mapStateToProps(state) {
  return {
    loading: state.auth.requested,
    registered: state.auth.registered,
    loggedUser: state.authUser.user,
    users: state.users.users,
    chats: state.chat.chats,
    // connect_requests: state.connect.connect_requests,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    // registeredComplete: () => dispatch(removeRegistered()),
    // login: (credentials) => dispatch(login(credentials)),
    // loginGoogle: () => dispatch(signupGoogle()),

    getUser: (uid) => dispatch(getUser(uid)),
    getAllUsers: () => dispatch(getAllUsers()),
    getChats: (uid) => dispatch(getChats(uid)),
    sendMessage: (msg) => dispatch(sendMessage(msg)),
    startChat: (msg) => dispatch(startChat(msg)),
    readMessages: (msg) => dispatch(readMessages(msg)),

    // removeConnect: (loggedUid, uid) => dispatch(removeConnect(loggedUid, uid)),
    // getConnectRequests: () => dispatch(getConnectRequests()),
    // removeRequest: (uid) => dispatch(removeRequest(uid)),
    // acceptRequest: (uid) => dispatch(acceptRequest(uid)),

    // personelInfo: (Info) => dispatch(updatePersonelInfo(Info)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Chats);
