import firebase from "./../firebase";
export const getChats = (id) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("chats")
      .onSnapshot((query) => {
        var tempUsers = [];
        query.forEach((doc) => {
          if (doc.data().users.includes(id)) {
            tempUsers.push({ id: doc.id, ...doc.data() });
          }
        });

        dispatch({
          type: "GET_CHATS",
          chats: tempUsers,
        });
      });
  };
};
export const sendMessage = (message) => {
  return async (dispatch) => {
    var chat = await firebase
      .firestore()
      .collection("chats")
      .doc(message.chat_id)
      .get();

    var pushUser = chat.data().users.find((us) => us != message.user_id);

    var TempMessages = chat.data().messages;
    TempMessages.push({
      message: message.message,
      user_id: message.user_id,
      time: message.time,
    });
    firebase
      .firestore()
      .collection("chats")
      .doc(message.chat_id)
      .update({
        messages: TempMessages,
        unread: firebase.firestore.FieldValue.arrayUnion(pushUser),
      })
      .then((res) => {
        dispatch({
          type: "MESSAGE_SENT",
        });
      });
  };
};
export const startChat = (message) => {
  return async (dispatch) => {
    firebase
      .firestore()
      .collection("chats")
      .add({
        users: [message.user1, message.user2],
        unread: [message.user2],
        messages: [
          {
            message: message.message,
            time: message.time,
            user_id: message.user1,
          },
        ],
      })
      .then((res) => {
        dispatch({
          type: "CHAT_CREATED",
        });
      });
  };
};
export const readMessages = (message) => {
  return async (dispatch) => {
    var chat = await firebase
      .firestore()
      .collection("chats")
      .doc(message.chat_id)
      .get();

    var popUser = chat.data().users.find((us) => us == message.user_id);
    firebase
      .firestore()
      .collection("chats")
      .doc(message.chat_id)
      .update({
        unread: firebase.firestore.FieldValue.arrayRemove(popUser),
      })
      .then((res) => {
        dispatch({
          type: "MESSAGE_READ",
        });
      });
  };
};
