import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class EventsDetail extends Component {
	render() {
		return (
			<>
				<div class="container mt-5">
					<div class="row">
						<div class="col">
							<div class="card">
								<div class="card-header bg-primary text-white">
									<i class="fa fa-envelope"></i> Contact us.
								</div>
								<div class="card-body MYForm">
									<div class="form-group">
										<label for="name">Event Name:</label>
									</div>
									<div class="form-group">
										<label for="email">Event Date and Time</label>
									</div>
									<div class="form-group">
										<label for="message">Cost: </label>
									</div>
									<div class="form-group">
										<label for="message">
											External links for more information{" "}
										</label>
										<ul class="mbm_social">
											<li>
												<a class="social-facebook" href="#">
													<i class="fa fa-facebook">
														<small>facebook</small>
													</i>

													<div class="tooltip">
														<span>facebook</span>
													</div>
												</a>
											</li>
											<li>
												<a class="social-twitter" href="#">
													<i class="fa fa-twitter">
														<small>Twitter</small>
													</i>
													<div class="tooltip">
														<span>Twitter</span>
													</div>
												</a>
											</li>
											<li>
												<a class="social-google-plus" href="#">
													<i class="fa fa-google-plus">
														<small>google +</small>
													</i>
													<div class="tooltip">
														<span>google</span>
													</div>
												</a>
											</li>
											<li>
												<a class="social-linkedin" href="#">
													<i class="fa  fa-linkedin">
														<small>linkedin</small>
													</i>
													<div class="tooltip">
														<span>linkedin</span>
													</div>
												</a>
											</li>
										</ul>
									</div>
									<div class="form-group">
										<label for="message">
											When is the last day to rsvp by?{" "}
										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-4">
							<div class="card  mb-3">
								<div class="card-header bg-success text-white text-uppercase">
									<i class="fa fa-home"></i> Address
								</div>
								<div class="card-body myAdress">
									<p>3 rue des Champs Elysées 75008 PARIS</p>
									<p>Event organizer</p>
									<p>Email : email@example.com</p>
									<p>Tel. +33 12 56 11 51 84</p>
									<Link
										to={{
											pathname: "/dashboard/profile-detail",
											state: { is_business: true },
										}}
										className="btn btn-primary rounded d-block"
									>
										View Business
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
