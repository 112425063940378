import firebase from "./../firebase";
export const getNotifications = (id) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("notifications")
      .where("notification_for", "==", id)
      .orderBy("date","desc")
      .onSnapshot((query) => {
        var tempUsers = [];
        query.forEach((doc) => {
          tempUsers.push({ id: doc.id, ...doc.data() });
        });

        dispatch({
          type: "GET_NOTIFICATIONS",
          notifications: tempUsers,
        });
      });
  };
};
export const readNotifications = (id) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("notifications")
      .doc(id)
      .update({
        status: "read",
      })
      .then((res) => {
        dispatch({
          type: "READ_NOTIFICATIONS",
        });
      });
  };
};
