import React from "react";
import Landing from "./views/general/Landing";
import SignUp from "./views/general/SignUp";
import FillProfile from "./views/general/FillProfile";
import FillProfileBussiness from "./views/general/FillProfileBussiness";
import SignIn from "./views/general/SignIn";
import Dashboard from "./views/dashboard/Dashboard";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AboutUs from "./views/general/aboutUs";
import Privacy from "./views/general/privacyPolicy";
import TermsOfUse from "./views/general/termsOfUse";
import connectBeroot from "./views/general/connectBeroot";
// import EditBusinessProfile from "./views/dashboard/EditBusinessProfile";
import "./App.css";
import "./css/style.css";
import Forget from "./views/general/Forget";
import Forum from "./views/general/Forum";
import Post from "./views/general/Post";
function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" exact component={Landing}></Route>
          <Route exact path="/forum" exact component={Forum}></Route>
          <Route exact path="/forum/:id" exact component={Post}></Route>
          <Route exact path="/signup" exact component={SignUp} />
          <Route exact path="/signin" exact component={SignIn} />
          <Route exact path="/forgotpassword" exact component={Forget} />
          <Route exact path="/aboutus" exact component={AboutUs} />
          <Route exact path="/privacy-policy" exact component={Privacy} />
          <Route exact path="/terms-of-use" exact component={TermsOfUse} />
          <Route exact path="/aboutus" exact component={AboutUs} />
          <Route
            exact
            path="/connect-beyroot"
            exact
            component={connectBeroot}
          />
          <Route
            exact
            path="/fillprofile/individual"
            exact
            component={FillProfile}
          />
          <Route
            path="/fillprofile/business"
            exact
            component={FillProfileBussiness}
          />
          <Route path="/dashboard" component={Dashboard}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
