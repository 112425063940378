import React, { Component } from "react";
import { connect } from "react-redux";
import {
  acceptRequest,
  blockUser,
  getConnectRequests,
  removeRequest,
} from "../../store/actions/connectActions";
import {
  deletePost,
  getMyPosts,
  removePostImage,
  updatePost,
} from "../../store/actions/postsActions";
import { getUser } from "../../store/actions/userActions";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";

class MyPosts extends Component {
  componentDidMount() {
    var { loggedUser } = this.props;
    this.props.getUser(loggedUser.id);
    this.props.getPosts(loggedUser.id);
  }
  state = { search: "", modal: "" };
  toggleEditPost = (id) => {
    if (this.state.modal == id) {
      this.setState({ modal: "" });
    } else {
      this.setState({ modal: id });
    }
  };
  render() {
    var { loggedUser, posts } = this.props;

    return (
      <>
        <div className="minHeight">
          <div class=" mt-5 main-content container-fluid ">
            <div class=" ml-lg-5 ml-2 pl-2 page-title">
              <h3>My Posts</h3>
            </div>
          </div>
          {posts && posts.length ? (
            <div className="container-fluid   pl-3 pr-3  ">
              <div className="container-fluid   shadow   ">
                <section class="container-fluid  pt-3 mt-4 mb-4">
                  {/* table goes here */}
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Post Description</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody style={{ background: "white" }}>
                      {posts &&
                        posts.map((post) => {
                          return (
                            <tr>
                              <Link
                                className="text-decoration-none"
                                style={{ color: "black" }}
                                to={"/forum/" + post.id}
                                target="_blank"
                              >
                                <td>{post.text}</td>
                              </Link>
                              <td>
                                {moment(post.date).format("DD MMM")}&nbsp;
                                {moment(post.date).format("LT")}
                              </td>
                              <td>
                                <Button
                                  className="mr-2"
                                  size="sm"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.toggleEditPost(post.id);
                                  }}
                                  color="success"
                                >
                                  <i className="fas fa-edit" />
                                </Button>
                                <Modal
                                  isOpen={this.state.modal == post.id}
                                  toggle={(e) => {
                                    e.preventDefault();
                                    this.toggleEditPost(post.id);
                                  }}
                                >
                                  <ModalHeader
                                    toggle={(e) => {
                                      e.preventDefault();
                                      this.toggleEditPost(post.id);
                                    }}
                                  >
                                    Edit Post
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        if (e.target.text.value) {
                                          this.props.updatePost(
                                            {
                                              text: e.target.text.value,
                                              id: post.id,
                                            },

                                            e.target.image.files
                                          );
                                          this.toggleEditPost(post.id);
                                        } else {
                                          alert("Cannot Update Empty Post");
                                        }
                                      }}
                                    >
                                      <Label>Post Text</Label>
                                      <Input
                                        type="textarea"
                                        name="text"
                                        defaultValue={post.text}
                                        required
                                      />
                                      <Label>Post Image</Label>
                                      {post.image ? (
                                        <>
                                          <br />
                                          <div className="d-flex">
                                            {post.image &&
                                              post.image.map((img, index) => {
                                                return (
                                                  <div className="d-flex mr-2">
                                                    <img
                                                      src={img}
                                                      className="mb-2 mr-2"
                                                      style={{
                                                        width: "75px",
                                                        height: "75px",
                                                      }}
                                                    />
                                                    <i
                                                      className="fas fa-close text-danger"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        var confirm =
                                                          window.confirm(
                                                            "Are you sure you want to delete this image?"
                                                          );
                                                        if (confirm) {
                                                          this.props.removePostImage(
                                                            post.id,
                                                            index
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </div>
                                                );
                                              })}
                                          </div>
                                          <br />
                                        </>
                                      ) : null}
                                      <Input
                                        type="file"
                                        name="image"
                                        multiple
                                      />
                                      <Row>
                                        <Col className="text-right">
                                          <Button
                                            type="submit"
                                            className="m-2"
                                            color="info"
                                          >
                                            Update Post
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                                <Button
                                  className="mr-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    var confirm = window.confirm(
                                      "Are you sure you want to delete the post?"
                                    );
                                    if (confirm) this.props.deletePost(post.id);
                                  }}
                                  size="sm"
                                  color="danger"
                                >
                                  <i className="fas fa-trash" />
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      {/* {users &&
                    users
                      .filter((us) => us.id != uid)
                      .map((us) => {
                        return (
                          <tr>
                            <td>{us.name}</td>
                            <td>{us.email}</td>
                            <td>{us.country}</td>
                          </tr>
                        );
                      })} */}
                    </tbody>
                  </Table>
                </section>
              </div>
            </div>
          ) : (
            <div className="container">
              <h4>
                No Post exists{" "}
                <a href="/forum">
                  <Button color="success" size="sm">
                    Create new one
                  </Button>
                </a>{" "}
              </h4>
            </div>
          )}
        </div>
      </>
    );
  }
}
export function mapStateToProps(state) {
  return {
    // loading: state.auth.requested,
    // registered: state.auth.registered,
    loggedUser: state.authUser.user,
    posts: state.post.posts,
    // connect_requests: state.connect.connect_requests,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    // registeredComplete: () => dispatch(removeRegistered()),
    // login: (credentials) => dispatch(login(credentials)),
    // loginGoogle: () => dispatch(signupGoogle()),

    getUser: (uid) => dispatch(getUser(uid)),
    getPosts: (id) => dispatch(getMyPosts(id)),
    deletePost: (id) => dispatch(deletePost(id)),
    updatePost: (post, image) => dispatch(updatePost(post, image)),
    removePostImage: (post_id, index) =>
      dispatch(removePostImage(post_id, index)),

    // getAllUsers: () => dispatch(getAllUsers()),
    // connectRequest: (loggedUid, uid) =>
    //   dispatch(connectRequest(loggedUid, uid)),
    // getConnectRequests: () => dispatch(getConnectRequests()),
    // removeRequest: (uid) => dispatch(removeRequest(uid)),
    // blockUser: (uid) => dispatch(blockUser(uid)),
    // acceptRequest: (uid) => dispatch(acceptRequest(uid)),

    // personelInfo: (Info) => dispatch(updatePersonelInfo(Info)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyPosts);
