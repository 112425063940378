import React, { Component } from 'react';
import firebase from '../../store/firebase';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { phoneLogin } from '../../store/actions/authActions';
import { connect } from 'react-redux';

class phoneComponent extends Component {
	componentDidMount() {
		console.log(this.props);
		const uiConfig = {
			signInOptions: [firebase.auth.PhoneAuthProvider.PROVIDER_ID],
			callbacks: {
				signInSuccessWithAuthResult: (authResult, redirectUrl) => {
					// Handle the result
					this.props.phoneLogin(authResult.user, {
						current_location: 'N/A',
						lat: 'N/A',
						lng: 'N/A',
					});
					return false;
				},
			},
		};
		var ui =
			firebaseui.auth.AuthUI.getInstance() ||
			new firebaseui.auth.AuthUI(firebase.auth());
		ui.start('#firebaseui-auth-container', uiConfig);
	}
	render() {
		return <div id='firebaseui-auth-container'></div>;
	}
}
export function mapStateToProps(state) {
	return {
		loading: state.auth.requested,
		registered: state.auth.registered,
		user: state.authUser.user,
		auth: state.auth,
		capche: state.auth.capche,
	};
}
export function mapDispatchToProps(dispatch) {
	return {
		// registeredComplete: () => dispatch(removeRegistered()),
		phoneLogin: (user, location) => dispatch(phoneLogin(user, location)),

		// getUser: (uid) => dispatch(getUser(uid)),
		// loginGoogle: () => dispatch(signupGoogle()),
		// loginFacebook: () => dispatch(signupFacebook()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(phoneComponent);
