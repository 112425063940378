import React, { Component } from 'react'
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import './privacyPolicy.css';

class TermsOfUse extends Component {
    // componentDidMount=()=>{
    // 	window.scrollTo(0, 0)
    // }
    render() {
        return (
            <>
                <Navbar />
                <div class="privacy-banner">
                    <div class="container">
                        <div class="row">
                            {/* <!-- Left side Image--> */}
                            <div class="col-lg-12 privacy-Banner">
                                <h1>Terms Of Use</h1>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--Banner Section start here--> */}

                <div class="container content">
                    {/* <!--Content Section start here--> */}
                    <h3>Connect Beyroot Privacy Policy</h3><br />
                    <b>Welcome to the Connect Beyroot Privacy Policy ("Policy")!</b><br /> This explains how we collect, store,
                    protect, and share your information, and with whom we share it. Whilst you're enjoying the
                    Connect Beyroot mobile application ("App"), we collect some information about you. In\
                    addition, you may choose to use the App to share information with other Connect Beyrootrs,
                    including your friends and contacts ("Users"). We may also need to share your information
                    sometimes.<br></br>
                    Connect Beyroot is a global app, and your information will be sent to and used in the United
                    States regardless of the country you reside in. This Policy explains how we protect your personal
                    data when we transfer it overseas, so please read very carefully!
                    If you have any questions or comments about this Policy, please feel free to contact us at <br />
                    <b>connectbeyroot@gmail.com</b><br></br>
                    <h3>1. COLLECTION OF INFORMATION</h3>.<br></br>
                    <b>Registration Information</b><br></br>
                    When you download the App and create an account ("Account"), we may collect certain
                    information ("Registration Information") about you, such as but not limited to:
                    • Name;<br></br>
                    • Username;<br></br>
                    • Email address;<br></br>
                    • Mobile number;<br></br>
                    • Date of birth;<br></br>
                    • Photographs;<br></br>
                    • Location<br></br>
                    • Login information and friend lists for social media accounts that you connect to your
                    Connect Beyroot Account (this could include, for example, your Facebook and Instagram
                    accounts ("Social Media Accounts").<br></br>
                    Indiv Bizz<br></br>
                    Sign in:<br></br>
                    FB , #, email, criteria<br></br>
                    Sign in:<br></br>
                    FB , #, email, Criteria <br></br>
                    You will also be required to create a password in connection with the registration of your
                    Account. Once you register, you will be able to review and change this information at any time
                    by accessing your “Profile” page! You control your user profile and are able to correct or update
                    (other than your email address, mobile number, date of birth, gender and location (which is
                    automatically updated)) your information at any time by just logging in to Connect Beyroot.
                    The information we collect helps to enhance the App and verify our Users (robots are not
                    welcome!!). Registration Information such as your name and username may be visible to the
                    public on your profile page.<br></br>
                    <h3>Additional Information</h3>
                    We may ask you to provide your full name, address and email address. Such details may be
                    shared with third parties for purposes relating to, for example, any of our loyalty programs,
                    sending gifts or delivery of merchandise.<br></br>
                    We may also collect additional information in connection with your use of Connect Beyroot, for
                    example if you use our super cool electronic messaging function.<br></br>
                    If you contact our Customer Support team, we will receive your email address, and may track
                    your IP address as well as the information you send to us to help resolve your query. We will
                    keep records of our communications with you, including any complaints that we receive from
                    you about other Users (and from other Users about you).<br></br>
                    We recommend and encourage you (and all our members) to think carefully about the
                    information you disclose about yourself. We also do not recommend that you put email
                    addresses, URLs, instant messaging details, phone numbers, full names or addresses, credit card
                    details, national identity numbers, drivers' license details and other sensitive information on your
                    profile which is open to abuse and misuse.<br></br>
                    Please be careful about posting sensitive details about yourself on your profile such as your
                    religious denomination and health details. You may also choose to add sensitive information
                    about yourself when you add certain Connect Beyroot Badges to your profile, such as your<br></br>
                    religion and political leanings. While you may voluntarily provide this information to us when
                    you create your profile, including your sexual preferences, there is no requirement to do so.
                    Please remember that photographs that you post on Connect Beyroot may reveal information<br></br>
                    about yourself as well. Where you do upload and choose to tell us sensitive information about
                    yourself, including through adding certain Badges to your profile, you are explicitly consenting
                    to our processing of your information and making this public to other users.<br></br>
                    When you post information about yourself or use the messaging function, the amount of personal
                    information you share is at your own risk. Please see Section 4 below for more information on
                    who can access what you post on Connect Beyroot. If you post anything that is inconsistent with
                    our Terms and Conditions of Use, we reserve the right to terminate your Account - we really
                    don't want to have to do this so please play by the rules!<br></br>
                    For safety and security and to ensure you have the best possible user experience, we require
                    users to verify their accounts and might ask for your phone number. We want to make sure you
                    are not a robot! And we also want to avoid fake Connect Beyroot accounts being created which
                    can be used for malicious activities and cybercrime – they threaten the Connect Beyroot network
                    and spoil things for everyone. We use a third party provider for account verification who will
                    keep your phone number for up to 90 days for fraud identification purposes. We collect and use
                    your phone number on the basis of our legitimate interests identified in section 11 below and for
                    the prevention of fraud.<br></br>
                    It is your responsibility to ensure that your account details are kept up to date. If your phone
                    number changes, please ensure that you update this in your account.<br></br>
                    To prevent abuse of the app/site, Connect Beyroot uses automated decisions and moderators to
                    block accounts as part of its moderation procedures. In order to do this, we check accounts and
                    messages for content that indicates breaches of our Terms and Conditions of Use. This is done
                    through a combination of automated systems and our team of moderators. If an account or<br></br>
                    message meets certain criteria that demonstrate that the Terms and Conditions of Use are likely
                    to have been breached, the relevant account will automatically be blocked. All users of accounts
                    that have been blocked will be notified that their account has been blocked and affected users can
                    contact Connect Beyroot to contest the decision..<br></br>
                    Connect Beyroot also uses automated decisions to prevent fraudulent payment transactions being
                    processed as part of its anti-fraud procedures. In order to do this, our systems check payment
                    transactions for behavior that indicates breaches of our Terms and Conditions of Use. If a
                    transaction meets certain criteria that demonstrate that the Terms and Conditions of Use are
                    likely to have been breached and the transaction is likely to be fraudulent, the relevant<br></br>
                    transaction may automatically be blocked. Where a transaction is blocked, the user will be
                    notified that their transaction cannot be processed and affected users can contact Connect
                    Beyroot to contest the decision.
                    Please note that if your account is blocked, any account(s) that you have on our other group
                    platforms/applications may also be blocked as part of our anti-spam and anti-fraud procedures.
                    Our other group platforms/applications are listed here: https://magiclab.co/brands.
                    If you decide to purchase any of our premium services, we will process your payment
                    information and retain this securely for the prevention of fraud and for audit/tax purposes.
                    We process some limited data (demographics and information that you have optionally provided
                    us with via Connect Beyroot Badges) to drive targeted advertising in our legitimate interest, via
                    promo cards and ads on our App. We also process limited data (device identifiers) and share this
                    with third party advertising networks which host our ads. You can stop targeted advertising
                    within the “Contact & FAQ” page accessible via your profile. If you opt-out of targeted
                    advertising you will still see adverts though they will be less relevant to you. Through your
                    device’s security settings you also have the option to prevent or limit device identifiers being
                    shared with third party advertisers and what use is made of the device identifiers. If you would
                    like more information about this practice and to know your choices about not having this
                    information used by these companies, please visit this page.
                    Finally, we want to keep in touch with you to make sure you know about the great promotions
                    and offers we have available. If you’ve told us it’s OK, we will use your email address and
                    phone number to send you information on these. You can withdraw this consent at any time via
                    Settings in the App.<br></br>
                    The data we may collect falls within the following categories of “Identifiers, such as name and
                    location;<br></br>
                    1. Personal information (including email and telephone number) and financial information;<br></br>
                    2. Such as age, gender identity, marital status, sexual orientation, race, ancestry, national
                    origin, religion, and medical conditions<br></br>
                    3. Commercial information, such as transaction information and purchase history;
                    4. Biometric information (not relevant here);<br></br>
                    5. Internet or network activity information, such as browsing history and interactions with
                    our websites and apps;<br></br>
                    6. Geolocation data, such as mobile device location;<br></br>
                    7. Audio, electronic, visual and similar information, such as photos and videos;<br></br>
                    8. Professional or employment-related information, such as work history and prior
                    employer;<br></br>
                    9. Non-public education information<br></br>
                    10. Inferences drawn from any of the personal information listed above to create a profile or
                    summary about, for example, an individual’s preferences and characteristics.
                    Data Storage<br></br>
                    By using the App, you acknowledge that Connect Beyroot is a global app operating through
                    servers located in a number of countries around the world, including the United States. If you
                    live in a country with data protection laws, the storage of your personal data may not provide you
                    with the same protections as you enjoy in your country of residence.<br></br>
                    <h3>Geolocation Information</h3><br></br>
                    If you turn these features on, when you use your mobile, we will collect information about Wi-Fi
                    access points as well as other location information about your longitude and latitude and may
                    save your device's coordinates to offer certain features to you. This information helps us identify
                    your physical location and we may use it to personalize the App and make it easier for you to
                    interact with other Users, by enabling the information to be displayed and shared with other
                    members choosing to view “nearby” posts.<br></br>
                    If you have enabled location services, but wish to turn them off, you can do so by the following
                    methods Currently via webapp and in the future via Cellular device.<br></br>
                    1. iPhone app — settings, privacy, location services, Connect Beyroot<br></br>
                    2. Android — settings, location, Connect Beyroot, permissions, location
                    Log and Usage Data<br></br>
                    We keep your personal information only as long as we need it for legitimate business purposes
                    (as set out in Section 11 below) and as permitted by applicable law.
                    In practice, we delete or anonymize your information upon deletion of your account (following
                    the safety retention window), unless:<br></br>
                    1. We must keep it to comply with applicable law (for instance, some “traffic data” is kept
                    for one year to comply with statutory data retention obligations);<br></br>
                    2. we must keep it to evidence our compliance with applicable law (for instance, records of
                    consents to our Terms, Privacy Policy and other similar consents are kept for five years);<br></br>
                    3. there is an outstanding issue, claim or dispute requiring us to keep the relevant
                    information until it is resolved; or<br></br>
                    4. the information must be kept for our legitimate business interests, such as fraud
                    prevention and enhancing users' safety and security. For example, information may need to be
                    kept to prevent a user who was banned for unsafe behavior or security incidents from opening a
                    new account.<br></br>
                    Where Connect Beyroot uses machine learning, for example, to help us detect and prevent
                    fraudulent card transactions, and to detect and prevent spam communications on the App (as
                    explained above), we may need to keep personal information for a longer period than the<br></br>
                    retention periods explained above, to enable the machine learning to work properly. Where this
                    is the case, we always seek to minimize the amount of personal information that is used and we
                    ensure that it is ring-fenced and kept securely from other user personal information. We regularly
                    review the period for which personal information is required for machine learning purposes and
                    delete any identifiable information when it is no longer required.<br></br>
                    <h3>Device Information</h3><br></br>
                    We may collect information about your device when you use the App including the unique
                    device identifier, device model, operating system, and MAC address. In addition, if you permit
                    us to do so, the App may access your device's address book solely in order to add someone to
                    your contacts.<br></br>
                    <b>Links</b><br></br>
                    We may keep track of how you interact with links available on Connect Beyroot including third
                    party services and clients by redirecting clicks or through other means. We may share aggregate
                    click statistics such as how many times a particular link was clicked on.<br></br>
                    <b>Cookies</b><br></br>
                    Unlike the chocolate chip kind, these cookies have no calories! They are small data files that are
                    transferred to your computer's hard disk and are basically a tool that stores information about
                    website visits, recognizes you and your preferences each time you visit Connect Beyroot, and
                    ensures site functionality and enables us to provide the services our members request.<br></br>
                    The cookies we collect enable us to learn how people interact with Connect Beyroot, which in
                    turn helps us make a better product for you! Cookies store information about your website visits
                    and can recognize you and your preferences each time you visit the Connect Beyroot site. They
                    help us to provide a better service to you!<br></br>
                    Connect Beyroot’s use of cookies and local storage devices is basically related to the
                    performance of Connect Beyroot’s site, such as analytics that help us determine how our site is
                    performing and ensuring a smooth and trouble-free experience for our members and visitors.
                    Below is a table to explain Connect Beyroot’s cookie functions and why they’re used.<br></br>
                    If for any reason you decide that you do not want all of your Connect Beyroot activities to be
                    stored you may set your browser and mobile settings to block cookies and local storage devices,
                    but please remember that if you do so, you may not be able to access all of the features Connect
                    Beyroot offers!<br></br>
                    <h3>2. USE OF INFORMATION:</h3><br></br>
                    Our main goal is to ensure your experience on Connect Beyroot is an enjoyable one and you
                    don't end up getting stung! In order to deliver an enjoyable experience to you, we may use your
                    Registration and other information to:<br></br>
                    • offer you our services and features;<br></br>
                    • contact you with information about the App (e.g., updates and new offerings);<br></br>
                    • personalize the App and the content we deliver to you;<br></br>
                    • conduct research and analytics about how you use and interact with the App;<br></br>
                    • resolve disputes between you and other Users; and<br></br>
                    • investigate fraud, protect our legal rights, and to enforce our Terms & Conditions.<br></br>
                    <h3>3. DISCLOSURE OF INFORMATION:</h3><br></br>
                    Our policy is to not disclose your Registration Information, except in the limited circumstances<br></br>
                    <b>described here:</b><br></br>
                    Circumstances where data may be disclosed<br></br>
                    Your Consent – If you consent, we may share or disclose your Registration Information, such as
                    when you use a third party web client or application to access your Account. This could include
                    all data, including all CCPA Categories listed above<br></br>
                    Service Providers - We engage certain trusted third parties to perform functions and provide
                    services to us. We may share your Registration Information with these third parties, but only for
                    the purposes of performing these functions and providing such services e.g. information<br></br>
                    technology companies (hardware and software) which provide services to support our products
                    and help us with information security matters. This could include all data, including all CCPA
                    Categories listed above<br></br>
                    Moderators – To monitor activity on the apps and approve content. Name and user registration
                    details, profile information, content of messages and photographs (CCPA Categories A, B, C, E,
                    and H)<br></br>
                    Payment Processing and Telecommunications Companies – To facilitate payments for our
                    premium services. Cardholder name, cardholder address, card number, payment amount,
                    transaction date/time (CCPA Categories A, B, and D).<br></br>
                    Law and Harm – We think it is very important that all Connect Beyrooters behave whilst using
                    the App. We are not responsible for any communication that happens on or off the app. We are
                    not responsible for any of the meet ups. Be safe, as we do not take responsibility for anyone’s
                    actions. We will cooperate with all third parties to enforce their intellectual property or other
                    rights. We will also cooperate with law enforcement enquiries from within or outside your<br></br>
                    country of residence where we are required to by law, where there is an investigation into alleged
                    criminal behavior or to protect the vital interests of a person. This may include preserving or
                    disclosing any of your information, including your Registration Information, if we believe in
                    good faith that it is necessary to comply with a law or regulation, or when we believe that<br></br>
                    disclosure is necessary to comply with a judicial proceeding, court order, or legal request; to
                    protect the safety of any person; to address fraud, security or technical issues e.g. through antispam providers to protect the service from criminal activity or to protect our rights or property or
                    those of third parties. In such cases we may raise or waive any legal objection or right available<br></br>
                    to us. This could include any personal data that Connect Beyroot holds about you, depending on
                    the nature of the request or the issue that we are dealing with, including all CCPA Categories
                    listed above<br></br>
                    Business Transfers -In the event that Connect Beyroot or any of our affiliates undergoes a
                    business transition or change of ownership, such as a merger, acquisition by another company,
                    re-organization, or sale of all or a portion of its assets, or in the event of insolvency or<br></br>
                    administration, we may be required to disclose your personal data. This could include all
                    personal data that Connect Beyroot holds about you, including all CCPA Categories listed above
                    Advertising Partners – To serve targeted advertisements to users. Device ID, geolocation data,
                    demographic data (CCPA Categories C, F, and G)<br></br>
                    Anti-Spam and Anti-Fraud -Your data may be shared with other Connect Beyroot group
                    companies, for example, to block accounts and suspected fraudulent payment transactions as part
                    of our anti-spam and anti-fraud procedures. Email address, phone number, IP address and IP
                    session information, social network ID, username, user agent string, and transaction and payment
                    data (CCPA Categories B, F and D).<br></br>
                    Aggregated Information – We may share aggregated information with third parties that includes
                    your personal data (but which doesn’t identify you directly) together with other information
                    including log data for industry analysis and demographic profiling. You may opt-out of receiving
                    marketing messages by using the opt-out mechanisms and links provided in each email.<br></br>
                    We ensure these parties must adhere to strict data protection and confidentiality provisions that
                    are consistent with this Policy. Measures are taken to ensure that the data shared is nonattributable to the greatest extent possible.
                    Connect Beyroot does not sell your data and has not sold your personal data in the previous 12
                    months.<br></br>
                    <h3>4. WHAT OTHERS MAY SEE ABOUT YOU:</h3><br></br>
                    We think our Connect Beyrooters are awesome, and we want you to share how awesome you are
                    with the world, so we have built certain features to enable this. Our App is designed to make it
                    easier for you to connect with other Users and to interact with them. If you register or log in to
                    the App using your Facebook account, you are authorizing us to access certain Facebook account<br></br>
                    information, including information you make available via Facebook, your friends list, current
                    location and those friends you have in common with other Connect Beyrooters. You also
                    understand that your Connect Beyroot profile and other information you make available via the
                    App, including information you provide directly or indirectly through Facebook (i.e., your
                    Facebook photos, your name, age, approximate location, friends you have in common with other
                    Connect Beyrooters and other profile information), may be viewed and shared by Connect<br></br>
                    Beyrooters with individuals who may or may not be Users or via third party applications in such
                    Connect Beyrooters’ discretion. For example, a User may recommend you as a match to his or
                    her Facebook friends by sharing your picture, regardless of whether such friend(s) is also a User
                    of the App. You might share information like the photos and messages you send or upload and
                    any metadata provided with those messages, along with any comments or information you
                    provide in connection with your photos.<br></br>
                    When using Connect Beyroot, you should assume that anything you post or submit on the App
                    may be publicly-viewable and accessible, both by Users of the App and non-Users of the App.
                    We want our Connect Beyrooters to be careful about posting information that will eventually be
                    made public. If you log in to or access Connect Beyroot through your Facebook profile,
                    remember to log off Facebook when you leave a device in order to prevent other users from
                    accessing your account.<br></br>
                    <h3>5. MODIFYING YOUR REGISTRATION INFORMATION:</h3><br></br>
                    You may access or modify the Registration Information you provided at any time or permanently
                    delete your Account (although we really hope you don't!) When your Account is deactivated, we
                    take reasonable efforts to make sure it is no longer viewable on the App. For up to 30 days it is
                    still possible to restore your Account if it was accidentally or wrongfully deactivated. After 30
                    days, we begin the process of deleting your Account from our systems. We are not responsible
                    for any information, pictures, comments, or other content that is deleted from our systems
                    resulting from the deactivation of your Account.<br></br>
                    To prevent abuse and/or misuse of Connect Beyroot by a User following termination or deletion
                    of a profile/Account we shall retain such information as we deem in our sole discretion may be
                    necessary to ensure that User does not open a new Account and profile in breach our terms or
                    ensure compliance with all laws and regulations.<br></br>
                    You are not allowed to delete your profile before
                    Warning: Even after you remove information from your profile or delete your Account, copies of
                    that information may still be viewable and/or accessed to the extent such information has been
                    previously shared with others, or copied or stored by other Users or to the extent such<br></br>
                    information has been shared with search engines. We cannot control this, nor do we accept any
                    liability for this. If you have given third party applications or websites access to your personal
                    information they may retain such information to the extent permitted under their terms of service
                    or privacy policies. Removed and deleted information may persist in backup copies for up to 30
                    days to enable restoration, but will not be available to others in the meantime.<br></br>
                    <h3>6. OUR POLICY TOWARDS AGE:</h3><br></br>
                    Although we want as many people as possible to enjoy our creation, you have to be at least 18
                    years old to use the App - sorry kids, we know Connect Beyroot is cool, but you'll have to come
                    back when you're old enough! Connect Beyroot does not knowingly collect any information<br></br>
                    about or market to children, minors or anyone under the age of 18. If you are less than 18 years
                    old, we request that you do not submit information to us. If we become aware that a child, minor
                    or anyone under the age of 18 has registered with us and provided us with personal information,
                    we will take steps to terminate that person's registration and delete their Registration Information
                    from Connect Beyroot. If we do delete a profile because you violated our no children rules, we<br></br>
                    may retain your email and IP address to ensure that you do not try to get around our rules by
                    creating a new profile.<br></br>
                    <h3>7. CHANGES TO THIS POLICY:</h3><br></br>
                    As Connect Beyroot evolves, we may revise this Privacy Policy from time to time. The most
                    current version of the policy will govern our use of your information and will always be on our
                    webapp. If we make a change to this policy that, in our sole discretion, is material, we will notify
                    you, for example, via an email to the email associated with your Account or by posting a notice
                    within Connect Beyroot. By continuing to access or use the App after those changes become
                    effective, you agree to be bound by the revised Privacy Policy.<br></br>
                    <h3>8. SECURITY:</h3><br></br>
                    Here at Connect Beyroot we pride ourselves on taking commercially reasonable security
                    measures to help protect your information against loss, misuse and unauthorized access, or
                    disclosure. We use reasonable security measures to safeguard the confidentiality of your personal
                    information such as secured servers using firewalls. Unfortunately no website or Internet<br></br>
                    transmission is ever completely 100% secure and even we cannot guarantee that unauthorized
                    access, hacking, data loss or other breaches will never occur, but here are some handy tips to
                    help keep your data secure:<br></br>
                    1. Please make sure you log out of your Account after use as you never know who may
                    stumble onto your Account!<br></br>
                    2. Please don't share your social media password with anyone else!<br></br>
                    3. Change your social media password periodically.<br></br>
                    If you ever think someone has had access to your social media password, please report it to the
                    relevant social media platform and change your password immediately. We cannot guarantee the
                    security of your personal data while it is being transmitted to our site and any transmission is at
                    your own risk.<br></br>
                    WE EXPRESSLY DISCLAIM ANY REPRESENTATION OR WARRANTY, WHETHER
                    EXPRESS OR IMPLIED, WITH RESPECT TO ANY BREACHES OF SECURITY, DAMAGE
                    TO YOUR DEVICE, OR ANY LOSS OR UNAUTHORISED USE OF YOUR
                    REGISTRATION INFORMATION OR OTHER DATA.<br></br>
                    <h3>9. THIRD PARTY ACCOUNTS</h3><br></br>
                    If you have registered with Connect Beyroot using a third party social media platform, such as
                    Facebook, your Connect Beyroot profile will be available to all Users of Connect Beyroot
                    whether via our App or our partners' websites.<br></br>
                    Remember that when you register with a third party, you are also giving them personal
                    information, so we recommend that you read their privacy policies as Connect Beyroot does not
                    control how they use their information.<br></br>
                    If you have registered on one of these third party websites, such as Facebook, or you have
                    verified your Account via a third party website, we may connect your Connect Beyroot profile
                    with your profile on such website. We may do this by way of new products and applications
                    which we introduce from time to time, including new products powered by our group companies.<br></br>
                    If you have created a Connect Beyroot profile through a third party website and you do not want
                    to link your Connect Beyroot profile to your profile on the third party website, Connect Beyroot
                    cannot amend these settings. Please visit the application settings on your third party website
                    profile and follow the instructions to remove the Connect Beyroot access permissions.<br></br>
                    We only use the APIs, OAuth Tokens, Widgets, or other means provided by the applicable
                    Social Media Account to integrate your Social Media Account with our App. As such, we only
                    receive the limited information that each Social Media Account permits to be transferred.<br></br>
                    <h3>10. YOUR CALIFORNIA PRIVACY RIGHTS:</h3><br></br>
                    For users who are California residents, you have the following rights (in addition to those listed
                    at section 11 below) under the California Consumer Privacy Act, and you have the right to be
                    free from unlawful discrimination for exercising your rights under the Act:<br></br>
                    1. Right to be informed: what personal data an organization is processing and why (we
                    provide this information to you in this Privacy Policy).<br></br>
                    2. Right of access: you can request a copy of your data.<br></br>
                    3. Right of rectification: if the data held is inaccurate, you have the right to have it
                    corrected.<br></br>
                    4. Right to erasure: you have the right to have your data deleted in certain circumstances.<br></br>
                    5. Right to restrict processing: in limited circumstances, you have the right to request that
                    processing is stopped but the data retained.<br></br>
                    6. Right to data portability: you can request a copy of your data in a machine-readable form
                    that can be transferred to another provider.<br></br>
                    7. Right to object: in certain circumstances (including where data is processed on the basis
                    of legitimate interests or for the purposes of marketing) you may object to that processing.<br></br>
                    8. Rights related to automated decision making including profiling: there are several rights
                    in this area where processing carried out on a solely automated basis results in a decision which
                    has legal or significant effects for the individual. In these circumstances your rights include the
                    right to ensure that there is human intervention in the decision-making process.
                    How does Connect Beyroot protect my personal data?<br></br>
                    Connect Beyroot has implemented appropriate security measures to protect and prevent the loss,
                    misuse, and alteration of the information under our control, including your personal data. Our
                    technical experts at Connect Beyroot work hard to ensure your secure use of our site.<br></br>
                    While we take reasonable precautions against possible security breaches of our website, member
                    database and records, no website or Internet transmission is completely secure and we cannot
                    guarantee that unauthorized access, hacking, data loss, or other breaches will never occur. We
                    urge you to take steps to keep your personal data safe (including your password) and to log out
                    of your account after use. If you lose your password or give it out, or another service provider
                    that you use suffers a data breach and you have used the same credentials with that service
                    provider as you have with Connect Beyroot, your personal data may be compromised.
                    Where is my personal data kept?<br></br>
                    We want you to be able to access Connect Beyroot wherever you happen to be in the world. To
                    enable us to provide that service, we operate a global network of servers including all countries.
                    The hardware is located in third-party data canters but is owned by the Connect Beyroot group.
                    Data collected by advertising partners may also be held outside the European Economic Area.
                    Does Connect Beyroot collect any other personal data about me?<br></br>
                    We may track your IP address, as well as the information you send to us to help resolve your
                    query. We will keep records of our communications with you, including any complaints that we
                    receive from you about other users (and from other users about you) for 6 years after deletion of
                    your account.<br></br>
                    Does Connect Beyroot use my personal data for any other purpose?
                    We may use material that you post on the open access areas of Connect Beyroot in advertising
                    and promotional materials on our partner sites and partner products. We believe these uses are in
                    line with our legitimate interests in enabling us to improve our site and better tailor your online
                    experience to meet your needs. If we intend to do this, we will contact you for your prior<br></br>
                    approval. From time to time we may run surveys for research purposes and we may contact you
                    to find out if you would like to take part. We may also contact you to find out if you would like
                    to take part in marketing campaigns. Such surveys and marketing campaigns are optional and
                    more information will be provided at the point of contact. We may also contact you for feedback
                    purposes. If you do not wish to be contacted to take part in a survey or marketing campaign,
                    please contact our Customer Support Team via Feedback Page. We explain in the relevant
                    sections in this Policy how you can withdraw consent or opt-out of certain data uses (where
                    applicable).<br></br>
                    To provide you with the Connect Beyroot social networking service. You provide your name,
                    email address and date of birth to us. We obtain location data from the device that you use to
                    access the service. To facilitate networking opportunities on the Connect Beyroot site. Optional
                    information that you choose to provide in your profile, including through adding Connect
                    Beyroot badges, which may include information about your sexual preferences, non-binary
                    gender, religion, ethnic background, photos etc. The English version shall prevail. Thanks for
                    reading, we hope we didn't bore you for too long!
                    Effective date<br></br>
                    <b>This Privacy Policy was last updated on 30 December 2020.</b>
                </div>

                <Footer />
            </>
        )
    }
}

export default TermsOfUse;