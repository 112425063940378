import React, { Component } from "react";
import "../../css/bootstrap.css";
import "../../css/FillProfile.css";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { DatePicker, Upload } from "antd";
import "antd/lib/date-picker/style/css";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Cropper from "react-easy-crop";
import "../../js/script";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { login, removeRegistered } from "../../store/actions/authActions";
import { v4 as uuidV4 } from "uuid";
import {
  personelInfo,
  getUser,
  personelInfoPhone,
} from "../../store/actions/userActions";
import { Redirect } from "react-router-dom";
import {
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Button,
  ModalFooter,
} from "reactstrap";
import moment from "moment";
import getCroppedImg from "../../components/cropImage";
import ImgCrop from "antd-img-crop";

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
});
const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.title,
});
// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const countries = [
  { title: "Åland Islands" },
  { title: "Afghanistan" },
  { title: "Albania" },
  { title: "Algeria" },
  { title: "American Samoa" },
  { title: "Andorra" },
  { title: "Angola" },
  { title: "Anguilla" },
  { title: "Antarctica" },
  { title: "Antigua and Barbuda" },
  { title: "Argentina" },
  { title: "Armenia" },
  { title: "Aruba" },
  { title: "Austria" },
  { title: "Azerbaijan" },
  { title: "Bahamas" },
  { title: "Bahrain" },
  { title: "Bangladesh" },
  { title: "Barbados" },
  { title: "Belarus" },
  { title: "Belgium" },
  { title: "Belize" },
  { title: "Benin" },
  { title: "Bermuda" },
  { title: "Bhutan" },
  { title: "Bolivia, Plurinational State of" },
  { title: "Bonaire, Sint Eustatius and Saba" },
  { title: "Bosnia and Herzegovina" },
  { title: "Bouvet Island" },
  { title: "Brazil" },
  { title: "British Indian Ocean Territory" },
  { title: "Brunei Darussalam" },
  { title: "Bulgaria" },
  { title: "Burkina Faso" },
  { title: "Burundi" },
  { title: "Cambodia" },
  { title: "Cameroon" },
  { title: "Canada" },
  { title: "Cape Verde" },
  { title: "Cayman Islands" },
  { title: "Central African Republic" },
  { title: "Chad" },
  { title: "Chile" },
  { title: "China" },
  { title: "Christmas Island" },
  { title: "Cocos (Keeling) Islands" },
  { title: "Colombia" },
  { title: "Comoros" },
  { title: "Congo" },
  { title: "Congo, the Democratic Republic of the" },
  { title: "Cook Islands" },
  { title: "Costa Rica" },
  { title: "Côte d'Ivoire" },
  { title: "Croatia" },
  { title: "Cuba" },
  { title: "Curaçao" },
  { title: "Cyprus" },
  { title: "Czech Republic" },
  { title: "Denmark" },
  { title: "Djibouti" },
  { title: "Dominica" },
  { title: "Dominican Republic" },
  { title: "Ecuador" },
  { title: "Egypt" },
  { title: "El Salvador" },
  { title: "England" },
  { title: "Equatorial Guinea" },
  { title: "Eritrea" },
  { title: "Estonia" },
  { title: "Ethiopia" },
  { title: "Falkland Islands (Malvinas)" },
  { title: "Faroe Islands" },
  { title: "Fiji" },
  { title: "Finland" },
  { title: "France" },
  { title: "French Guiana" },
  { title: "French Polynesia" },
  { title: "French Southern Territories" },
  { title: "Gabon" },
  { title: "Gambia" },
  { title: "Georgia" },
  { title: "Germany" },
  { title: "Ghana" },
  { title: "Gibraltar" },
  { title: "Greece" },
  { title: "Greenland" },
  { title: "Grenada" },
  { title: "Guadeloupe" },
  { title: "Guam" },
  { title: "Guatemala" },
  { title: "Guernsey" },
  { title: "Guinea" },
  { title: "Guinea-Bissau" },
  { title: "Guyana" },
  { title: "Haiti" },
  { title: "Heard Island and McDonald Islands" },
  { title: "GuaHoly See (Vatican City State)m" },
  { title: "Honduras" },
  { title: "Hong Kong" },
  { title: "Hungary" },
  { title: "Iceland" },
  { title: "India" },
  { title: "Indonesia" },
  { title: "Iran, Islamic Republic of" },
  { title: "Iraq" },
  { title: "Ireland" },
  { title: "Isle of Man" },
  { title: "Israel" },
  { title: "Italy" },
  { title: "Jamaica" },
  { title: "Japan" },
  { title: "Jersey" },
  { title: "Jordan" },
  { title: "Kazakhstan" },
  { title: "Kenya" },
  { title: "Kiribati" },
  { title: "Korea, Democratic People's Republic of" },
  { title: "Korea, Republic of" },
  { title: "Kuwait" },
  { title: "Kyrgyzstan" },
  { title: "Lao People's Democratic Republic" },
  { title: "Latvia" },
  { title: "Lebanon" },
  { title: "Lesotho" },
  { title: "Liberia" },
  { title: "Libya" },
  { title: "Lithuania" },
  { title: "Luxembourg" },
  { title: "Macao" },
  { title: "Macedonia, the former Yugoslav Republic of" },
  { title: "Madagascar" },
  { title: "Malawi" },
  { title: "Malaysia" },
  { title: "Maldives" },
  { title: "Mali" },
  { title: "Malta" },
  { title: "Marshall Islands" },
  { title: "Martinique" },
  { title: "Mauritania" },
  { title: "Mauritius" },
  { title: "Mayotte" },
  { title: "Mexico" },
  { title: "Micronesia, Federated States of" },
  { title: "Moldova, Republic of" },
  { title: "Monaco" },
  { title: "Mongolia" },
  { title: "Morocco" },
  { title: "Mozambique" },
  { title: "Myanmar" },
  { title: "Namibia" },
  { title: "Nauru" },
  { title: "Nepal" },
  { title: "Netherlands" },
  { title: "New Caledonia" },
  { title: "New Zealand" },
  { title: "Nicaragua" },
  { title: "Niger" },
  { title: "Nigeria" },
  { title: "Niue" },
  { title: "Norfolk Island" },
  { title: "Northern Mariana Islands" },
  { title: "Norway" },
  { title: "Oman" },
  { title: "Pakistan" },
  { title: "Palau" },
  { title: "Palestinian Territory, Occupied" },
  { title: "Panama" },
  { title: "Papua New Guinea" },
  { title: "Peru" },
  { title: "Philippines" },
  { title: "Pitcairn" },
  { title: "Poland" },
  { title: "Portugal" },
  { title: "Puerto" },
  { title: "Rico" },
  { title: "Qatar" },
  { title: "Réunion" },
  { title: "Romania" },
  { title: "Russian Federation" },
  { title: "Rwanda" },
  { title: "Saint Barthélemy" },
  { title: "Saint Helena, Ascension and Tristan da Cunha" },
  { title: "Saint Kitts and Nevis" },
  { title: "Saint Lucia" },
  { title: "Saint Martin (French part)" },
  { title: "Saint Pierre and Miquelon" },
  { title: "Saint Vincent and the Grenadines" },
  { title: "Samoa" },
  { title: "San Marino" },
  { title: "Sao Tome and Principe" },
  { title: "Saudi Arabia" },
  { title: "Scontland" },
  { title: "Senegal" },
  { title: "Serbia" },
  { title: "Seychelles" },
  { title: "Sierra Leone" },
  { title: "Singapore" },
  { title: "Sint Maarten (Dutch part)" },
  { title: "Slovakia" },
  { title: "Slovenia" },
  { title: "Solomon Islands" },
  { title: "Somalia" },
  { title: "South Africa" },
  { title: "South Georgia and the South Sandwich Islands" },
  { title: "South Sudan" },
  { title: "Spain" },
  { title: "Sri Lanka" },
  { title: "Sudan" },
  { title: "Suriname" },
  { title: "Svalbard and Jan Mayen" },
  { title: "Swaziland" },
  { title: "Sweden" },
  { title: "Switzerland" },
  { title: "Syrian Arab Republic" },
  { title: "Taiwan, Province of China" },
  { title: "Tajikistan" },
  { title: "Tanzania, United Republic of" },
  { title: "Thailand" },
  { title: "Timor-Leste" },
  { title: "Togo" },
  { title: "Tokelau" },
  { title: "Tonga" },
  { title: "Trinidad and Tobago" },
  { title: "Tunisia" },
  { title: "Turkey" },
  { title: "Turkmenistan" },
  { title: "Turks and Caicos Islands" },
  { title: "Tuvalu" },
  { title: "Uganda" },
  { title: "Ukraine" },
  { title: "United Arab Emirates" },
  { title: "United States" },
  { title: "United States Minor Outlying Islands" },
  { title: "Uruguay" },
  { title: "Uzbekistan" },
  { title: "	Venezuela, Bolivarian Republic of" },
  { title: "Viet Nam" },
  { title: "Virgin Islands, British" },
  { title: "Virgin Islands, U.S." },
  { title: "Wales" },
  { title: "Wallis and Futuna" },
  { title: "Western Sahara" },
  { title: "Yemen" },
  { title: "Zambia" },
  { title: "Zimbabwe" },
];

const languages = [
  { title: "Afrikaans" },
  { title: "Albanian" },
  { title: "Arabic" },
  { title: "Armenian" },
  { title: "Basque" },
  { title: "Bengali" },
  { title: "Bulgarian" },
  { title: "Catalan" },
  { title: "Cambodian" },
  { title: "Chinese (Mandarin)" },
  { title: "Croatian" },
  { title: "Czech" },
  { title: "Danish" },
  { title: "Dutch" },
  { title: "English" },
  { title: "Estonian" },
  { title: "Fiji" },
  { title: "Finnish" },
  { title: "French" },
  { title: "Georgian" },
  { title: "German" },
  { title: "Greek" },
  { title: "Gujarati" },
  { title: "Hebrew" },
  { title: "Hindi" },
  { title: "Hungarian" },
  { title: "Icelandic" },
  { title: "Indonesian" },
  { title: "Irish" },
  { title: "Italian" },
  { title: "Japanese" },
  { title: "Javanese" },
  { title: "Korean" },
  { title: "Latin" },
  { title: "Latvian" },
  { title: "Lithuanian" },
  { title: "Macedonian" },
  { title: "Malay" },
  { title: "Malayalam" },
  { title: "Maltese" },
  { title: "Maori" },
  { title: "Marathi" },
  { title: "Mongolian" },
  { title: "Nepali" },
  { title: "Norwegian" },
  { title: "Persian" },
  { title: "Polish" },
  { title: "Portuguese" },
  { title: "Punjabi" },
  { title: "Quechua" },
  { title: "Romanian" },
  { title: "Russian" },
  { title: "Samoan" },
  { title: "Serbian" },
  { title: "Slovak" },
  { title: "Slovenian" },
  { title: "Spanish" },
  { title: "Swahili" },
  { title: "Swedish" },
  { title: "Tamil" },
  { title: "Tatar" },
  { title: "Telugu" },
  { title: "Thai" },
  { title: "Tibetan" },
  { title: "Tonga" },
  { title: "Turkish" },
  { title: "Ukrainian" },
  { title: "Urdu" },
  { title: "Uzbek" },
  { title: "Vietnamese" },
  { title: "Welsh" },
  { title: "Xhosa" },
];

const districts = [
  { title: "Akkar" },
  { title: "Ashrafiyeh" },
  { title: "Baabda" },
  { title: "Baalbek" },
  { title: "Batroun" },
  { title: "Bint Jbeil" },
  { title: "Bsharri" },
  { title: "Byblos" },
  { title: "Chouf- Beiteddine" },
  { title: "Hasbaya" },
  { title: "Hermel" },
  { title: "Jezzine" },
  { title: "Keserwan- Jounieh" },
  { title: "Marjeyoun" },
  { title: "Matn- Jdeideh" },
  { title: "Miniyeh-Danniyeh District" },
  { title: "Nabatieh" },
  { title: "Rashaya" },
  { title: "Sidon" },
  { title: "Tripoli" },
  { title: "Tyre" },
  { title: "Zahle" },
  { title: "Zgharta" },
];

const lookings = [
  { title: "Ancestry" },
  { title: "Business" },
  { title: "Dating" },
  { title: "Friendship" },
  { title: "Networking" },
];

const smoke = [
  { title: "Yes, frequent smoke" },
  { title: "Social Smoker" },
  { title: "Hookah Smoker" },
  { title: "Non Smoker" },
];
const drinking = [
  { title: "No, never" },
  { title: "Social drinker" },
  { title: "Frequent drinker" },
];
const relegions = [
  { title: "Armenian Catholic" },
  { title: "Armenian Orthodox" },
  { title: "Christian" },
  { title: "Druze" },
  { title: "Maronite" },
  { title: "Melkite" },
  { title: "Muslim" },
  { title: "Orthodox" },
  { title: "Syriac Catholic" },
  { title: "Roman Catholic" },
  { title: "Other" },
];

const professions = [
  { title: "Arts" },
  { title: "Accounting/ Finance" },
  { title: "Agriculture" },
  { title: "AI/ machine learning" },
  { title: "Army/ Military" },
  { title: "Architect" },
  { title: "Athletics" },
  { title: "Automotive" },
  { title: "Construction" },
  { title: "Consulting" },
  { title: "Education" },
  { title: "Engineering" },
  { title: "Finance" },
  { title: "Fishing and farming" },
  { title: "Graphic design/Website Development" },
  { title: "Healthcare" },
  { title: "Hospitality/Tourisim" },
  { title: "IT" },
  { title: "Law /Legal" },
  { title: "Manufacturing" },
  { title: "Marketing" },
  { title: "Media" },
  { title: "Oil and Gas" },
  { title: "Other" },
  { title: "Pharmacital" },
  { title: "Real Estate" },
  { title: "Transportation" },
];
const education = [
  { title: "High school" },
  { title: "GED" },
  { title: "In college" },
  { title: "In school" },
  { title: "Tech/ vocational" },
  { title: "Associates" },
  { title: "Bachelors" },
  { title: "Masters" },
  { title: "PhD" },
  { title: "Doctorate" },
];

const status = [
  { title: "Divorced" },
  { title: "In relationship" },
  { title: "Married" },
  { title: "Other" },
  { title: "Seperated" },
  { title: "Single" },
  { title: "Widowed" },
];
// countries
const defaultProps = {
  options: countries,
  getOptionLabel: (option) => option.title,
};
const flatProps = {
  options: countries.map((option) => option.title),
};

//   languages
const flatProps2 = {
  options: languages.map((option) => option.title),
};
const defaultProps2 = {
  options: languages,
  getOptionLabel: (option) => option.title,
};

//  districts
const defaultProps3 = {
  options: districts,
  getOptionLabel: (option) => option.title,
};

const flatProps3 = {
  options: districts.map((option) => option.title),
};
// looking for
const defaultProps4 = {
  options: lookings,
  getOptionLabel: (option) => option.title,
};

const flatProps4 = {
  options: lookings.map((option) => option.title),
};
//   smoke
const defaultProps5 = {
  options: smoke,
  getOptionLabel: (option) => option.title,
};

const flatProps5 = {
  options: smoke.map((option) => option.title),
};
//   drinking
const defaultProps6 = {
  options: drinking,
  getOptionLabel: (option) => option.title,
};

const flatProps6 = {
  options: smoke.map((option) => option.title),
};
//   relegions
const defaultProps7 = {
  options: relegions,
  getOptionLabel: (option) => option.title,
};

const flatProps7 = {
  options: relegions.map((option) => option.title),
};

//   professions
const defaultProps8 = {
  options: professions,
  getOptionLabel: (option) => option.title,
};

const flatProps8 = {
  options: professions.map((option) => option.title),
};
//   education

const defaultProps9 = {
  options: education,
  getOptionLabel: (option) => option.title,
};

const flatProps9 = {
  option: education.map((option) => option.title),
};

//   status

const defaultProps10 = {
  options: status,
  getOptionLabel: (option) => option.title,
};

const flatProps10 = {
  option: status.map((option) => option.title),
};
// var demo = ["Demo1", "Demo2"];
class FillProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      selectedFileList: [],
      activeTab: 1,
      files: [],
      filesUrl: [],
      croppedImages: [],
      croppedImagesUrls: [],
      countries: [],
      languages: [],
      district: [],
      looking_for: [],
      Age: null,
      description: "",
      gender: "ml",
      experience: null,
      name: null,
      smoke: "",
      drinking: "",
      religion: "",
      height_in: "ft",
      height_inch: 5,
      crop: { x: 0, y: 0 },
      height: 5,
      zoom: 1,
      aspect: 1 / 1,
      status: "",
    };
  }
  onChange = (info) => {
    console.log("Info:", info);
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        nextState.selectedFileList = [
          ...this.state.selectedFileList,
          info.file,
        ];
        break;
      case "done":
        let selectedFileList = JSON.parse(
          JSON.stringify(this.state.selectedFileList)
        );
        let index = selectedFileList.findIndex(
          (file) => file.uid == info.file.uid
        );
        selectedFileList[index] = info.file;
        nextState.selectedFile = info.file;
        nextState.selectedFileList = selectedFileList;
        break;

      default:
        // error or removed
        nextState.selectedFile = null;
        nextState.selectedFileList = this.state.selectedFileList.filter(
          (file) => file.name != info.file.name
        );
    }
    console.log(nextState);
    this.setState(() => ({ ...nextState }));
  };
  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedArea, croppedAreaPixels });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };
  saveImage = async (index) => {
    console.log(index);
    console.log(this.state.files[index]);
    try {
      const croppedImage = await getCroppedImg(
        this.state.filesUrl[index],
        this.state.croppedAreaPixels
      );
      console.log("donee", { croppedImage });
      this.setState({
        croppedImagesUrls: [...this.state.croppedImagesUrls, croppedImage.url],
        croppedImages: [...this.state.croppedImages, croppedImage],
      });
      console.log(this.state.croppedImages);
      // setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e);
    }
  };
  handleFile = (e) => {
    e.preventDefault();
    var files = e.target.files;
    var flag = true;
    for (var i = 0; i < e.target.files.length; i++) {
      if (
        files[i].name
          .substr(files[i].name.lastIndexOf(".") + 1, files[i].length)
          .toLowerCase() != "png" &&
        files[i].name
          .substr(files[i].name.lastIndexOf(".") + 1, files[i].length)
          .toLowerCase() != "jpg" &&
        files[i].name
          .substr(files[i].name.lastIndexOf(".") + 1, files[i].length)
          .toLowerCase() != "jpeg"
      ) {
        flag = false;
      }
    }
    if (flag) {
      if (e.target.files.length + this.state.files.length < 5) {
        this.setState({ files: [...this.state.files, ...e.target.files] });
        var newUrls = [];
        for (var i = 0; i < e.target.files.length; i++) {
          newUrls.push(URL.createObjectURL(e.target.files[i]));
        }
        this.setState({ filesUrl: [...this.state.filesUrl, ...newUrls] });
      } else {
        alert("Please Select maximum of 4 files");
      }
    } else {
      alert("Only Select Image files");
    }
    // await this.setState({
    //   [e.target.name]: e.target.files,
    //   filesUrl: [
    //     ...this.state.filesUrl,
    //     URL.createObjectURL(e.target.files[0]),
    //   ],
    // });
    // this.setState({
    //   files: [...this.state.files, e.target.files],
    // });
  };
  range = (start, end) => {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    // this.props.getUser(this.props.authUser.uid);
    // if (localStorage.getItem("reloaded") == null) {
    //   location.reload();
    //   localStorage.setItem("reloaded", true);
    // }
    this.props.getUser(this.props.authUser.uid);
  };
  // componentWillMount() {
  //   if (localStorage.getItem("reloaded") == null) {
  //     location.reload();
  //     localStorage.setItem("reloaded", true);
  //   }
  // }
  componentWillUnmount() {
    localStorage.removeItem("reloaded");
  }

  personelInfo = (e) => {
    e.preventDefault();
    // console.log(e.target.Age.value);
    var newHeight =
      this.state.height_in == "ft"
        ? parseInt(this.state.height) + parseFloat(this.state.height_inch / 100)
        : this.state.height;
    if (this.state.experience && this.state.experience > 50) {
      alert("Experience cannot be above 50 years");
    } else {
      if (
        this.state.height_in === "cm" &&
        (this.state.height < 90 || this.state.height > 230)
      ) {
        alert("Height should be between 90cm to 230cm");
      } else {
        if (
          this.state.height_in === "ft" &&
          (this.state.height < 1 || this.state.height > 8)
        ) {
          alert("Height should be between 1ft to 8ft");
        } else {
          if (
            this.state.height_in === "inch" &&
            (this.state.height < 36 || this.state.height > 96)
          ) {
            alert("Height should be between 36inches to 96 inches");
          } else {
            if (
              this.state.description.length < 10 ||
              this.state.description.length > 500
            ) {
              alert("Description should be between 10 to 500 characters");
            } else {
              if (this.state.first_name) {
                this.props.personelInfoPhone({
                  id: this.props.authUser.uid,
                  first_name: this.state.first_name,
                  last_name: this.state.last_name,
                  gender: this.state.gender,
                  files: this.state.selectedFileList,
                  age: this.state.Age,
                  description: this.state.description,
                  experience: this.state.experience,
                  countries: this.state.countries,
                  languages: this.state.languages,
                  district: this.state.district,
                  looking_for: this.state.looking_for,
                  // smoke: this.state.smoke,
                  // drinking: this.state.drinking,
                  religion: this.state.religion,
                  profession: this.state.profession,
                  education: this.state.education,
                  status: this.state.status,
                  // height: newHeight,
                  // height_in: this.state.height_in,
                });
              } else {
                this.props.personelInfo({
                  id: this.props.authUser.uid,
                  gender: this.state.gender,
                  files: this.state.selectedFileList,
                  age: this.state.Age,
                  description: this.state.description,
                  experience: this.state.experience,
                  countries: this.state.countries,
                  languages: this.state.languages,
                  district: this.state.district,
                  looking_for: this.state.looking_for,
                  smoke: this.state.smoke,
                  drinking: this.state.drinking,
                  religion: this.state.religion,
                  profession: this.state.profession,
                  education: this.state.education,
                  status: this.state.status,
                  height: newHeight,
                  height_in: this.state.height_in,
                });
              }
            }
          }
        }
      }
    }
  };

  render() {
    const { classes, auth, authUser, user } = this.props;
    // if (user && user.age) {
    //   return <Redirect to="/dashboard" />;
    // }
    // console.log(user && user.current_location);
    // console.log(auth && !authUser.uid);
    if (authUser && !authUser.uid) {
      return <Redirect to="/" />;
    }

    if (authUser && user && user.age) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <>
        <Navbar />
        <div className="container fill_Container  ">
          {/* <div className="stepwizard">
            <div className="stepwizard-row setup-panel">
              <div className="stepwizard-step">
                <a
                  href="#step-1"
                  type="button"
                  className="btn btn-primary btn-circle"
                >
                  1
                </a>
                <p>Personal</p>
              </div>
              <div className="stepwizard-step">
                <a
                  href="#step-2"
                  type="button"
                  className="btn btn-default btn-circle"
                  disabled="disabled"
                >
                  2
                </a>
                <p>Interest</p>
              </div>
              <div className="stepwizard-step">
                <a
                  href="#step-3"
                  type="button"
                  className="btn btn-default btn-circle"
                  disabled="disabled"
                >
                  3
                </a>
                <p>Other</p>
              </div>
            </div>
          </div>
           */}
          <div className="wrapper-progressBar border">
            <ul className="progressBar">
              <li
                className={`${
                  (this.state.activeTab == 1 || this.state.activeTab >= 1) &&
                  "active"
                }`}
              >
                <span>1</span>
              </li>
              <li
                className={`${
                  (this.state.activeTab == 2 || this.state.activeTab >= 2) &&
                  "active"
                }`}
              >
                <span>2</span>
              </li>
              <li
                className={`${
                  (this.state.activeTab == 3 || this.state.activeTab >= 3) &&
                  "active"
                }`}
              >
                <span>3</span>
              </li>
            </ul>
          </div>
          <form role="form" onSubmit={this.personelInfo}>
            <div
              className={`row setup-content ${
                this.state.activeTab == "1" ? "d-flex" : "d-none"
              }`}
              id="step-1"
            >
              <div className="col-xs-12">
                <div className="col-md-12">
                  <h3>PERSONAL INFORMATION</h3>
                  <div className="form-group">
                    <label htmlFor="Country">First Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="first_name"
                      readOnly={user.first_name ? true : false}
                      defaultValue={user && user.first_name}
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({
                          first_name: e.target.value,
                        });
                      }}
                      placeholder="First Name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="Country">Last Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="last_name"
                      readOnly={user.last_name ? true : false}
                      defaultValue={user && user.last_name}
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({
                          last_name: e.target.value,
                        });
                      }}
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="Country">Gender:</label>
                    <select
                      className="form-select"
                      id="gender"
                      name="gender"
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({
                          gender: e.target.value,
                        });
                      }}
                    >
                      <option value="ml">Male</option>
                      <option value="fml">Female</option>
                      <option value="fml">Other</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="Country">Upload 4 Photos of Yourself</label>
                    <br />
                    {/* <label>
                      <input
                        className="mt-3 d-none"
                        type="file"
                        onChange={this.handleFile}
                        multiple
                        id="myFile"
                        name="listingFile"
                        accept="image/*"
                        required
                        disabled={this.state.filesUrl.length == 4}
                      />
                      <i class="fas fa-lg text-primary fa-upload"></i> Upload
                      files
                    </label>
                     */}
                    <ImgCrop grid>
                      <Upload
                        listType="picture-card"
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        fileList={this.state.selectedFileList}
                        customRequest={dummyRequest}
                        onChange={this.onChange}
                      >
                        {this.state.selectedFileList.length < 4 && "+ Upload"}
                      </Upload>
                    </ImgCrop>
                    {/* filesUrl */}
                    <br></br>

                    {/* {this.state.selectedFileList.map((item, index) => {
                      delete _item["originFileObj"];
                      if (item.status != "uploading") {
                        console.log("Item:", item["originFileObj"]);
                        return (
                          <>
                            <img
                              key={index}
                              height="70"
                              width="70"
                              src={URL.createObjectURL(item["originFileObj"])}
                            />
                            {/* <i
                            onClick={() => {
                              let {
                                filesUrl,
                                files,
                                croppedImages,
                                croppedImagesUrls,
                              } = this.state;

                              filesUrl.splice(index, 1);
                              files.splice(index, 1);
                              croppedImages.splice(index, 1);
                              croppedImagesUrls.splice(index, 1);

                              this.setState({
                                filesUrl,
                                files,
                                croppedImages,
                                croppedImagesUrls,
                              }); // This will update the state and trigger a rerender of the components
                            }}
                            class=" mr-2 far text-danger fa-times-circle"
                          ></i>
                          </>
                        );
                      }
                    })} 
                    */}
                    <p className="text-danger">
                      *Maximum 4 pictures can be uploaded*
                    </p>
                  </div>
                  <div className=" mt-3 form-group">
                    <label htmlFor="Country">Date Of Birth:</label>
                    <DatePicker
                      format="YYYY-MM-DD"
                      value={this.state.Age ? moment(this.state.Age) : null}
                      disabledDate={(current) => {
                        return (
                          (current &&
                            current.isAfter(
                              moment().subtract(18, "years"),
                              "s"
                            )) ||
                          (current &&
                            current.isBefore(
                              moment().subtract(99, "years"),
                              "s"
                            ))
                        );
                      }}
                      style={{ width: "100%" }}
                      name="Age"
                      placeholder="Age 18-----99"
                      onChange={(date, dateString) => {
                        this.setState({ Age: dateString });
                      }}
                    />
                    {/* <input
                      type="date"
                      className="form-control"
                      name="Age"
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({
                          Age: e.target.value,
                        });
                      }}
                      placeholder="Age 18-----99"
                      min={moment().subtract(99, "y").format("YYYY-MM-DD")}
                      max={moment().subtract(18, "y").format("YYYY-MM-DD")}
                      value={this.state.Age}
                    /> */}
                  </div>
                  <div className="form-group">
                    <label htmlFor="Country">Years Of Work Experience:</label>
                    <input
                      type="number"
                      className="form-control"
                      id="age"
                      onChange={(e) => {
                        e.preventDefault();
                        if (e.target.value < 0) {
                          alert("Cannot add negative experience");
                        } else if (e.target.value.length >= 3) {
                          alert("experience should be less than 50");
                        } else if (e.target.value <= 50) {
                          this.setState({
                            experience: e.target.value,
                          });
                        } else {
                          alert("experience should be less than 50");
                        }
                      }}
                      min={0}
                      name="experience"
                      value={this.state.experience}
                      placeholder="Years Of Experience"
                    />
                  </div>

                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps}
                      filterOptions={filterOptions}
                      id="auto-complete"
                      autoComplete
                      multiple={true}
                      includeInputInList
                      onChange={(e, value) => {
                        e.preventDefault();
                        if (value.length > 4) {
                          alert("Maximum 4 countries can be added");
                        } else {
                          this.setState({
                            countries: value,
                          });
                        }
                      }}
                      value={this.state.countries}
                      renderInput={(params) => (
                        <TextField
                          id="outlined-basic"
                          style={{ padding: "10px" }}
                          {...params}
                          label="WHAT COUNTRIES DO YOU IDENTIFY WITH (Select up to 4)"
                          margin="normal"
                          name="countries"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps2}
                      id="auto-complete"
                      autoComplete
                      filterOptions={filterOptions}
                      multiple={true}
                      includeInputInList
                      onChange={(e, value) => {
                        e.preventDefault();
                        if (value.length > 4) {
                          alert("Maximum 4 languages can be added");
                        } else {
                          this.setState({
                            languages: value,
                          });
                        }
                      }}
                      value={this.state.languages}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ padding: "10px" }}
                          label="LANGUAGE(S) YOU FEEL COMFORTABLE CONVERSING IN. (Select up to 4)"
                          margin="normal"
                          name="languages"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps3}
                      id="auto-complete"
                      autoComplete
                      filterOptions={filterOptions}
                      multiple={true}
                      includeInputInList
                      onChange={(e, value) => {
                        e.preventDefault();
                        if (value.length > 2) {
                          alert("Maximum 2 districts can be added");
                        } else {
                          this.setState({
                            district: value,
                          });
                        }
                      }}
                      value={this.state.district}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ padding: "10px" }}
                          label="WHICH DISTRICT  DO YOU/ YOUR FAMILY COME FROM IN LEBANON? (Select up to 2)"
                          margin="normal"
                          name="district"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="Country">
                      A short Bio/ describe yourself/ what you are looking for:
                      <b className="text-danger">
                        *Description should be at least 10 characters long*
                      </b>
                    </label>
                    <Input
                      type="textarea"
                      className="form-control"
                      name="description"
                      maxLength={500}
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({
                          description: e.target.value,
                        });
                      }}
                      placeholder="Description"
                    />
                  </div>
                  <div class="float-right mb-5">
                    <button
                      className="btn btn-primary nextBtn  btn-lg "
                      type="button"
                      disabled={
                        !this.state.gender ||
                        this.state.description.length < 10 ||
                        this.state.description.length > 500 ||
                        !this.state.countries ||
                        !this.state.languages ||
                        !this.state.district ||
                        !this.state.selectedFileList.length
                      }
                      onClick={(e) => this.setState({ activeTab: 2 })}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`row setup-content ${
                this.state.activeTab == "2" ? "d-flex" : "d-none"
              }`}
              id="step-2"
            >
              <div className="col-xs-12">
                <div className="col-md-12">
                  <h3>INTEREST</h3>
                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps4}
                      id="auto-complete"
                      autoComplete
                      filterOptions={filterOptions}
                      multiple={true}
                      includeInputInList
                      value={this.state.looking_for}
                      onChange={(e, value) => {
                        e.preventDefault();
                        if (value.length > 4) {
                          alert("Maximum 4 interests can be added");
                        } else {
                          this.setState({
                            looking_for: value,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ padding: "10px" }}
                          label="What are you looking for on this app?(select up to 4):"
                          margin="normal"
                          name="looking_for"
                        />
                      )}
                    />
                  </div>
                  {/* <div className="form-group">
                    <Autocomplete
                      {...defaultProps5}
                      id="auto-complete"
                      autoComplete
                      filterOptions={filterOptions}
                      includeInputInList
                      onChange={(e, value) => {
                        e.preventDefault();
                        this.setState({
                          smoke: value.title,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ padding: "10px" }}
                          label="SMOKE (Optional):"
                          margin="normal"
                          name="smoke"
                        />
                      )}
                    />
                  </div> */}
                  {/* <div className="form-group">
                    <Autocomplete
                      {...defaultProps6}
                      id="auto-complete"
                      filterOptions={filterOptions}
                      autoComplete
                      includeInputInList
                      onChange={(e, value) => {
                        e.preventDefault();
                        this.setState({
                          drinking: value.title,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ padding: "10px" }}
                          label="ALCOHOL (Optional):"
                          margin="normal"
                          name="drinking"
                        />
                      )}
                    />
                  </div> */}
                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps7}
                      id="auto-complete"
                      autoComplete
                      filterOptions={filterOptions}
                      includeInputInList
                      onChange={(e, value) => {
                        e.preventDefault();
                        this.setState({
                          religion: value.title,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ padding: "10px" }}
                          label="RELIGION (Optional):"
                          margin="normal"
                          name="religion"
                        />
                      )}
                    />
                  </div>
                  <div className="float-right mb-3">
                    <button
                      className="backBtn btn btn-primary btn-lg mr-2"
                      type="button"
                      onClick={(e) => this.setState({ activeTab: 1 })}
                    >
                      Back
                    </button>
                    <button
                      className="nextBtn  btn btn-primary btn-lg"
                      type="button"
                      disabled={!this.state.looking_for.length}
                      onClick={(e) => this.setState({ activeTab: 3 })}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`row setup-content ${
                this.state.activeTab == "3" ? "d-flex" : "d-none"
              }`}
              id="step-3"
            >
              <div className="col-xs-12">
                <div className="col-md-12">
                  <h3>OTHER</h3>
                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps8}
                      id="auto-complete"
                      autoComplete
                      filterOptions={filterOptions}
                      includeInputInList
                      onChange={(e, value) => {
                        e.preventDefault();
                        this.setState({
                          profession: value.title,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ padding: "10px" }}
                          label="Profession/ industry:"
                          margin="normal"
                          name="profession"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps9}
                      id="auto-complete"
                      autoComplete
                      filterOptions={filterOptions}
                      includeInputInList
                      onChange={(e, value) => {
                        e.preventDefault();
                        this.setState({
                          education: value.title,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ padding: "10px" }}
                          label="Highest level of education:"
                          margin="normal"
                          name="education"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      {...defaultProps10}
                      id="auto-complete"
                      autoComplete
                      filterOptions={filterOptions}
                      includeInputInList
                      onChange={(e, value) => {
                        e.preventDefault();
                        this.setState({
                          status: value.title,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ padding: "10px" }}
                          label="RELATIONSHIP STATUS (Optional):"
                          margin="normal"
                          name="status"
                        />
                      )}
                    />
                  </div>
                  {/* <div className="form-group">
                    <label htmlFor="Country">Height:</label>
                    <InputGroup>
                      <Input
                        className="form-control"
                        name="height"
                        onChange={(e) => {
                          e.preventDefault();
                          this.setState({
                            height: e.target.value,
                          });
                        }}
                        value={this.state.height}
                        defaultValue={this.state.height_in === "ft" ? 5 : 90}
                        type="select"
                      >
                        {this.state.height_in === "ft"
                          ? this.range(1, 8).map((value) => {
                            return <option>{value}</option>;
                          })
                          : this.state.height_in === "cm"
                            ? this.range(90, 230).map((value) => {
                              return <option value={value}>{value}</option>;
                            })
                            : null}
                      </Input>
                      <Input
                        className="form-control"
                        name="height_inch"
                        hidden={this.state.height_in !== "ft"}
                        onChange={(e) => {
                          e.preventDefault();
                          this.setState({
                            height_inch: e.target.value,
                          });
                        }}
                        defaultValue={5}
                        value={this.state.height_inch}
                        type="select"
                      >
                        {this.state.height_in === "ft"
                          ? this.range(1, 11).map((value) => {
                            return <option value={value}>{value}</option>;
                          })
                          : null}
                      </Input>
                      <InputGroupAddon style={{ width: "20%" }}>
                        <Input
                          name="height_in"
                          type="select"
                          onChange={(e) => {
                            e.preventDefault();
                            this.setState({
                              height_in: e.target.value,
                            });
                          }}
                        >

                          <option>cm</option>
                        </Input>
                      </InputGroupAddon>
                    </InputGroup>
                  </div> */}
                  <div className="float-right mb-3">
                    <button
                      className="backBtn btn btn-primary btn-lg mr-2"
                      type="button"
                      disabled={this.props.loading}
                      onClick={(e) => this.setState({ activeTab: 2 })}
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-primary nextBtn btn-lg"
                      disabled={
                        !this.state.profession ||
                        !this.state.education ||
                        // !this.state.height ||
                        this.props.loading
                      }
                      type="submit"
                    >
                      {this.props.loading ? <Spinner size="sm" /> : "Finish"}
                      {/* Finish */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <Modal
          isOpen={
            this.state.files.length &&
            this.state.croppedImages.length < this.state.files.length
          }
        >
          <ModalHeader>Crop Images</ModalHeader>
          <ModalBody>
            <div
              style={{
                position: "relative",
                top: "0",
                width: "100%",
                height: "60vh",
                paddingLeft: "10%",
              }}
            >
              <Cropper
                image={this.state.filesUrl[this.state.croppedImages.length]}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={this.state.aspect}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
                style={{ width: "100%" }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={(e) => {
                e.preventDefault();
                this.saveImage(this.state.croppedImages.length);
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
        <Footer />
      </>
    );
  }
}
export function mapStateToProps(state) {
  return {
    loading: state.auth.requested,
    registered: state.auth.registered,
    auth: state.auth,
    user: state.authUser.user,
    authUser: state.authUser,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    // registeredComplete: () => dispatch(removeRegistered()),
    getUser: (uid) => dispatch(getUser(uid)),
    personelInfo: (Info) => dispatch(personelInfo(Info)),
    personelInfoPhone: (Info) => dispatch(personelInfoPhone(Info)),
    // loginGoogle: () => dispatch(signupGoogle()),
    // loginFacebook: () => dispatch(signupFacebook()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(FillProfile));
