import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import couple from "../../images/couple.png";
import businessman from "../../images/businessman.png";
import enginer from "../../images/engineers.png";
import { fadeInUp } from "react-animations";
import Radium, { StyleRoot } from "radium";
import "font-awesome/css/font-awesome.min.css";
import PhoneComponent from "./phoneComponent";

// import { Button } from "bootstrap";
import {
  Col,
  Button,
  Form,
  Input,
  Row,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Label,
  Spinner,
} from "reactstrap";
import { connect } from "react-redux";
import {
  login,
  phoneLogin,
  phoneLoginCapcha,
  phoneRegRemove,
  removeRegistered,
  signupFacebook,
  recoverAccount,
} from "./../../store/actions/authActions";
import { getUser } from "../../store/actions/userActions";

export class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animating1: false,
      modal: false,
      modal2: false,
      modal3: false,
      animating2: false,
      animating3: false,
      fadeInUp: {
        animation: "x 1.5s",
        animationName: Radium.keyframes(fadeInUp, "bounce"),
      },
    };
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
    window.scrollTo(0, 0);
    var { phone_registered } = this.props;
    if (phone_registered) {
      this.toggle();
    }
    // this.props.phoneRegRemove();
    // this.props.registeredComplete();
    if (this.props.authUser.uid) {
      this.props.getUser(this.props.authUser.uid);
    }
    // navigator.geolocation.getCurrentPosition(this.getCoordinates);

    // var { capche } = this.props;
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };
  // getCoordinates = async (position) => {
  //   await console.log(position);

  //   Geocode.fromLatLng(
  //     position.coords.latitude,
  //     position.coords.longitude
  //   ).then(
  //     (response) => {
  //       var result = response.results;
  //       const address = response.results[result.length - 3].formatted_address;
  //       this.setState({
  //         address,
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       });
  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   );
  // };

  handleScroll = (event) => {
    const isTop = window.scrollY > 350;
    const isTop2 = window.scrollY > 700;
    const isTop3 = window.scrollY > 1100;
    if (isTop == true) {
      this.setState({
        animating1: true,
      });
    }
    if (isTop2 == true) {
      this.setState({
        animating2: true,
      });
    }
    if (isTop3 == true) {
      this.setState({
        animating3: true,
      });
    }
  };
  state = {
    redirect: false,
    type: "",
  };
  recoverAccount = (e) => {
    e.preventDefault();
    this.props.recoverAccount(e.target.email.value);
  };
  render() {
    var { user, capche, phone_registered } = this.props;
    if (user && user.age) {
      return <Redirect to="/dashboard" />;
    }
    if (user && user.type === "individual") {
      return <Redirect to="/fillprofile/individual" />;
    }
    if (user && user.type === "business") {
      return <Redirect to="/fillprofile/business" />;
    }
    return (
      <>
        <Navbar />

        <div className="signUp_Banner  mt-sm-160px">
          <div className="container">
            <div className="row FormSection">
              <div className="col-sm-7 Left_bannerSection  order-2 order-md-1">
                <StyleRoot>
                  <div style={this.state.fadeInUp}>
                    <h1>FIND YOUR BEYROOTERS</h1>
                    <h5>
                      Connecting millions of Lebanese around the globe to unite,
                      inspire and create forever lasting relations.
                    </h5>
                    <p className="text-justify">
                      Beirut (/beɪˈruːt/ Arabic: توريب, romanized: Bayrūt) is
                      the capital and largest city of Lebanon. Root-/ro͞ot/the
                      part of a plant which attaches it to the ground or to a
                      support, typically underground, conveying water and
                      nourishment to the rest of the plant via numerous branches
                      and fibers. Like the cedar tree of Lebanon with roots that
                      spreadout all over, the Lebanese diaspora also have roots
                      that’s spread all over the world but Beirut will always be
                      the origin. This is why we established Bey-Root.
                    </p>
                    <div className="banner_icon">
                      <div className="img1">
                        <img src={couple} className />
                        <p>
                          <strong>MAKE</strong>
                          <br />
                          FRIENDS
                        </p>
                      </div>
                      <div className="img1">
                        <img src={enginer} className />
                        <p>
                          <strong>TAQ</strong>
                          <br />
                          THEM
                        </p>
                      </div>
                      <div className="img1">
                        <img src={businessman} className />
                        <p>
                          <strong>SHARE</strong>
                          <br />
                          THEM
                        </p>
                      </div>
                    </div>
                  </div>
                </StyleRoot>
              </div>
              <div className="col-sm-5 right_FormSection  order-1 order-md-2">
                {/*Form with header*/}
                <StyleRoot>
                  <div style={this.state.fadeInUp}>
                    <div className="card border-primary rounded-0 bg">
                      <div
                        style={{ backgroundColor: "#232E3C" }}
                        className="card-header p-0"
                      >
                        <div className="bg-info1 text-white text-center py-2">
                          <h3>Forgot Password</h3>
                        </div>
                      </div>

                      <form onSubmit={this.recoverAccount}>
                        <div className="card-body p-3 customCard">
                          {/*Body*/}
                          <div className="form-group">
                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">
                                  <i className="fa fa-envelope text-info" />
                                </div>
                              </div>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                placeholder="Email"
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <Link className="text-white" to="/signin">
                              Remember Password Sign In?
                            </Link>
                          </div>

                          <div className="text-center mb-5">
                            <Button
                              type="submit"
                              disabled={this.props.loading}
                              className="btn btn-info btn-block rounded-0 py-2 mt-4"
                              // onClick={() => {
                              // 	this.props.history.push("/fillprofile");
                              // }}
                              color="info"
                            >
                              {this.props.loading ? (
                                <Spinner size="sm" />
                              ) : (
                                "Recover Account"
                              )}{" "}
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </StyleRoot>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export function mapStateToProps(state) {
  return {
    loading: state.auth.requested,
    registered: state.auth.registered,
    phone_registered: state.auth.phone_registered,
    user: state.authUser.user,
    auth: state.auth,
    authUser: state.authUser,
    capche: state.auth.capche,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    // registeredComplete: () => dispatch(removeRegistered()),
    // phoneRegRemove: () => dispatch(phoneRegRemove()),
    // login: (credentials) => dispatch(login(credentials)),
    recoverAccount: (credentials) => dispatch(recoverAccount(credentials)),

    getUser: (uid) => dispatch(getUser(uid)),
    // loginGoogle: () => dispatch(signupGoogle()),
    // loginFacebook: () => dispatch(signupFacebook()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
