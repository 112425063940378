import React from "react";

function Feature(props) {
	return (
		<>
			<div class="col-sm-4 mb-5">
				<img src={props.img} />
				<p>{props.text}</p>
			</div>
		</>
	);
}

export default Feature;
