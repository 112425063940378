import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Button,
  NavLink,
  NavbarText,
  Row,
  Col,
} from "reactstrap";
import Drawer from "@material-ui/core/Drawer";
import "./assets/css/bootstrap.css";

// import './assets/vendors/chartjs/Chart.min.css'

// import './assets/vendors/perfect-scrollbar/perfect-scrollbar.css'
import "./assets/css/app.css";
import "./style.css";
import logo from "../../images/logo1.PNG";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import Chats from "./Chats";
import ConnectsRequest from "./ConnectsRequest";

import Home from "./Home";
import Connects from "./Connects";
import Events from "./Events";
import EditProfile from "./EditProfile";
import Search from "./Search";
import EventsDetail from "./EventsDetail";
import ProfileDetail from "./ProfileDetail";
import EditBusinessProfile from "./EditBusinessProfile";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";
import ViewProfile from "./ViewProfile";
import { getAllUsers, getUser } from "../../store/actions/userActions";
import { getChats } from "../../store/actions/chatsActions";
import { getConnectRequests } from "../../store/actions/connectActions";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Notifications from "./Notifications";
import { getNotifications } from "../../store/actions/notificationActions";
import { notification } from "antd";
import { Badge } from "@material-ui/core";
import MyPosts from "./MyPosts";
import DashboardFooter from "../../components/DashboardFooter";
// import { Dropdown } from "antd";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isDesktop: false,
      dropdownOpen: false,
    };
  }
  componentDidMount = () => {
    var { auth, chats, authUser } = this.props;
    this.props.getUser(authUser.uid);
    this.props.getAllUsers();
    this.props.getChats(authUser.uid);
    this.props.getNotifications(authUser.uid);
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  };
  componentWillUnmount = () => {
    // window.removeEventListener("resize", this.updatePredicate);
  };

  updatePredicate = async () => {
    await this.setState({ isDesktop: window.innerWidth < 768 });
  };

  toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ [anchor]: open });
  };
  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  dropdownToggle = (e) => {
    e.preventDefault();
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  render() {
    var { auth, authUser, user, users, chats, notifications } = this.props;
    if (authUser && !authUser.uid) {
      return <Redirect to="/" />;
    }
    if (!user.age) {
      return <Redirect to="/fillprofile/individual" />;
    }
    var unreadChat =
      chats &&
      chats.find((chat) => {
        return chat.unread.includes(authUser.uid);
      });
    if (unreadChat) {
      console.log("found");
    }
    return (
      <>
        <Router>
          <>
            {/* ------------------------------------------------- */}
            {this.state.isDesktop == true ? (
              ["left"].map((anchor) => (
                <React.Fragment>
                  <Button
                    className="ml-3 toggle-button bg-light mt-4"
                    onClick={this.toggleDrawer(anchor, true)}
                  >
                    <i class="fas text-secondary  fa-bars"></i>
                  </Button>
                  <Drawer
                    anchor={anchor}
                    open={this.state[anchor]}
                    onClose={this.toggleDrawer(anchor, false)}
                  >
                    <ul>
                      <Link
                        className="text-decoration-none   d-block d-md-inline d-lg-inline"
                        to="/dashboard"
                      >
                        <li className=" mt-3 mb-3  menu-items">
                          <img
                            src={logo}
                            className=" position-relative  pt-1 pb-1"
                            width="60"
                          />
                        </li>
                      </Link>
                      <Link
                        className="text-decoration-none   d-block d-md-inline d-lg-inline"
                        to="/dashboard"
                      >
                        <li className=" d-flex align-items-center menu-items">
                          <i style={{ width: "20px" }} class="fas  fa-home"></i>
                          Home
                        </li>
                      </Link>
                      <Link
                        className="text-decoration-none ml-md-2 mr-md-2  d-block d-md-inline  d-lg-inline"
                        to="/dashboard/search"
                      >
                        <li className=" d-flex align-items-center  menu-items">
                          <i
                            style={{ width: "20px" }}
                            class="fas  fa-search"
                          ></i>
                          Search
                        </li>
                      </Link>
                      <Link
                        className="text-decoration-none d-block d-md-inline  d-lg-inline"
                        to={{
                          pathname: "/dashboard/profile-detail",
                          state: { is_own: true },
                        }}
                      >
                        <li className=" d-flex align-items-center  menu-items">
                          <i
                            style={{ width: "20px" }}
                            class="fas  fa-male ml-1"
                          ></i>
                          Profile
                        </li>
                      </Link>
                      <Link
                        className="text-decoration-none ml-md-2 mr-md-2 d-block d-md-inline  d-lg-inline"
                        to="/dashboard/connects"
                      >
                        <li className=" d-flex align-items-center  menu-items">
                          {" "}
                          <i
                            style={{ width: "20px" }}
                            class="fas  fa-suitcase-rolling"
                          ></i>
                          Connects
                        </li>
                      </Link>
                      <Link
                        className="text-decoration-none d-block d-md-inline  d-lg-inline"
                        to="/dashboard/connects-request"
                      >
                        <li className=" d-flex align-items-center  menu-items">
                          <i
                            style={{ width: "20px" }}
                            class="fas  fa-suitcase-rolling"
                          ></i>
                          Connect Requests
                        </li>
                      </Link>
                      <Link
                        className="text-decoration-none d-block d-md-inline  d-lg-inline"
                        to="/dashboard/myposts"
                      >
                        <li className=" d-flex align-items-center  menu-items">
                          <i
                            style={{ width: "20px" }}
                            class="fas  fa-suitcase-rolling"
                          ></i>
                          My Posts
                        </li>
                      </Link>
                      <a
                        className="text-decoration-none d-block d-md-inline  d-lg-inline"
                        href="/forum"
                      >
                        <li className=" d-flex align-items-center  menu-items">
                          <i
                            style={{ width: "20px" }}
                            class="fas  fa-suitcase-rolling"
                          ></i>
                          Forum
                        </li>
                      </a>
                      <Link
                        className="text-decoration-none d-block d-md-inline  d-lg-inline"
                        to="/dashboard/notifications"
                      >
                        <li className=" d-flex align-items-center  menu-items">
                          <i
                            style={{ width: "20px" }}
                            class="fas  fa-calendar-week"
                          ></i>
                          Notifications&nbsp;
                          {notifications &&
                          notifications.filter(
                            (noti) => noti.status === "unread"
                          ).length ? (
                            <b
                              style={{
                                background: "Red",
                                color: "white",
                                padding: "3px",
                                borderRadius: "10px",
                              }}
                            >
                              {
                                notifications.filter(
                                  (noti) => noti.status === "unread"
                                ).length
                              }
                            </b>
                          ) : null}
                        </li>
                      </Link>
                      {/* <Link
                      className="text-decoration-none ml-md-2 mr-md-2 d-block d-md-inline  d-lg-inline"
                      to="/dashboard/events"
                    >
                      {" "}
                      <li className="   menu-items">
                        <i style={{width:"20px",}}class="fas  fa-calendar-week"></i>Events
                      </li>
                    </Link> */}
                      <Link
                        className="text-decoration-none d-block d-md-inline mr-2 d-lg-inline"
                        to="/dashboard/chats"
                      >
                        <li className=" d-flex align-items-center  menu-items">
                          {" "}
                          <i
                            style={{ width: "20px", justifySelf: "center" }}
                            class="far fa-comments"
                          ></i>
                          Chats
                          {unreadChat ? (
                            <i
                              style={{
                                fontSize: "10px",
                                color: "red",
                              }}
                              class="fas fa-circle ml-1 red-dot-nav"
                            ></i>
                          ) : null}
                        </li>
                      </Link>
                      <Button
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.logout();
                        }}
                      >
                        Logout
                      </Button>
                      {/* <Link className="text-decoration-none d-block d-md-inline  d-lg-inline" to="/"><li className="   menu-items">  <i class="pr-2 fa fa-sign-out"></i>Logout</li></Link> */}
                    </ul>
                  </Drawer>
                </React.Fragment>
              ))
            ) : (
              <>
                <Navbar
                  className=" pt-0 pb-0 fixed-top  shadow-sm menu-bar "
                  light
                  expand="md"
                >
                  <NavbarBrand className=" position-relative pl-3" href="/">
                    <img
                      src={logo}
                      className=" position-relative  pt-1 pb-1"
                      width="60"
                    />
                  </NavbarBrand>
                  <NavbarToggler onClick={this.toggle} />
                  <Collapse className=" bar " isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto  " navbar>
                      <ul>
                        <Link
                          className="text-decoration-none   d-block d-md-inline d-lg-inline"
                          to="/dashboard"
                        >
                          <li className="   menu-items">
                            <i class="fas pr-2 fa-home"></i>Home
                          </li>
                        </Link>
                        <Link
                          className="text-decoration-none ml-md-2 mr-md-2  d-block d-md-inline  d-lg-inline"
                          to="/dashboard/search"
                        >
                          <li className="   menu-items">
                            <i class="fas pr-2 fa-search"></i>Search
                          </li>
                        </Link>
                        <Link
                          className="text-decoration-none d-block d-md-inline  d-lg-inline"
                          to={{
                            pathname: "/dashboard/profile-detail",
                            state: { is_own: true },
                          }}
                        >
                          <li className="   menu-items">
                            <i class="fas pr-2 fa-male"></i>Profile
                          </li>
                        </Link>
                        <Link
                          className="text-decoration-none ml-md-2 mr-md-2 d-block d-md-inline  d-lg-inline"
                          to="/dashboard/connects"
                        >
                          <li className="   menu-items">
                            {" "}
                            <i class="fas pr-2 fa-suitcase-rolling"></i>Connects
                          </li>
                        </Link>
                        <Link
                          className="text-decoration-none d-block d-md-inline  d-lg-inline"
                          to="/dashboard/connects-request"
                        >
                          <li className="   menu-items">
                            <i class="fas pr-2 fa-suitcase-rolling"></i>Connects
                            Requests
                          </li>
                        </Link>
                        <Link
                          className="text-decoration-none d-block d-md-inline  d-lg-inline"
                          to="/dashboard/myposts"
                        >
                          <li className="   menu-items">
                            <i class="fas pr-2 fa-suitcase-rolling"></i>My Posts
                          </li>
                        </Link>
                        <a
                          className="text-decoration-none d-block d-md-inline  d-lg-inline"
                          href="/forum"
                        >
                          <li className="   menu-items">
                            <i class="fas pr-2 fa-suitcase-rolling"></i>Forum
                          </li>
                        </a>
                        <Link
                          className="text-decoration-none ml-md-2 mr-md-2 d-block d-md-inline  d-lg-inline"
                          to="/dashboard/notifications"
                          // onClick={this.dropdownToggle}
                        >
                          {" "}
                          <li className="   menu-items">
                            <i class="fas pr-2 fa-calendar-week"></i>
                            Notifications&nbsp;
                            {notifications &&
                            notifications.filter(
                              (noti) => noti.status === "unread"
                            ).length ? (
                              <b
                                style={{
                                  background: "Red",
                                  color: "white",
                                  padding: "3px",
                                  borderRadius: "10px",
                                }}
                              >
                                {
                                  notifications.filter(
                                    (noti) => noti.status === "unread"
                                  ).length
                                }
                              </b>
                            ) : null}
                          </li>
                        </Link>
                        <Link
                          className="text-decoration-none d-block d-md-inline mr-2 d-lg-inline"
                          to="/dashboard/chats"
                        >
                          <li className="   menu-items">
                            {" "}
                            <i class="far pr-2 fa-comments"></i>Chats
                            {unreadChat ? (
                              <i
                                style={{
                                  fontSize: "10px",
                                  color: "red",
                                }}
                                class="fas fa-circle ml-1 red-dot-nav"
                              ></i>
                            ) : null}
                          </li>
                        </Link>
                        <Button
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.logout();
                          }}
                        >
                          Logout
                        </Button>
                        {/* <Link className="text-decoration-none d-block d-md-inline  d-lg-inline" to="/"><li className="   menu-items">  <i class="pr-2 fa fa-sign-out"></i>Logout</li></Link> */}
                      </ul>
                    </Nav>
                  </Collapse>
                </Navbar>
              </>
            )}

            <div className="mt-5 pt-5">
              <div className="text-center bg-danger ">
                <h3 className="text-white">Beta testing in progress</h3>
              </div>

              <Switch>
                <Route path="/dashboard/chats" component={Chats} />
                <Route
                  path="/dashboard/connects-request"
                  component={ConnectsRequest}
                />
                <Route path="/dashboard/myposts" component={MyPosts} />
                <Route exact path="/dashboard" component={Home} />
                <Route path="/dashboard/connects" component={Connects} />
                <Route path="/dashboard/events" component={Events} />
                <Route
                  path="/dashboard/notifications"
                  component={Notifications}
                />
                <Route path="/dashboard/edit-profile" component={EditProfile} />
                <Route
                  path="/dashboard/edit-business-profile"
                  exact
                  component={EditBusinessProfile}
                />
                <Route
                  path="/dashboard/events-detail"
                  component={EventsDetail}
                />
                <Route
                  path="/dashboard/profile-detail"
                  component={ProfileDetail}
                />
                <Route
                  path="/dashboard/view-profile/:id"
                  component={ViewProfile}
                />

                <Route path="/dashboard/search" component={Search} />
              </Switch>
            </div>
            <DashboardFooter />
          </>
        </Router>
      </>
    );
  }
}
export function mapStateToProps(state) {
  return {
    auth: state.auth,
    // registered: state.auth.registered,
    authUser: state.authUser,
    user: state.authUser.user,
    // users: state.users.users,
    chats: state.chat.chats,
    notifications: state.notifications.notifications,
    connect_requests: state.connect.connect_requests,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    // registeredComplete: () => dispatch(removeRegistered()),
    logout: () => dispatch(logout()),
    // loginGoogle: () => dispatch(signupGoogle()),

    getAllUsers: () => dispatch(getAllUsers()),

    getUser: (uid) => dispatch(getUser(uid)),
    getNotifications: (uid) => dispatch(getNotifications(uid)),
    // getAllUsers: () => dispatch(getAllUsers()),
    getChats: (uid) => dispatch(getChats(uid)),
    // sendMessage: (msg) => dispatch(sendMessage(msg)),

    // removeConnect: (loggedUid, uid) => dispatch(removeConnect(loggedUid, uid)),
    getConnectRequests: () => dispatch(getConnectRequests()),
    // removeRequest: (uid) => dispatch(removeRequest(uid)),
    // acceptRequest: (uid) => dispatch(acceptRequest(uid)),

    // personelInfo: (Info) => dispatch(updatePersonelInfo(Info)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
