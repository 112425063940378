import firebase from "../firebase";
import { v4 as uuidv4 } from "uuid";
export const getUser = (id) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("users")
      .doc(id)
      .onSnapshot((doc) => {
        var tempUser = { id: doc.id, ...doc.data() };
        dispatch({
          type: "GET_USER",
          user: tempUser,
        });
      });
  };
};
export const getAllUsers = () => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("users")
      .onSnapshot((query) => {
        var tempUsers = [];
        query.forEach((doc) => {
          tempUsers.push({ id: doc.id, ...doc.data() });
        });

        dispatch({
          type: "GET_USERS",
          users: tempUsers,
        });
      });
  };
};

export const personelInfo = (Info) => {
  return async (dispatch) => {
    console.log("Info in func:", Info);
    dispatch({
      type: "ACTION_REQUEST",
    });
    var url = [];
    var tempUrl = null;
    for (var i = 0; i < Info.files.length; i++) {
      console.log("File:", Info.files[i].originFileObj);
      var data = await firebase
        .storage()
        .ref()
        .child(
          Info.files[i].originFileObj.type.split("/").pop() === "jpeg"
            ? "personel_images/" + uuidv4() + ".jpg"
            : "personel_images/" +
            uuidv4() +
            "." +
            Info.files[i].originFileObj.type.split("/").pop()
        )
        .put(Info.files[i].originFileObj, {
          contentType: Info.files[i].originFileObj.type,
        });
      tempUrl = await data.ref.getDownloadURL();
      url.push(tempUrl);
    }

    firebase
      .firestore()
      .collection("users")
      .doc(Info.id)
      .update({
        gender: Info.gender,
        images: url,
        profile_pic: url[0],
        age: Info.age,
        experience: Info.experience,
        description: Info.description,
        filled: true,
        countries: Info.countries,
        languages: Info.languages,
        district: Info.district,
        looking_for: Info.looking_for,
        smoke: Info.smoke,
        drinking: Info.drinking,
        religion: Info.religion,
        profession: Info.profession,
        education: Info.education,
        status: Info.status,
        height: Info.height,
        height_in: Info.height_in,
      })
      .then((doc) => {
        dispatch({
          type: "ACTION_REQUEST_END",
        });
        dispatch({
          type: "PERSONEL_INFO",
        });
      })
      .catch((err) => {
        dispatch({
          type: "ACTION_REQUEST_END",
        });
        alert(err, "danger");
      });
  };
};
export const personelInfoPhone = (Info) => {
  return async (dispatch) => {
    console.log("Info in func:", Info);
    dispatch({
      type: "ACTION_REQUEST",
    });
    var url = [];
    var tempUrl = null;
    for (var i = 0; i < Info.files.length; i++) {
      console.log("File:", Info.files[i].originFileObj);
      var data = await firebase
        .storage()
        .ref()
        .child(
          Info.files[i].originFileObj.type.split("/").pop() === "jpeg"
            ? "personel_images/" + uuidv4() + ".jpg"
            : "personel_images/" +
            uuidv4() +
            "." +
            Info.files[i].originFileObj.type.split("/").pop()
        )
        .put(Info.files[i].originFileObj, {
          contentType: Info.files[i].originFileObj.type,
        });
      tempUrl = await data.ref.getDownloadURL();
      url.push(tempUrl);
    }
    console.log("URLS:", url);
    firebase
		.firestore()
		.collection('users')
		.doc(Info.id)
		.update({
			first_name: Info?.first_name || '',
			last_name: Info?.last_name || '',
			gender: Info?.gender || '',
			images: url || '',
			profile_pic: url[0] || '',
			age: Info?.age || '',
			filled: true,
			experience: Info?.experience || '',
			description: Info?.description || '',
			countries: Info?.countries || '',
			languages: Info?.languages || '',
			district: Info?.district || '',
			looking_for: Info?.looking_for || '',
			smoke: Info?.smoke || '',
			drinking: Info?.drinking || '',
			religion: Info?.religion || '',
			profession: Info?.profession || '',
			education: Info?.education || '',
			status: Info?.status || '',
			height: Info?.height || '',
			height_in: Info?.height_in || '',
		})
		.then(doc => {
			dispatch({
				type: 'ACTION_REQUEST_END',
			});
			dispatch({
				type: 'PERSONEL_INFO',
			});
		})
		.catch(err => {
			dispatch({
				type: 'ACTION_REQUEST_END',
			});
			alert(err, 'danger');
		})
		.finally(() => {
			dispatch({
				type: 'ACTION_REQUEST_END',
			});
		});
  };
};
export const updatePersonelInfo = (Info) => {
  console.log('info', Info);
  return async (dispatch) => {
    dispatch({
      type: "ACTION_REQUEST",
    });

    var tempUrl = null;
    var userData = await firebase
      .firestore()
      .collection("users")
      .doc(Info.id)
      .get();
    var previousImages = await userData.data().images;
    // console.log('prev imgs', previousImages);
    for (var i = previousImages.length; i < Info.files.length; i++) {
      // if (Info.files[i].file != undefined) {
      var data = await firebase
        .storage()
        .ref()
        .child(
          Info.files[i].originFileObj.type.split("/").pop() === "jpeg"
            ? "personel_images/" + uuidv4() + ".jpg"
            : "personel_images/" +
            uuidv4() +
            "." +
            Info.files[i].originFileObj.type.split("/").pop()
        )
        .put(Info.files[i].originFileObj, { contentType: Info.files[i].originFileObj.type });
      tempUrl = await data.ref.getDownloadURL();
      // }
      // console.log('temp url', tempUrl);
      previousImages.push(tempUrl);
    }
    // console.log('prev imgs', previousImages);
    firebase
      .firestore()
      .collection("users")
      .doc(Info.id)
      .update({
        first_name: Info.first_name,
        last_name: Info.last_name,
        gender: Info.gender,
        images: previousImages,
        age: Info.age,
        experience: Info.experience,
        description: Info.description,
        countries: Info.countries,
        languages: Info.languages,
        district: Info.district,
        looking_for: Info.looking_for,
        // smoke: Info.smoke,
        // drinking: Info.drinking,
        religion: Info.religion,
        profession: Info.profession,
        education: Info.education,
        status: Info.status,
        // height: Info.height,
      })
      .then((doc) => {
        dispatch({
          type: "PERSONEL_INFO_UPDATED",
        });
        alert("User Information is updated");

        dispatch({
          type: "ACTION_REQUEST_END",
        });
        dispatch({
          type: "UPDATE_COMPLETE",
        });
      });
  };
};
export const removeUpdate = () => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_COMPLETE_REMOVE",
    });
  };
};
export const removeImage = (image, user_id) => {
  return async (dispatch) => {
    dispatch({
      type: "ACTION_REQUEST",
    });
    await firebase.storage().refFromURL(image).delete();
    var user = await firebase
      .firestore()
      .collection("users")
      .doc(user_id)
      .get();
    var images = user.data().images;
    var newImages = images.filter((img) => img !== image);
    await firebase
      .firestore()
      .collection("users")
      .doc(user_id)
      .update({ images: newImages });
    dispatch({
      type: "UPDATE_IMAGE_REMOVE",
    });
    dispatch({
      type: "ACTION_REQUEST_END",
    });
    dispatch({
      type: "UPDATE_COMPLETE_REMOVE",
    });
  };
};

export const updateUserLocation = (id, current_location, lat, lng) => {
  return async (dispatch) => {
    firebase.firestore().collection("users").doc(id).update({
      current_location,
      lat,
      lng,
    });
  };
};

export const makeProfile = (index, user_id) => {
  return async (dispatch) => {
    var user = await firebase
      .firestore()
      .collection("users")
      .doc(user_id)
      .get();
    var newProfile = user.data().images[index];
    firebase
      .firestore()
      .collection("users")
      .doc(user_id)
      .update({
        profile_pic: newProfile,
      })
      .then((res) => {
        dispatch({ type: "PROFILE_CHANGE" });
      });
  };
};
