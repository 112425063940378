import React, { Component } from "react";
import Feature from "../../components/sub-components/Feature";
import messenger from "../../images/messenger.png";
import searchProfiles from "../../images/search-profiles.png";
import callUsers from "../../images/call-users.png";
import makeFriends from "../../images/make-friends.png";
import forum from "../../images/forum.png";
import connectPeople from "../../images/connect-people.png";
import sidemain from "../../images/sidemain.jpg";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import WorldMap from "react-world-map";
import "./landing.css";
import pic2 from "../../images/2.jpg";
import pic3 from "../../images/3.jpg";
import pic4 from "../../images/4.jpg";
import pic5 from "../../images/5.jpg";
import pic6 from "../../images/6.jpg";
import { fadeInUp } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { ListItemSecondaryAction } from "@material-ui/core";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
export default class Landing extends Component {
  constructor(props) {
    super(props);
    this.preview_ref = React.createRef();
    this.info_ref = React.createRef();
    this.info_ref2 = React.createRef();
    this.state = {
      activeIndex: 0,
      animating: false,
      items: [
        {
          src: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E",
          altText:
            "	Lorem Ipsum is simply dummy text of the printing an typesetting industry Lorem Ipsum is simply dummy te the printing and typesetting industry",
          caption: "-Lorem Ipsum 1",
        },
        {
          src: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E",
          altText:
            "	Lorem Ipsum is simply dummy text of the printing an typesetting industry Lorem Ipsum is simply dummy te the printing and typesetting industry",
          caption: "-Lorem Ipsum 2",
        },
        {
          src: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E",
          altText:
            "	Lorem Ipsum is simply dummy text of the printing an typesetting industry Lorem Ipsum is simply dummy te the printing and typesetting industry",
          caption: "-Lorem Ipsum 3",
        },
      ],
      selected: "as",
      previous: "",
      continentName: "",
      continents: [
        {
          id: "as",
          name: "Asia",
        },
        {
          id: "na",
          name: "North America",
        },
        {
          id: "sa",
          name: "South america",
        },
        {
          id: "af",
          name: "Africa",
        },
        {
          id: "eu",
          name: "Europe",
        },
        {
          id: "oc",
          name: "oceania",
        },
      ],
      animating1: false,
      animating2: false,
      animating3: false,
      fadeInUp: {
        animation: "x 1.5s",
        animationName: Radium.keyframes(fadeInUp, "bounce"),
      },
    };
    this.randomSelector();
  }
  drawRegionsMap() {
    var data = google.visualization.arrayToDataTable([
      ["Country", "People"],
      ["Brazil", 7000000],
      ["Argentina", 3500000],
      ["Colombia", 3400000],
      ["United States", 900000],
      ["Venezuela", 500000],
      ["Australia", 350000],
      ["France", 300000],
      ["Mexico", 400000],
      ["Canada", 270000],
      ["Saudi Arabia", 299000],
      ["Ecuador", 250000],
      ["United Arab Emirates", 156000],
      ["Germany", 50000],
      ["New Zealand", 45300],
      ["Kuwait", 40500],
      ["Sierra Leone", 40000],
      ["Chile", 32000],
      ["Costa Rica", 30000],
      ["Senegal", 30000],
      ["Greece", 27420],
      ["El Salvador", 27400],
      ["Cyprus", 25700],
      ["Guatemala", 22500],
      ["Cuba", 20000],
      ["Honduras", 20000],
      ["South Africa", 20000],
    ]);

    var options = {
      colors: ["#65bcbf", "#0d1b46"],
    };

    var chart = new google.visualization.GeoChart(
      document.getElementById("regions_div")
    );

    chart.draw(data, options);
  }
  componentDidMount = () => {
    // window.addEventListener("scroll", this.handleScroll);
    // window.scrollTo(0, 0);
    google.charts.load("current", {
      packages: ["geochart"],
      mapsApiKey: "AIzaSyDdeQwJJ61bETzgmPj8_beZ2VOaQgw1GpI",
    });
    google.charts.setOnLoadCallback(this.drawRegionsMap);
    const preview = this.preview_ref.current;
    // const information = this.info_ref.current;
    // const information2 = this.info_ref2.current;
    // console.log(information);
    // window.addEventListener("scroll", () => isActive(preview));
    // window.addEventListener("scroll", () => classActive(information));
    // window.addEventListener("scroll", () => classActive(information2));

    // Checking if element is in viewport
    function isActive(el) {
      let prev = el.getBoundingClientRect();
      if (prev.top > -50 && prev.top < 633) {
        el.className = "fly";
      }
    }

    function classActive(el) {
      let prev = el.getBoundingClientRect();
      if (prev.top > -50 && prev.top < 633) {
        el.className = "fly information";
      }
    }
  };

  componentWillUnmount = () => {
    // window.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = (event) => {
    const isTop = window.scrollY > 350;
    const isTop2 = window.scrollY > 700;
    const isTop3 = window.scrollY > 1100;
    if (isTop == true) {
      this.setState({
        animating1: true,
      });
    }
    if (isTop2 == true) {
      this.setState({
        animating2: true,
      });
    }
    if (isTop3 == true) {
      this.setState({
        animating3: true,
      });
    }
  };

  next = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({
      activeIndex: nextIndex,
    });
  };

  previous = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.items.length - 1
        : this.state.activeIndex - 1;
    this.setState({
      activeIndex: nextIndex,
    });
  };

  goToIndex = (newIndex) => {
    if (this.state.animating) return;
    this.setState({
      activeIndex: newIndex,
    });
  };

  randomSelector = () => {
    let i = 0;
    setInterval(() => {
      let { continents } = this.state;
      i += 1;
      if (i < continents.length) {
        this.setState({
          selected: continents[i].id,
        });
      } else {
        i = null;
      }
    }, 2000);
  };

  render() {
    let { selected, previous } = this.state;

    if (selected == null) selected = previous;
    const contArr = {
      na: 2570000,
      sa: 68000,
      af: 14000,
      eu: 430000,
      as: 2600,
      oc: 435000,
      aw: 265000,
      la: 8584000,
    };
    let users = contArr[selected];
    return (
      <div className="wrapper">
        <Navbar />
        <section className="hero d-block d-md-flex">
          <div className="container">
            <Row>
              <Col lg="6" className=" d-none d-lg-block">
                <h1
                  style={{ fontSize: 72 }}
                  className="mt-5 text-indigo text-uppercase"
                >
                  Connect
                  <br />
                  <span className="ml-5 text-indigo">Beyroot</span>
                </h1>
              </Col>
              <Col lg="6" className="d-lg-none">
                <h1
                  style={{ fontSize: 52 }}
                  className="mt-5 text-indigo text-uppercase"
                >
                  Connect
                  <br />
                  <span className="ml-5 text-indigo">Beyroot</span>
                </h1>
              </Col>
              <Col lg="6" className="text-center d-none d-lg-block">
                <div className="big-circle-wrapper">
                  <div className="big-circle mt-4 mt-lg-0">
                    <Link to="/signup">Sign up</Link>
                    <h6>Already have an account?</h6>
                    <Link to="/signin">Sign in</Link>
                  </div>
                  <div className="big-circle1 d-none d-lg-block"></div>
                  <div className="big-circle2 d-none d-lg-block"></div>
                </div>
              </Col>
              <Col className="text-right d-lg-none mt-4 mr-2">
                <Link
                  to="/signup"
                  className="header_BtnBtn "
                  style={{
                    background: "#008dc7",
                    padding: "10px 20px",
                    fontSize: "1.2rem",
                  }}
                >
                  Sign up
                </Link>
              </Col>
              <Col className="text-left d-lg-none mt-4 ml-2">
                <Link
                  to="/signin"
                  className="header_BtnBtn mr-auto "
                  style={{
                    background: "#24c8bf",
                    padding: "10px 20px",
                    fontSize: "1.2rem",
                  }}
                >
                  Sign in
                </Link>
              </Col>
            </Row>
          </div>
        </section>
        {/* <div className='main_Banner'>
				<Navbar />
				<div className='container header'>
					<div className='col-sm-12 header_Heading'>
						<StyleRoot>
							<h1
								// style={{ marginTop: "150px" }}
								className='d-block d-md-none d-lg-none text-uppercase'
								style={{
									...this.state.fadeInUp,
									marginTop: '150px',
								}}
							>
								Connect With Lebanese
								<br />
								Diaspora
							</h1>
						</StyleRoot>
						<StyleRoot>
							<h1
								style={this.state.fadeInUp}
								className='d-none d-md-block d-lg-block text-uppercase'
							>
								Connect With Lebanese
								<br />
								Diaspora
							</h1>
						</StyleRoot>
					</div>
				</div>
			</div>
			 */}
        <div className="">
          <div className="container">
            <div className="row">
              {/* <div className="col-md-4 d-flex align-items-center justify-content-center text-center">
								<StyleRoot>
									<div style={this.state.fadeInUp}>
										<small>
											People from{" "}
											<span className="text-uppercase font-weight-bold">
												{
													this.state.continents.find(
														item => item.id == selected
													).name
												}
											</span>
											<h1 style={{ fontSize: "120px" }}>{users}</h1>
										</small>
									</div>
								</StyleRoot>
							</div>
							<div className="col-md-8">
								<StyleRoot>
									<div style={this.state.fadeInUp}>
										{/* <WorldMap
											selected={selected}
											onSelect={continent => {
												this.setState({
													selected: continent,
													previous: this.state.selected,
												});
											}}
										/>
									</div>
								</StyleRoot>
							</div> */}
              <div
                className="d-none d-lg-block"
                id="regions_div"
                style={{ overflowX: "auto" }}
              ></div>
              {/* DESKTOP VERSRION   */}
              <div
                // style={{ minHeight: "100vh" }}
                className="d-none d-lg-flex why_connect_div flex-column align-items-center justify-content-center"
              >
                <h3 className="text-center text-indigo text text-sm-white">
                  Why Connect Beyroot?
                </h3>

                <section
                  id="preview"
                  ref={this.preview_ref}
                  className="d-none d-lg-flex"
                >
                  <div class="flip-card  ">
                    <div class="flip-card-inner">
                      <div class="flip-card-front">
                        <img
                          src={pic3}
                          class="flip-img"
                          alt={"Image not found"}
                        />
                      </div>

                      <div class="flip-card-back">
                        <div>
                          <h5 style={{ color: "#0d1b46" }}>
                            <b>Jobs and employment</b>
                          </h5>
                          <p className="flip-text">
                            Find a job or find employees all in one convenient
                            location. You can also find investors, mentors, or
                            experts in the field.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flip-card ">
                    <div class="flip-card-inner">
                      <div class="flip-card-front">
                        <img src={pic5} class="flip-img" />
                      </div>

                      <div class="flip-card-back">
                        <div>
                          <h5 style={{ color: "#0d1b46" }}>
                            <b>Lebanese Community</b>
                          </h5>
                          <p className="flip-text">
                            Connect with Lebanese in your local area or perhaps
                            connect with individuals in the motherland.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flip-card ">
                    <div class="flip-card-inner">
                      <div class="flip-card-front">
                        <img src={pic4} class="flip-img" />
                      </div>

                      <div class="flip-card-back">
                        <div>
                          <h5 style={{ color: "#0d1b46" }}>
                            <b>Friends and Networking</b>
                          </h5>
                          <p className="flip-text">
                            Make new friends or network your way to your next
                            opportunity. Be social and professional all in one
                            place!{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section
                  id="preview"
                  ref={this.preview_ref}
                  className="d-lg-none"
                >
                  <div class="flip-card-mobile ">
                    <div class="flip-card-inner">
                      <div class="flip-card-front">
                        <img src={pic5} class="flip-img" />
                      </div>

                      <div class="flip-card-back">
                        <div>
                          <h5 style={{ color: "#0d1b46" }}>
                            <b>Lebanese Community</b>
                          </h5>
                          <p className="flip-text">
                            Connect with Lebanese in your local area or perhaps
                            connect with individuals in the motherland.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="preview"
                  ref={this.preview_ref}
                  className="d-lg-none"
                >
                  <div class="flip-card-mobile ">
                    <div class="flip-card-inner">
                      <div class="flip-card-front">
                        <img src={pic4} class="flip-img" />
                      </div>

                      <div class="flip-card-back">
                        <div>
                          <h5 style={{ color: "#0d1b46" }}>
                            <b>Friends and Networking</b>
                          </h5>
                          <p className="flip-text">
                            Make new friends or network your way to your next
                            opportunity. Be social and professional all in one
                            place!{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {/* mobile version */}
              <div
                style={{ minHeight: "30vh" }}
                className="d-lg-none why_connect_div flex-column align-items-center justify-content-center"
              >
                <h3 className="text-center text-indigo ">
                  Why Connect Beyroot?
                </h3>
                <div className="text-center ">
                  <i class="fas fa-circle text-white m-4 d-block"></i>
                  <i class="fas fa-circle text-white m-4 d-block"></i>
                  <i class="fas fa-circle text-white m-4 d-block"></i>
                </div>
              </div>
              <section
                className="information "
                ref={this.info_ref}
              >
                <Row className="p-3">
                  <Col xs="12" lg="4" className="p-0">
                    <img src={pic2} className="side-img " />
                  </Col>
                  <Col lg="8" xs="12" className="p-0">
                    <div>
                      <h1>Mission</h1>
                      <p
                        style={{
                          fontSize: "1.1rem",
                          fontWeight: 500,
                        }}
                      >
                        To connect and empower the Lebanese Diaspora all over
                        the world in one platform. To help business owners and
                        entrepreneurs connect to the right induvials to help
                        them take the next step in their endeavors. To connect
                        individuals who are new to a country to induvials who
                        have been in their shoes. To recognize the success
                        stories of Lebanese expats with various backgrounds and
                        to connect them with their roots, which would begin our
                        journey to transform Lebanon. Connecting the Lebanese
                        that you wouldn’t necessarily connect to in your areas
                        (since you didn’t know they existed).
                      </p>
                    </div>
                  </Col>
                </Row>
              </section>

              <section
                className="information "
                ref={this.info_ref2}
              >
                <Row className="p-3">
                  <Col lg="4" xs="12" className="p-0 order-lg-2">
                    <img src={pic6} className="side-img" />
                  </Col>
                  <Col lg="8" xs="12" className="p-0 order-lg-1">
                    <div>
                      <h1>Vision</h1>
                      <p
                        style={{
                          fontSize: "1.1rem",
                          fontWeight: 500,
                        }}
                      >
                        To unite, inspire and equip the Lebanese Diaspora so
                        that together we can transform our motherland. To
                        provide Lebanese with the opportunity to connect,
                        provide resources and assist each other.
                      </p>
                    </div>
                  </Col>
                </Row>
              </section>
            </div>
          </div>
        </div>
        {/* <div className="container top_Sec">
          <div className="row">
            <div className="col-sm-12">
              <StyleRoot>
                <div style={this.state.animating1 ? this.state.fadeInUp : {}}>
                  <h2>Beyroot Features</h2>
                  <hr
                    style={{
                      width: "50px",
                      height: "3px",
                      background: "black",
                      margin: "16px auto",
                    }}
                  />
                </div>
              </StyleRoot>
            </div>
            <StyleRoot>
              <div
                className="d-flex"
                style={this.state.animating1 ? this.state.fadeInUp : {}}
              >
                <Feature
                  className="imgs-animation"
                  img={messenger}
                  text="Messenger"
                />
                <Feature
                  className="imgs-animation"
                  img={callUsers}
                  text="Phone Calls"
                />
                <Feature
                  className="imgs-animation"
                  img={searchProfiles}
                  text="Search Profiles"
                />
              </div>
              <div
                className="d-flex"
                style={this.state.animating1 ? this.state.fadeInUp : {}}
              >
                <Feature
                  className="imgs-animation"
                  img={connectPeople}
                  text="Connecting Users"
                />
                <Feature
                  className="imgs-animation"
                  img={makeFriends}
                  text=" Make Friends"
                />
                <Feature className="imgs-animation" img={forum} text="Forums" />
              </div>
            </StyleRoot>
          </div>
        </div> */}

        {/* <div className="container beruit_Sec">
          <div className="row align-items-stretch">
            <div className="col-md-12">
              <StyleRoot>
                <div style={this.state.animating2 ? this.state.fadeInUp : {}}>
                  <h3>Why Beyroot</h3>
                </div>
              </StyleRoot>
            </div>

            <div className="col-md-6 col-12">
              <StyleRoot>
                <div
                  className="d-flex"
                  style={this.state.animating2 ? this.state.fadeInUp : {}}
                >
                  <div>
                    <span className="badge-secondary rounded-circle">
                      <span className="b-avatar-text">
                        <span>1</span>
                      </span>
                    </span>
                  </div>
                  <div>
                    <h6 className="text-left1">Friends</h6>
                    <p className="text-left1">
                      Find friends in your local area that share a similar
                      interest. Connect with people that you didn’t even know
                      existed. Maybe you are new in an area and would like to
                      meet new people.
                    </p>
                  </div>
                </div>
              </StyleRoot>
              <StyleRoot>
                <div
                  className="d-flex"
                  style={this.state.animating2 ? this.state.fadeInUp : {}}
                >
                  <div>
                    <span className="badge-secondary rounded-circle">
                      <span className="b-avatar-text2">
                        <span>2</span>
                      </span>
                    </span>
                  </div>
                  <div>
                    <h6 className="text-left1">Networking</h6>
                    <p className="text-left1">
                      87% of opportunities are gained through networking.
                      Networking is essential for advancement in your career and
                      or business. Perhaps you need a job, creating a new
                      organization, looking for a mentor, maybe making an
                      investment or perhaps looking for employees.
                    </p>
                  </div>
                </div>
              </StyleRoot>
              <StyleRoot>
                <div
                  className="d-flex"
                  style={this.state.animating2 ? this.state.fadeInUp : {}}
                >
                  <div>
                    <span className="badge-secondary rounded-circle">
                      <span className="b-avatar-text3">
                        <span>3</span>
                      </span>
                    </span>
                  </div>
                  <div>
                    <h6 className="text-left1">Jobs</h6>
                    <p className="text-left1">
                      Are you looking for a fulltime, part time or maybe a
                      freelance opportunity? Maybe you are the one who is hiring
                      an employee or a freelancer.
                    </p>
                  </div>
                </div>
              </StyleRoot>
              <StyleRoot>
                <div
                  className="d-flex"
                  style={this.state.animating2 ? this.state.fadeInUp : {}}
                >
                  <div>
                    <span className="badge-secondary rounded-circle">
                      <span className="b-avatar-text5">
                        <span>4</span>
                      </span>
                    </span>
                  </div>
                  <div>
                    <h6 className="text-left1">Entertainment</h6>
                    <p className="text-left1">
                      Do you want to know where you can find a local restaurant,
                      hafli, or concert? Then this will be theplace for you.
                    </p>
                  </div>
                </div>
              </StyleRoot>
              <StyleRoot>
                <div
                  className="d-flex"
                  style={this.state.animating2 ? this.state.fadeInUp : {}}
                >
                  <div>
                    <span className="badge-secondary rounded-circle">
                      <span className="b-avatar-text6">
                        <span>5</span>
                      </span>
                    </span>
                  </div>
                  <div>
                    <h6 className="text-left1">Ancestry</h6>
                    <p className="text-left1">
                      Have you ever wondered where your family/ friends
                      emigrated to? Perhaps you want to see where all the people
                      from your village in Lebanon settled after they left their
                      home country. Maybe you want to discover people that came
                      from the same district in your local area. Connect back to
                      your roots and track your friends and family. You never
                      know what you’ll discover.
                    </p>
                  </div>
                </div>
              </StyleRoot>
              <StyleRoot>
                <div
                  className="d-flex"
                  style={this.state.animating2 ? this.state.fadeInUp : {}}
                >
                  <div>
                    <span className="badge-secondary rounded-circle">
                      <span className="b-avatar-text4">
                        <span>6</span>
                      </span>
                    </span>
                  </div>
                  <div>
                    <h6 className="text-left1">Events</h6>
                    <p className="text-left1">
                      Do you want to know where you can find local or virtual
                      Lebanese events? Perhaps it’s a local hafli, concertor
                      maybe a virtual cooking class or prayer session. Then this
                      is the place for you!
                    </p>
                  </div>
                </div>
              </StyleRoot>
            </div> */}

        {/* <div
              className="col-md-6 col-12 d-flex align-items-center"
              style={{ backgroundColor: "#F2F2F2" }}
            >
              <StyleRoot>
                <img
                  src={sidemain}
                  style={this.state.animating2 ? this.state.fadeInUp : {}}
                />
              </StyleRoot>
            </div>
          </div>
        </div> */}

        {/* <div className="skip_way" style={{ background: "#f9f9f9" }}>
					<div className="container skkp_Sec">
						<div className="row">
							<div className="col-sm-12">
								<h3>What is Connect Beyroot?</h3>
								<p className="text-justify">
									To connect and empower the Lebanese Diaspora all over the
									world in one platform. To help business owners and
									entrepreneurs connect to the right induvials to help them take
									the next step in their endeavors. To connect individuals who
									are new to a country toinduvials who have been in their shoes.
									To recognize the success stories of Lebanese expats with
									various backgrounds and to connect them with their roots,
									which would begin our journey to transform Lebanon. Connecting
									the Lebanese that you wouldn’t necessarily connect to in your
									areas (since you didn’t know they existed).
								</p>
							</div>
							<div className="col-sm-12">
								<h4>About Lebanon</h4>
								<p className="text-justify">
									Lebanon, is one of the world’s smallest sovereign states and
									is one of theoldest citiesinthe world, having been inhabited
									for more than 5,000 years. Lebanon has one operating airport;
									Rafic Hariri International Airport located in the capital
									Beirut which is also the largest city in Lebanon. Lebanon has
									many cultural characteristics with the Arab world, but it has
									attributes that differentiate it from the neighboring Arab
									countries. Lebanon is surprisingly very mountainous compared
									to many other countries. Luckily the mountainous landscape has
									served as an asylum for diverse religious and ethnic groups
									throughout history. Lebanon is generally subtropical and is
									characterized by hot, dry summers and mild, humid winters
									(this also depends where you are, and which mountain you’re
									visiting). In late Spring / early summers you could have a
									snowball fight, then drive a couple hours and tan at the
									beach. This is an extraordinary feature in Lebanon due to the
									mountainous country. Lebanon is one of the most densely
									populated countries in the Mediterranean area with about 7
									million people total. Out of that 7 million, about 4.8 million
									are registered Lebanese, just under 1 million foreign workers
									and about half a million refugees from Syria and Palestine.
									Lebanon has one of the highest literacy rates in the Middle
									East. The majority of Lebanese in Lebanon,speak English,
									Arabic and French fluently. Coincidently enough, Lebanese tend
									to mix all 3 languages when conversing;Hi(Hello in English)
									kifik/ kifuk(how are you in Arabic) Ca va(I’m ok in French)?
									Even though Lebanon is in the Middle East, don’t assume that
									everyone is Muslim and must cover up. This is a typical
									misconception about Lebanon. In fact, there are 18 religious
									communities in Lebanon. Lebanese are also known for their
									array of delicious Mediterranean food. We are sure you’ll find
									something you love!
								</p>
							</div>
							<div className="col-sm-12">
								<h4>Lebanese Diaspora</h4>
								<p className="text-justify">
									We are descendants of Lebanese immigrants who, voluntarily or
									as refugees, emigrated from Lebanon to live and work abroad.
									Although there isn’t an exact number of how many Lebanese
									people there are, it is estimated that there are about 13-18
									million Lebanese worldwide and about 8.6 -14 million of those
									individuals live outside of Lebanon.
								</p>
							</div>
						</div>
					</div>
				</div> */}

        {/* <div className="container testimonial_Sec">
          <div className="row">
            <div className="col-sm-12">
              <StyleRoot>
                <div style={this.state.animating3 ? this.state.fadeInUp : {}}>
                  <h3>Testimonials</h3>
                  <hr
                    style={{
                      width: "50px",
                      height: "3px",
                      background: "black",
                      margin: "16px auto",
                    }}
                  />
                </div>
              </StyleRoot>

              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-ride="carousel"
              >
                <Carousel
                  activeIndex={this.state.activeIndex}
                  next={this.next}
                  previous={this.previous}
                  autoPlay={true}
                >
                  <CarouselIndicators
                    items={this.state.items}
                    activeIndex={this.state.activeIndex}
                    onClickHandler={this.goToIndex}
                  />
                  {this.state.items.map((item) => {
                    return (
                      <CarouselItem
                        onExiting={() => this.setState({ animating: true })}
                        onExited={() => this.setState({ animating: false })}
                        key={item.src}
                      >
                        <p>{item.altText}</p>
                        <h5>{item.caption}</h5>{" "}


                      </CarouselItem>
                    );
                  })}
                  <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={this.previous}
                  />
                  <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={this.next}
                  />
                </Carousel>
              </div>
            </div>
          </div>

        </div> */}

        <Footer />
      </div>
    );
  }
}
