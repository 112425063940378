import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  acceptRequest,
  blockUser,
  getConnectRequests,
  removeRequest,
} from "../../store/actions/connectActions";
import { getAllUsers, getUser } from "../../store/actions/userActions";

class ConnectsRequest extends Component {
  componentDidMount() {
    var { loggedUser } = this.props;
    this.props.getUser(loggedUser.id);
    this.props.getConnectRequests();
  }
  state = { search: "" };
  render() {
    var { loggedUser, users, connect_requests } = this.props;
    var requestUsers =
      loggedUser &&
      connect_requests &&
      connect_requests
        .filter((req) => req.requested_to === loggedUser.id)
        .reduce((acc, val) => {
          return acc.concat(val.request_by);
        }, []);
    return (
      <>
        <div className="minHeight">
          <div class=" mt-5 main-content container-fluid">
            <div class=" ml-lg-5 ml-2 pl-2 page-title">
              <h3>Connect Requests</h3>
            </div>
            <div class="col-md-4   col-md-offset-3">
              <div id="custom-search-input">
                <div class="input-group col-md-12 p-0">
                  <input
                    type="text"
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ search: e.target.value });
                    }}
                    class="  search-query form-control"
                    placeholder="Search"
                  />
                  <span class="input-group-btn">
                    <button class="btn btn-danger" type="button">
                      <span class=" glyphicon glyphicon-search"></span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid   pl-3 pr-3  ">
            <div className="container-fluid   shadow   ">
              <section class="container-fluid  pt-3 mt-4 mb-4 ">
                <div class="container-fluid p-0">
                  <div class="row mb-3">
                    {users &&
                    users
                      .filter((us) => requestUsers.includes(us.id))
                      .filter((us) => {
                        if (this.state.search) {
                          return (
                            us.first_name
                              .toLowerCase()
                              .includes(this.state.search.toLowerCase()) ||
                            us.last_name
                              .toLowerCase()
                              .includes(this.state.search.toLowerCase())
                          );
                        } else {
                          return true;
                        }
                      }).length ? null : this.state.search ? (
                      <p className="text-danger">Try some other search!</p>
                    ) : (
                      <p className="text-danger">
                        There is no pending Request!
                      </p>
                    )}
                    {users &&
                      users
                        .filter((us) => requestUsers.includes(us.id))
                        .filter((us) => {
                          if (this.state.search) {
                            return (
                              us.first_name
                                .toLowerCase()
                                .includes(this.state.search.toLowerCase()) ||
                              us.last_name
                                .toLowerCase()
                                .includes(this.state.search.toLowerCase())
                            );
                          } else {
                            return true;
                          }
                        })
                        .map((user) => {
                          return (
                            <div class="col-md-6 mt-2 p-0">
                              <div class="d-flex flex-row border rounded">
                                <div class="p-0 img-div-cust-width">
                                  <Link
                                    onClick={(e) => {
                                      e.preventDefault();
                                      var thisRequest =
                                        loggedUser &&
                                        connect_requests &&
                                        connect_requests.find((req) => {
                                          return (
                                            req.request_by === user.id &&
                                            req.requested_to === loggedUser.id
                                          );
                                        });
                                      window.open(
                                        "/dashboard/view-profile/" +
                                          thisRequest.request_by
                                      );
                                    }}
                                  >
                                    <img
                                      // src={user && user.images && user.images[0]}
                                      src={
                                        user && user.images && user.images[0]
                                      }
                                      alt="sunil"
                                      // style={{
                                      //   width: "150px",
                                      //   height: "150px",
                                      //   // borderRadius: "50%",
                                      // }}
                                    />
                                  </Link>
                                </div>
                                <div class="pl-3 pt-2 pr-2 pb-2 w-75 border-left">
                                  <Link
                                    className="text-decoration-none"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      var thisRequest =
                                        loggedUser &&
                                        connect_requests &&
                                        connect_requests.find((req) => {
                                          return (
                                            req.request_by === user.id &&
                                            req.requested_to === loggedUser.id
                                          );
                                        });
                                      window.open(
                                        "/dashboard/view-profile/" +
                                          thisRequest.request_by
                                      );
                                    }}
                                  >
                                    <h4 class="text-primary">
                                      {" "}
                                      {user.first_name}&nbsp;{user.last_name}
                                    </h4>
                                  </Link>
                                  <h5 class="text-info">{user.profession}</h5>
                                  <div class="text-right custAlign m-0 d-none d-lg-block">
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        var thisRequest =
                                          loggedUser &&
                                          connect_requests &&
                                          connect_requests.find((req) => {
                                            return (
                                              req.request_by === user.id &&
                                              req.requested_to === loggedUser.id
                                            );
                                          });
                                        this.props.acceptRequest(
                                          thisRequest.id
                                        );
                                      }}
                                      className="ml-2 btn btn-primary btn-sm"
                                    >
                                      <span class="icon-wrench"></span> Accept{" "}
                                    </button>

                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        var thisRequest =
                                          loggedUser &&
                                          connect_requests &&
                                          connect_requests.find((req) => {
                                            return (
                                              req.request_by === user.id &&
                                              req.requested_to === loggedUser.id
                                            );
                                          });
                                        this.props.removeRequest(
                                          thisRequest.id
                                        );
                                      }}
                                      className="ml-2 btn btn-danger btn-sm"
                                    >
                                      <span class="icon-trash"></span> Reject
                                    </button>
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        var thisRequest =
                                          loggedUser &&
                                          connect_requests &&
                                          connect_requests.find((req) => {
                                            return (
                                              req.request_by === user.id &&
                                              req.requested_to === loggedUser.id
                                            );
                                          });
                                        this.props.blockUser({
                                          request_id: thisRequest.id,
                                          logged_id: loggedUser.id,
                                          block_id: user.id,
                                        });
                                      }}
                                      className="ml-2 btn btn-dark btn-sm"
                                    >
                                      <span class="icon-trash"></span> Block
                                    </button>
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        var thisRequest =
                                          loggedUser &&
                                          connect_requests &&
                                          connect_requests.find((req) => {
                                            return (
                                              req.request_by === user.id &&
                                              req.requested_to === loggedUser.id
                                            );
                                          });
                                        window.open(
                                          "/dashboard/view-profile/" +
                                            thisRequest.request_by
                                        );
                                      }}
                                      className=" ml-2 btn btn-success btn-sm"
                                    >
                                      View Profile
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div class="custAlign my-2 d-lg-none">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    var thisRequest =
                                      loggedUser &&
                                      connect_requests &&
                                      connect_requests.find((req) => {
                                        return (
                                          req.request_by === user.id &&
                                          req.requested_to === loggedUser.id
                                        );
                                      });
                                    this.props.acceptRequest(thisRequest.id);
                                  }}
                                  className="ml-2 btn btn-primary btn-sm"
                                >
                                  <span class="icon-wrench"></span> Accept{" "}
                                </button>

                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    var thisRequest =
                                      loggedUser &&
                                      connect_requests &&
                                      connect_requests.find((req) => {
                                        return (
                                          req.request_by === user.id &&
                                          req.requested_to === loggedUser.id
                                        );
                                      });
                                    this.props.removeRequest(thisRequest.id);
                                  }}
                                  className="ml-2 btn btn-danger btn-sm"
                                >
                                  <span class="icon-trash"></span> Reject
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    var thisRequest =
                                      loggedUser &&
                                      connect_requests &&
                                      connect_requests.find((req) => {
                                        return (
                                          req.request_by === user.id &&
                                          req.requested_to === loggedUser.id
                                        );
                                      });
                                    this.props.blockUser({
                                      request_id: thisRequest.id,
                                      logged_id: loggedUser.id,
                                      block_id: user.id,
                                    });
                                  }}
                                  className="ml-2 btn btn-dark btn-sm"
                                >
                                  <span class="icon-trash"></span> Block
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    var thisRequest =
                                      loggedUser &&
                                      connect_requests &&
                                      connect_requests.find((req) => {
                                        return (
                                          req.request_by === user.id &&
                                          req.requested_to === loggedUser.id
                                        );
                                      });
                                    window.open(
                                      "/dashboard/view-profile/" +
                                        thisRequest.request_by
                                    );
                                  }}
                                  className=" ml-2 btn btn-success btn-sm"
                                >
                                  View Profile
                                </button>
                              </div>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export function mapStateToProps(state) {
  return {
    loading: state.auth.requested,
    registered: state.auth.registered,
    loggedUser: state.authUser.user,
    users: state.users.users,
    connect_requests: state.connect.connect_requests,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    // registeredComplete: () => dispatch(removeRegistered()),
    // login: (credentials) => dispatch(login(credentials)),
    // loginGoogle: () => dispatch(signupGoogle()),

    getUser: (uid) => dispatch(getUser(uid)),
    getAllUsers: () => dispatch(getAllUsers()),
    // connectRequest: (loggedUid, uid) =>
    //   dispatch(connectRequest(loggedUid, uid)),
    getConnectRequests: () => dispatch(getConnectRequests()),
    removeRequest: (uid) => dispatch(removeRequest(uid)),
    blockUser: (uid) => dispatch(blockUser(uid)),
    acceptRequest: (uid) => dispatch(acceptRequest(uid)),

    // personelInfo: (Info) => dispatch(updatePersonelInfo(Info)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectsRequest);
