import React, { Component } from 'react';
import avatar from './assets/images/user-profile.png';
import Iframe from 'react-iframe';
import Person1 from '../../images/person1.jpeg';
import Person2 from '../../images/person2.jpeg';
import Person3 from '../../images/person3.jpeg';
import Person4 from '../../images/person4.jpeg';
import Business1 from '../../images/business1.jpeg';
import Business2 from '../../images/business2.jpeg';
import Business3 from '../../images/business3.jpeg';
import Profile from '../../images/profile-business.jpeg';
import Avatar from '@material-ui/core/Avatar';
import BusinessProfile from '../../images/businessacc1.jpeg';
import BusinessImage1 from '../../images/businessimg1.jpg';
import BusinessImage2 from '../../images/businessimg2.jpg';
import BusinessImage3 from '../../images/businessimg3.jpg';
import { connect } from 'react-redux';
import { getAllUsers, getUser } from '../../store/actions/userActions';
import {
	acceptRequest,
	blockUser,
	connectRequest,
	getConnectRequests,
	removeConnect,
	removeRequest,
	unblockUser,
} from '../../store/actions/connectActions';
import {
	Modal,
	Button,
	ModalBody,
	ModalHeader,
	Label,
	Input,
	Row,
	Col,
	Form,
} from 'reactstrap';
import moment from 'moment';
import { getChats, startChat } from '../../store/actions/chatsActions';
import { geocodeByAddress } from 'react-places-autocomplete';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

class ProfileDetail extends Component {
	Persons = [Person1, Person2, Person3, Person4];
	Business = [Business1, Business2, Business3];
	BusinessImgs = [BusinessImage1, BusinessImage2, BusinessImage3];
	state = { redirect: false, chat: '', location: '' };
	componentDidMount() {
		var { loggedUser } = this.props;
		this.props.getConnectRequests();
		this.props.getChats(loggedUser.id);
		this.props.getAllUsers();
		this.props.getUser(loggedUser.id);
		// For Formatted Location Purpose
		var userID = this.props.match.params.id;
		var user =
			this.props.users && this.props.users.find(us => us.id === userID);
		if (user != null) {
			this.formatAddress(user.current_location);
		}
	}
	state = { modal: false, photoModal: '' };
	photoToggle = id => {
		if (this.state.photoModal != id) {
			this.setState({ photoModal: id });
		} else {
			this.setState({ photoModal: '' });
		}
	};
	toggle = e => {
		e.preventDefault();
		this.setState({ modal: !this.state.modal });
	};
	formatAddress = address => {
		geocodeByAddress(address)
			.then(results => {
				// console.log(results);
				let city, state, country;
				for (let i = 0; i < results[0].address_components.length; i++) {
					for (
						let j = 0;
						j < results[0].address_components[i].types.length;
						j++
					) {
						switch (results[0].address_components[i].types[j]) {
							case 'administrative_area_level_2':
								city =
									results[0].address_components[i].long_name;
								break;
							case 'administrative_area_level_1':
								state =
									results[0].address_components[i].long_name;
								break;
							case 'country':
								country =
									results[0].address_components[i].long_name;
								break;
						}
					}
				}
				this.setState({
					location: city + ', ' + state + ', ' + country,
				});
				// console.log(city, state, country);
			})
			.catch(error => console.error('Error', error));
	};
	render() {
		if (this.state.redirect) {
			return (
				<Redirect
					to={{
						pathname: '/dashboard/chats',
						state: { chat: this.state.chat },
					}}
				/>
			);
		}
		var { users, loggedUser, connect_requests, chats } = this.props;
		var userID = this.props.match.params.id;
		var user = users && users.find(us => us.id === userID);
		var requestExists =
			connect_requests &&
			loggedUser &&
			connect_requests.find(req => {
				return (
					req.request_by === loggedUser.id &&
					req.requested_to === userID
				);
			});
		var reversRequestExists =
			connect_requests &&
			loggedUser &&
			connect_requests.find(req => {
				return (
					req.request_by === userID &&
					req.requested_to === loggedUser.id
				);
			});
		var thisChat =
			chats &&
			loggedUser &&
			user &&
			chats.find(
				chat =>
					chat.users.includes(loggedUser.id) &&
					chat.users.includes(user.id)
			);
		var type = user && user.type;
		if (user && loggedUser) {
			return (
				<>
					<div class='main-content mt-3'>
						{
							<section className='container'>
								<div className='row'>
									<div className='col-md-6 profile-section'>
										<div className='description'>
											<div className='row'>
												<div className='col-md-4'>
													<div class='circular'>
														<img
															src={
																user &&
																user.profile_pic
															}
															alt='sunil'
															style={{
																width: '150px',
																height: '150px',
																borderRadius:
																	'50%',
															}}
														/>
													</div>
												</div>
												<div className='col-md-8'>
													<div className='general-info'>
														<h2 className='text-capitalize'>
															{user &&
																user.first_name}
															&nbsp;
															{user &&
																user.last_name}
														</h2>
														<ul
															style={{
																listStyle:
																	'none',
																margin: '0',
																padding: '0',
															}}
														>
															{/* <li>
                                <i class="fas fa-map-marker-alt mr-2"></i>
                                {this.formatAddress(user.lat, user.lng)}
                                {this.state.location}
                              </li> */}
															<li>
																<i class='far fa-flag mr-2'></i>
																{user &&
																	user.district.map(
																		(
																			lang,
																			index
																		) => {
																			if (
																				user
																					.district
																					.length -
																					1 ===
																				index
																			) {
																				return (
																					lang.title +
																					'.'
																				);
																			}
																			return (
																				lang.title +
																				', '
																			);
																		}
																	)}
															</li>
															<li>
																<i class='fa fa-language mr-2'></i>
																Knows{' '}
																{user &&
																	user.languages.map(
																		(
																			lang,
																			index
																		) => {
																			if (
																				user
																					.languages
																					.length -
																					1 ===
																				index
																			) {
																				return (
																					lang.title +
																					'.'
																				);
																			}
																			return (
																				lang.title +
																				', '
																			);
																		}
																	)}
															</li>
															<li>
																<i class='fas fa-user mr-2'></i>
																{user.status
																	? 'is '
																	: null}
																{user.status
																	? user.status
																	: 'Status Not Mentioned'}
															</li>
															<li>
																<i class='fas fa-binoculars mr-2'></i>
																{user &&
																	user.looking_for.map(
																		(
																			look,
																			index
																		) => {
																			if (
																				user
																					.looking_for
																					.length -
																					1 ===
																				index
																			) {
																				return (
																					look.title +
																					'.'
																				);
																			}
																			return (
																				look.title +
																				', '
																			);
																		}
																	)}{' '}
															</li>
															<li>
																<i class='fas fa-globe-americas mr-2'></i>
																{user &&
																	user.countries.map(
																		(
																			look,
																			index
																		) => {
																			if (
																				user
																					.countries
																					.length -
																					1 ===
																				index
																			) {
																				return (
																					look.title +
																					'.'
																				);
																			}
																			return (
																				look.title +
																				', '
																			);
																		}
																	)}
															</li>
															{/* <li>
                                <i class="fa fa-briefcase mr-2"></i>Business
                                Profile
                              </li> */}
														</ul>
													</div>
												</div>
											</div>
										</div>
										<div className='contact-info'>
											{loggedUser &&
											loggedUser.connects.includes(
												userID
											) ? (
												<Button
													className='mt-3'
													color='dark'
													size='md'
													onClick={
														thisChat
															? e => {
																	e.preventDefault();
																	this.setState(
																		{
																			chat: thisChat.id,
																			redirect: true,
																		}
																	);
															  }
															: this.toggle
													}
												>
													Send Message
												</Button>
											) : null}

											<Modal
												isOpen={this.state.modal}
												toggle={this.toggle}
											>
												<ModalHeader
													toggle={this.toggle}
												>
													Send Message
												</ModalHeader>
												<ModalBody>
													<Form
														onSubmit={e => {
															e.preventDefault();
															if (
																e.target.message
																	.value
															) {
																this.props.startChat(
																	{
																		user1: loggedUser.id,
																		user2: user.id,
																		message:
																			e
																				.target
																				.message
																				.value,
																		time: moment().format(),
																	}
																);
																this.setState({
																	redirect: true,
																});
																this.toggle(e);
															} else {
																alert(
																	'Cannot send empty message'
																);
															}
														}}
													>
														<Label>
															Type Message
														</Label>
														<Input
															type='textarea'
															name='message'
														/>
														<Row>
															<Col className='text-right'>
																<Button className='mt-3'>
																	Send
																</Button>
															</Col>
														</Row>
													</Form>
												</ModalBody>
											</Modal>
										</div>
										<div className='short-description'>
											<h3 className='font-weight-bold  my-4'>
												Short Description
											</h3>
											<p className='font-weight-bold'>
												{user && user.description}
											</p>
										</div>
									</div>
									<div className='col-md-6'>
										<div className='d-flex'>
											<div className='ml-auto'>
												{reversRequestExists ? (
													<>
														<Button
															onClick={e => {
																e.preventDefault();

																this.props.acceptRequest(
																	reversRequestExists.id
																);
															}}
															color='success'
														>
															Accept
														</Button>
														<Button
															className='ml-2'
															onClick={e => {
																e.preventDefault();

																this.props.removeRequest(
																	reversRequestExists.id
																);
															}}
															color='danger'
														>
															Reject
														</Button>
														<Button
															className='ml-2'
															onClick={e => {
																e.preventDefault();

																this.props.blockUser(
																	{
																		request_id:
																			reversRequestExists.id,
																		logged_id:
																			loggedUser.id,
																		block_id:
																			userID,
																	}
																);
															}}
															color='dark'
														>
															Block
														</Button>
													</>
												) : user.block.includes(
														loggedUser.id
												  ) ? null : (
													<button
														disabled={requestExists}
														onClick={e => {
															e.preventDefault();
															if (
																loggedUser.block.includes(
																	user.id
																)
															) {
																this.props.unblockUser(
																	{
																		logged_id:
																			loggedUser.id,
																		block_id:
																			user.id,
																	}
																);
															} else if (
																loggedUser.connects.includes(
																	user.id
																)
															) {
																this.props.removeConnect(
																	loggedUser.id,
																	user.id
																);
															} else {
																this.props.connectRequest(
																	loggedUser.id,
																	user.id
																);
															}
														}}
														className='btn btn-dark mr-3'
													>
														{requestExists
															? 'Requested'
															: loggedUser &&
															  loggedUser.connects.includes(
																	user.id
															  )
															? 'Remove Connect'
															: loggedUser.block.includes(
																	user.id
															  )
															? 'Unblock'
															: 'Connect'}
													</button>
												)}
											</div>
										</div>
										<div className='friends mb-4'>
											<h2 className='font-weight-bold'>
												Friends
											</h2>

											<div className='row'>
												{user &&
													loggedUser &&
													user.connects.map(
														connectId => {
															var friend =
																users &&
																users.find(
																	us =>
																		us.id ===
																		connectId
																);
															return (
																<div className='col-6 col-md-2 mb-4'>
																	<Link
																		to={
																			friend.id ==
																			loggedUser.id
																				? '/dashboard/profile-detail'
																				: '/dashboard/view-profile/' +
																				  friend.id
																		}
																	>
																		<div
																			className='position-relative'
																			style={{
																				width: '100%',
																				height: 0,
																				paddingTop:
																					'100%',
																			}}
																		>
																			<img
																				src={
																					friend
																						.images[0]
																				}
																				style={{
																					position:
																						'absolute',
																					top: 0,
																					left: 0,
																					width: '100%',
																					height: '100%',
																					objectFit:
																						'cover',
																				}}
																			></img>
																		</div>
																	</Link>
																</div>
															);
														}
													)}
											</div>
										</div>
										<div className='pictures mb-4'>
											<h2 className='font-weight-bold'>
												Pictures
											</h2>
											<div className='row'>
												{user &&
													user.images.map(
														business => (
															<div className='col-6 col-md-4 mb-4'>
																<div
																	className='position-relative'
																	style={{
																		width: '100%',
																		height: 0,
																		paddingTop:
																			'60%',
																	}}
																>
																	<img
																		src={
																			business
																		}
																		onClick={e => {
																			e.preventDefault();
																			this.photoToggle(
																				business
																			);
																		}}
																		style={{
																			position:
																				'absolute',
																			top: 0,
																			left: 0,
																			width: '100%',
																			height: '100%',
																			objectFit:
																				'cover',
																		}}
																	></img>
																</div>
																<Modal
																	isOpen={
																		this
																			.state
																			.photoModal ==
																		business
																	}
																	toggle={e => {
																		e.preventDefault();
																		this.photoToggle(
																			business
																		);
																	}}
																>
																	<ModalBody>
																		<img
																			style={{
																				width: '100%',
																			}}
																			src={
																				business
																			}
																		></img>
																	</ModalBody>
																</Modal>
															</div>
														)
													)}
											</div>
										</div>
									</div>
								</div>
							</section>
							//  (
							//   <section className="container">
							//     <div className="row">
							//       <div className="col-md-6 profile-section">
							//         <div className="description">
							//           <div className="row">
							//             <div className="col-md-4">
							//               <div className="rounded-circle overflow-hidden">
							//                 <img src={BusinessProfile} alt="sunil" />
							//               </div>
							//             </div>
							//             <div className="col-md-8">
							//               <div className="general-info">
							//                 <h2>Ajeen</h2>
							//                 <ul
							//                   style={{
							//                     listStyle: "none",
							//                     margin: "0",
							//                     padding: "0",
							//                   }}
							//                 >
							//                   <li>
							//                     <i class="fas fa-map-marker-alt mr-2"></i>Boston
							//                   </li>
							//                   <li>
							//                     <i class="fa fa-language mr-2"></i>
							//                     English, French, Arabic
							//                   </li>
							//                   <li>
							//                     <i class="fa fa-briefcase mr-2"></i>Business
							//                   </li>
							//                 </ul>
							//               </div>
							//             </div>
							//           </div>
							//         </div>
							//         <div className="contact-info">
							//           <ul
							//             className="d-inline-flex mt-4"
							//             style={{
							//               listStyle: "none",
							//               padding: "0",
							//               margin: "0",
							//               boxShadow:
							//                 "0 3px 6px rgb(0 0 0 / 20%), 0 3px 4px rgb(0 0 0 / 30%)",
							//               background: "rgb(236, 227, 226)",
							//             }}
							//           >
							//             <li className="p-2">
							//               <i class="fa fa-inbox"></i>
							//             </li>
							//             <li className="p-2">
							//               <i class="fa fa-phone"></i>
							//             </li>
							//             <li className="p-2">
							//               <i class="fa fa-envelope"></i>
							//             </li>
							//             <li className="p-2">
							//               <i class="fa fa-map"></i>
							//             </li>
							//           </ul>
							//         </div>
							//         <div className="short-description">
							//           <h3 className="font-weight-bold  my-4">
							//             Short Description
							//           </h3>
							//           <p className="font-weight-bold">
							//             Ajeen, authentic Lebanese food without travelling to
							//             Lebanon. Ajeen serves a variety of foods including mneech,
							//             kababs, falefel, salads, desserts and more! Ajeen is hands
							//             down the most delicious manoushe spot in Boston! Check it
							//             out for yourself!
							//           </p>
							//         </div>
							//       </div>
							//       <div className="col-md-6">
							//         <div className="d-flex">
							//           <div className="ml-auto">
							//             {this.props.history.location.state &&
							//             this.props.history.location.state.is_own ? (
							//               <button
							//                 className="btn btn-dark"
							//                 onClick={(e) =>
							//                   this.props.history.push(
							//                     "/dashboard/edit-business-profile"
							//                   )
							//                 }
							//               >
							//                 Edit
							//               </button>
							//             ) : (
							//               <div className="d-inline-block">
							//                 <i
							//                   className="fa fa-star"
							//                   style={{ fontSize: "32px" }}
							//                 ></i>
							//                 <i
							//                   className="fa fa-star"
							//                   style={{ fontSize: "32px" }}
							//                 ></i>
							//                 <i
							//                   className="fa fa-star"
							//                   style={{ fontSize: "32px" }}
							//                 ></i>
							//                 <i
							//                   className="far fa-star"
							//                   style={{ fontSize: "32px" }}
							//                 ></i>
							//                 <i
							//                   className="far fa-star"
							//                   style={{ fontSize: "32px" }}
							//                 ></i>
							//               </div>
							//             )}
							//             <i className="fa fa-ellipsis-v ml-3"></i>
							//           </div>
							//         </div>
							//         <div className="pictures mb-4">
							//           <h2 className="font-weight-bold">Pictures</h2>
							//           <div className="row">
							//             {user &&
							//               user.images.map((img) => (
							//                 <div className="col-md-4 mb-4">
							//                   <div
							//                     className="position-relative"
							//                     style={{
							//                       width: "100%",
							//                       height: 0,
							//                       paddingTop: "60%",
							//                     }}
							//                   >
							//                     <img
							//                       src={img}
							//                       style={{
							//                         position: "absolute",
							//                         top: 0,
							//                         left: 0,
							//                         width: "100%",
							//                         objectFit: "cover",
							//                       }}
							//                     ></img>
							//                   </div>
							//                 </div>
							//               ))}
							//           </div>
							//         </div>
							//         <div className="pictures mb-4">
							//           <h2 className="font-weight-bold">Location</h2>
							//           <Iframe
							//             url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2948.6100527302824!2d-71.13302628448052!3d42.350836479187436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e379c5d9881d01%3A0xbd064db202aa6e44!2s1219%20Commonwealth%20Avenue%2C%20Allston%2C%20MA%2002134%2C%20USA!5e0!3m2!1sen!2slb!4v1606249663630!5m2!1sen!2slb"
							//             width="100%"
							//             height="450px"
							//             frameborder="0"
							//             style="border:0;"
							//             allowfullscreen=""
							//             aria-hidden="false"
							//             tabindex="0"
							//           ></Iframe>
							//         </div>
							//       </div>
							//     </div>
							//   </section>
							// )}
						}
					</div>
				</>
			);
		} else {
			return <h3>Loading...</h3>;
		}
	}
}
export function mapStateToProps(state) {
	return {
		loading: state.auth.requested,
		registered: state.auth.registered,
		loggedUser: state.authUser.user,
		users: state.users.users,
		chats: state.chat.chats,
		connect_requests: state.connect.connect_requests,
	};
}
export function mapDispatchToProps(dispatch) {
	return {
		// registeredComplete: () => dispatch(removeRegistered()),
		// login: (credentials) => dispatch(login(credentials)),
		// loginGoogle: () => dispatch(signupGoogle()),

		getUser: uid => dispatch(getUser(uid)),
		getAllUsers: () => dispatch(getAllUsers()),
		connectRequest: (loggedUid, uid) =>
			dispatch(connectRequest(loggedUid, uid)),
		removeConnect: (loggedUid, uid) =>
			dispatch(removeConnect(loggedUid, uid)),
		getConnectRequests: () => dispatch(getConnectRequests()),
		startChat: msg => dispatch(startChat(msg)),
		getChats: uid => dispatch(getChats(uid)),
		blockUser: uid => dispatch(blockUser(uid)),
		unblockUser: uid => dispatch(unblockUser(uid)),
		removeRequest: uid => dispatch(removeRequest(uid)),
		acceptRequest: uid => dispatch(acceptRequest(uid)),
		// personelInfo: (Info) => dispatch(updatePersonelInfo(Info)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetail);
