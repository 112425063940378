import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
	Button,
	Input,
	NavItem,
	NavLink,
	Nav,
	TabContent,
	TabPane,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	ModalFooter,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import E1 from "../../images/e1.jpg";
import E2 from "../../images/e2.jpg";
import E3 from "../../images/e3.jpg";
import E4 from "../../images/e4.jfif";
import E5 from "../../images/e5.jfif";
import E6 from "../../images/e6.jpg";

export default class Events extends Component {
	Images = [E1, E2, E3, E4, E5, E6];
	constructor(props) {
		super(props);
		this.state = {
			activeTab: "1",
			modal: false,
			open: false,
			startDate: new Date(),
			time: "",
		};
	}

	componentDidMount = () => {
		var today = new Date();
		this.setState({
			time:
				today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(),
		});
	};
	toggle = tab => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	};
	modalToggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};
	render() {
		var type = localStorage.getItem("type");
		var content;

		if (type === "individual") {
			content = (
				<>
					<div class=" mt-5 main-content container-fluid">
						<div class=" ml-5 pl-3 page-title">
							<h3 className="ml-5 pl-3">Events</h3>
							<div class="row">
								<div class="col-md-4 col-md-offset-3">
									<div id="custom-search-input">
										<div class=" ml-5  input-group col-md-12">
											<input
												type="text"
												class="  search-query form-control"
												placeholder="Search"
											/>
											<span class="input-group-btn">
												<button class="btn btn-danger" type="button">
													<span class=" glyphicon glyphicon-search"></span>
												</button>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<section class="mt-4 mb-4">
						<div class="container">
							<div class="row mb-3">
								{this.Images.map(image => (
									<div class="col-md-4">
										<Link
											to="/dashboard/events-detail"
											className="text-decoration-none"
										>
											<div class="event d-flex flex-column">
												<img
													src={image}
													class="event-image"
													style={{ height: "10em", objectFit: "cover" }}
												/>
												<h3 className="my-3 font-weight-bold text-dark">
													Lorem ipsum sit amet
												</h3>
											</div>
										</Link>
									</div>
								))}
							</div>
						</div>
						<div class="container">
							<div class="row mb-3">
								{this.Images.map(image => (
									<div class="col-md-4">
										<Link
											to="/dashboard/events-detail"
											className="text-decoration-none"
										>
											<div class="event d-flex flex-column">
												<img
													src={image}
													class="event-image"
													style={{ height: "10em", objectFit: "cover" }}
												/>
												<h3 className="my-3 font-weight-bold text-dark">
													Lorem ipsum sit amet
												</h3>
											</div>
										</Link>
									</div>
								))}
							</div>
						</div>
					</section>
				</>
			);
		} else {
			content = (
				<>
					<Modal isOpen={this.state.modal} toggle={this.modalToggle}>
						<ModalHeader toggle={this.modalToggle}>
							<span className="text-warning">Add New Listing</span>
						</ModalHeader>
						<ModalBody>
							<Form>
								<Input
									className="mt-3"
									type="text"
									placeholder="Event Name"
									name="eventName"
									value={this.state.eventName}
									onChange={this.handleChange}
									required
								/>

								<DatePicker
									className="mt-3"
									selected={this.state.startDate}
									onChange={date =>
										this.setState({
											startDate: date,
										})
									}
									required
								/>

								<TimePicker
									className="ml-3"
									onChange={time =>
										this.setState({
											time: time,
										})
									}
									value={this.state.time}
									required
								/>
								<Input
									className="mt-3"
									type="text"
									placeholder="Event Organizer"
									name="eventOrganizer"
									value={this.state.listingDescription}
									onChange={this.handleChange}
									required
								/>

								<select
									className="mt-3 form-select"
									id="language"
									name="location"
									value={this.state.location}
								>
									<option disabled selected>
										Location
									</option>
									<option value="remote">Remote</option>
									<option value="via zoom">Via Zoom</option>
									<option value="via facebook">Via Facebook</option>
									<option value="in person">In Person</option>
								</select>
								<label>Contact Information</label>
								<Input
									className="mt-3"
									type="email"
									placeholder="Email"
									name="email"
									value={this.state.email}
									onChange={this.handleChange}
									required
								/>
								<Input
									className="mt-3"
									type="text"
									placeholder="Phone Number"
									name="phoneNumber"
									value={this.state.phoneNumber}
									onChange={this.handleChange}
									required
								/>

								<Input
									className="mt-3"
									type="text"
									placeholder="Address"
									name="address"
									value={this.state.address}
									onChange={this.handleChange}
									required
								/>
								<Input
									className="mt-3"
									type="text"
									placeholder="Cost"
									name="cost"
									value={this.state.cost}
									onChange={this.handleChange}
									required
								/>
								<Input
									className="mt-3"
									type="text"
									placeholder="External Liks"
									name="externalLinks"
									value={this.state.externalLinks}
									onChange={this.handleChange}
									required
								/>

								<ModalFooter>
									<Button color="secondary" onClick={this.modalToggle}>
										cancel
									</Button>{" "}
									<Button color="primary">
										{this.state.requested ? (
											<Loader
												type="TailSpin"
												color="#fff"
												height={20}
												width={30}
											/>
										) : (
											"save"
										)}
									</Button>
								</ModalFooter>
							</Form>
						</ModalBody>
					</Modal>
					<div className="nav-tabs-navigation">
						<div className="nav-tabs-wrapper">
							<Nav role="tablist" tabs>
								<NavItem>
									<NavLink
										className={` font-weight-bold ${
											this.state.activeTab === "1" ? "active" : ""
										}`}
										onClick={() => {
											this.toggle("1");
										}}
									>
										Global Events
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={` font-weight-bold ${
											this.state.activeTab === "2" ? "active" : ""
										}`}
										onClick={() => {
											this.toggle("2");
										}}
									>
										My Events
									</NavLink>
								</NavItem>
							</Nav>
						</div>
					</div>
					<TabContent className="following" activeTab={this.state.activeTab}>
						<TabPane tabId="1" id="follows">
							<div class=" mt-5 main-content container-fluid">
								<div class=" ml-5 pl-3 page-title">
									<h3 className="ml-5 pl-3">Events</h3>
									<div class="row">
										<div class="col-md-4 col-md-offset-3">
											<div id="custom-search-input">
												<div class=" ml-5  input-group col-md-12">
													<input
														type="text"
														class="  search-query form-control"
														placeholder="Search"
													/>
													<span class="input-group-btn">
														<button class="btn btn-danger" type="button">
															<span class=" glyphicon glyphicon-search"></span>
														</button>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<section class="mt-4 mb-4">
								<div class="container">
									<div class="row mb-3">
										{this.Images.map(image => (
											<div class="col-md-4">
												<Link
													to="/dashboard/events-detail"
													className="text-decoration-none"
												>
													<div class="event d-flex flex-column">
														<img
															src={image}
															class="event-image"
															style={{ height: "10em", objectFit: "cover" }}
														/>
														<h3 className="my-3 font-weight-bold text-dark">
															Lorem ipsum sit amet
														</h3>
													</div>
												</Link>
											</div>
										))}
									</div>
								</div>
								<div class="container">
									<div class="row mb-3">
										{this.Images.map(image => (
											<div class="col-md-4">
												<Link
													to="/dashboard/events-detail"
													className="text-decoration-none"
												>
													<div class="event d-flex flex-column">
														<img
															src={image}
															class="event-image"
															style={{ height: "10em", objectFit: "cover" }}
														/>
														<h3 className="my-3 font-weight-bold text-dark">
															Lorem ipsum sit amet
														</h3>
													</div>
												</Link>
											</div>
										))}
									</div>
								</div>
							</section>
						</TabPane>

						<TabPane tabId="2" id="follows">
							<div class=" mt-5 main-content container-fluid">
								<div class=" ml-5 pl-3 page-title">
									<h3 className="ml-5 pl-3">Events</h3>
									<div class="row">
										<div class="col-md-4 col-md-offset-3">
											<div id="custom-search-input">
												<div class=" ml-5  input-group col-md-12">
													<input
														type="text"
														class="  search-query form-control"
														placeholder="Search"
													/>
													<span class="input-group-btn">
														<button class="btn btn-danger" type="button">
															<span class=" glyphicon glyphicon-search"></span>
														</button>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<button
								onClick={this.modalToggle}
								style={{ marginTop: "-50px", marginRight: "140px" }}
								className=" float-right  btn btn-primary"
							>
								Create Event
							</button>
							<section class="mt-4 mb-4">
								<div class="container">
									<div class="row mb-3">
										{this.Images.map(image => (
											<div class="col-md-4">
												<Link
													to="/dashboard/events-detail"
													className="text-decoration-none"
												>
													<div class="event d-flex flex-column">
														<img
															src={image}
															class="event-image"
															style={{ height: "10em", objectFit: "cover" }}
														/>
														<h3 className="my-3 font-weight-bold text-dark">
															Lorem ipsum sit amet
														</h3>
													</div>
												</Link>
											</div>
										))}
									</div>
								</div>
								<div class="container">
									<div class="row mb-3">
										{this.Images.map(image => (
											<div class="col-md-4">
												<Link
													to="/dashboard/events-detail"
													className="text-decoration-none"
												>
													<div class="event d-flex flex-column">
														<img
															src={image}
															class="event-image"
															style={{ height: "10em", objectFit: "cover" }}
														/>
														<h3 className="my-3 font-weight-bold text-dark">
															Lorem ipsum sit amet
														</h3>
													</div>
												</Link>
											</div>
										))}
									</div>
								</div>
							</section>
						</TabPane>
					</TabContent>
				</>
			);
		}

		return <>{content}</>;
	}
}
