import React, { Component } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import "./landing.css";
import pic1 from "../../images/e1.jpg";
import pic2 from "../../images/e2.jpg";
import pic3 from "../../images/e3.jpg";
import Radium, { StyleRoot } from "radium";
import logo from "../../images/logo1.PNG";
import { fadeInUp } from "react-animations";
import {
  Button,
  Col,
  Form,
  Label,
  ModalBody,
  ModalHeader,
  Row,
  Modal,
  Input,
  Card,
  CardBody,
  CardImg,
  CardHeader,
  UncontrolledCarousel,
  CardFooter,
} from "reactstrap";
import { connect } from "react-redux";
import { getUser, getAllUsers } from "../../store/actions/userActions";
import {
  getPosts,
  createPost,
  comment as Comment,
  upPost,
  downPost,
  upComment,
  downComment,
  deleteComment,
} from "../../store/actions/postsActions";
import moment from "moment";
import { Link } from "react-router-dom";
import ForumCarousel from "./ForumCarousel";
class Forum extends Component {
  state = {
    fadeInUp: {
      animation: "x 1.5s",
      animationName: Radium.keyframes(fadeInUp, "bounce"),
    },
    modal: false,
    showComments: [],
    showCommentField: [],
  };
  toggle = (e) => {
    e.preventDefault();
    this.setState({ modal: !this.state.modal });
  };
  componentDidMount() {
    this.props.getPosts();
    this.props.getAllUsers();
  }
  arrayToItems = (array) => {
    console.log(array);
    let newArray = [];

    array.forEach((item) => {
      newArray.push({
        src: item,
      });
    });
    return newArray;
  };
  checkForUrl = (text) => {
    if (!text) {
      return false;
    }
    var t = text;
    var expression =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    var regex = new RegExp(expression);
    var url = [...t.matchAll(regex)];
    if (url.length) {
      // console.log(url[0][0]);
      return url[0][0];
    } else {
      return false;
    }
  };
  render() {
    var { auth, posts, users, authUser } = this.props;

    return (
      <div>
        <div className="wrapper">
          <div className="main_Banner">
            <Navbar />
            <div className="container header">
              <div className="col-sm-12 header_Heading">
                <StyleRoot>
                  <h1
                    // style={{ marginTop: "150px" }}
                    className="d-block d-md-none d-lg-none text-uppercase"
                    style={{ ...this.state.fadeInUp, marginTop: "150px" }}
                  >
                    Connect With Lebanese
                    <br />
                    Diaspora
                  </h1>
                </StyleRoot>
                <StyleRoot>
                  <h1
                    style={this.state.fadeInUp}
                    className="d-none d-md-block d-lg-block text-uppercase"
                  >
                    Connect With Lebanese
                    <br />
                    Diaspora
                  </h1>
                </StyleRoot>
              </div>
            </div>
          </div>
          <main id="forumPage" className="mt-5">
            <section id="forum">
              <Row>
                <Col className="text-center">
                  <h3>Forum</h3>
                </Col>
              </Row>
              <Row className="p-3">
                <Col className="text-left">
                  <Button
                    color="info"
                    hidden={!authUser.uid}
                    onClick={this.toggle}
                  >
                    Add Post
                  </Button>
                  <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Add Post</ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          if (e.target.text.value) {
                            this.props.createPost(
                              {
                                text: e.target.text.value,

                                user_id: authUser.uid,
                              },
                              e.target.image.files
                            );
                            this.toggle(e);
                          } else {
                            alert("Cannot Post Empty Post");
                          }
                        }}
                      >
                        <Label>Post Text</Label>
                        <Input type="textarea" name="text" required />
                        <Label>Post Image</Label>
                        <Input type="file" name="image" multiple />
                        <Row>
                          <Col className="text-right">
                            <Button type="submit" className="m-2" color="info">
                              Submit Post
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>

              <Row className="font-weight-bold">
                <Col lg={{ size: 9 }}>
                  {posts && posts.length
                    ? posts
                        .sort((a, b) => {
                          var lastTime1 = moment(a.date);
                          var lastTime2 = moment(b.date);

                          if (lastTime1.isBefore(lastTime2, "s")) {
                            return 1;
                          } else {
                            if (lastTime1.isAfter(lastTime2, "s")) {
                              return -1;
                            } else {
                              return 0;
                            }
                          }
                        })

                        .map((LatestPost) => {
                          if (
                            LatestPost &&
                            LatestPost.text &&
                            this.checkForUrl(LatestPost.text)
                          ) {
                            return (
                              <>
                                <Row>
                                  <Col lg={{ size: 12 }}>
                                    <Card className="mt-2">
                                      <CardBody>
                                        <Row>
                                          {LatestPost.image != null &&
                                          Array.isArray(LatestPost.image) ? (
                                            <Col lg={{ size: 6 }}>
                                              <ForumCarousel
                                                className="post-carousel"
                                                items={this.arrayToItems(
                                                  LatestPost.image
                                                )}
                                                interval={false}
                                              />
                                            </Col>
                                          ) : null}
                                          <Col className="text-center text-lg-left ">
                                            <p>{LatestPost.text}</p>
                                          </Col>
                                          <Col
                                            className="text-center text-lg-right"
                                            xs={{ size: 12 }}
                                            lg={{ size: 2 }}
                                          >
                                            <img
                                              style={{
                                                width: "100px",
                                                height: "100px",
                                              }}
                                              src={
                                                users &&
                                                users.find(
                                                  (us) =>
                                                    us.id == LatestPost.user_id
                                                )
                                                  ? users.find(
                                                      (us) =>
                                                        us.id ==
                                                        LatestPost.user_id
                                                    ).profile_pic
                                                  : null
                                              }
                                            ></img>
                                            <p>Posted By</p>
                                            {users &&
                                            users.find(
                                              (us) =>
                                                us.id == LatestPost.user_id
                                            ) ? (
                                              <p>
                                                {
                                                  users.find(
                                                    (us) =>
                                                      us.id ==
                                                      LatestPost.user_id
                                                  ).first_name
                                                }
                                                &nbsp;{" "}
                                                {
                                                  users.find(
                                                    (us) =>
                                                      us.id ==
                                                      LatestPost.user_id
                                                  ).last_name
                                                }
                                              </p>
                                            ) : null}

                                            <p>
                                              {" "}
                                              {moment(LatestPost.date).format(
                                                "MMM DD"
                                              )}
                                              &nbsp;
                                              {moment(LatestPost.date).format(
                                                "LT"
                                              )}
                                            </p>
                                          </Col>
                                          <Col
                                            className="mt-2 py-3"
                                            xs={{ size: 12 }}
                                            style={{
                                              background: "#dcdcdc",
                                              color: "#7e7e7e",
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              cursor: "pointer",
                                            }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              window.open(
                                                this.checkForUrl(
                                                  LatestPost.text
                                                ),
                                                "_blank"
                                              );
                                            }}
                                          >
                                            {this.checkForUrl(LatestPost.text)}
                                          </Col>
                                          <Col
                                            className="mt-2"
                                            lg={{ size: 12 }}
                                          >
                                            <p style={{ fontSize: "20px" }}>
                                              <Button
                                                size="sm"
                                                className={"active"}
                                                disabled={!authUser.uid}
                                                style={
                                                  LatestPost.vote.includes(
                                                    authUser.uid
                                                  )
                                                    ? {
                                                        cursor: "pointer",
                                                        background: "#C5E2E0",
                                                        border: "none",
                                                      }
                                                    : {
                                                        cursor: "pointer",
                                                        background:
                                                          "transparent",
                                                        border: "none",
                                                      }
                                                }
                                                color="light"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.props.upPost(
                                                    LatestPost.id,
                                                    authUser.uid
                                                  );
                                                }}
                                              >
                                                <i className="fas fa-thumbs-up text-success" />
                                              </Button>
                                              &nbsp; {LatestPost.vote.length}{" "}
                                              &nbsp;likes &nbsp;
                                              <Button
                                                size="sm"
                                                className={"active"}
                                                disabled={!authUser.uid}
                                                style={
                                                  LatestPost.downVote.includes(
                                                    authUser.uid
                                                  )
                                                    ? {
                                                        cursor: "pointer",
                                                        background: "#C5E2E0",
                                                        border: "none",
                                                      }
                                                    : {
                                                        cursor: "pointer",
                                                        background:
                                                          "transparent",
                                                        border: "none",
                                                      }
                                                }
                                                color="light"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.props.downPost(
                                                    LatestPost.id,
                                                    authUser.uid
                                                  );
                                                }}
                                              >
                                                <i className="fas fa-thumbs-down text-danger" />
                                              </Button>{" "}
                                              &nbsp;{" "}
                                              {LatestPost.downVote.length}{" "}
                                              &nbsp;dislikes &nbsp;
                                              <button
                                                className=" bg-transparent"
                                                style={{
                                                  border: "none",
                                                  fontSize: "20px",
                                                  // color: "black",
                                                  // padding: "5px",
                                                }}
                                                hidden={!authUser.uid}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  if (
                                                    this.state.showCommentField.includes(
                                                      LatestPost.id
                                                    )
                                                  ) {
                                                    let newShow =
                                                      this.state.showCommentField.filter(
                                                        (id) =>
                                                          id != LatestPost.id
                                                      );
                                                    this.setState({
                                                      showCommentField: newShow,
                                                    });
                                                  } else {
                                                    this.setState({
                                                      showCommentField: [
                                                        ...this.state
                                                          .showCommentField,
                                                        LatestPost.id,
                                                      ],
                                                    });
                                                  }
                                                }}
                                              >
                                                {this.state.showCommentField.includes(
                                                  LatestPost.id
                                                ) ? (
                                                  <>
                                                    <i className="fas fa-close" />
                                                    &nbsp; Comment
                                                  </>
                                                ) : (
                                                  "Comment"
                                                )}
                                              </button>
                                            </p>
                                            <button
                                              className="text-primary bg-transparent"
                                              style={{
                                                border: "none",
                                                fontSize: "12px",
                                              }}
                                              hidden={
                                                !LatestPost.comments.length
                                              }
                                              onClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  this.state.showComments.includes(
                                                    LatestPost.id
                                                  )
                                                ) {
                                                  let newShow =
                                                    this.state.showComments.filter(
                                                      (id) =>
                                                        id != LatestPost.id
                                                    );
                                                  this.setState({
                                                    showComments: newShow,
                                                  });
                                                } else {
                                                  this.setState({
                                                    showComments: [
                                                      ...this.state
                                                        .showComments,
                                                      LatestPost.id,
                                                    ],
                                                  });
                                                }
                                              }}
                                            >
                                              {this.state.showComments.includes(
                                                LatestPost.id
                                              )
                                                ? "Hide Comments"
                                                : "Show Comments"}{" "}
                                            </button>
                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  {this.state.showComments.includes(
                                    LatestPost.id
                                  )
                                    ? LatestPost.comments
                                        .sort((a, b) => {
                                          var lastTime1 = moment(a.date);
                                          var lastTime2 = moment(b.date);

                                          if (
                                            lastTime1.isBefore(lastTime2, "s")
                                          ) {
                                            return -1;
                                          } else {
                                            if (
                                              lastTime1.isAfter(lastTime2, "s")
                                            ) {
                                              return 1;
                                            } else {
                                              return 0;
                                            }
                                          }
                                        })
                                        .map((comment, index) => {
                                          return (
                                            <Col lg={{ size: 11, offset: 1 }}>
                                              <Card
                                                className={
                                                  comment.user_id ==
                                                  authUser.uid
                                                    ? ""
                                                    : ""
                                                }
                                              >
                                                <CardBody>
                                                  <Row>
                                                    <Col>
                                                      <p className="text-left">
                                                        {comment.text}
                                                      </p>
                                                      <p className="text-right">
                                                        {moment(
                                                          comment.date
                                                        ).format("MMM DD")}
                                                        &nbsp;
                                                        {moment(
                                                          comment.date
                                                        ).format("LT")}
                                                      </p>
                                                    </Col>
                                                    <Col lg={{ size: 12 }}>
                                                      <p
                                                        style={{
                                                          fontSize: "20px",
                                                        }}
                                                      >
                                                        <Button
                                                          size="sm"
                                                          disabled={
                                                            !authUser.uid
                                                          }
                                                          style={
                                                            comment.vote.includes(
                                                              authUser.uid
                                                            )
                                                              ? {
                                                                  cursor:
                                                                    "pointer",
                                                                  background:
                                                                    "#C5E2E0",
                                                                  border:
                                                                    "none",
                                                                }
                                                              : {
                                                                  cursor:
                                                                    "pointer",
                                                                  background:
                                                                    "transparent",
                                                                  border:
                                                                    "none",
                                                                }
                                                          }
                                                          className={"active"}
                                                          color="light"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.upComment(
                                                              {
                                                                index,
                                                                user_id:
                                                                  authUser.uid,
                                                              },
                                                              LatestPost.id
                                                            );
                                                          }}
                                                        >
                                                          <i className="fas fa-thumbs-up text-success" />
                                                        </Button>
                                                        &nbsp;{" "}
                                                        {comment.vote.length}
                                                        &nbsp;likes &nbsp;
                                                        <Button
                                                          size="sm"
                                                          className={"active"}
                                                          disabled={
                                                            !authUser.uid
                                                          }
                                                          style={
                                                            comment.downVote.includes(
                                                              authUser.uid
                                                            )
                                                              ? {
                                                                  cursor:
                                                                    "pointer",
                                                                  background:
                                                                    "#C5E2E0",
                                                                  border:
                                                                    "none",
                                                                }
                                                              : {
                                                                  cursor:
                                                                    "pointer",
                                                                  background:
                                                                    "transparent",
                                                                  border:
                                                                    "none",
                                                                }
                                                          }
                                                          color="light"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.downComment(
                                                              {
                                                                index,
                                                                user_id:
                                                                  authUser.uid,
                                                              },
                                                              LatestPost.id
                                                            );
                                                          }}
                                                        >
                                                          <i className="fas fa-thumbs-down text-danger" />
                                                        </Button>{" "}
                                                        &nbsp;{" "}
                                                        {
                                                          comment.downVote
                                                            .length
                                                        }
                                                        &nbsp;dislikes &nbsp;
                                                        <Button
                                                          color="danger"
                                                          className="ml-3"
                                                          hidden={
                                                            comment.user_id !=
                                                            authUser.uid
                                                          }
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            var confirm =
                                                              window.confirm(
                                                                "Are you sure you want to delete this comment?"
                                                              );
                                                            if (confirm) {
                                                              this.props.deleteComment(
                                                                index,
                                                                LatestPost.id
                                                              );
                                                            }
                                                          }}
                                                          size="sm"
                                                        >
                                                          <i className="fas fa-trash" />
                                                        </Button>
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                </CardBody>
                                              </Card>
                                            </Col>
                                          );
                                        })
                                    : null}
                                </Row>

                                <Row
                                  className="mt-2"
                                  hidden={
                                    !authUser.uid ||
                                    !this.state.showCommentField.includes(
                                      LatestPost.id
                                    )
                                  }
                                >
                                  <Col xs={{ size: 12 }}>
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        if (e.target.comment.value) {
                                          this.props.comment(
                                            {
                                              text: e.target.comment.value,
                                              user_id: authUser.uid,
                                            },
                                            LatestPost.id
                                          );
                                          e.target.comment.value = "";
                                          this.setState({
                                            showComments: [
                                              ...this.state.showComments,
                                              LatestPost.id,
                                            ],
                                          });
                                        } else {
                                          alert("Cannot send empty Comment");
                                        }
                                      }}
                                    >
                                      <Input
                                        type="textarea"
                                        name="comment"
                                        style={{ border: "1px solid black" }}
                                      />
                                      <Row className="mt-2">
                                        <Col className="text-right">
                                          <Button color="info" size="sm">
                                            Comment
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </Col>
                                </Row>
                              </>
                            );
                          }
                          return (
                            <>
                              <Row>
                                <Col lg={{ size: 12 }}>
                                  <Card className="mt-2">
                                    <CardBody>
                                      <Row>
                                        {LatestPost.image != null &&
                                        Array.isArray(LatestPost.image) ? (
                                          <Col lg={{ size: 6 }}>
                                            <ForumCarousel
                                              className="post-carousel"
                                              items={this.arrayToItems(
                                                LatestPost.image
                                              )}
                                              interval={false}
                                            />
                                          </Col>
                                        ) : null}
                                        <Col className="text-center text-lg-left ">
                                          <p>{LatestPost.text}</p>
                                        </Col>
                                        <Col
                                          className="text-center text-lg-right"
                                          xs={{ size: 12 }}
                                          lg={{ size: 2 }}
                                        >
                                          <img
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                            }}
                                            src={
                                              users &&
                                              users.find(
                                                (us) =>
                                                  us.id == LatestPost.user_id
                                              )
                                                ? users.find(
                                                    (us) =>
                                                      us.id ==
                                                      LatestPost.user_id
                                                  ).profile_pic
                                                : null
                                            }
                                          ></img>
                                          <p>Posted By</p>
                                          {users &&
                                          users.find(
                                            (us) => us.id == LatestPost.user_id
                                          ) ? (
                                            <p>
                                              {
                                                users.find(
                                                  (us) =>
                                                    us.id == LatestPost.user_id
                                                ).first_name
                                              }
                                              &nbsp;{" "}
                                              {
                                                users.find(
                                                  (us) =>
                                                    us.id == LatestPost.user_id
                                                ).last_name
                                              }
                                            </p>
                                          ) : null}

                                          <p>
                                            {" "}
                                            {moment(LatestPost.date).format(
                                              "MMM DD"
                                            )}
                                            &nbsp;
                                            {moment(LatestPost.date).format(
                                              "LT"
                                            )}
                                          </p>
                                        </Col>
                                        <Col className="mt-2" lg={{ size: 12 }}>
                                          <p style={{ fontSize: "20px" }}>
                                            <Button
                                              size="sm"
                                              className={"active"}
                                              disabled={!authUser.uid}
                                              style={
                                                LatestPost.vote.includes(
                                                  authUser.uid
                                                )
                                                  ? {
                                                      cursor: "pointer",
                                                      background: "#C5E2E0",
                                                      border: "none",
                                                    }
                                                  : {
                                                      cursor: "pointer",
                                                      background: "transparent",
                                                      border: "none",
                                                    }
                                              }
                                              color="light"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.props.upPost(
                                                  LatestPost.id,
                                                  authUser.uid
                                                );
                                              }}
                                            >
                                              <i className="fas fa-thumbs-up text-success" />
                                            </Button>
                                            &nbsp; {LatestPost.vote.length}{" "}
                                            &nbsp;likes &nbsp;
                                            <Button
                                              size="sm"
                                              className={"active"}
                                              disabled={!authUser.uid}
                                              style={
                                                LatestPost.downVote.includes(
                                                  authUser.uid
                                                )
                                                  ? {
                                                      cursor: "pointer",
                                                      background: "#C5E2E0",
                                                      border: "none",
                                                    }
                                                  : {
                                                      cursor: "pointer",
                                                      background: "transparent",
                                                      border: "none",
                                                    }
                                              }
                                              color="light"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.props.downPost(
                                                  LatestPost.id,
                                                  authUser.uid
                                                );
                                              }}
                                            >
                                              <i className="fas fa-thumbs-down text-danger" />
                                            </Button>{" "}
                                            &nbsp; {LatestPost.downVote.length}{" "}
                                            &nbsp;dislikes &nbsp;
                                            <button
                                              className=" bg-transparent"
                                              style={{
                                                border: "none",
                                                fontSize: "20px",
                                                // color: "black",
                                                // padding: "5px",
                                              }}
                                              hidden={!authUser.uid}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  this.state.showCommentField.includes(
                                                    LatestPost.id
                                                  )
                                                ) {
                                                  let newShow =
                                                    this.state.showCommentField.filter(
                                                      (id) =>
                                                        id != LatestPost.id
                                                    );
                                                  this.setState({
                                                    showCommentField: newShow,
                                                  });
                                                } else {
                                                  this.setState({
                                                    showCommentField: [
                                                      ...this.state
                                                        .showCommentField,
                                                      LatestPost.id,
                                                    ],
                                                  });
                                                }
                                              }}
                                            >
                                              {this.state.showCommentField.includes(
                                                LatestPost.id
                                              ) ? (
                                                <>
                                                  <i className="fas fa-close" />
                                                  &nbsp; Comment
                                                </>
                                              ) : (
                                                "Comment"
                                              )}
                                            </button>
                                          </p>
                                          <button
                                            className="text-primary bg-transparent"
                                            style={{
                                              border: "none",
                                              fontSize: "12px",
                                            }}
                                            hidden={!LatestPost.comments.length}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (
                                                this.state.showComments.includes(
                                                  LatestPost.id
                                                )
                                              ) {
                                                let newShow =
                                                  this.state.showComments.filter(
                                                    (id) => id != LatestPost.id
                                                  );
                                                this.setState({
                                                  showComments: newShow,
                                                });
                                              } else {
                                                this.setState({
                                                  showComments: [
                                                    ...this.state.showComments,
                                                    LatestPost.id,
                                                  ],
                                                });
                                              }
                                            }}
                                          >
                                            {this.state.showComments.includes(
                                              LatestPost.id
                                            )
                                              ? "Hide Comments"
                                              : "Show Comments"}{" "}
                                          </button>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                                {this.state.showComments.includes(LatestPost.id)
                                  ? LatestPost.comments
                                      .sort((a, b) => {
                                        var lastTime1 = moment(a.date);
                                        var lastTime2 = moment(b.date);

                                        if (
                                          lastTime1.isBefore(lastTime2, "s")
                                        ) {
                                          return -1;
                                        } else {
                                          if (
                                            lastTime1.isAfter(lastTime2, "s")
                                          ) {
                                            return 1;
                                          } else {
                                            return 0;
                                          }
                                        }
                                      })
                                      .map((comment, index) => {
                                        return (
                                          <Col lg={{ size: 11, offset: 1 }}>
                                            <Card
                                              className={
                                                comment.user_id == authUser.uid
                                                  ? ""
                                                  : ""
                                              }
                                            >
                                              <CardBody>
                                                <Row>
                                                  <Col>
                                                    <p className="text-left">
                                                      {comment.text}
                                                    </p>
                                                    <p className="text-right">
                                                      {moment(
                                                        comment.date
                                                      ).format("MMM DD")}
                                                      &nbsp;
                                                      {moment(
                                                        comment.date
                                                      ).format("LT")}
                                                    </p>
                                                  </Col>
                                                  <Col lg={{ size: 12 }}>
                                                    <p
                                                      style={{
                                                        fontSize: "20px",
                                                      }}
                                                    >
                                                      <Button
                                                        size="sm"
                                                        disabled={!authUser.uid}
                                                        style={
                                                          comment.vote.includes(
                                                            authUser.uid
                                                          )
                                                            ? {
                                                                cursor:
                                                                  "pointer",
                                                                background:
                                                                  "#C5E2E0",
                                                                border: "none",
                                                              }
                                                            : {
                                                                cursor:
                                                                  "pointer",
                                                                background:
                                                                  "transparent",
                                                                border: "none",
                                                              }
                                                        }
                                                        className={"active"}
                                                        color="light"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          this.props.upComment(
                                                            {
                                                              index,
                                                              user_id:
                                                                authUser.uid,
                                                            },
                                                            LatestPost.id
                                                          );
                                                        }}
                                                      >
                                                        <i className="fas fa-thumbs-up text-success" />
                                                      </Button>
                                                      &nbsp;{" "}
                                                      {comment.vote.length}
                                                      &nbsp;likes &nbsp;
                                                      <Button
                                                        size="sm"
                                                        className={"active"}
                                                        disabled={!authUser.uid}
                                                        style={
                                                          comment.downVote.includes(
                                                            authUser.uid
                                                          )
                                                            ? {
                                                                cursor:
                                                                  "pointer",
                                                                background:
                                                                  "#C5E2E0",
                                                                border: "none",
                                                              }
                                                            : {
                                                                cursor:
                                                                  "pointer",
                                                                background:
                                                                  "transparent",
                                                                border: "none",
                                                              }
                                                        }
                                                        color="light"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          this.props.downComment(
                                                            {
                                                              index,
                                                              user_id:
                                                                authUser.uid,
                                                            },
                                                            LatestPost.id
                                                          );
                                                        }}
                                                      >
                                                        <i className="fas fa-thumbs-down text-danger" />
                                                      </Button>{" "}
                                                      &nbsp;{" "}
                                                      {comment.downVote.length}
                                                      &nbsp;dislikes &nbsp;
                                                      <Button
                                                        color="danger"
                                                        className="ml-3"
                                                        hidden={
                                                          comment.user_id !=
                                                          authUser.uid
                                                        }
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          var confirm =
                                                            window.confirm(
                                                              "Are you sure you want to delete this comment?"
                                                            );
                                                          if (confirm) {
                                                            this.props.deleteComment(
                                                              index,
                                                              LatestPost.id
                                                            );
                                                          }
                                                        }}
                                                        size="sm"
                                                      >
                                                        <i className="fas fa-trash" />
                                                      </Button>
                                                    </p>
                                                  </Col>
                                                </Row>
                                              </CardBody>
                                            </Card>
                                          </Col>
                                        );
                                      })
                                  : null}
                              </Row>

                              <Row
                                className="mt-2"
                                hidden={
                                  !authUser.uid ||
                                  !this.state.showCommentField.includes(
                                    LatestPost.id
                                  )
                                }
                              >
                                <Col xs={{ size: 12 }}>
                                  <Form
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      if (e.target.comment.value) {
                                        this.props.comment(
                                          {
                                            text: e.target.comment.value,
                                            user_id: authUser.uid,
                                          },
                                          LatestPost.id
                                        );
                                        e.target.comment.value = "";
                                        this.setState({
                                          showComments: [
                                            ...this.state.showComments,
                                            LatestPost.id,
                                          ],
                                        });
                                      } else {
                                        alert("Cannot send empty Comment");
                                      }
                                    }}
                                  >
                                    <Input
                                      type="textarea"
                                      name="comment"
                                      style={{ border: "1px solid black" }}
                                    />
                                    <Row className="mt-2">
                                      <Col className="text-right">
                                        <Button color="info" size="sm">
                                          Comment
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Form>
                                </Col>
                              </Row>
                            </>
                          );
                        })
                    : null}
                </Col>
                <Col lg={{ size: 3 }}>
                  <h3 className="text-center mt-3">Suggested Post</h3>
                  {posts &&
                    posts.slice(0, 3).map((post) => {
                      if (post && post.text && this.checkForUrl(post.text)) {
                        return (
                          <>
                            <Card>
                              <CardHeader className="px-2 py-2">
                                <Row>
                                  <Col xs="4">
                                    <div className="p-1 suggestedpost-user-img">
                                      <img
                                        className="w-100 h-100"
                                        style={{
                                          borderRadius: "50%",
                                          border: "2px solid #3F8A0F",
                                        }}
                                        src={
                                          users &&
                                          users.find(
                                            (us) => us.id == post.user_id
                                          )
                                            ? users.find(
                                                (us) => us.id == post.user_id
                                              ).profile_pic
                                            : null
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col
                                    className="d-flex p-0"
                                    style={{ alignItems: "center" }}
                                  >
                                    <p className="mb-1">
                                      {users &&
                                      users.find((us) => us.id == post.user_id)
                                        ? users.find(
                                            (us) => us.id == post.user_id
                                          ).first_name +
                                          " " +
                                          users.find(
                                            (us) => us.id == post.user_id
                                          ).last_name
                                        : null}
                                    </p>
                                  </Col>
                                </Row>
                              </CardHeader>
                              <Link
                                className="text-decoration-none text-dark"
                                to={"/forum/" + post.id}
                              >
                                <CardBody className="py-3 px-3">
                                  <p className="text-left suggestedpost-text">
                                    {post.text.length > 100
                                      ? post.text.substring(0, 100) + "..."
                                      : post.text}
                                  </p>
                                </CardBody>
                                <CardImg
                                  className="p-2"
                                  style={{
                                    width: "100%",
                                    height: "175px",
                                    borderBottom: "0.5px solid #d1d1d1",
                                    borderTop: "0.5px solid #d1d1d1",
                                  }}
                                  src={post.image || logo}
                                  alt="suggested"
                                />
                              </Link>
                              <CardFooter
                                className="mt-2 py-3"
                                style={{
                                  background: "#dcdcdc",
                                  color: "#7e7e7e",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open(
                                    this.checkForUrl(post.text),
                                    "_blank"
                                  );
                                }}
                              >
                                {this.checkForUrl(post.text)}
                              </CardFooter>
                            </Card>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <Card>
                              <CardHeader className="px-2 py-2">
                                <Row>
                                  <Col xs="4">
                                    <div className="p-1 suggestedpost-user-img">
                                      <img
                                        className="w-100 h-100"
                                        style={{
                                          borderRadius: "50%",
                                          border: "2px solid #3F8A0F",
                                        }}
                                        src={
                                          users &&
                                          users.find(
                                            (us) => us.id == post.user_id
                                          )
                                            ? users.find(
                                                (us) => us.id == post.user_id
                                              ).profile_pic
                                            : null
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col
                                    className="d-flex p-0"
                                    style={{ alignItems: "center" }}
                                  >
                                    <p className="mb-1">
                                      {users &&
                                      users.find((us) => us.id == post.user_id)
                                        ? users.find(
                                            (us) => us.id == post.user_id
                                          ).first_name +
                                          " " +
                                          users.find(
                                            (us) => us.id == post.user_id
                                          ).last_name
                                        : null}
                                    </p>
                                  </Col>
                                </Row>
                              </CardHeader>
                              <Link
                                className="text-decoration-none text-dark"
                                to={"/forum/" + post.id}
                              >
                                <CardBody className="py-3 px-3">
                                  <p className="text-left suggestedpost-text">
                                    {post.text.length > 100
                                      ? post.text.substring(0, 100) + "..."
                                      : post.text}
                                  </p>
                                </CardBody>
                                <CardImg
                                  className="p-2"
                                  style={{
                                    width: "100%",
                                    height: "175px",
                                    borderBottom: "0.5px solid #d1d1d1",
                                    borderTop: "0.5px solid #d1d1d1",
                                  }}
                                  src={post.image || logo}
                                  alt="suggested"
                                />
                              </Link>
                            </Card>
                          </>
                        );
                      }
                    })}
                </Col>
              </Row>
            </section>
          </main>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    loading: state.auth.requested,
    registered: state.auth.registered,
    auth: state.auth,
    posts: state.post.posts,
    users: state.users.users,
    authUser: state.authUser,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    // registeredComplete: () => dispatch(removeRegistered()),
    getUser: (uid) => dispatch(getUser(uid)),
    getAllUsers: () => dispatch(getAllUsers()),
    getPosts: () => dispatch(getPosts()),
    deleteComment: (index, post_id) => dispatch(deleteComment(index, post_id)),
    createPost: (post, img) => dispatch(createPost(post, img)),
    comment: (com, post) => dispatch(Comment(com, post)),
    upPost: (post_id, uid) => dispatch(upPost(post_id, uid)),
    upComment: (com, post_id) => dispatch(upComment(com, post_id)),
    downPost: (post_id, uid) => dispatch(downPost(post_id, uid)),
    downComment: (com, post_id) => dispatch(downComment(com, post_id)),
    // personelInfo: (Info) => dispatch(personelInfo(Info)),
    // personelInfoPhone: (Info) => dispatch(personelInfoPhone(Info)),
    // loginGoogle: () => dispatch(signupGoogle()),
    // loginFacebook: () => dispatch(signupFacebook()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Forum);
